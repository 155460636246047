import { netToGross } from "@secureo/common/utils/tax"

import {
	CylinderConfigurationVariant,
	CylinderLockingMechanismProduct,
	CylinderLockingMechanism,
	SafeLockConfigurationProduct,
} from "@secureo/common/typings/Product"
import { VariantsByVariantId } from "./getVariantsByVariantId"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import {
	ProductConfiguration,
	ProductConfigurationsByConfigurationGroup,
	PricePortions,
	PDPDeliveryOption,
} from ".."

const getProductSingleNetPriceCents = (
	variantsByVariantId: VariantsByVariantId,
	variantId: number,
	cylinderConfigurationVariant: CylinderConfigurationVariant,
	countryCode: CountryCode,
) => {
	if (cylinderConfigurationVariant) {
		const productSingleNetPriceCents =
			cylinderConfigurationVariant?.masterVariant?.pricesByCountryCode?.[countryCode] ?? 0
		return productSingleNetPriceCents
	}

	const productSingleNetPriceCents =
		variantsByVariantId?.[variantId]?.pricesByCountryCode?.[countryCode] ?? 0
	return productSingleNetPriceCents
}

const getTotalConfigurationsPriceCents = (
	configurationsByConfigurationGroup: ProductConfigurationsByConfigurationGroup,
	taxRate: number,
) => {
	const configurations = Object.values(configurationsByConfigurationGroup).reduce(
		(configurations, configurationGroup) => {
			const configurationGroupConfigurations = Object.values(configurationGroup)
			return [...configurations, ...configurationGroupConfigurations]
		},
		[] as ProductConfiguration[],
	)

	const totalConfigurationsPriceCents = configurations.reduce(
		(
			{
				totalConfigurationsNetPriceCentsPerProductQuantity,
				totalConfigurationsGrossPriceCentsPerProductQuantity,
			},
			configuration,
		) => {
			const { singleNetPriceCents, quantity } = configuration

			const singleGrossPriceCents = Math.round(netToGross(singleNetPriceCents, taxRate))

			const totalNetPriceCents = singleNetPriceCents * quantity
			const totalGrossPriceCents = singleGrossPriceCents * quantity

			return {
				totalConfigurationsNetPriceCentsPerProductQuantity:
					totalConfigurationsNetPriceCentsPerProductQuantity + totalNetPriceCents,
				totalConfigurationsGrossPriceCentsPerProductQuantity:
					totalConfigurationsGrossPriceCentsPerProductQuantity + totalGrossPriceCents,
			}
		},
		{
			totalConfigurationsNetPriceCentsPerProductQuantity: 0,
			totalConfigurationsGrossPriceCentsPerProductQuantity: 0,
		},
	)

	return totalConfigurationsPriceCents
}

const getPricePortions = (
	variantsByVariantId: VariantsByVariantId,
	variantId: number,
	taxRate: number,
	quantity: number,
	countryCode: CountryCode,
	configurationsByConfigurationGroup: ProductConfigurationsByConfigurationGroup,
	cylinderConfigurationVariant: CylinderConfigurationVariant,
	cylinderLockingMechanismProducts?: {
		single: CylinderLockingMechanismProduct
		simultaneous: CylinderLockingMechanismProduct
	},
	selectedCylinderLockingMechanism?: {
		mechanism: CylinderLockingMechanism
		productId: string
		variantId: number
	},
	safeLockConfigurationProduct?: SafeLockConfigurationProduct,
	selectedDeliveryOption?: PDPDeliveryOption,
) => {
	const productSingleNetPriceCents = getProductSingleNetPriceCents(
		variantsByVariantId,
		variantId,
		cylinderConfigurationVariant,
		countryCode,
	)
	const productSingleGrossPriceCents = Math.round(netToGross(productSingleNetPriceCents, taxRate))

	const cylinderLockingMechanismProduct =
		cylinderLockingMechanismProducts?.[selectedCylinderLockingMechanism?.mechanism] ?? null

	const {
		totalConfigurationsNetPriceCentsPerProductQuantity,
		totalConfigurationsGrossPriceCentsPerProductQuantity,
	} = getTotalConfigurationsPriceCents(configurationsByConfigurationGroup, taxRate)

	const cylinderLockingMechanismProductSingleNetPriceCents =
		cylinderLockingMechanismProduct?.netPriceCents ?? 0
	const cylinderLockingMechanismProductSingleGrossPriceCents = Math.round(
		netToGross(cylinderLockingMechanismProductSingleNetPriceCents, taxRate),
	)

	const safeLockConfigurationProductSingleNetPriceCents =
		safeLockConfigurationProduct?.pricesByCountryCode?.[countryCode] ?? 0
	const safeLockConfigurationProductSingleGrossPriceCents = Math.round(
		netToGross(safeLockConfigurationProductSingleNetPriceCents, taxRate),
	)

	const deliveryOptionSingleNetPriceCents = selectedDeliveryOption?.singleNetPriceCents ?? 0
	const deliveryOptionSingleGrossPriceCents = Math.round(
		netToGross(deliveryOptionSingleNetPriceCents, taxRate),
	)

	const pricePortions: PricePortions = {
		productSingleNetPriceCents,
		productSingleGrossPriceCents,
		cylinderLockingMechanismProductSingleNetPriceCents,
		cylinderLockingMechanismProductSingleGrossPriceCents,
		totalConfigurationsNetPriceCentsPerProductQuantity,
		totalConfigurationsGrossPriceCentsPerProductQuantity,
		safeLockConfigurationProductSingleNetPriceCents,
		safeLockConfigurationProductSingleGrossPriceCents,
		deliveryOptionSingleNetPriceCents,
		deliveryOptionSingleGrossPriceCents,
	}

	const singleNetPriceCents =
		productSingleNetPriceCents +
		totalConfigurationsNetPriceCentsPerProductQuantity +
		cylinderLockingMechanismProductSingleNetPriceCents +
		safeLockConfigurationProductSingleNetPriceCents +
		deliveryOptionSingleNetPriceCents
	const singleGrossPriceCents =
		productSingleGrossPriceCents +
		totalConfigurationsGrossPriceCentsPerProductQuantity +
		cylinderLockingMechanismProductSingleGrossPriceCents +
		safeLockConfigurationProductSingleGrossPriceCents +
		deliveryOptionSingleGrossPriceCents

	const totalNetPriceCents = singleNetPriceCents * quantity
	const totalGrossPriceCents = singleGrossPriceCents * quantity

	return {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
	}
}

export default getPricePortions
