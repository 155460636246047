import { useContext } from "react"
import {
	IconButton,
	DialogContent,
	Box,
	Button,
	DialogTitle,
	Dialog,
	useMediaQuery,
} from "@mui/material"
import { Theme, useTheme } from "@mui/material/styles"
import Icon from "@mdi/react"
import { mdiMenu, mdiClose, mdiBasket, mdiCardAccountDetailsOutline } from "@mdi/js"

import AppContext from "@secureo/common/context/AppContext/AppContext"

import Link from "../i18n/Link"
import MobileMenuList from "./MobileMenuList"
import MobileChangeLocaleAndCurrency from "./MobileChangeLocaleAndCurrency"

import useToggle from "@secureo/common/hooks/useToggle"
import useTranslation from "hooks/useTranslation"

import { makeStyles } from "makeStyles"
import LogoDark from "../../svg/logo/SafeHero-dark.svg"

const useStyles = makeStyles()((theme: Theme) => ({
	sectionMobile: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
}))

const MobileMenu = () => {
	const [isOpen, toggleIsOpen] = useToggle()
	const { t } = useTranslation("common")
	const { isBusinessVersion, dispatch } = useContext(AppContext)

	const { classes } = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("lg"))

	const toggleIsBusinessVersion = () => {
		isBusinessVersion
			? dispatch({ type: "SET_DEFAULT_VERSION" })
			: dispatch({ type: "SET_BUSINESS_VERSION" })
	}

	return (
		<span className={classes.sectionMobile}>
			<IconButton
				aria-label="show more"
				onClick={toggleIsOpen}
				aria-haspopup="true"
				color="inherit"
				size="large"
			>
				<Icon
					path={mdiMenu}
					title="Menu"
					size={1.3}
					color={!isBusinessVersion ? "#ffffff" : "#274041"}
				/>
			</IconButton>
			<Dialog
				open={isOpen}
				onClose={toggleIsOpen}
				fullScreen={fullScreen}
				// scroll={scroll}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<Button onClick={toggleIsOpen}>
						<Icon path={mdiClose} title="Close Menu" size={1.3} />
					</Button>
				</DialogTitle>
				<div
					style={{
						position: "absolute",
						top: "35px",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}
				>
					<Link href="/" onClick={toggleIsOpen}>
						<LogoDark
							style={{
								height: "32px",
								paddingTop: "15px",
								objectFit: "contain",
								position: "relative",
							}}
						/>
					</Link>
				</div>
				<DialogContent style={{ margin: 0, padding: 0 }}>
					<MobileMenuList toggleIsOpen={toggleIsOpen} />
				</DialogContent>
				<div
					style={{
						position: "absolute",
						width: "100%",
						height: "50px",
						bottom: 0,
						zIndex: 0,
						boxShadow: "0px -1px 5px -1px rgba(0,0,0,0.15)",
						paddingBottom: "10px",
						background: "#7abfac",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-evenly",
						}}
					>
						<Button
							onClick={toggleIsBusinessVersion}
							style={{
								width: "55%",
							}}
						>
							<Box ml={1} onClick={toggleIsOpen}>
								<Icon
									path={
										isBusinessVersion ? mdiBasket : mdiCardAccountDetailsOutline
									}
									title={
										isBusinessVersion
											? t("privateCustomers")
											: t("businessCustomers")
									}
									size={0.9}
									color="white"
									style={{ marginRight: "5px" }}
								/>
							</Box>
							{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
							<div
								style={{ color: "white" }}
								onClick={toggleIsOpen}
								role="button"
								tabIndex={0}
							>
								{isBusinessVersion ? t("privateCustomers") : t("businessCustomers")}
							</div>
						</Button>
						<div
							style={{
								width: "45%",
								borderLeft: "1px solid lightgrey",
								background: "whitesmoke",
							}}
						>
							<MobileChangeLocaleAndCurrency />
						</div>
					</div>
				</div>
			</Dialog>
		</span>
	)
}

export default MobileMenu
