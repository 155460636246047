import { createTheme } from "@mui/material/styles"

const theme = createTheme({
	spacing: 8,
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: "#7abfac",
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			// light: will be calculated from palette.primary.main,
			main: "#E7EEF0",
			dark: "#274041",
			contrastText: "#274041",
		},
		// error: will use the default color
	},

	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					size: "medium",
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					dense: true,
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					dense: true,
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					dense: true,
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					size: "small",
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					dense: true,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					dense: true,
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					dense: true,
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					size: "medium",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					dense: true,
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					dense: true,
				},
			},
		},
		// If we want to disable the Ripples -> enable below
		// MuiButtonBase: {
		//   disableRipple: true 💣!
		// }
	},
	// overrides: {
	// 	MuiIconButton: {
	// 		sizeSmall: {
	// 			// Adjust spacing to reach minimal touch target hitbox
	// 			marginLeft: 4,
	// 			marginRight: 4,
	// 			padding: 12,
	// 		},
	// 	},
	// 	MuiInputLabel: {
	// 		outlined: {
	// 			backgroundColor: "#fff",
	// 			paddingLeft: 2,
	// 			paddingRight: 5,
	// 		},
	// 	},
	// 	MuiMenuItem: {
	// 		root: {
	// 			color: "#274041",
	// 		},
	// 	},
	// 	MuiDialogContent: {
	// 		root: {
	// 			color: "#274041",
	// 		},
	// 	},
	// 	MuiDialogTitle: {
	// 		root: {
	// 			color: "#274041",
	// 		},
	// 	},
	// },
})

export default theme
