import supportsLocalStorage from "../detection/supportsLocalStorage"

const authTokenLocalStorageKey = `authToken-${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}`

export interface AuthToken {
	accessToken: string
	refreshToken: string
	expiryDateMs: number
	isCustomerToken?: boolean
}

export const saveAuthTokenToLocalStorage = (authToken: AuthToken, isCustomerToken = false) => {
	if (supportsLocalStorage())
		localStorage.setItem(
			authTokenLocalStorageKey,
			JSON.stringify({ ...authToken, isCustomerToken }),
		)
}

export const restoreAuthTokenFromLocalStorage = () => {
	if (!supportsLocalStorage()) return null

	const authToken = localStorage.getItem(authTokenLocalStorageKey)

	if (!authToken) return null
	return JSON.parse(authToken) as AuthToken
}

export const removeAuthTokenFromLocalStorage = () => {
	if (supportsLocalStorage()) localStorage.removeItem(authTokenLocalStorageKey)
}
