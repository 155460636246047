// https://github.com/zeit/next.js/issues/8241
import { useContext } from "react"
import NextLink from "next/link"
import { makeStyles } from "makeStyles"

import AppContext, { AppContextState } from "@secureo/common/context/AppContext/AppContext"

const useStyles = makeStyles()(() => ({
	link: {
		textDecoration: "none",
		color: "inherit",
	},
}))

/**
 * @param href - Filesystem path in /pages (With leading slash, without "/[locale]")
 * @param pageType - Used for localization of path. If no pageType is passed, localization will be same as omitted)
 */

const Link = ({
	href,
	style = {},
	className = "",
	children,
	target = "_self",
	prefetch = true,
	...props
}: Props) => {
	const { classes } = useStyles()
	const { isBusinessVersion } = useContext(AppContext) as AppContextState

	const queryParams = isBusinessVersion ? "?pro=true" : ""

	const localizedHref = `${href}${queryParams}`

	if (!prefetch) (props as any).prefetch = false

	return (
		<NextLink
			{...props}
			style={style}
			className={classes.link + ` ${className}`}
			target={target}
			href={localizedHref}
		>
			{children}
		</NextLink>
	)
}

interface Props {
	href: string // Path on filesystem in repository
	children: React.ReactNode
	style?: React.CSSProperties
	className?: string
	onClick?: any
	target?: "_self" | "_blank"
	prefetch?: true | false
}

export default Link
