import { NextSeoProps } from "next-seo"
import { OpenGraph } from "next-seo/lib/types"
import { parseUrlParts } from "parse-url-parts"

import getValueFromLocalizedAttribute from "../i18n/getValueFromLocalizedAttribute"
import getSEOConfig, { SEOPageType } from "./config"

import getFallback from "./pages/fallback"
import { supportedValidLocales } from "../i18n/supportedLocalesAndCurrencyCodes"

import { LocalizedString } from "../../typings/Localization"
import { CurrencyCode } from "@secureo/common/typings/Forex"

const getLocalizedPageSEOAttribute = (
	localizedAttribute: LocalizedString,
	language: string,
	fallbackAttributes: LocalizedString[] = [], // You can provide multiple fallbacks
) => {
	const preferredLocalizedAttribute =
		localizedAttribute &&
		(getValueFromLocalizedAttribute(localizedAttribute, language) as string)

	return (
		preferredLocalizedAttribute ||
		(fallbackAttributes.reduce(
			(attribute, localizedFallbackAttribute) =>
				attribute ||
				(localizedFallbackAttribute &&
					getValueFromLocalizedAttribute(localizedFallbackAttribute, language)),
			null,
		) as string)
	)
}

const getOpenGraphLocaleFromLanguage = (language: string) => {
	switch (language) {
		case "de":
			return "de_DE"
		default:
			return "de_DE"
	}
}

const getLanguageAlternatesMetaTags = (canonical: string, asPath: string) => {
	const disableHrefLangMetaTags = asPath.includes("page=")
	if (disableHrefLangMetaTags) return []

	const regex = /\/(?<locale>\w{2}-\w{2})(?<rest>.*)/
	try {
		const { protocol, host, path = "" } = parseUrlParts(canonical)
		if (!regex.test(path)) return []
		const { rest } = regex.exec(path).groups

		const languageAlternates = supportedValidLocales.reduce(
			(languageAlternates, locale) => {
				return [
					...languageAlternates,
					{ hrefLang: locale, href: `${protocol}://${host}/${locale}${rest}` },
				]
			},
			[{ hrefLang: "de", href: `${protocol}://${host}/de-DE${rest}` }],
		)

		return languageAlternates
	} catch (err) {
		console.error("Failed to create languageAlternates from: ", canonical)
		console.error(err)
		return []
	}
}

const NEXT_PUBLIC_HOST =
	process.env.NEXT_PUBLIC_HOST ||
	(process.env.NEXT_PUBLIC_VERCEL_URL
		? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
		: "http://localhost:3000")

const prependHostToPath = (path: string, locale: string) => `${NEXT_PUBLIC_HOST}/${locale}${path}`

const getNextSEOProps = (
	language: string,
	locale: string,
	currencyCode: CurrencyCode,
	path = "",
	pageType?: SEOPageType,
	metaTitle?: string,
	metaDescription?: string,
	openGraphProps?: OpenGraph,
): NextSeoProps => {
	const seoConfig = getSEOConfig(locale, currencyCode, pageType)
	const canonical = prependHostToPath(path.split("?")[0], locale)
	const languageAlternates = getLanguageAlternatesMetaTags(canonical, path)

	const fallback = getFallback(locale, currencyCode)

	const title =
		metaTitle || getLocalizedPageSEOAttribute(seoConfig.title, language, [fallback.title])
	const description =
		metaDescription ||
		getLocalizedPageSEOAttribute(seoConfig.description, language, [fallback.description])

	// OpenGraph Tags
	const openGraph = {
		url: canonical,
		title: title || getLocalizedPageSEOAttribute(seoConfig.openGraph.title, language),
		description:
			description || getLocalizedPageSEOAttribute(seoConfig.openGraph.description, language),
		images: openGraphProps?.images || seoConfig.openGraph.images || fallback.openGraph.images,
		site_name: fallback.openGraph.site_name,
		locale: getOpenGraphLocaleFromLanguage(language),
	}

	// Twitter Cards
	const twitter = {
		site: fallback.twitter.site,
		cardType: seoConfig.twitter.cardType || fallback.twitter.cardType,
	}

	const nextSEOProps: NextSeoProps = {
		title,
		description,
		canonical,
		languageAlternates,
		openGraph,
		twitter,
	}

	return nextSEOProps
}

export default getNextSEOProps
