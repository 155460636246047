import { ProductDetailPageReducerState } from ".."

const getAdditionalDeliveryTimeWorkDays = (
	reducerState: Pick<
		ProductDetailPageReducerState,
		"configurationsByConfigurationGroup" | "selectedDeliveryOption"
	>,
) => {
	const { configurationsByConfigurationGroup, selectedDeliveryOption } = reducerState

	const additionalConfigurationsDeliveryDays = Object.values(
		configurationsByConfigurationGroup,
	).reduce((additionalConfigurationsDeliveryDays, configurationGroup) => {
		const configurationGroupDeliveryDays = Object.values(configurationGroup).reduce(
			(configurationGroupDeliveryDays, configurationProduct) => {
				return (
					configurationGroupDeliveryDays +
					(configurationProduct?.deliveryTimeWorkDays ?? 0)
				)
			},
			0,
		)

		return additionalConfigurationsDeliveryDays + configurationGroupDeliveryDays
	}, 0)

	return (
		additionalConfigurationsDeliveryDays + (selectedDeliveryOption?.deliveryTimeWorkDays ?? 0)
	)
}

export default getAdditionalDeliveryTimeWorkDays
