import setIsLoading from "../utils/setIsLoading"
import {
	addLineItemsToCart,
	addLineItemsToShoppingList,
	setLineItemDeliveryOption,
} from "@secureo/common/context/AppContext/actions"
import { trackAddToCart, trackAddToCartGA4 } from "@secureo/common/utils/tracking/cart"

import {
	MyLineItemDraft,
	ShoppingListLineItemDraft,
} from "@secureo/common/typings/generated/commercetools-graphql"
import { AppContextState } from "@secureo/common/context/AppContext/AppContext"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import { ProductDetailPageReducerState, DispatchProductDetailPageReducer } from ".."
import { EnhancedEcommerceCartProductFieldObject } from "@secureo/common/typings/EnhancedEcommerce"
import { centsToEuros } from "@secureo/common/utils/forex/convertCurrencyFraction"
import mergeConfiguredLineItems from "@secureo/common/utils/commerceTools/mergeConfiguredLineItems"
import { GA4Item } from "@secureo/common/typings/GoogleAnalytics4Ecommerce"
import { CurrencyCode } from "@secureo/common/typings/Forex"

export const distributionChannel: MyLineItemDraft["distributionChannel"] = {
	typeId: "channel",
	key: process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY,
}

export const custom: MyLineItemDraft["custom"] = {
	type: {
		typeId: "type",
		key: "configuredLineItem",
	},
	fields: [],
}

const getLineItemDrafts = (reducerState: ProductDetailPageReducerState) => {
	const {
		productId,
		variantId,
		variantsByVariantId,
		slug: masterVariantSlug,
		quantity,
		configurationsByConfigurationGroup,
		selectedCylinderLockingMechanism,
		selectedSafeLockConfigurationProduct,
	} = reducerState

	const masterVariant = variantsByVariantId?.[variantId]
	const masterVariantSku = masterVariant?.sku

	const drafts: MyLineItemDraft[] = [
		{
			productId,
			variantId,
			quantity,
			distributionChannel,
			custom: {
				...custom,
				fields: [
					{
						name: "masterVariantSku",
						value: `"${masterVariantSku}"`,
					},
					{
						name: "masterVariantSlug",
						value: `"${masterVariantSlug}"`,
					},
				],
			},
		},
	]

	// Add cylinderLockingMechanism product
	if (selectedCylinderLockingMechanism) {
		const draft: MyLineItemDraft = {
			productId: selectedCylinderLockingMechanism.productId,
			variantId: selectedCylinderLockingMechanism.variantId,
			quantity,
			distributionChannel,
			custom,
		}

		drafts.push(draft)
	}

	if (selectedSafeLockConfigurationProduct) {
		const draft: MyLineItemDraft = {
			productId: selectedSafeLockConfigurationProduct.productId,
			variantId: selectedSafeLockConfigurationProduct.variantId,
			quantity,
			distributionChannel,
			custom,
		}

		drafts.push(draft)
	}

	// Add configurations
	for (const configurationGroupProductsBySku of Object.values(
		configurationsByConfigurationGroup,
	)) {
		const configurations = Object.values(configurationGroupProductsBySku)

		for (const configuration of configurations) {
			const { productId, variantId } = configuration

			const configurationQuantity = quantity * configuration.quantity

			const draft: MyLineItemDraft = {
				productId,
				variantId,
				quantity: configurationQuantity,
				distributionChannel,
				custom,
			}

			drafts.push(draft)
		}
	}

	return drafts
}

const getAddToCartFieldObjects = (
	reducerState: ProductDetailPageReducerState,
	mostSpecificBreadCrumbCategoryName: string,
) => {
	const {
		variantId,
		name,
		variantsByVariantId,
		attributes,

		// Price
		quantity,
		pricePortions: {
			productSingleGrossPriceCents,
			cylinderLockingMechanismProductSingleGrossPriceCents,
		},
		configurationsByConfigurationGroup,

		// Cylinder
		cylinderConfigurationVariant,
		selectedCylinderLockingMechanism,
	} = reducerState

	const masterVariant = variantsByVariantId?.[variantId]
	const masterVariantSku = masterVariant?.sku
	const variantSku = cylinderConfigurationVariant?.sku ?? variantsByVariantId?.[variantId]?.sku
	const manufacturer = attributes?.manufacturer ?? null

	const addToCartFieldObjects: EnhancedEcommerceCartProductFieldObject[] = [
		{
			name,
			id: masterVariantSku,
			price: centsToEuros(productSingleGrossPriceCents),
			brand: manufacturer,
			category: mostSpecificBreadCrumbCategoryName,
			variant: variantSku,
			quantity,
		},
	]

	if (selectedCylinderLockingMechanism) {
		const fieldObject: EnhancedEcommerceCartProductFieldObject = {
			name:
				selectedCylinderLockingMechanism.mechanism === "single"
					? "Einzelschließend"
					: "Gleichschließend",
			id: selectedCylinderLockingMechanism.sku,
			price: centsToEuros(cylinderLockingMechanismProductSingleGrossPriceCents),
			brand: manufacturer,
			category: "",
			quantity,
		}

		addToCartFieldObjects.push(fieldObject)
	}

	for (const configurationGroupProductsBySku of Object.values(
		configurationsByConfigurationGroup,
	)) {
		const configurations = Object.values(configurationGroupProductsBySku)

		for (const configuration of configurations) {
			const { name, sku, singleNetPriceCents } = configuration

			const configurationQuantity = quantity * configuration.quantity

			const fieldObject: EnhancedEcommerceCartProductFieldObject = {
				name,
				id: sku,
				price: centsToEuros(singleNetPriceCents),
				brand: manufacturer,
				category: "",
				quantity: configurationQuantity,
			}

			addToCartFieldObjects.push(fieldObject)
		}
	}

	return addToCartFieldObjects
}

const getGA4Items = (
	reducerState: ProductDetailPageReducerState,
	mostSpecificBreadCrumbCategoryName: string,
) => {
	const {
		variantId,
		name,
		variantsByVariantId,
		attributes,

		// Price
		quantity,
		pricePortions: {
			productSingleGrossPriceCents,
			cylinderLockingMechanismProductSingleGrossPriceCents,
		},
		configurationsByConfigurationGroup,

		// Cylinder
		cylinderConfigurationVariant,
		selectedCylinderLockingMechanism,
	} = reducerState

	const masterVariant = variantsByVariantId?.[variantId]
	const masterVariantSku = masterVariant?.sku
	const variantSku = cylinderConfigurationVariant?.sku ?? variantsByVariantId?.[variantId]?.sku
	const manufacturer = attributes?.manufacturer ?? null

	const items: GA4Item[] = [
		{
			item_name: name,
			item_id: masterVariantSku,
			price: centsToEuros(productSingleGrossPriceCents),
			item_brand: manufacturer,
			item_category: mostSpecificBreadCrumbCategoryName,
			item_variant: variantSku,
			quantity,
		},
	]

	if (selectedCylinderLockingMechanism) {
		const item: GA4Item = {
			item_name:
				selectedCylinderLockingMechanism.mechanism === "single"
					? "Einzelschließend"
					: "Gleichschließend",
			item_id: selectedCylinderLockingMechanism.sku,
			price: centsToEuros(cylinderLockingMechanismProductSingleGrossPriceCents),
			item_brand: manufacturer,
			item_category: "",
			quantity,
		}

		items.push(item)
	}

	for (const configurationGroupProductsBySku of Object.values(
		configurationsByConfigurationGroup,
	)) {
		const configurations = Object.values(configurationGroupProductsBySku)

		for (const configuration of configurations) {
			const { name, sku, singleNetPriceCents } = configuration

			const configurationQuantity = quantity * configuration.quantity

			const item: GA4Item = {
				item_name: name,
				item_id: sku,
				price: centsToEuros(singleNetPriceCents),
				item_brand: manufacturer,
				item_category: "",
				quantity: configurationQuantity,
			}

			items.push(item)
		}
	}

	return items
}

export const addProductAndConfigurationsToCart = async (
	dispatch: DispatchProductDetailPageReducer,
	appContextState: AppContextState,
	reducerState: ProductDetailPageReducerState,
	language: string,
	countryCode: CountryCode,
	mostSpecificBreadCrumbCategoryName: string,
	currencyCode: CurrencyCode,
) => {
	setIsLoading(dispatch, true)

	const { selectedDeliveryOption } = reducerState

	const lineItemDrafts = getLineItemDrafts(reducerState)
	const updatedCart = await addLineItemsToCart(
		appContextState,
		lineItemDrafts,
		language,
		countryCode,
		currencyCode,
	)
	if (!updatedCart) return false

	// Set delivery option
	if (selectedDeliveryOption) {
		const mergedConfiguredLineItems = mergeConfiguredLineItems(updatedCart.lineItems)

		const sortedLineItems = [...mergedConfiguredLineItems].sort((a, b) =>
			b.addedAt.localeCompare(a.addedAt),
		)

		const mostRecentLineItemId = sortedLineItems[0].id

		await setLineItemDeliveryOption(
			appContextState.accessToken,
			appContextState.dispatch,
			updatedCart.id,
			mostRecentLineItemId,
			selectedDeliveryOption.sku,
			countryCode,
			language,
		)
	}

	const addToCartFieldObjects = getAddToCartFieldObjects(
		reducerState,
		mostSpecificBreadCrumbCategoryName,
	)
	trackAddToCart(addToCartFieldObjects)

	const ga4Items = getGA4Items(reducerState, mostSpecificBreadCrumbCategoryName)
	trackAddToCartGA4(ga4Items)

	setIsLoading(dispatch, false)

	dispatch({ type: "TOGGLE_INTERMEDIATE_SHOPPING_CART" })
	return true
}

const getShoppingListLineItemDrafts = (reducerState: ProductDetailPageReducerState) => {
	const lineItemDrafts = getLineItemDrafts(reducerState)

	const shoppingListLineItemDrafts = lineItemDrafts.map((lineItemDraft) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { distributionChannel: _, ...relevantLineItemDraft } = lineItemDraft
		const shoppingListLineItemDraft: ShoppingListLineItemDraft = {
			...relevantLineItemDraft,
		}

		return shoppingListLineItemDraft
	})

	return shoppingListLineItemDrafts
}

export const addProductAndConfigurationsToShoppingList = async (
	dispatch: DispatchProductDetailPageReducer,
	appContextState: AppContextState,
	reducerState: ProductDetailPageReducerState,
) => {
	setIsLoading(dispatch, true)

	const shoppingListLineItemDrafts = getShoppingListLineItemDrafts(reducerState)
	await addLineItemsToShoppingList(appContextState, shoppingListLineItemDrafts)

	setIsLoading(dispatch, false)
}
