import { SafeLockConfigurationProductsBySku } from "@secureo/common/typings/Product"
import { VariantsByVariantId } from "./getVariantsByVariantId"

const getSafeLockConfigurationProduct = (
	variantsByVariantId: VariantsByVariantId,
	variantId: number,
	safeLockConfigurationProductsBySku: SafeLockConfigurationProductsBySku,
) => {
	const variant = variantsByVariantId?.[variantId]
	const { variantConfigurationProductSku } = variant?.attributes ?? {}

	const safeLockConfigurationProduct =
		safeLockConfigurationProductsBySku?.[variantConfigurationProductSku] ?? null

	return safeLockConfigurationProduct
}

export default getSafeLockConfigurationProduct
