import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"
import prependFragmentsToQuery from "../prependFragmentsToQuery"
import fetchActiveCart from "../queries/fetchActiveCart"
import formatCart from "@secureo/common/utils/commerceTools/formatters/formatCart"
import { ProviderContext } from "notistack"

import {
	MyCartUpdateAction,
	Cart as CommerceToolsCart,
} from "@secureo/common/typings/generated/commercetools-graphql"
import { Cart } from "@secureo/common/typings/Cart"

const handleCartVersionMismatchError = async (
	accessToken: string,
	id: string,
	locale: string,
	actions: MyCartUpdateAction[],
) => {
	console.info("Handling cart version mismatch")
	try {
		const activeCart = await fetchActiveCart(accessToken, locale)
		const { version } = activeCart

		/* eslint-disable @typescript-eslint/no-use-before-define */
		const updatedCart = await updateCart(accessToken, id, version, locale, actions)
		/* eslint-enable @typescript-eslint/no-use-before-define */
		return updatedCart
	} catch (err) {
		console.error(err)
		return await fetchActiveCart(accessToken, locale)
	}
}

const updateCart = async (
	accessToken: string,
	id: string,
	version: number,
	locale: string,
	actions: MyCartUpdateAction[],
	enqueueSnackbar?: ProviderContext["enqueueSnackbar"],
): Promise<Cart> => {
	try {
		const query = prependFragmentsToQuery(
			/* GraphQL */ `
				mutation updateMyCart(
					$id: String!
					$version: Long!
					$locale: Locale
					$actions: [MyCartUpdateAction!]!
				) {
					updateMyCart(id: $id, version: $version, actions: $actions, storeKey: "${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}") {
						...cart
					}
				}
			`,
			["cart"],
		)

		const data = (await fetchCommerceToolsGraphQLApi(accessToken, query, {
			id,
			version,
			locale,
			actions,
		})) as {
			updateMyCart: CommerceToolsCart
		}

		const cart = data?.updateMyCart ?? null
		if (!cart) return null

		const formattedCart = formatCart(cart)
		return formattedCart
	} catch (err) {
		// TODO: Capture sentry exception
		console.error("Failed to update cart")
		console.error(err)

		const isVersionMismatchError =
			typeof err === "string" && err.includes("different version than expected")
		if (isVersionMismatchError)
			return handleCartVersionMismatchError(accessToken, id, locale, actions)
		else {
			if (enqueueSnackbar) enqueueSnackbar(err, { variant: "error" })
		}

		return fetchActiveCart(accessToken, locale)
	}
}

export default updateCart
