import { LockingSystemChargesByMatrixType } from "@secureo/common/typings/LockingSystem"
import { RawLockingSystemCharges } from "@secureo/common/typings/ProductAttributes"

const getLockingSystemChargesByMatrixType = (rawLockingSystemCharges: RawLockingSystemCharges) => {
	const lockingSystemChargesByMatrix: LockingSystemChargesByMatrixType =
		rawLockingSystemCharges.value.reduce(
			(lockingSystemChargesByMatrix, rawLockingSystemCharge) => {
				const lockingSystemMatrixType =
					rawLockingSystemCharge.find(({ name }) => name === "lockingSystemMatrixType")
						?.value ?? null
				const numberOfFreeIncludedKeys =
					rawLockingSystemCharge.find(({ name }) => name === "numberOfFreeIncludedKeys")
						?.value ?? 0
				const netChargeEuroCents =
					rawLockingSystemCharge.find(({ name }) => name === "netChargeEuroCents")
						?.value ?? 0

				return {
					...lockingSystemChargesByMatrix,
					[lockingSystemMatrixType]: {
						netChargeEuroCents,
						numberOfFreeIncludedKeys,
					},
				}
			},
			{} as LockingSystemChargesByMatrixType,
		)

	return lockingSystemChargesByMatrix
}

export default getLockingSystemChargesByMatrixType
