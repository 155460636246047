/* eslint-disable jsx-a11y/anchor-is-valid */
import { BreadcrumbJsonLd } from "next-seo"
import { Container, Paper, Breadcrumbs, Box } from "@mui/material"

import { makeStyles } from "makeStyles"

import Link from "./i18n/Link"

import useTranslation from "../hooks/useTranslation"
import { NEXT_PUBLIC_HOST } from "utils/envVariables"

const useStyles = makeStyles()({
	paper: {
		padding: "5px 10px",
		position: "absolute",
		zIndex: 0,
		background: "none",
	},
})

export interface BreadCrumbAncestor {
	label: string
	href: string
}

export const BreadCrumbStructuredData = ({
	breadCrumbsPaths = [],
	currentPath,
}: BreadCrumbStructuredDataProps) => {
	const { locale } = useTranslation()

	const rootBreadCrumb: BreadCrumbAncestor = {
		label: "SafeHero",
		href: `/`,
	}

	const itemListElements = [rootBreadCrumb, ...breadCrumbsPaths, currentPath].map(
		({ label, href }, i) => {
			return {
				position: i + 1,
				name: label,
				item: `${NEXT_PUBLIC_HOST}/${locale}${href}`,
			}
		},
	)

	return <BreadcrumbJsonLd itemListElements={itemListElements} />
}

interface BreadCrumbStructuredDataProps {
	breadCrumbsPaths: BreadCrumbAncestor[]
	currentPath: BreadCrumbAncestor
}

export const getCountryNameFromLocale = (locale: string) => {
	switch (locale?.toLowerCase()) {
		case "pl-pl":
			return "Polska"
		case "de-at":
			return "Österreich"
		case "de-ch":
			return "Schweiz"
		case "de-de":
		default:
			return "Deutschland"
	}
}

const BreadCrumbs = ({ breadCrumbsPaths = [], currentPath }: Props) => {
	const { classes } = useStyles()
	const { locale } = useTranslation()

	const homePageBreadCrumbLabel = `Safehero ${getCountryNameFromLocale(locale)}`

	return (
		<Box
			sx={{
				display: {
					xs: "none",
					md: "block",
				},
			}}
		>
			<Container>
				<Paper elevation={0} className={classes.paper}>
					<Breadcrumbs aria-label="breadcrumb">
						<Link href="/">{homePageBreadCrumbLabel}</Link>
						{breadCrumbsPaths.map((path) => {
							const { label, href } = path

							return (
								<Link key={href} href={href}>
									{label}
								</Link>
							)
						})}
						<span>{currentPath.label}</span>
					</Breadcrumbs>
				</Paper>
			</Container>
			<BreadCrumbStructuredData
				breadCrumbsPaths={breadCrumbsPaths}
				currentPath={currentPath}
			/>
		</Box>
	)
}

interface Props {
	breadCrumbsPaths?: BreadCrumbAncestor[]
	currentPath: BreadCrumbAncestor
}

export default BreadCrumbs
