import { isPaymentMethodAllowed } from "@secureo/common/utils/payments/paymentMethods"

import { CheckoutContextState, CheckoutContextReducerAction } from "../CheckoutContext"
import { FormAddress, AddressValidationErrors } from "@secureo/common/typings/FormAddress"
import { PaymentMethod } from "@secureo/common/typings/Payment"

interface CheckoutContextAddressReducerAction extends CheckoutContextReducerAction {
	type:
		| "SET_BILLING_ADDRESS"
		| "TOGGLE_BILLING_ADDRESS_IS_COMPANY"
		| "SET_SHIPPING_ADDRESS"
		| "TOGGLE_SHIPPING_ADDRESS_IS_COMPANY"
		| "TOGGLE_SHIPPING_ADDRESS_DIFFERS"
		| "SET_SHIPPING_ADDRESS_ERRORS"
		| "SET_BILLING_ADDRESS_ERRORS"
}

interface CheckoutContextAddressStateUpdate {
	shippingAddressDiffers?: boolean
	shippingAddress?: FormAddress
	billingAddress?: FormAddress
	shippingAddressValidationErrors?: AddressValidationErrors
	billingAddressValidationErrors?: AddressValidationErrors
}

const klarnaPaymentsIsAllowed = (state: CheckoutContextState, paymentMethods: PaymentMethod[]) => {
	const {
		billingAddress: { country: billingAddressCountryCode, isCompany },
	} = state

	const isAllowed = isPaymentMethodAllowed(
		paymentMethods,
		"pay_later",
		billingAddressCountryCode,
		isCompany,
	)

	return isAllowed
}

const getValidPaymentMethod = (state: CheckoutContextState, paymentMethods: PaymentMethod[]) => {
	const {
		selectedPaymentMethod,
		billingAddress: { country: billingAddressCountryCode, isCompany },
	} = state
	const fallbackPaymentMethodKey = paymentMethods[0].key
	const isAllowed = isPaymentMethodAllowed(
		paymentMethods,
		selectedPaymentMethod,
		billingAddressCountryCode,
		isCompany,
	)

	return isAllowed ? selectedPaymentMethod : fallbackPaymentMethodKey
}

const getStateAfterAddressUpdate = (
	state: CheckoutContextState,
	action: CheckoutContextReducerAction,
) => {
	const { type, payload } = action as CheckoutContextAddressReducerAction
	const { shippingAddress, billingAddress, shippingAddressDiffers, paymentMethods } = state

	let stateUpdate: CheckoutContextAddressStateUpdate = {}

	// ADDRESS UPDATES
	// BillingAddress update, addresses are same 💸
	if (type === "SET_BILLING_ADDRESS" && !shippingAddressDiffers) {
		stateUpdate = {
			billingAddress: {
				...billingAddress,
				...payload,
			},
			shippingAddress: {
				...shippingAddress,
				...payload,
			},
		}
	}

	// BillingAddress update, addresses differ 💸
	if (type === "SET_BILLING_ADDRESS" && shippingAddressDiffers) {
		stateUpdate = {
			billingAddress: {
				...billingAddress,
				...payload,
			},
		}
	}

	// ShippingAddress update 🚢
	if (type === "SET_SHIPPING_ADDRESS") {
		stateUpdate = {
			shippingAddress: {
				...shippingAddress,
				...payload,
			},
		}
	}

	// IS COMPANY TOGGLES
	// BillingAddress isCompany toggle, addresses are same 💸🔂
	if (type === "TOGGLE_BILLING_ADDRESS_IS_COMPANY" && !shippingAddressDiffers) {
		stateUpdate = {
			billingAddress: {
				...billingAddress,
				isCompany: !billingAddress.isCompany,
			},
			shippingAddress: {
				...shippingAddress,
				isCompany: !billingAddress.isCompany,
			},
		}
	}

	// BillingAddress isCompany toggle, addresses differ 💸🔂
	if (type === "TOGGLE_BILLING_ADDRESS_IS_COMPANY" && shippingAddressDiffers) {
		stateUpdate = {
			billingAddress: {
				...billingAddress,
				isCompany: !billingAddress.isCompany,
			},
		}
	}

	// ShippingAddress isCompany toggle 🚢🔂
	if (type === "TOGGLE_SHIPPING_ADDRESS_IS_COMPANY") {
		stateUpdate = {
			shippingAddress: {
				...shippingAddress,
				isCompany: !shippingAddress.isCompany,
			},
		}
	}

	// ADDRESSES DIFFER TOGGLE
	// shippingAddressDiffers toggle, addresses are same 💸🚢🔂
	if (type === "TOGGLE_SHIPPING_ADDRESS_DIFFERS" && !shippingAddressDiffers) {
		stateUpdate = {
			shippingAddressDiffers: !shippingAddressDiffers,
		}
	}

	// shippingAddressDiffers toggle, addresses differ 💸🚢🔂
	if (type === "TOGGLE_SHIPPING_ADDRESS_DIFFERS" && shippingAddressDiffers) {
		stateUpdate = {
			shippingAddressDiffers: !shippingAddressDiffers,
			shippingAddress: billingAddress,
		}
	}

	// ADDRESS
	// BillingAddress errors update, addresses are same 💸
	if (type === "SET_BILLING_ADDRESS_ERRORS" && !shippingAddressDiffers) {
		stateUpdate = {
			billingAddressValidationErrors: payload,
			shippingAddressValidationErrors: payload,
		}
	}

	// BillingAddress errors update, addresses differ 💸
	if (type === "SET_BILLING_ADDRESS_ERRORS" && shippingAddressDiffers) {
		stateUpdate = {
			billingAddressValidationErrors: payload,
		}
	}

	if (type === "SET_SHIPPING_ADDRESS_ERRORS" && shippingAddressDiffers) {
		stateUpdate = {
			shippingAddressValidationErrors: payload,
		}
	}

	const updatedState: CheckoutContextState = {
		...state,
		...stateUpdate,
	}

	// Check if klarnaPayments' initializedPaymentMethod should be reset
	// This allows the PaymentMethods component to reload klarnaPayments' widget
	const prevStateAllowedKlarna = klarnaPaymentsIsAllowed(state, paymentMethods)
	const updatedStateAllowsKlarna = klarnaPaymentsIsAllowed(updatedState, paymentMethods)
	const initializedKlarnaPaymentMethodShouldBeReset =
		!prevStateAllowedKlarna && updatedStateAllowsKlarna

	const updatedStateWithValidatedPaymentMethod = {
		...updatedState,
		selectedPaymentMethod: getValidPaymentMethod(updatedState, paymentMethods),
		klarnaPayments: {
			...updatedState.klarnaPayments,
			initializedPaymentMethod: initializedKlarnaPaymentMethodShouldBeReset
				? null
				: updatedState.klarnaPayments.initializedPaymentMethod,
		},
	}

	return updatedStateWithValidatedPaymentMethod
}

export default getStateAfterAddressUpdate
