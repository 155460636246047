import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"
import prependFragmentsToQuery from "../prependFragmentsToQuery"

import { InStoreMe } from "@secureo/common/typings/generated/commercetools-graphql"
import formatCart from "@secureo/common/utils/commerceTools/formatters/formatCart"

const fetchActiveCart = async (accessToken: string, locale: string) => {
	const query = prependFragmentsToQuery(
		/* GraphQL */ `
			query activeCart($locale: Locale!) {
				inStore(key: "${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}") {
					me {
						activeCart {
							...cart
						}
					}
				}
			}
		`,
		["cart"],
	)

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query, { locale })) as {
		inStore: {
			me: Pick<InStoreMe, "activeCart">
		}
	}

	const cart = data?.inStore?.me?.activeCart
	if (!cart) return null

	const formattedCart = formatCart(cart)

	return formattedCart
}

export default fetchActiveCart
