import { useContext, useState, useEffect } from "react"
import * as React from "react"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import { ThemeProvider } from "@mui/material/styles"
import { Grid, Container, Box } from "@mui/material"
import { makeStyles } from "makeStyles"
import { getCookieForCookieBanner, setCookieForCookieBanner } from "../utils/cookieBannerHandler"

import Header from "./Header/Header"
import CheckoutHeader from "../containers/CheckoutPage/CheckoutHeader"
import BackToTop from "./BackToTop"
import Cookie from "./CookieSnackbar"
import Footer from "./Footer"
import LoginRegister from "./Login/LoginRegister"
import AccountNav from "../containers/Account/AccountNav"
import AuthenticatedPage from "./AuthenticatedPage"

import defaultTheme from "../theme"
import businessTheme from "../themeBusiness"

const useStyles = makeStyles()({
	root: {
		background: "#F7FAFA",
	},
})

const Layout = ({ asPath, children, locale }: Props) => {
	const { classes } = useStyles()
	const [showCookieBanner, setShowCookieBanner] = useState(false)

	const { isBusinessVersion, language, countryCode } = useContext(AppContext)
	const isCheckout = asPath.includes("checkout")
	const isAccount = asPath.includes("account")

	useEffect(() => {
		const isCookieSet = getCookieForCookieBanner()
		if (!isCookieSet) setShowCookieBanner(true)
	}, [])

	const cookieBannerClickHandler = () => {
		setCookieForCookieBanner()
		setShowCookieBanner(false)
	}

	const theme = isBusinessVersion ? businessTheme : defaultTheme

	return (
		<ThemeProvider theme={theme}>
			{/* Material UI ICONS */}
			<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
			<div className={classes.root}>
				{!isCheckout ? <Header locale={locale} /> : <CheckoutHeader />}
				<>
					{!isAccount ? (
						<>{children}</>
					) : (
						<AuthenticatedPage>
							<Container>
								<Box my={5}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={3}>
											<AccountNav />
										</Grid>
										<Grid item xs={12} md={9}>
											{children}
										</Grid>
									</Grid>
								</Box>
							</Container>
						</AuthenticatedPage>
					)}
				</>
				<BackToTop />
				<Cookie
					showCookieBanner={showCookieBanner}
					cookieBannerClickHandler={cookieBannerClickHandler}
				/>
				<Footer countryCode={countryCode} language={language} />
				<LoginRegister />
			</div>
		</ThemeProvider>
	)
}

interface Props {
	children: React.ReactNode
	asPath: string
	locale: string
}

export default Layout
