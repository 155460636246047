import { useEffect, useState } from "react"
import { AppProps } from "next/app"
import { useRouter } from "next/router"

import { appWithTranslation } from "next-i18next"
import { AppContextProvider } from "@secureo/common/context/AppContext/AppContext"
import { CheckoutContextProvider } from "@secureo/common/context/CheckoutContext/CheckoutContext"
import AppContextController from "@secureo/common/context/AppContext/AppContextController"
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter"
import { SnackbarProvider } from "notistack"

import useRouteChange from "@secureo/common/hooks/useRouteChange"

import Layout from "../components/Layout"
import { DefaultSEO } from "../components/SEO"
import GeneralStructuredData from "@secureo/common/components/StructuredData/GeneralStructuredData"

import { CountryCode } from "@secureo/common/typings/CountryCode"
import { PageTransitionSpinner } from "../components/Spinner"
import SwitchCountryDialog from "../components/SwitchCountryDialog"

import { pushEventToDataLayer } from "@secureo/common/components/GoogleTagManager"
import isProduction from "@secureo/common/utils/isProduction"
import { getLocalizedPhoneNumber } from "components/i18n/Contact"
import getGeoCountry from "@secureo/common/utils/getGeoCountry"
import normalizeLocale from "@secureo/common/utils/normalizeLocale"

import { paymentMethods } from "config/paymentMethods"
import nextI18NextConfig from "../next-i18next.config.js"

import "../styles/global.scss"
import { CurrencyCode } from "typings/Forex"

export const AppProviders = (_props: AppProvidersProps) => {
	const { children, ...props } = _props
	return (
		<AppCacheProvider {...props}>
			<SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
		</AppCacheProvider>
	)
}

interface AppProvidersProps {
	children: React.ReactNode
}

const CustomApp = (props: AppProps) => {
	const { Component, pageProps } = props
	const router = useRouter()
	const { asPath, locale } = router
	const { countryCode, language } = normalizeLocale(locale)
	const currencyCode: CurrencyCode = countryCode === "PL" ? "PLN" : "EUR"

	const isChangingRoute = useRouteChange()

	const phoneNumber = getLocalizedPhoneNumber(countryCode as CountryCode)
	const [geoCountryCode, setGetGeoCountry] = useState(null as CountryCode)

	useEffect(() => {
		getGeoCountry().then(setGetGeoCountry).catch(console.error)
	}, [])

	const initialIsBusinessVersion = false

	return (
		<>
			<AppCacheProvider {...props}>
				<SnackbarProvider maxSnack={3}>
					<AppContextProvider
						initialIsBusinessVersion={initialIsBusinessVersion}
						language={language}
						countryCode={countryCode}
						currencyCode={currencyCode}
					>
						<AppContextController
							asPath={asPath}
							shopCountryCode={countryCode}
							language={language}
						>
							<CheckoutContextProvider
								shopCountryCode={countryCode}
								paymentMethods={paymentMethods}
							>
								<Layout asPath={asPath} locale={locale}>
									<DefaultSEO asPath={asPath} />
									<PageTransitionSpinner isHidden={isChangingRoute}>
										<Component {...pageProps} />
									</PageTransitionSpinner>
									<GeneralStructuredData
										locale={locale}
										phoneNumber={phoneNumber}
									/>
									<SwitchCountryDialog
										countryCode={countryCode}
										geoCountryCode={geoCountryCode}
										language={language}
									/>
								</Layout>
							</CheckoutContextProvider>
						</AppContextController>
					</AppContextProvider>
				</SnackbarProvider>
			</AppCacheProvider>
		</>
	)
}

export function reportWebVitals({ id, name, label, value }) {
	// Only report web vitals in production
	if (isProduction()) {
		pushEventToDataLayer({
			event: "web-vitals",
			event_category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
			event_action: name,
			// Google Analytics metrics must be integers, so the value is rounded.
			// For CLS the value is first multiplied by 1000 for greater precision
			// (note: increase the multiplier for greater precision if needed).
			event_value: Math.round(name === "CLS" ? value * 1000 : value),
			// The `id` value will be unique to the current page load. When sending
			// multiple values from the same page (e.g. for CLS), Google Analytics can
			// compute a total by grouping on this ID (note: requires `eventLabel` to
			// be a dimension in your report).
			event_label: id,
		})
	}
}

export default appWithTranslation(CustomApp, nextI18NextConfig)
