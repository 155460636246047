import React from "react"

import JSONLD from "./JSONLD"

const WebsiteJsonLD = ({ locale }: WebsiteJsonLDProps) => {
	const json = {
		"@context": "https://schema.org",
		"@type": "WebSite",
		url: `${process.env.NEXT_PUBLIC_HOST}/${locale}/`,
		potentialAction: {
			"@type": "SearchAction",
			target: {
				"@type": "EntryPoint",
				urlTemplate: `${process.env.NEXT_PUBLIC_HOST}/${locale}/search?query={search_term_string}`,
			},
			"query-input": {
				"@type": "PropertyValueSpecification",
				valueRequired: "https://schema.org/True",
				valueName: "search_term_string",
			},
		},
	}

	return <JSONLD metaKey="jsonld-website" json={json} />
}

interface WebsiteJsonLDProps {
	locale: string
}

const OrganizationJsonLD = ({ phoneNumber }: OrganizationJsonLDJsonLDProps) => {
	const json = {
		"@context": "https://schema.org",
		"@type": "Organization",
		url: `${process.env.NEXT_PUBLIC_HOST}/`,
		name: "Digitalzylinder Shop",
		logo: `${process.env.NEXT_PUBLIC_HOST}/android-chrome-384x384.png`,
		// TODO: Add social media links
		sameAs: [],
		contactPoint: [
			{
				"@type": "ContactPoint",
				telephone: phoneNumber,
				contactType: "customer service",
			},
		],
	}

	return <JSONLD metaKey="jsonld-organization" json={json} />
}

interface OrganizationJsonLDJsonLDProps {
	phoneNumber: string
}

const GeneralStructuredData = ({ locale, phoneNumber }: Props) => {
	return (
		<>
			<WebsiteJsonLD locale={locale} />
			<OrganizationJsonLD phoneNumber={phoneNumber} />
		</>
	)
}

interface Props {
	locale: string
	phoneNumber: string
}

export default GeneralStructuredData
