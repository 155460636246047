import { CountryCode } from "@secureo/common/typings/CountryCode"
import { ProductConfiguration } from "../.."
import { ConfigurationGroupProduct } from "@secureo/common/typings/Product"

const getProductConfigurationFromConfigurationProduct = (
	configurationProduct: ConfigurationGroupProduct,
	configurationGroupName: string,
	quantity: number,
	countryCode: CountryCode,
) => {
	const configuration: ProductConfiguration = {
		productId: configurationProduct.productId,
		variantId: 1,
		sku: configurationProduct.sku,
		name: configurationProduct.name,
		singleNetPriceCents: configurationProduct.pricesByCountryCode?.[countryCode] ?? 0,
		singleNetMsrpPriceCents: configurationProduct.netMsrpPriceByCountryCode?.[countryCode] ?? 0,
		deliveryTimeWorkDays:
			configurationProduct.deliveryTimeWorkDaysByShopCountryCode?.[countryCode] ?? 0,
		quantity,
		configurationGroupName,
	}

	return configuration
}

export default getProductConfigurationFromConfigurationProduct
