import React, { useEffect, useContext } from "react"

import AppContext from "./AppContext"
import {
	restoreOrCreateAuthToken,
	// restoreCurrencyPreference,
	refreshAndDispatchAuthToken,
	updateTaxes,
} from "./actions"

import fetchActiveCart from "@secureo/common/utils/commerceTools/graphql/queries/fetchActiveCart"
import fetchCustomer from "@secureo/common/utils/commerceTools/graphql/queries/fetchCustomer"
import fetchMyDefaultShoppingList from "@secureo/common/utils/commerceTools/graphql/queries/fetchMyDefaultShoppingList"
import deleteMyCart from "@secureo/common/utils/commerceTools/graphql/mutations/deleteMyCart"
import { getRemainingAccessTokenLifeTimeMs } from "@secureo/common/utils/commerceTools/token/isAuthTokenExpired"
import isProduction from "@secureo/common/utils/isProduction"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const logBuildIdAndArtWork = () => {
	switch (process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY) {
		case "DZN":
			console.info(`
	____  _       _ _____ __              
	/ __ \\(_)___ _(_) ___// /_  ____  ____ 
   / / / / / __ \`/ /\\__ \\/ __ \\/ __ \\/ __ \\
  / /_/ / / /_/ / /___/ / / / / /_/ / /_/ /
 /_____/_/\\__, /_//____/_/ /_/\\____/ .___/ 
		 /____/                   /_/      
`)
			break
		case "TRE":
			console.info(`
_____                               
/__   \\_ __ ___  ___  ___  _ __ ___  
  / /\\/ '__/ _ \\/ __|/ _ \\| '__/ _ \\ 
 / /  | | |  __/\\__ \\ (_) | | | (_) |
 \\/   |_|  \\___||___/\\___/|_|  \\___/ 
`)
			break
		default:
			console.info(`
 _____        __     _                    
/  ___|      / _|   | |                   
\\ \\\`--.  __ _| |_ ___| |__   ___ _ __ ___  
 \`--. \\/ _\` |  _/ _ \\ '_ \\ / _ \\ '__/ _ \\ 
/\\__/ / (_| | ||  __/ | | |  __/ | | (_) |
\\____/ \\__,_|_| \\___|_| |_|\\___|_|  \\___/ 											  	  
`)
	}
	console.info(`Build ${process.env.NEXT_PUBLIC_BUILD_ID}`)
}

const oneMinuteInMs = 60 * 1000
const oneHourInMs = oneMinuteInMs / 10
const authSessionFlowIntervalMs = oneMinuteInMs * 30

const AppContextController = ({ children, asPath, shopCountryCode, language = "de" }: Props) => {
	const appContext = useContext(AppContext)
	const { dispatch, expiryDateMs, isInitialized, currencyCode } = appContext

	// Uncomment the line below to debug context
	// console.debug({ appContext })

	const isCheckoutPage = asPath?.includes("checkout")
	const isOrderSuccessPage = asPath?.includes("success")

	useEffect(() => {
		if (!isInitialized) {
			if (isProduction()) logBuildIdAndArtWork()

			// restoreCurrencyPreference(dispatch)
			restoreOrCreateAuthToken(dispatch).then(async ({ accessToken, isCustomerToken }) => {
				// Cart and default shopping list
				const [activeCart, defaultShoppingList] = await Promise.all([
					fetchActiveCart(accessToken, language),
					fetchMyDefaultShoppingList(accessToken, currencyCode),
				])

				if (activeCart) {
					const hasMatchingCurrency = activeCart?.currencyCode === currencyCode
					if (!hasMatchingCurrency) {
						console.info(
							`Deleting cart ${activeCart?.id} because of currency mismatch...`,
						)
						await deleteMyCart(accessToken, activeCart?.id, activeCart?.version)
					}

					if (hasMatchingCurrency && !isOrderSuccessPage) {
						// Do not restore cart in orderSuccess, will confuse user
						if (!isCheckoutPage) {
							// Do not calculate taxes in checkout, as checkout page will handle this
							await updateTaxes(
								accessToken,
								dispatch,
								activeCart.id,
								shopCountryCode,
								shopCountryCode,
								false,
								true,
								false,
								"",
								null,
								language,
							)
							const updatedCart = await fetchActiveCart(accessToken, language)
							dispatch({
								type: "SET_CART",
								payload: updatedCart,
							})
						} else {
							dispatch({
								type: "SET_CART",
								payload: activeCart,
							})
						}
					}
				}

				// Customer
				if (isCustomerToken) {
					const customer = await fetchCustomer(accessToken)
					dispatch({
						type: "SET_CUSTOMER",
						payload: customer,
					})
				}

				// Default shopping list
				if (defaultShoppingList) {
					dispatch({
						type: "SET_SHOPPING_LIST",
						payload: defaultShoppingList,
					})
				}

				dispatch({
					type: "SET_IS_INITIALIZED",
				})
			})
		}
	}, [
		dispatch,
		shopCountryCode,
		isInitialized,
		isCheckoutPage,
		isOrderSuccessPage,
		language,
		currencyCode,
	])

	// In-session auth flow
	useEffect(() => {
		const intervalId = setInterval(() => {
			const remainingAccessTokenLifeTimeMs = getRemainingAccessTokenLifeTimeMs(expiryDateMs)
			if (expiryDateMs && remainingAccessTokenLifeTimeMs < oneHourInMs) {
				refreshAndDispatchAuthToken(dispatch)
			}
		}, authSessionFlowIntervalMs)

		return () => clearInterval(intervalId)
	}, [expiryDateMs, dispatch])

	return <>{children}</>
}

interface Props {
	children: React.ReactNode
	asPath: string
	shopCountryCode: CountryCode
	language?: string
}

export default AppContextController
