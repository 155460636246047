import axios from "axios"

import logAxiosError from "@secureo/common/utils/logAxiosError"
import handleGraphQLError from "@secureo/common/utils/handleGraphQLError"

const api = `https://api.europe-west1.gcp.commercetools.com/${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}/graphql`

interface GraphQlQueryVariables {
	[variableName: string]: any
}

interface GraphQlQueryBody {
	query: string
	variables?: GraphQlQueryVariables
}

const fetchCommerceToolsGraphQLApi = async (
	accessToken: string,
	query: string,
	variables?: GraphQlQueryVariables,
) => {
	const body: GraphQlQueryBody = {
		query,
	}

	if (variables) body.variables = variables

	try {
		const { data: response } = await axios.post(api, body, {
			headers: {
				Authorization: accessToken,
			},
		})

		const { data, errors } = response

		if (errors) throw errors

		return data
	} catch (err) {
		if (err.response && err.response.data.errors) handleGraphQLError(err.response.data.errors)
		if (Array.isArray(err)) handleGraphQLError(err)
		else logAxiosError(err)
		return null
	}
}

export default fetchCommerceToolsGraphQLApi
