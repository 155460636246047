import { RawMSRPPriceAttribute, NetMSRPByShopCountryCode } from "@secureo/common/typings/Product"

const getNetMSRPByShopCountryCode = (msrpPriceAttribute: RawMSRPPriceAttribute) => {
	const netMsrpPriceByShopCountryCode: NetMSRPByShopCountryCode = msrpPriceAttribute.value.reduce(
		(
			netMsrpPriceByShopCountryCode,
			[
				{ value: shopCountryCode },
				{
					value: { centAmount: netMsrp },
				},
			],
		) => {
			return {
				...netMsrpPriceByShopCountryCode,
				[shopCountryCode]: netMsrp,
			}
		},
		{},
	)

	return netMsrpPriceByShopCountryCode
}

export default getNetMSRPByShopCountryCode
