import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"
import prependFragmentsToQuery from "../prependFragmentsToQuery"

import { Cart, MyCartDraft } from "@secureo/common/typings/generated/commercetools-graphql"
import formatCart from "@secureo/common/utils/commerceTools/formatters/formatCart"

const createMyCart = async (accessToken: string, locale: string, draft: MyCartDraft) => {
	const query = prependFragmentsToQuery(
		/* GraphQL */ `
			mutation createMyCart($locale: Locale!, $draft: MyCartDraft!) {
				createMyCart(draft: $draft, storeKey: "${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}") {
					...cart
				}
			}
		`,
		["cart"],
	)

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query, {
		locale,
		draft,
	})) as {
		createMyCart: Cart
	}

	const cart = data?.createMyCart ?? null
	if (!cart) return null

	const formattedCart = formatCart(cart)

	return formattedCart
}

export default createMyCart
