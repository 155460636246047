const moneyFragment = {
	fragment: /* GraphQL */ `
		fragment money on Money {
			centAmount
			currencyCode
		}
	`,
	dependencies: [],
}

export default moneyFragment
