const cartFragment = {
	fragment: /* GraphQL */ `
		fragment cart on Cart {
			id
			version
			anonymousId
			cartState
			lastModifiedAt
			customerEmail
			country
			locale
			billingAddress {
				...address
			}
			shippingAddress {
				...address
			}
			taxMode
			totalPrice {
				currencyCode
			}
			taxedPrice {
				totalNet {
					...money
				}
				totalGross {
					...money
				}
				taxPortions {
					name
					rate
					amount {
						...money
					}
				}
			}
			lineItems {
				...lineItem
			}
			customLineItems {
				...customLineItem
			}
			discountCodes {
				discountCode {
					...discountCode
				}
			}
		}
	`,
	dependencies: ["money", "address", "lineItem", "customLineItem", "discountCode"],
}

export default cartFragment
