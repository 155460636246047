export interface LocalizedMetaData {
	nameDE?: string
	nameAT?: string
	nameCH?: string
	namePL?: string
	metaTitleDE?: string
	metaTitleAT?: string
	metaTitleCH?: string
	metaTitlePL?: string
	metaDescriptionDE?: string
	metaDescriptionAT?: string
	metaDescriptionCH?: string
	metaDescriptionPL?: string
	descriptionDE?: string
	descriptionPL?: string
}

export const getLocalizedName = (metaData: LocalizedMetaData, locale: string) => {
	const { nameAT, nameCH, nameDE = "", namePL = "" } = metaData

	let name = ""

	switch (locale.toLowerCase()) {
		case "de-at":
			name = nameAT
			break
		case "de-ch":
			name = nameCH
			break
		case "pl-pl":
			name = namePL
			break
	}

	return name || nameDE
}

export const getLocalizedMetaTitle = (metaData: LocalizedMetaData, locale: string) => {
	const { metaTitleAT, metaTitleCH, metaTitleDE = "", metaTitlePL = "" } = metaData

	let metaTitle = ""

	switch (locale.toLowerCase()) {
		case "de-at":
			metaTitle = metaTitleAT
			break
		case "de-ch":
			metaTitle = metaTitleCH
			break
		case "pl-pl":
			metaTitle = metaTitlePL
			break
	}

	return metaTitle || metaTitleDE
}

export const getLocalizedMetaDescription = (metaData: LocalizedMetaData, locale: string) => {
	const {
		metaDescriptionAT,
		metaDescriptionCH,
		metaDescriptionDE = "",
		metaDescriptionPL = "",
	} = metaData

	let metaDescription = ""

	switch (locale.toLowerCase()) {
		case "de-at":
			metaDescription = metaDescriptionAT
			break
		case "de-ch":
			metaDescription = metaDescriptionCH
			break
		case "pl-pl":
			metaDescription = metaDescriptionPL
			break
	}

	return metaDescription || metaDescriptionDE
}
