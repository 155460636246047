const parseLineItemUuidCustomField = (productId: string, uuidCustomFieldValue?: string) => {
	if (!uuidCustomFieldValue)
		return {
			isConfiguration: false,
			configurationForProductId: null,
			uuid: null,
		}

	const { configuredProductId, uuid } = /^\[(?<configuredProductId>.+)\]-(?<uuid>.+)$/.exec(
		uuidCustomFieldValue,
	).groups

	const isConfiguration = configuredProductId !== productId

	return {
		isConfiguration,
		configurationForProductId: isConfiguration ? configuredProductId : null,
		uuid,
	}
}

export default parseLineItemUuidCustomField
