import { Options, BaseTransportOptions } from "@sentry/types/types"
import { BrowserOptions, NodeOptions, EdgeOptions } from "@sentry/nextjs"

const ignoreErrors: Options<BaseTransportOptions>["ignoreErrors"] = [
	"https://reactjs.org/docs/error-decoder.html?invariant=418", // Hydration failed because the initial UI does not match what was rendered on the server.
	"https://reactjs.org/docs/error-decoder.html?invariant=422", // There was an error while hydrating this Suspense boundary. Switched to client rendering.
	"https://reactjs.org/docs/error-decoder.html?invariant=423", // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
	"https://reactjs.org/docs/error-decoder.html?invariant=425", // Text content does not match server-rendered HTML...
]

export const sharedClientOptions: BrowserOptions = {
	ignoreErrors,
}
export const sharedServerOptions: NodeOptions = {
	ignoreErrors,
}
export const sharedEdgeOptions: EdgeOptions = {
	ignoreErrors,
}
