import { TaxRate as CommerceToolsTaxRate } from "@secureo/common/typings/generated/commercetools-graphql"
import { TaxRate, TaxRateType } from "@secureo/common/typings/Cart"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const lineItemTaxRateRegex =
	/^(?<customerType>private|company)-from-(?<shippingOriginationCountryCode>\w{2})-to-(?<shippingDestinationCountryCode>\w{2})$/
const lineItemShippingCostTaxRateRegex =
	/^shipping-\[(?<lineItemId>.+)\]-(?<customerType>private|company)-from-(?<shippingOriginationCountryCode>\w{2})-to-(?<shippingDestinationCountryCode>\w{2})$/
const lineItemDeliveryOptionTaxRateRegex =
	/^shipping-deliveryOption-\[(?<lineItemId>.+)\]-(?<customerType>private|company)-from-(?<shippingOriginationCountryCode>\w{2})-to-(?<shippingDestinationCountryCode>\w{2})$/
const nonHaulageDeliveryFlatFeeTaxRateRegex =
	/^shipping-nonHaulageDeliveryFlatFee-(?<customerType>private|company)-from-(?<shippingOriginationCountryCode>\w{2})-to-(?<shippingDestinationCountryCode>\w{2})$/
const smallAmountFlatFeeTaxRateRegex =
	/^shipping-smallAmountFlatFee-(?<customerType>private|company)-from-(?<shippingOriginationCountryCode>\w{2})-to-(?<shippingDestinationCountryCode>\w{2})$/

export const getTaxRateType = (name: string): TaxRateType => {
	if (lineItemTaxRateRegex.test(name)) return "lineItem"
	if (lineItemShippingCostTaxRateRegex.test(name)) return "lineItemShippingCost"
	if (lineItemDeliveryOptionTaxRateRegex.test(name)) return "lineItemDeliveryOption"
	if (nonHaulageDeliveryFlatFeeTaxRateRegex.test(name)) return "nonHaulageDeliveryFlatFee"
	if (smallAmountFlatFeeTaxRateRegex.test(name)) return "smallAmountFlatFee"
	return null
}

export const parseTaxRate = (
	name: string,
	taxRateType: TaxRateType,
): {
	customerType: "private" | "company"
	shippingOriginationCountryCode: CountryCode
	shippingDestinationCountryCode: CountryCode
	lineItemId?: string
} => {
	switch (taxRateType) {
		case "lineItem":
			return lineItemTaxRateRegex.exec(name).groups as any
		case "lineItemShippingCost":
			return lineItemShippingCostTaxRateRegex.exec(name).groups as any
		case "lineItemDeliveryOption":
			return lineItemDeliveryOptionTaxRateRegex.exec(name).groups as any
		case "nonHaulageDeliveryFlatFee":
			return nonHaulageDeliveryFlatFeeTaxRateRegex.exec(name).groups as any
		case "smallAmountFlatFee":
			return smallAmountFlatFeeTaxRateRegex.exec(name).groups as any
		default:
			return null
	}
}

const formatTaxRate = (
	taxRate: Pick<CommerceToolsTaxRate, "name" | "amount" | "includedInPrice">,
) => {
	try {
		if (!taxRate) return null

		const { name, amount, includedInPrice } = taxRate

		const type = getTaxRateType(name)
		const { customerType, shippingOriginationCountryCode, shippingDestinationCountryCode } =
			parseTaxRate(name, type)
		const isCompanyTaxRate = customerType === "company"

		const formattedTaxRate: TaxRate = {
			type: type,
			rate: amount,
			includedInPrice,
			isCompanyTaxRate,
			shippingOriginationCountryCode,
			shippingDestinationCountryCode,
		}

		return formattedTaxRate
	} catch (error) {
		console.error("Failed to format taxRate")
		console.error(JSON.stringify({ taxRate }))
		console.error(error)
		return null
	}
}

export default formatTaxRate
