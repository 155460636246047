const addressFragment = {
	fragment: /* GraphQL */ `
		fragment address on Address {
			id
			salutation
			firstName
			lastName
			streetName
			streetNumber
			postalCode
			city
			country
			email
			phone
			company
			department
			externalId
			additionalAddressInfo
		}
	`,
	dependencies: [],
}

export default addressFragment
