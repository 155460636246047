import {
	RawMiscAttributesAttributesAttribute,
	MiscAttributes,
} from "@secureo/common/typings/Product"

const formatMiscAttributeValue = (name: string, rawValue: string) => {
	switch (name) {
		case "Außenlänge":
		case "Innenlänge":
			return Number(rawValue)
		case "Medien":
			return rawValue.split("|")

		default: {
			if (rawValue.includes("|")) return rawValue.split("|")
			return rawValue
		}
	}
}

const getMiscAttributes = (miscAttributes: RawMiscAttributesAttributesAttribute) => {
	const [rawNames, rawValues] = miscAttributes.value?.[0] ?? [null, null]

	const names = rawNames?.value?.de?.split(";") ?? []
	const values = rawValues?.value?.de?.split(";") ?? []

	const miscAttributesByAttributeName = names.reduce((miscAttributesByAttributeName, name, i) => {
		const rawValue = values[i]

		if (!rawValue) return miscAttributesByAttributeName

		const value = formatMiscAttributeValue(name, rawValue)

		return {
			[name]: value,
			...miscAttributesByAttributeName,
		}
	}, {} as MiscAttributes)

	return miscAttributesByAttributeName
}

export default getMiscAttributes
