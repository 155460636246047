import * as React from "react"
import { Button as MaterialUIButton } from "@mui/material"

import { makeStyles } from "makeStyles"

type ButtonColor =
	| "default"
	| "primary"
	| "primaryOutlined"
	| "secondary"
	| "secondaryOutlined"
	| "cart"
	| "danger"
	| "link"

const useStyles = makeStyles()((theme) => ({
	// Lightgrey
	default: {
		background: "whitesmoke",
		textTransform: "inherit",
		border: "1px solid lightgrey",
		width: "100%",
		color: theme.palette.secondary.dark,
		"&:hover": {
			background: "#abaeb3",
			color: theme.palette.secondary.dark,
			border: "1px solid #abaeb3",
		},
	},
	// MintGreen
	primary: {
		background: theme.palette.primary.main,
		color: "#f7fafa",
		textTransform: "inherit",
		width: "100%",
		border: "1px solid #7abfac",
		"&:hover": {
			background: theme.palette.primary.dark,
			border: "1px solid #6FAD85",
		},
	},
	// MintGreen Outlined
	primaryOutlined: {
		background: "none",
		color: theme.palette.primary.main,
		textTransform: "inherit",
		width: "100%",
		border: "1px solid #7abfac",
		"&:hover": {
			background: theme.palette.primary.main,
			border: "1px solid #6FAD85",
			color: "#f7fafa",
		},
	},
	// Cart Button
	cart: {
		background: "#FFD462",
		color: theme.palette.secondary.dark,
		textTransform: "inherit",
		width: "100%",
		"&:hover": {
			background: "#EDAE38",
			color: theme.palette.secondary.dark,
		},
	},
	// blue
	secondary: {
		background: theme.palette.secondary.main,
		color: "white !important",
		textTransform: "inherit",
		width: "100%",
		"&:hover": {
			background: theme.palette.secondary.main,
			color: "#f7fafa",
		},
	},
	// blue Outlined
	secondaryOutlined: {
		background: "none",
		color: theme.palette.secondary.main,
		textTransform: "inherit",
		width: "100%",
		border: "1px solid #1B5C71",
		"&:hover": {
			background: theme.palette.secondary.main,
			color: "#f7fafa",
			border: "1px solid #1B5C71",
		},
	},
	// red
	danger: {
		background: "#CF423B",
		color: "white !important",
		textTransform: "inherit",
		width: "100%",
		"&:hover": {
			background: theme.palette.secondary.main,
			color: "#f7fafa",
		},
	},
	// link
	link: {
		background: "none",
		color: "#274041",
		textTransform: "inherit",
		margin: 0,
		padding: 2,
		textAlign: "left",
		"&:hover": {
			background: "none",
		},
	},
}))

const getButtonClassName = (
	classes: {
		default: any
		primary: any
		primaryOutlined: any
		secondary: any
		secondaryOutlined: any
		cart: any
		danger: any
		link: any
	},
	color: ButtonColor,
) => {
	switch (color) {
		case "primary":
			return classes.primary
		case "primaryOutlined":
			return classes.primaryOutlined
		case "secondary":
			return classes.secondary
		case "secondaryOutlined":
			return classes.secondaryOutlined
		case "cart":
			return classes.cart
		case "danger":
			return classes.danger
		case "link":
			return classes.link
		default:
			return classes.default
	}
}

const Button = ({ color, type = "button", size, children, ...props }: Props) => {
	const { classes } = useStyles()
	const className = getButtonClassName(classes, color)

	return (
		<MaterialUIButton className={className} type={type} {...props} size={size}>
			{children}
		</MaterialUIButton>
	)
}

interface Props {
	children: any
	color?: ButtonColor
	onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	style?: React.CSSProperties
	type?: "button" | "submit" | "reset"
	disabled?: boolean
	tabIndex?: number
	className?: any
	size?: any
}

export default Button
