import { shopCountryCodes } from "../i18n/supportedLocalesAndCurrencyCodes"

import { LineItem, MergedConfiguredLineItem } from "@secureo/common/typings/Cart"
import { DeliveryTimeWorkDaysByShopCountryCode } from "@secureo/common/typings/Product"

const getAggregatedDeliveryTimeWorkdaysByShopCountryCode = (
	configuredLineItem: LineItem,
	configurations: LineItem[],
) => {
	try {
		const lineItemDeliveryTimeWorkDaysByShopCountryCode =
			configuredLineItem.variant.deliveryTimeWorkDaysByShopCountryCode

		const aggregateDeliveryTimeWorkDaysByShopCountryCode: DeliveryTimeWorkDaysByShopCountryCode =
			shopCountryCodes.reduce(
				(aggregateDeliveryTimeWorkDaysByShopCountryCode, shopCountryCode) => {
					const aggregateDeliveryTimeWorkDays = configurations.reduce(
						(aggregateDeliveryTimeWorkDays, configuration) => {
							const configurationDeliveryTimeWorkDays =
								configuration.variant.deliveryTimeWorkDaysByShopCountryCode[
									shopCountryCode
								] || 0

							return aggregateDeliveryTimeWorkDays + configurationDeliveryTimeWorkDays
						},
						lineItemDeliveryTimeWorkDaysByShopCountryCode[shopCountryCode] || 0,
					)

					return {
						...aggregateDeliveryTimeWorkDaysByShopCountryCode,
						[shopCountryCode]: aggregateDeliveryTimeWorkDays,
					}
				},
				{},
			)

		return aggregateDeliveryTimeWorkDaysByShopCountryCode
	} catch {
		return {}
	}
}

const mergeConfiguredLineItems = (
	lineItems: LineItem[],
	aggregateTaxedPrice = true,
): MergedConfiguredLineItem[] => {
	try {
		const uniqueUuids = Array.from(
			new Set(lineItems.filter(({ uuid }) => !!uuid).map(({ uuid }) => uuid)),
		)
		const unConfiguredLineItems = lineItems.filter(({ uuid }) => !uuid)
		const mergedConfiguredLineItems = []

		for (const uuid of uniqueUuids) {
			const correspondingLineItems = lineItems.filter((lineItem) => lineItem.uuid === uuid)
			const configuredLineItem = correspondingLineItems.find(
				({ configurationForProductId }) => !configurationForProductId,
			)

			if (!configuredLineItem) {
				unConfiguredLineItems.push(...correspondingLineItems)
				continue
			}

			const configurations = correspondingLineItems.filter(
				({ configurationForProductId }) =>
					configurationForProductId === configuredLineItem.productId,
			)

			const aggregateDeliveryTimeWorkDaysByShopCountryCode =
				getAggregatedDeliveryTimeWorkdaysByShopCountryCode(
					configuredLineItem,
					configurations,
				)

			const mergedConfiguredLineItem: MergedConfiguredLineItem = {
				...configuredLineItem,
				variant: {
					...configuredLineItem.variant,
					deliveryTimeWorkDaysByShopCountryCode:
						aggregateDeliveryTimeWorkDaysByShopCountryCode,
				},
				configurations,
			}

			if (aggregateTaxedPrice) {
				const configuredLineItemTaxedPrice = configurations.reduce(
					(configuredLineItemTaxedPrice, configuration) => {
						const totalNetCents = configuration?.taxedPrice?.totalNet?.centAmount ?? 0
						const totalGrossCents =
							configuration?.taxedPrice?.totalGross?.centAmount ?? 0
						return {
							totalGross: {
								centAmount:
									(configuredLineItemTaxedPrice?.totalGross?.centAmount ?? 0) +
									totalGrossCents,
							},
							totalNet: {
								centAmount:
									(configuredLineItemTaxedPrice?.totalNet?.centAmount ?? 0) +
									totalNetCents,
							},
						}
					},
					configuredLineItem?.taxedPrice ?? {
						totalNet: {
							centAmount: 0,
						},
						totalGross: {
							centAmount: 0,
						},
					},
				)

				mergedConfiguredLineItem.taxedPrice = configuredLineItemTaxedPrice
			}

			mergedConfiguredLineItems.push(mergedConfiguredLineItem)
		}

		return [...mergedConfiguredLineItems, ...unConfiguredLineItems]
	} catch (err) {
		console.error(err)
		return lineItems
	}
}

export default mergeConfiguredLineItems
