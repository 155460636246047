import { netToGross } from "@secureo/common/utils/tax"

import {
	ProductDetailPageReducerState,
	PricePortions,
	ProductConfigurationsByConfigurationGroup,
	ProductConfiguration,
	PDPDeliveryOption,
} from ".."
import { ProductAttributes, SafeLockConfigurationProduct } from "@secureo/common/typings/Product"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const getTotalConfigurationsMsrpPriceCents = (
	configurationsByConfigurationGroup: ProductConfigurationsByConfigurationGroup,
	taxRate: number,
) => {
	const configurations = Object.values(configurationsByConfigurationGroup).reduce(
		(configurations, configurationGroup) => {
			const configurationGroupConfigurations = Object.values(configurationGroup)
			return [...configurations, ...configurationGroupConfigurations]
		},
		[] as ProductConfiguration[],
	)

	const totalConfigurationsMsrpPriceCents = configurations.reduce(
		(
			{
				totalConfigurationsNetMsrpPriceCentsPerProductQuantity,
				totalConfigurationsGrossMsrpPriceCentsPerProductQuantity,
			},
			configuration,
		) => {
			const { quantity, singleNetMsrpPriceCents } = configuration

			const singleGrossMsrpPriceCents = Math.round(
				netToGross(singleNetMsrpPriceCents, taxRate),
			)

			const totalNetPriceCents = singleNetMsrpPriceCents * quantity
			const totalGrossPriceCents = singleGrossMsrpPriceCents * quantity

			return {
				totalConfigurationsNetMsrpPriceCentsPerProductQuantity:
					totalConfigurationsNetMsrpPriceCentsPerProductQuantity + totalNetPriceCents,
				totalConfigurationsGrossMsrpPriceCentsPerProductQuantity:
					totalConfigurationsGrossMsrpPriceCentsPerProductQuantity + totalGrossPriceCents,
			}
		},
		{
			totalConfigurationsNetMsrpPriceCentsPerProductQuantity: 0,
			totalConfigurationsGrossMsrpPriceCentsPerProductQuantity: 0,
		},
	)

	return totalConfigurationsMsrpPriceCents
}

const getMsrpPricePortions = (
	quantity: number,
	taxRate: number,
	countryCode: CountryCode,
	attributes: ProductAttributes,
	configurationsByConfigurationGroup: ProductConfigurationsByConfigurationGroup,
	cylinderLockingMechanismProductSingleNetMsrpPriceCents = 0,
	cylinderLockingMechanismProductSingleGrossMsrpPriceCents = 0,
	safeLockConfigurationProduct?: SafeLockConfigurationProduct,
	selectedDeliveryOption?: PDPDeliveryOption,
) => {
	const { netMsrpPriceByShopCountryCode } = attributes

	// Product
	const productSingleNetMsrpPriceCents = netMsrpPriceByShopCountryCode?.[countryCode] ?? 0
	const productSingleGrossMsrpPriceCents = Math.round(
		netToGross(productSingleNetMsrpPriceCents, taxRate),
	)

	// Configurations
	const {
		totalConfigurationsNetMsrpPriceCentsPerProductQuantity,
		totalConfigurationsGrossMsrpPriceCentsPerProductQuantity,
	} = getTotalConfigurationsMsrpPriceCents(configurationsByConfigurationGroup, taxRate)

	const safeLockConfigurationProductSingleNetMsrpPriceCents =
		safeLockConfigurationProduct?.netMSRPByShopCountryCode?.[countryCode] ?? 0
	const safeLockConfigurationProductSingleGrossMsrpPriceCents = Math.round(
		netToGross(safeLockConfigurationProductSingleNetMsrpPriceCents, taxRate),
	)

	const deliveryOptionSingleNetMsrpPriceCents = selectedDeliveryOption?.singleNetPriceCents ?? 0
	const deliveryOptionSingleGrossMsrpPriceCents = Math.round(
		netToGross(deliveryOptionSingleNetMsrpPriceCents, taxRate),
	)

	const pricePortions: PricePortions = {
		productSingleNetPriceCents: productSingleNetMsrpPriceCents,
		productSingleGrossPriceCents: productSingleGrossMsrpPriceCents,
		cylinderLockingMechanismProductSingleNetPriceCents:
			cylinderLockingMechanismProductSingleNetMsrpPriceCents,
		cylinderLockingMechanismProductSingleGrossPriceCents:
			cylinderLockingMechanismProductSingleGrossMsrpPriceCents,
		totalConfigurationsNetPriceCentsPerProductQuantity:
			totalConfigurationsNetMsrpPriceCentsPerProductQuantity,
		totalConfigurationsGrossPriceCentsPerProductQuantity:
			totalConfigurationsGrossMsrpPriceCentsPerProductQuantity,
		safeLockConfigurationProductSingleNetPriceCents:
			safeLockConfigurationProductSingleNetMsrpPriceCents,
		safeLockConfigurationProductSingleGrossPriceCents:
			safeLockConfigurationProductSingleGrossMsrpPriceCents,
		deliveryOptionSingleNetPriceCents: deliveryOptionSingleNetMsrpPriceCents,
		deliveryOptionSingleGrossPriceCents: deliveryOptionSingleGrossMsrpPriceCents,
	}

	const singleNetMsrpPriceCents =
		productSingleNetMsrpPriceCents +
		totalConfigurationsNetMsrpPriceCentsPerProductQuantity +
		cylinderLockingMechanismProductSingleNetMsrpPriceCents +
		safeLockConfigurationProductSingleNetMsrpPriceCents +
		deliveryOptionSingleNetMsrpPriceCents
	const singleGrossMsrpPriceCents =
		productSingleGrossMsrpPriceCents +
		totalConfigurationsGrossMsrpPriceCentsPerProductQuantity +
		cylinderLockingMechanismProductSingleGrossMsrpPriceCents +
		safeLockConfigurationProductSingleGrossMsrpPriceCents +
		deliveryOptionSingleGrossMsrpPriceCents

	const totalNetMsrpPriceCents = singleNetMsrpPriceCents * quantity
	const totalGrossMsrpPriceCents = singleGrossMsrpPriceCents * quantity

	const msrpPricePortions: Pick<
		ProductDetailPageReducerState,
		| "singleNetMsrpPriceCents"
		| "singleGrossMsrpPriceCents"
		| "totalNetMsrpPriceCents"
		| "totalGrossMsrpPriceCents"
		| "msrpPricePortions"
	> = {
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions: pricePortions,
	}

	return msrpPricePortions
}

export default getMsrpPricePortions
