import { CountryCode } from "@secureo/common/typings/CountryCode"
import { DispatchAppContext } from "../AppContext"

export const changeLocale = async (
	language: string,
	countryCode: CountryCode,
	dispatch: DispatchAppContext,
) => {
	dispatch({
		type: "CHANGE_LOCALE",
		payload: {
			language,
			countryCode,
		},
	})
}
