import { useContext } from "react"
import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Box,
	Divider,
	Hidden,
	Drawer,
	Button,
} from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import {
	mdiViewGrid,
	mdiCardAccountDetails,
	mdiLock,
	mdiCartArrowRight,
	mdiLogoutVariant,
	mdiBackburger,
	mdiStar,
	mdiAccountCircle,
} from "@mdi/js"
import { Trans } from "next-i18next"

import AppContext from "@secureo/common/context/AppContext/AppContext"

import { Phone, Email } from "../../components/i18n/Contact"
import Link from "../../components/i18n/Link"

import useToggle from "@secureo/common/hooks/useToggle"
import useTranslation from "hooks/useTranslation"

export const accountLinks = [
	{
		labelByLanguage: { de: "Account", pl: "Nazwisko" },
		href: "/account",
		iconPath: mdiViewGrid,
	},
	{
		labelByLanguage: { de: "Adressen", pl: "Adresy" },
		href: "/account/addresses",
		iconPath: mdiCardAccountDetails,
	},
	{
		labelByLanguage: { de: "Sicherheit", pl: "Bezpieczeństwo" },
		href: "/account/security",
		iconPath: mdiLock,
	},
	{
		labelByLanguage: { de: "Bestellungen", pl: "Zamówienia" },
		href: "/account/orders",
		iconPath: mdiCartArrowRight,
	},
	{
		labelByLanguage: { de: "Produkte bewerten", pl: "Oceń produkty" },
		href: "/account/rate-products",
		iconPath: mdiStar,
	},
	{
		labelByLanguage: { de: "Logout", pl: "Wylogowanie" },
		href: "/logout",
		iconPath: mdiLogoutVariant,
	},
]

const useStyles = makeStyles()((theme) => ({
	list: {
		width: 250,
		color: theme.palette.secondary.dark,
	},
	fullList: {
		width: "auto",
	},
}))

const AccountNav = () => {
	const { classes } = useStyles()
	const { isLoggedIn, customer, language } = useContext(AppContext)
	const [isOpen, toggleIsOpen] = useToggle() // Mobile
	const { t } = useTranslation(["common", "account"])

	return (
		<>
			<Hidden mdDown>
				<Box mb={1} pl={2}>
					{isLoggedIn && (
						<div style={{ display: "flex", alignItems: "center" }}>
							<Icon
								path={mdiAccountCircle}
								size={1.7}
								color="#7abfac"
								style={{ marginRight: "15px" }}
							/>
							<div>
								{t("common:hello")} {customer.firstName}
							</div>
						</div>
					)}
					<Box mt={1}>
						<Divider />
					</Box>
				</Box>

				<List component="nav" aria-label="main mailbox folders">
					{accountLinks.map(({ labelByLanguage, href, iconPath }) => {
						const label = labelByLanguage[language] ?? labelByLanguage["de"] ?? ""

						return (
							<Link href={href} key={href}>
								<ListItemButton>
									<ListItemIcon>
										<Icon path={iconPath} size={1} color="#7abfac" />
									</ListItemIcon>
									<ListItemText primary={label} />
								</ListItemButton>
							</Link>
						)
					})}
				</List>
				<Divider />
				<Box mt={1} pt={2} pl={2}>
					<div className="h2">{t("account:doYouHaveQuestions")}</div>
					<p>
						<Trans t={t} i18nKey="account:youCanReachUs" />
					</p>
					<div style={{ color: "#168DB5" }}>
						<Phone />
						<br />
						<Email />
					</div>
					<p>
						<Trans t={t} i18nKey="account:contactUsPerMail" />
					</p>
				</Box>
			</Hidden>
			<Hidden mdUp>
				<Button
					onClick={toggleIsOpen}
					style={{
						display: "flex",
						textAlign: "left",
					}}
				>
					<Icon path={mdiBackburger} size={1} />
					<Box ml={1}>{t("navigation")}</Box>
				</Button>
				<Drawer open={isOpen} onClose={toggleIsOpen}>
					<div className={classes.list} role="presentation" onClick={toggleIsOpen}>
						<Box mt={4} pl={1}>
							<List component="nav" aria-label="main mailbox folders">
								{accountLinks.map(({ labelByLanguage, href, iconPath }) => {
									const label =
										labelByLanguage[language] ?? labelByLanguage["de"] ?? ""

									return (
										<Link href={href} key={href}>
											<ListItemButton>
												<ListItemIcon>
													<Icon
														path={iconPath}
														size={0.7}
														color="#7abfac"
													/>
												</ListItemIcon>
												<ListItemText primary={label} />
											</ListItemButton>
										</Link>
									)
								})}
							</List>
						</Box>
					</div>
				</Drawer>
			</Hidden>
		</>
	)
}

export default AccountNav
