import { ProductVariant } from "@secureo/common/typings/Product"

export interface VariantsByVariantId {
	[variantId: string]: ProductVariant
}

const getVariantsByVariantId = (variants: ProductVariant[]) => {
	const variantsByVariantId = variants.reduce((variantsByVariantId, variant) => {
		const { id } = variant
		return {
			...variantsByVariantId,
			[id]: variant,
		}
	}, {} as VariantsByVariantId)

	return variantsByVariantId
}

export default getVariantsByVariantId
