import { DispatchProductDetailPageReducer, ProductDetailPageReducerState } from ".."
import getPricePortions from "../utils/getPricePortions"
import getMsrpPricePortions from "../utils/getMsrpPricePortions"

type SetQuantityPayload = Pick<
	ProductDetailPageReducerState,
	| "quantity"
	| "singleNetPriceCents"
	| "singleGrossPriceCents"
	| "totalNetPriceCents"
	| "totalGrossPriceCents"
	| "singleNetMsrpPriceCents"
	| "singleGrossMsrpPriceCents"
	| "totalNetMsrpPriceCents"
	| "totalGrossMsrpPriceCents"
	| "msrpPricePortions"
>

export const setQuantity = (
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	quantity: number,
) => {
	if (quantity < 1) return

	const {
		variantsByVariantId,
		variantId,
		attributes,
		taxRate,
		countryCode,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
		configurationsByConfigurationGroup,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	} = reducerState

	const {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
	} = getPricePortions(
		variantsByVariantId,
		variantId,
		taxRate,
		quantity,
		countryCode,
		configurationsByConfigurationGroup,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	)

	const {
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	} = getMsrpPricePortions(
		quantity,
		taxRate,
		countryCode,
		attributes,
		configurationsByConfigurationGroup,
		pricePortions.cylinderLockingMechanismProductSingleNetPriceCents,
		pricePortions.cylinderLockingMechanismProductSingleGrossPriceCents,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	)

	const payload: SetQuantityPayload = {
		quantity,
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	}

	dispatch({
		type: "SET_PRODUCT_QUANTITY",
		payload,
	})
}
