import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"
import prependFragmentsToQuery from "../prependFragmentsToQuery"

import { InStoreMe } from "@secureo/common/typings/generated/commercetools-graphql"

const fetchCustomer = async (accessToken: string) => {
	const query = prependFragmentsToQuery(
		/* GraphQL */ `
			query customer {
				inStore(key: "${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}") {
					me {
						customer {
							...customer
						}
					}
				}
			}
		`,
		["customer"],
	)

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query)) as {
		inStore: {
			me: Pick<InStoreMe, "customer">
		}
	}

	return data?.inStore?.me?.customer ?? null
}

export default fetchCustomer
