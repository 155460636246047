import { ChangeEvent } from "react"
import { InnerLengthsByOuterLengths } from "@secureo/common/typings/Product"

export const getAvailableInnerLengthsForOuterLength = (
	innerLengthsByOuterLengths: InnerLengthsByOuterLengths,
	outerLength: number,
) => {
	const availableInnerLengthsForSelectedOuterLength =
		innerLengthsByOuterLengths?.[outerLength] ?? []

	return availableInnerLengthsForSelectedOuterLength
}

const useInnerLengthsByOuterLengthsConfiguration = (
	innerLengthsByOuterLengths: InnerLengthsByOuterLengths,
	selectedOuterLength: number,
	selectedInnerLength: number,
	onOuterLengthChange: (selectedOuterLength: number) => void,
	onInnerLengthChange: (selectedInnerLength: number) => void,
) => {
	const availableOuterLengths = Object.keys(innerLengthsByOuterLengths)
		.map((outerLength) => Number(outerLength))
		.sort()

	const availableInnerLengths = getAvailableInnerLengthsForOuterLength(
		innerLengthsByOuterLengths,
		selectedOuterLength,
	)

	const outerLengthInputProps = {
		value: selectedOuterLength,
		onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			const selectedOuterLength = Number(event.target.value)
			onOuterLengthChange(selectedOuterLength)
		},
	}

	const innerLengthInputProps = {
		value: selectedInnerLength,
		onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			const selectedInnerLength = Number(event.target.value)
			onInnerLengthChange(selectedInnerLength)
		},
	}

	return {
		innerLengthInputProps,
		outerLengthInputProps,
		availableOuterLengths,
		availableInnerLengths,
		onOuterLengthChange,
		onInnerLengthChange,
	}
}

export default useInnerLengthsByOuterLengthsConfiguration
