import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"
import getIncludeNamesFilter from "../getIncludeNamesFilter"

import { RawProduct } from "./fetchProduct"
import formatCylinderConfigurationVariant from "@secureo/common/utils/commerceTools/formatters/formatCylinderConfigurationVariant"

const masterVariantAttributesRawIncludeNames = [
	// General
	"shortDescription",
	// "technDetails",
	"msrpPrice",
	"configurationsGroups",

	// Shipping & delivery
	"deliveryTimeWorkdays",

	// Cross & Upsell
	"crossSellProductSkus",
	"upSellProductSkus",
	"lockingSystemCharges",
]

const fetchCylinderConfigurationVariant = async (
	accessToken: string,
	masterVariantSku: string,
	locale: string,
) => {
	const query = /* GraphQL */ `
        fragment asset on Asset {
			name(locale: "de")
			sources {
				uri
			}
		}
            
        fragment Variant on ProductVariant {
			id
			sku
			prices {
				country
				channel {
					key
				}
				value {
      				centAmount
					currencyCode
    			}
			}
			images {
				url
			}
			assets {
          		...asset
            }
            attributesRaw(includeNames: [${getIncludeNamesFilter(
				masterVariantAttributesRawIncludeNames,
			)}]) {
				name
				value
			}
		}
            
		query cylinderConfigurationProduct($sku: String!) {
			product(sku: $sku) {
				id
				masterData {
					current {
						nameDE: name(locale: "de")
  						nameAT: name(locale: "de-AT")
  						nameCH: name(locale: "de-CH")
  						descriptionDE: description(locale: "de")
  						descriptionAT: description(locale: "de-AT")
  						descriptionCH: description(locale: "de-CH")
						masterVariant {
							...Variant
						}
					}
				}
			}
		}
	`

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query, {
		sku: masterVariantSku,
	})) as {
		product: RawProduct
	}

	const cylinderConfigurationProduct = data?.product ?? null

	if (!cylinderConfigurationProduct) return null

	const formattedCylinderConfigurationVariant = formatCylinderConfigurationVariant(
		cylinderConfigurationProduct,
		locale,
	)

	return formattedCylinderConfigurationVariant
}

export default fetchCylinderConfigurationVariant
