import React, { createContext, useReducer } from "react"

import { CurrencyCode, ExchangeRates } from "@secureo/common/typings/Forex"
import { Cart } from "@secureo/common/typings/Cart"
import { ShoppingList } from "@secureo/common/typings/ShoppingList"
import { Customer } from "@secureo/common/typings/generated/commercetools-graphql"
import { CountryCode } from "@secureo/common/typings/CountryCode"

export const defaultAppContext: AppContextState = {
	isInitialized: false,
	loginRegisterFormIsOpen: false,
	openLoginRegisterFormTab: "login",
	isLoggedIn: false,
	showHeader: true,
	accessToken: null,
	expiryDateMs: null,
	cart: null,
	shoppingList: null,
	customer: null,
	currencyCode: "EUR" as CurrencyCode,
	language: "de",
	countryCode: "DE",
	isBusinessVersion: false,
	exchangeRatesEuro: {
		EUR: {
			rate: 1,
			validUntilTimestamp: Infinity,
		},
	} as ExchangeRates,
	// eslint-disable-next-line
	dispatch: (action: AppContextReducerAction) => {},
}

export interface AppContextState {
	isInitialized: boolean
	loginRegisterFormIsOpen: boolean
	openLoginRegisterFormTab: "login" | "register"
	isLoggedIn: boolean
	showHeader: boolean
	accessToken?: string
	expiryDateMs?: number
	cart?: Cart
	shoppingList?: ShoppingList
	customer?: Customer
	isBusinessVersion: boolean
	currencyCode: CurrencyCode
	exchangeRatesEuro?: ExchangeRates
	language: string
	countryCode: CountryCode
	dispatch: DispatchAppContext
}

const appContextReducer = (state: AppContextState, action: AppContextReducerAction) => {
	const { type, payload } = action

	switch (type) {
		case "SET_IS_INITIALIZED":
			return {
				...state,
				isInitialized: true,
			}

		case "TOGGLE_LOGIN_REGISTER_FORM_IS_OPEN":
			return {
				...state,
				loginRegisterFormIsOpen: !state.loginRegisterFormIsOpen,
			}

		case "SET_OPEN_LOGIN_REGISTER_FORM_TAB":
			return {
				...state,
				openLoginRegisterFormTab: payload,
			}

		case "OPEN_LOGIN_REGISTER_FORM_TAB":
			return {
				...state,
				loginRegisterFormIsOpen: true,
				openLoginRegisterFormTab: payload,
			}

		case "LOGOUT":
			return {
				...state,
				isLoggedIn: false,
				accessToken: null,
				expiryDateMs: null,
				cart: null,
				customer: null,
			}

		case "SET_ACCESS_TOKEN":
			return {
				...state,
				accessToken: payload.accessToken,
				expiryDateMs: payload.expiryDateMs,
			}

		case "SET_CART":
			return {
				...state,
				cart: payload,
			}

		case "SET_SHOPPING_LIST":
			return {
				...state,
				shoppingList: payload,
			}

		case "SET_CUSTOMER":
			return {
				...state,
				customer: payload,
				isLoggedIn: true,
			}

		case "SET_BUSINESS_VERSION":
			return {
				...state,
				isBusinessVersion: true,
			}

		case "SET_DEFAULT_VERSION":
			return {
				...state,
				...payload,
				isBusinessVersion: false,
			}

		case "CHANGE_CURRENCY":
			return {
				...state,
				currencyCode: payload.currencyCode,
				exchangeRatesEuro: {
					...state.exchangeRatesEuro,
					[payload.currencyCode]: {
						rate: payload.rate,
						validUntilTimestamp: payload.validUntilTimestamp,
					},
				},
			}

		case "CHANGE_LOCALE":
			return {
				...state,
				...payload,
			}

		case "SEARCHBOX_IS_OPEN":
			return {
				...state,
				showHeader: !state.showHeader,
			}

		default:
			console.error(`Undefined reducer action type "${type}"`)
			return state
	}
}

export const AppContext = createContext(defaultAppContext)

export const AppContextProvider = ({
	children,
	language,
	countryCode,
	currencyCode = "EUR",
	initialIsBusinessVersion = false,
}: AppContextProviderProps) => {
	const [state, dispatch] = useReducer(appContextReducer, {
		...defaultAppContext,
		language,
		countryCode,
		currencyCode,
		initialIsBusinessVersion,
	})
	return <AppContext.Provider value={{ ...state, dispatch }}>{children}</AppContext.Provider>
}

interface AppContextProviderProps {
	children: React.ReactNode
	language: string
	countryCode: CountryCode
	currencyCode?: CurrencyCode
	initialIsBusinessVersion?: boolean
}

export interface AppContextReducerAction {
	type:
		| "SET_IS_INITIALIZED"
		| "TOGGLE_LOGIN_REGISTER_FORM_IS_OPEN"
		| "SET_OPEN_LOGIN_REGISTER_FORM_TAB"
		| "OPEN_LOGIN_REGISTER_FORM_TAB"
		| "LOGOUT"
		| "SET_ACCESS_TOKEN"
		| "SET_CUSTOMER"
		| "SET_CART"
		| "SET_SHOPPING_LIST"
		| "SET_BUSINESS_VERSION"
		| "SET_DEFAULT_VERSION"
		| "CHANGE_CURRENCY"
		| "CHANGE_LOCALE"
		| "SEARCHBOX_IS_OPEN"
	payload?: any
}

export type DispatchAppContext = (action: AppContextReducerAction) => void

export default AppContext
