import { useState, useContext } from "react"
import { IconButton, Box, Popover } from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import { mdiEarth, mdiCircleMedium } from "@mdi/js"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import ChangeLocalAndCurrencyPopover from "../i18n/ChangeLocalAndCurrencyPopover"
import CountryFlag from "../i18n/CountryFlag"

import getCurrencySymbol from "@secureo/common/utils/forex/getCurrencySymbol"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const useStyles = makeStyles()(() => ({
	popoverPosition: {
		marginTop: "30px",
		maxWidth: 300,
	},
}))

// TODO: Change html lang tag
const ChangeLocaleAndCurrency = () => {
	const { classes } = useStyles()
	const { currencyCode, isBusinessVersion, countryCode, language } = useContext(AppContext)
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined

	return (
		<>
			<IconButton
				aria-label="Change locale and currency"
				aria-haspopup="true"
				color="inherit"
				style={{ padding: "2px" }}
				aria-describedby={id}
				onClick={handleClick}
				size="large"
			>
				<div
					style={{
						position: "absolute",
						top: "-5px",
						zIndex: 1,
						width: "25px",
						height: "100%",
						left: "-10px",
					}}
				>
					<CountryFlag countryCode={countryCode as CountryCode} />
				</div>
				<Icon
					path={mdiEarth}
					title="Change Language, country and currency"
					size={1.3}
					color={!isBusinessVersion ? "#ffffff" : ""}
				/>
			</IconButton>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				className={classes.popoverPosition}
			>
				<ChangeLocalAndCurrencyPopover handleClose={handleClose} />
			</Popover>

			<Box style={{ fontSize: "14px" }}>
				{language?.toUpperCase()}
				<Icon path={mdiCircleMedium} size={0.6} color="#f7fafa" />
				{getCurrencySymbol(currencyCode)}
			</Box>
		</>
	)
}

export default ChangeLocaleAndCurrency
