import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"
import prependFragmentsToQuery from "../prependFragmentsToQuery"
import formatShoppingList from "@secureo/common/utils/commerceTools/formatters/formatShoppingList"

import {
	ShoppingList,
	MyShoppingListUpdateAction,
} from "@secureo/common/typings/generated/commercetools-graphql"
import { CurrencyCode } from "@secureo/common/typings/Forex"

const updateMyShoppingList = async (
	accessToken: string,
	id: string,
	version: number,
	actions: MyShoppingListUpdateAction[],
	currencyCode: CurrencyCode,
) => {
	const query = prependFragmentsToQuery(
		/* GraphQL */ `
			mutation updateMyShoppingList(
				$id: String!
				$version: Long!
				$actions: [MyShoppingListUpdateAction!]!
			) {
				updateMyShoppingList(id: $id, version: $version, actions: $actions) {
					...shoppingList
				}
			}
		`,
		["shoppingList"],
	)

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query, {
		id,
		version,
		actions,
	})) as {
		updateMyShoppingList: ShoppingList
	}

	const shoppingList = data?.updateMyShoppingList ?? null
	const formattedShoppingList = formatShoppingList(shoppingList, currencyCode)

	return formattedShoppingList
}

export default updateMyShoppingList
