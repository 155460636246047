import axios from "axios"
import base64 from "base-64"

import logAxiosError from "../../logAxiosError"

const api = `https://auth.europe-west1.gcp.commercetools.com/oauth/${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}/anonymous/token`

const createAnonymousToken = async () => {
	try {
		const { data } = await axios.post(
			api,
			`grant_type=client_credentials&scope=${process.env.NEXT_PUBLIC_COMMERCETOOLS_FRONTEND_CLIENT_SCOPES} create_anonymous_token:${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
			{
				headers: {
					Authorization: `Basic ${base64.encode(
						`${process.env.NEXT_PUBLIC_COMMERCETOOLS_FRONTEND_CLIENT_ID}:${process.env.NEXT_PUBLIC_COMMERCETOOLS_FRONTEND_CLIENT_SECRET}`,
					)}`,
					"Content-Type": "application/x-www-form-urlencoded",
				},
			},
		)

		const { token_type, access_token, refresh_token, expires_in } = data

		const expiryDateMs = Date.now() + expires_in * 1000

		if (!data || !token_type || !access_token) {
			console.error("error in createAnonymousToken - data")
			console.error(data)
			throw data
		}

		return {
			accessToken: `${token_type} ${access_token}`,
			refreshToken: refresh_token,
			expiryDateMs,
		}
	} catch (err) {
		console.error("Failed to create anonymous accessToken")
		console.error(err)
		logAxiosError(err)
		return null
	}
}

export default createAnonymousToken
