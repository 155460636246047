import { ProductAttributes } from "@secureo/common/typings/Product"
import { ProductDetailPageReducerState } from ".."

export type CylinderConfigurationType =
	| "innerLengthsByOuterLengths"
	| "lengthCombinationsBySystem"
	| "outerLengths"
	| "outerLengthsBySystem"
	| "systems"

const getInitialCylinderConfigurations = (
	attributes: ProductAttributes,
): Partial<
	Pick<
		ProductDetailPageReducerState,
		| "cylinderConfigurationType"
		| "selectedSystem"
		| "selectedOuterLength"
		| "selectedInnerLength"
	>
> => {
	const {
		innerLengthsByOuterLengths,
		lengthCombinationsBySystem,
		outerLengths,
		outerLengthsBySystem,
		systems,
	} = attributes

	if (lengthCombinationsBySystem) {
		const systems = Object.keys(lengthCombinationsBySystem)

		const selectedSystem = systems?.[0] ?? null
		const selectedOuterLength =
			lengthCombinationsBySystem?.[selectedSystem]?.[0]?.outerLength ?? null
		const selectedInnerLength =
			lengthCombinationsBySystem?.[selectedSystem]?.[0]?.innerLength ?? null

		return {
			cylinderConfigurationType: "lengthCombinationsBySystem",
			selectedSystem,
			selectedOuterLength,
			selectedInnerLength,
		}
	}

	if (innerLengthsByOuterLengths) {
		const outerLengths = Object.keys(innerLengthsByOuterLengths)
			.map((outerLength) => Number(outerLength))
			.sort((a, b) => a - b)

		const selectedOuterLength = outerLengths?.[0] ?? null
		const selectedInnerLength = innerLengthsByOuterLengths?.[selectedOuterLength]?.[0] ?? null

		return {
			cylinderConfigurationType: "innerLengthsByOuterLengths",
			selectedSystem: null,
			selectedOuterLength,
			selectedInnerLength,
		}
	}

	if (outerLengths) {
		const [selectedOuterLength] = outerLengths

		return {
			cylinderConfigurationType: "outerLengths",
			selectedSystem: null,
			selectedOuterLength,
			selectedInnerLength: null,
		}
	}

	if (outerLengthsBySystem) {
		const systems = Object.keys(outerLengthsBySystem).sort()

		const selectedSystem = systems?.[0] ?? null
		const selectedOuterLength = outerLengthsBySystem?.[selectedSystem]?.[0]?.outerLength ?? null

		return {
			cylinderConfigurationType: "outerLengthsBySystem",
			selectedSystem,
			selectedOuterLength,
			selectedInnerLength: null,
		}
	}

	if (systems) {
		const selectedSystem = systems?.[0]?.system ?? null

		return {
			cylinderConfigurationType: "systems",
			selectedSystem,
			selectedOuterLength: null,
			selectedInnerLength: null,
		}
	}

	// Fallback
	const initialCylinderConfigurations = {
		cylinderConfigurationType: null,
		selectedSystem: null,
		selectedOuterLength: null,
		selectedInnerLength: null,
	}

	return initialCylinderConfigurations
}

export default getInitialCylinderConfigurations
