import { getLineItemTaxRate } from "@secureo/common/utils/tax"

import { CountryCode } from "@secureo/common/typings/CountryCode"
import { ShippingOriginationCountryCodesByDestinationCountryCode } from "@secureo/common/typings/Product"

const getTaxRate = (
	shippingOriginationCountryCodesByDestinationCountryCode: ShippingOriginationCountryCodesByDestinationCountryCode,
	destinationCountryCode: CountryCode,
) => {
	const shippingOriginationCountryCode =
		shippingOriginationCountryCodesByDestinationCountryCode?.[destinationCountryCode] ?? "DE"
	const { taxRate } = getLineItemTaxRate(shippingOriginationCountryCode, destinationCountryCode)

	return taxRate
}

export default getTaxRate
