import {
	LengthCombinationsBySystem,
	OuterLengthsBySystem,
	SystemCylinderConfiguration,
	ProductAttributes,
} from "@secureo/common/typings/Product"
import { CylinderConfigurationType } from "./getInitialCylinderConfigurations"
import { VariantsByVariantId } from "./getVariantsByVariantId"

const getCylinderConfigurationVariantSkuFromLengthCombinationsBySystem = (
	lengthCombinationsBySystem: LengthCombinationsBySystem,
	selectedSystem: string,
	selectedOuterLength: number,
	selectedInnerLength: number,
) => {
	const lengthCombinationsForSystem = lengthCombinationsBySystem?.[selectedSystem]

	const lengthCombination = lengthCombinationsForSystem.find(
		(lengthCombination) =>
			lengthCombination.outerLength === selectedOuterLength &&
			lengthCombination.innerLength === selectedInnerLength,
	)

	return lengthCombination?.sku ?? null
}

const getCylinderConfigurationVariantSkuFromOuterLengthsBySystem = (
	outerLengthsBySystem: OuterLengthsBySystem,
	selectedSystem: string,
	selectedOuterLength: number,
) => {
	const outerLengthsForSystem = outerLengthsBySystem?.[selectedSystem]

	const outerLength = outerLengthsForSystem.find(
		(outerLength) => outerLength.outerLength === selectedOuterLength,
	)

	return outerLength?.sku ?? null
}

const getCylinderConfigurationVariantSkuFromSystems = (
	systems: SystemCylinderConfiguration[],
	selectedSystem: string,
) => {
	const systemSku = systems.find(({ system }) => system === selectedSystem)?.sku ?? null

	return systemSku
}

const getCylinderConfigurationVariantSku = (
	cylinderConfigurationType: CylinderConfigurationType,
	attributes: ProductAttributes,
	variantsByVariantId: VariantsByVariantId,
	selectedSystem: string,
	selectedOuterLength: number,
	selectedInnerLength: number,
) => {
	const { lengthCombinationsBySystem, outerLengthsBySystem, systems } = attributes

	const masterVariantSku = variantsByVariantId?.[1]?.sku ?? null

	switch (cylinderConfigurationType) {
		case "innerLengthsByOuterLengths":
			return `${masterVariantSku}-${selectedOuterLength}-${selectedInnerLength}`

		case "lengthCombinationsBySystem":
			return getCylinderConfigurationVariantSkuFromLengthCombinationsBySystem(
				lengthCombinationsBySystem,
				selectedSystem,
				selectedOuterLength,
				selectedInnerLength,
			)

		case "outerLengths":
			return `${masterVariantSku}-${selectedOuterLength}`

		case "outerLengthsBySystem":
			return getCylinderConfigurationVariantSkuFromOuterLengthsBySystem(
				outerLengthsBySystem,
				selectedSystem,
				selectedOuterLength,
			)

		case "systems":
			return getCylinderConfigurationVariantSkuFromSystems(systems, selectedSystem)

		default: {
			return null
		}
	}
}

export default getCylinderConfigurationVariantSku
