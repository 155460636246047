import { centsToEuros } from "@secureo/common/utils/forex/convertCurrencyFraction"

import { EnhancedEcommerceCartProductFieldObject } from "@secureo/common/typings/EnhancedEcommerce"
import { Cart } from "@secureo/common/typings/Cart"
import { GA4Item } from "@secureo/common/typings/GoogleAnalytics4Ecommerce"

// TODO: Track custom line items also?
const getRemoveFromCartFieldObjects = (cart: Cart, lineItemIdsToRemove: string[]) => {
	const { lineItems } = cart

	const lineItemsToRemove = lineItemIdsToRemove.map((lineItemId) =>
		lineItems.find((lineItem) => lineItem.id === lineItemId),
	)

	const removeFromCartFieldObjects = lineItemsToRemove.map((lineItem) => {
		const {
			name,
			variant: { sku, manufacturer },
			quantity,
			taxedPrice: {
				totalGross: { centAmount: totalGrossCents },
			},
			masterVariantSku,
		} = lineItem

		const singleGrossCents = totalGrossCents / quantity

		const removeFromCartFieldObject: EnhancedEcommerceCartProductFieldObject = {
			name,
			id: masterVariantSku,
			price: centsToEuros(singleGrossCents),
			brand: manufacturer,
			category: "",
			variant: sku,
			quantity,
		}

		return removeFromCartFieldObject
	})

	return removeFromCartFieldObjects
}

export const getGA4RemoveFromCartItems = (cart: Cart, lineItemIdsToRemove: string[]) => {
	const { lineItems } = cart

	const lineItemsToRemove = lineItemIdsToRemove.map((lineItemId) =>
		lineItems.find((lineItem) => lineItem.id === lineItemId),
	)

	const removeFromCartItems = lineItemsToRemove.map((lineItem) => {
		const {
			name,
			variant: { sku, manufacturer },
			quantity,
			taxedPrice: {
				totalGross: { centAmount: totalGrossCents },
			},
			masterVariantSku,
		} = lineItem

		const singleGrossCents = totalGrossCents / quantity

		const removeFromCartItem: GA4Item = {
			item_name: name,
			item_id: masterVariantSku,
			price: centsToEuros(singleGrossCents),
			item_brand: manufacturer,
			item_category: "",
			item_variant: sku,
			quantity,
		}

		return removeFromCartItem
	})

	return removeFromCartItems
}

export default getRemoveFromCartFieldObjects
