import setIsLoading from "@secureo/common/reducers/productDetailPageReducer/utils/setIsLoading"
import getCylinderConfigurationVariantSku from "@secureo/common/reducers/productDetailPageReducer/utils/getCylinderConfigurationVariantSku"
import fetchCylinderConfigurationVariant from "@secureo/common/utils/commerceTools/graphql/queries/fetchCylinderConfigurationVariant"
import { setCylinderConfigurationVariant } from "./setCylinderConfigurationVariant"

import {
	DispatchProductDetailPageReducer,
	ProductDetailPageReducerState,
} from "@secureo/common/reducers/productDetailPageReducer"
import { CylinderConfigurationType } from "@secureo/common/reducers/productDetailPageReducer/utils/getInitialCylinderConfigurations"

export const getSelectedConfigurationsAfterInnerLengthChange = (
	cylinderConfigurationType: CylinderConfigurationType,
	selectedSystem: string,
	selectedOuterLength: number,
	selectedInnerLength: number,
): {
	selectedSystem: string
	selectedOuterLength: number
	selectedInnerLength: number
} => {
	switch (cylinderConfigurationType) {
		case "innerLengthsByOuterLengths":
			return {
				selectedSystem: null,
				selectedOuterLength,
				selectedInnerLength,
			}

		case "lengthCombinationsBySystem":
			return {
				selectedSystem,
				selectedOuterLength,
				selectedInnerLength,
			}

		default:
			console.error(
				`No action defined for cylinderConfigurationType "${cylinderConfigurationType}"`,
			)
			return null
	}
}

export const setSelectedInnerLength = async (
	accessToken: string,
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	selectedInnerLength: number,
) => {
	const { cylinderConfigurationType, attributes, variantsByVariantId, locale } = reducerState

	setIsLoading(dispatch, true)

	const { selectedSystem, selectedOuterLength } = getSelectedConfigurationsAfterInnerLengthChange(
		cylinderConfigurationType,
		reducerState.selectedSystem,
		reducerState.selectedOuterLength,
		selectedInnerLength,
	)

	dispatch({
		type: "SET_CYLINDER_CONFIGURATIONS",
		payload: {
			selectedInnerLength,
		},
	})

	const cylinderConfigurationVariantSku = getCylinderConfigurationVariantSku(
		cylinderConfigurationType,
		attributes,
		variantsByVariantId,
		selectedSystem,
		selectedOuterLength,
		selectedInnerLength,
	)
	const cylinderConfigurationVariant = await fetchCylinderConfigurationVariant(
		accessToken,
		cylinderConfigurationVariantSku,
		locale,
	)
	setCylinderConfigurationVariant(dispatch, reducerState, cylinderConfigurationVariant)

	setIsLoading(dispatch, false)
}
