import { connectHits } from "react-instantsearch-dom"
import { Grid, Box } from "@mui/material"
import { makeStyles } from "makeStyles"

import Link from "../../i18n/Link"

import formatPrice from "@secureo/common/utils/forex/formatPrice"

import { AlgoliaProductHit } from "@secureo/common/typings/Algolia"
import { CurrencyCode } from "typings/Forex"

const useStyles = makeStyles()(() => ({
	productCard: {
		display: "flex",
		position: "relative",
	},
	productImage: {
		width: "80px",
		height: "80px",
		objectFit: "contain",
		margin: "auto",
		marginRight: "15px",
		marginTop: "10px",
		textAlign: "center",
	},
	productPriceAndButton: {
		fontWeight: "bold",
	},
}))

const ProductResult = ({ hit, closeSearchBar, currencyCode }: ProductResultProps) => {
	const { classes } = useStyles()
	const { name, image, slug, grossPriceCents, grossPricePLNCents } = hit

	// const cutProductName = (name = "") => (name.length > 35 ? `${name.substring(0, 40)} ...` : name)

	return (
		<Grid item xs={12} sm={6}>
			<Link
				href={`/p/${slug}`}
				style={{ textDecoration: "none", color: "inherit" }}
				onClick={closeSearchBar}
			>
				<div className={classes.productCard}>
					<div>
						<img alt={name} src={image} className={classes.productImage} />
					</div>
					<Box mt={2}>
						<div>{name}</div>
						<Box className={classes.productPriceAndButton}>
							{formatPrice(
								currencyCode === "PLN" ? grossPricePLNCents : grossPriceCents,
								"de-DE",
								currencyCode,
							)}
						</Box>
					</Box>
				</div>
			</Link>
		</Grid>
	)
}

interface ProductResultProps {
	hit: AlgoliaProductHit
	closeSearchBar: () => void
	currencyCode: CurrencyCode
}

const ProductResults = ({ hits, isExpanded, closeSearchBar, currencyCode }: Props) => {
	const hasResults = hits.length > 0

	if (!isExpanded) return null

	return (
		<div>
			<Box mt={3}>
				<Grid container spacing={2}>
					{hasResults ? (
						hits.map((hit) => {
							const { objectID } = hit
							return (
								<ProductResult
									key={objectID}
									hit={hit}
									closeSearchBar={closeSearchBar}
									currencyCode={currencyCode}
								/>
							)
						})
					) : (
						<p>Keine Ergebnisse</p>
					)}
				</Grid>
			</Box>
		</div>
	)
}

interface Props {
	hits: AlgoliaProductHit[]
	isExpanded: boolean
	closeSearchBar: () => void
	currencyCode: CurrencyCode
}

export default connectHits(ProductResults)
