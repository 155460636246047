import { CountryCode } from "@secureo/common/typings/CountryCode"

import Austria from "../../svg/flags/Austria.svg"
import Germany from "../../svg/flags/Germany.svg"
import Switzerland from "../../svg/flags/Switzerland.svg"

import Belgium from "../../svg/flags/Belgium.svg"
import Bulgaria from "../../svg/flags/Bulgaria.svg"
import Croatia from "../../svg/flags/Croatia.svg"
import CzechRepublic from "../../svg/flags/CzechRepublic.svg"
import Denmark from "../../svg/flags/Denmark.svg"
import Estonia from "../../svg/flags/Estonia.svg"
import Finland from "../../svg/flags/Finland.svg"
import France from "../../svg/flags/France.svg"
import Greece from "../../svg/flags/Greece.svg"
import Hungary from "../../svg/flags/Hungary.svg"
import Ireland from "../../svg/flags/Ireland.svg"
import Italy from "../../svg/flags/Italy.svg"
import Latvia from "../../svg/flags/Latvia.svg"
import Lithuania from "../../svg/flags/Lithuania.svg"
import Luxembourg from "../../svg/flags/Luxembourg.svg"
import Liechtenstein from "../../svg/flags/Liechtenstein.svg"
import Netherlands from "../../svg/flags/Netherlands.svg"
import Norway from "../../svg/flags/Norway.svg"
import Poland from "../../svg/flags/Poland.svg"
import Portugal from "../../svg/flags/Portugal.svg"
import Romania from "../../svg/flags/Romania.svg"
import Slovakia from "../../svg/flags/Slovakia.svg"
import Slovenia from "../../svg/flags/Slovenia.svg"
import Spain from "../../svg/flags/Spain.svg"
import Sweden from "../../svg/flags/Sweden.svg"
import UnitedKingdom from "../../svg/flags/UnitedKingdom.svg"

// TODO: Add all countries
const CountryFlag = ({ countryCode }: Props) => {
	switch (countryCode) {
		case "AT":
			return <Austria />
		case "DE":
			return <Germany />
		case "CH":
			return <Switzerland />
		case "BE":
			return <Belgium />
		case "BG":
			return <Bulgaria />
		case "HR":
			return <Croatia />
		// case "CH":
		// 	return <Cyprus />
		case "CZ":
			return <CzechRepublic />
		case "DK":
			return <Denmark />
		case "EE":
			return <Estonia />
		case "FI":
			return <Finland />
		case "FR":
			return <France />
		case "GR":
			return <Greece />
		case "HU":
			return <Hungary />
		case "IE":
			return <Ireland />
		case "IT":
			return <Italy />
		case "LV":
			return <Latvia />
		case "LT":
			return <Lithuania />
		case "LU":
			return <Luxembourg />
		case "LI":
			return <Liechtenstein />
		case "NL":
			return <Netherlands />
		case "NO":
			return <Norway />
		case "PL":
			return <Poland />
		case "PT":
			return <Portugal />
		case "RO":
			return <Romania />
		case "SK":
			return <Slovakia />
		case "SI":
			return <Slovenia />
		case "ES":
			return <Spain />
		case "SE":
			return <Sweden />
		case "GB":
			return <UnitedKingdom />
		default:
			return null
	}
}

interface Props {
	countryCode: CountryCode
}

export default CountryFlag
