import { ConfigurationGroup } from "@secureo/common/typings/Product"
import {
	ProductConfigurationsByConfigurationGroup,
	ProductConfigurationsBySku,
} from "@secureo/common/reducers/productDetailPageReducer"
import { ProviderContext } from "notistack"

export const notifyUserOfChangedConfigurations = (
	validPrevConfigurations: ProductConfigurationsByConfigurationGroup,
	prevConfigurationsByConfigurationGroup: ProductConfigurationsByConfigurationGroup,
	enqueueSnackbar: ProviderContext["enqueueSnackbar"],
	componentName?: string,
) => {
	const prefix = componentName ? `${componentName}: ` : ""
	// Check which selected configurations where removed because they are not available in new variant
	const newConfigurationGroupNames = Object.keys(validPrevConfigurations)
	for (const [configurationGroupName, configurationsBySku] of Object.entries(
		prevConfigurationsByConfigurationGroup,
	)) {
		if (!newConfigurationGroupNames.includes(configurationGroupName)) {
			enqueueSnackbar(`${prefix}${configurationGroupName} entfernt`, {
				variant: "warning",
			})
		} else {
			const prevSelectedSkusInConfigurationGroup = Object.keys(
				validPrevConfigurations[configurationGroupName],
			)

			let numberOfRemovedConfigurationsInGroup = 0
			for (const sku of Object.keys(configurationsBySku)) {
				if (!prevSelectedSkusInConfigurationGroup.includes(sku)) {
					numberOfRemovedConfigurationsInGroup++
				}
			}

			if (numberOfRemovedConfigurationsInGroup > 0) {
				enqueueSnackbar(`${prefix}${configurationGroupName} angepasst`, {
					variant: "warning",
				})
			}
		}
	}
}

const validateConfigurations = (
	prevConfigurationsByConfigurationGroup: ProductConfigurationsByConfigurationGroup,
	newConfigurationGroups: ConfigurationGroup[],
) => {
	const newConfigurationGroupNames = newConfigurationGroups.map(
		(configurationGroup) => configurationGroup.name,
	)

	const validConfigurations = Object.entries(prevConfigurationsByConfigurationGroup).reduce(
		(validConfigurations, [configurationGroupName, configurationsBySku]) => {
			// Exclude all configurations from group if group is not available in new variant
			if (!newConfigurationGroupNames.includes(configurationGroupName)) {
				return validConfigurations
			}

			const newConfigurationGroup = newConfigurationGroups.find(
				(configurationGroup) => configurationGroup.name === configurationGroupName,
			)
			const newConfigurationGroupSkus = newConfigurationGroup.items.map(({ sku }) => sku)

			const validGroupConfigurationsBySku = Object.values(configurationsBySku).reduce(
				(validGroupConfigurationsBySku, configuration) => {
					const { sku } = configuration

					// Exclude configuration if sku is not available in updated configuration group
					if (!newConfigurationGroupSkus.includes(sku))
						return validGroupConfigurationsBySku

					return {
						...validGroupConfigurationsBySku,
						[sku]: configuration,
					}
				},
				{} as ProductConfigurationsBySku,
			)

			return {
				...validConfigurations,
				[configurationGroupName]: validGroupConfigurationsBySku,
			}
		},
		{} as ProductConfigurationsByConfigurationGroup,
	)

	return validConfigurations
}

export default validateConfigurations
