import axios from "axios"
import base64 from "base-64"

import logAxiosError from "../../logAxiosError"
import { removeAuthTokenFromLocalStorage } from "@secureo/common/utils/auth/authTokenPersistence"

const api = `https://auth.europe-west1.gcp.commercetools.com/oauth/token`

const refreshAuthToken = async (refreshToken: string) => {
	// Needs to be encoded, because it has a "=" char appended
	const encodedRefreshToken = encodeURIComponent(refreshToken)
	try {
		const { data } = await axios.post(
			api,
			`grant_type=refresh_token&refresh_token=${encodedRefreshToken}`,
			{
				headers: {
					Authorization: `Basic ${base64.encode(
						`${process.env.NEXT_PUBLIC_COMMERCETOOLS_FRONTEND_CLIENT_ID}:${process.env.NEXT_PUBLIC_COMMERCETOOLS_FRONTEND_CLIENT_SECRET}`,
					)}`,
					"Content-Type": "application/x-www-form-urlencoded",
				},
			},
		)

		const { token_type, access_token, expires_in } = data

		const expiryDateMs = Date.now() + expires_in * 1000

		console.info("Successfully refreshed authToken")

		return {
			accessToken: `${token_type} ${access_token}`,
			refreshToken,
			expiryDateMs,
		}
	} catch (err) {
		console.error("Error while refreshing accessToken")
		logAxiosError(err)
		console.info("Removing authToken from localStorage")
		removeAuthTokenFromLocalStorage()
		window.location.reload()
		return null
	}
}

export default refreshAuthToken
