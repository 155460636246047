import React, { createContext, useReducer } from "react"

import getStateAfterAddressUpdate from "./utils/getStateAfterAddressUpdate"

import { PaymentMethod, PaymentMethodKey } from "@secureo/common/typings/Payment"
import { FormAddress, AddressValidationErrors } from "@secureo/common/typings/FormAddress"
import { KlarnaPaymentMethodCategory } from "@secureo/common/typings/Klarna"
import {
	KlarnaFraudStatus,
	KlarnaAuthorizedPaymentMethod,
} from "@secureo/common/typings/KlarnaPaymentsSDK"
import { DeliveryOption } from "@secureo/common/typings/Product"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const defaultFormAddress: FormAddress = {
	isCompany: false,
	salutation: "Mr",
	firstName: "",
	lastName: "",
	streetName: "",
	streetNumber: "",
	postalCode: "",
	city: "",
	country: "DE",
	email: "",
	phone: "",
	company: "",
	vatId: "",
	department: "",
	additionalAddressInfo: "",
}

const getDefaultFormAddressFromShopCountryCode = (shopCountryCode: CountryCode) => {
	return {
		...defaultFormAddress,
		country: shopCountryCode,
	}
}

const defaultCheckoutContext: CheckoutContextState = {
	isInitialized: false,
	isLoading: true,
	isInCheckoutFlow: false,
	shippingAddress: defaultFormAddress,
	shippingAddressValidationErrors: {},
	billingAddress: defaultFormAddress,
	billingAddressValidationErrors: {},
	selectedPaymentMethod: "prepaid" as PaymentMethodKey,
	shippingAddressDiffers: false,
	hasOrderAnnotation: false,
	orderAnnotation: "",
	deliveryOptionsBySku: {},
	selectedDeliveryOptionSkuByLineItemId: {},
	paymentMethods: [],
	klarnaPayments: {
		isInitialized: false,
		initializedPaymentMethod: null,
		show_form: false,
		finalize_required: false,
		approved: false,
		fraud_status: null,
		session_id: null,
		client_token: null,
		authorization_token: null,
		payment_method_categories: [],
		authorized_payment_method: null,
		error: null,
		redirect_url: null,
		order_id: null,
	},
	hideKlarnaPayments: false,
	// eslint-disable-next-line
	dispatch: (action: CheckoutContextReducerAction) => {},
}

export interface CheckoutContextState {
	isInitialized: boolean
	isLoading: boolean
	isInCheckoutFlow: boolean
	shippingAddress: FormAddress
	shippingAddressValidationErrors: AddressValidationErrors
	billingAddress: FormAddress
	billingAddressValidationErrors: AddressValidationErrors
	selectedPaymentMethod: PaymentMethodKey
	shippingAddressDiffers: boolean
	hasOrderAnnotation: boolean
	orderAnnotation: string
	deliveryOptionsBySku: {
		[sku: string]: DeliveryOption
	}
	/**
	 * This field is unused in Tresoro, calculating it on the fly prevents race conditions
	 */
	selectedDeliveryOptionSkuByLineItemId: {
		[lineItemId: string]: string
	}
	paymentMethods: PaymentMethod[]
	klarnaPayments: {
		isInitialized: boolean
		initializedPaymentMethod: string
		show_form: boolean
		finalize_required: boolean
		approved: boolean
		fraud_status?: KlarnaFraudStatus
		session_id?: string
		client_token?: string
		authorization_token?: string
		payment_method_categories: KlarnaPaymentMethodCategory[]
		authorized_payment_method?: {
			type: KlarnaAuthorizedPaymentMethod
		}
		error?: {
			invalid_fields: string[]
		}
		redirect_url?: string
		order_id?: string
	}
	hideKlarnaPayments: boolean
	dispatch: DispatchCheckoutContext
}

const CheckoutContextReducer = (
	state: CheckoutContextState,
	action: CheckoutContextReducerAction,
) => {
	const { type, payload } = action

	switch (type) {
		case "SET_SHIPPING_ADDRESS":
		case "TOGGLE_SHIPPING_ADDRESS_IS_COMPANY":
		case "SET_BILLING_ADDRESS":
		case "TOGGLE_BILLING_ADDRESS_IS_COMPANY":
		case "TOGGLE_SHIPPING_ADDRESS_DIFFERS":
		case "SET_SHIPPING_ADDRESS_ERRORS":
		case "SET_BILLING_ADDRESS_ERRORS":
			return getStateAfterAddressUpdate(state, action)

		case "SET_IS_INITIALIZED":
			return {
				...state,
				isInitialized: true,
				isLoading: false,
			}

		case "INITIALIZE_KLARNA_PAYMENTS_SESSION":
			return {
				...state,
				klarnaPayments: {
					...state.klarnaPayments,
					...payload,
					isInitialized: true,
				},
			}

		case "UPDATE_KLARNA_PAYMENTS_SESSION":
			return {
				...state,
				klarnaPayments: {
					...state.klarnaPayments,
					...payload,
				},
			}

		case "SET_PAYMENT_METHOD":
			return {
				...state,
				selectedPaymentMethod: payload,
			}

		case "TOGGLE_HAS_ORDER_ANNOTATIONS":
			return {
				...state,
				hasOrderAnnotation: !state.hasOrderAnnotation,
			}

		case "SET_ORDER_ANNOTATION":
			return {
				...state,
				orderAnnotation: payload,
				hasOrderAnnotation: !!payload,
			}

		case "SET_DELIVERY_OPTIONS_BY_SKU":
			return {
				...state,
				deliveryOptionsBySku: payload,
			}

		case "SET_SELECTED_DELIVERY_OPTIONS_BY_SKU":
			return {
				...state,
				selectedDeliveryOptionSkuByLineItemId: {
					...state.selectedDeliveryOptionSkuByLineItemId,
					...payload,
				},
			}

		case "RESET_SELECTED_DELIVERY_OPTIONS_BY_SKU":
			return {
				...state,
				selectedDeliveryOptionSkuByLineItemId: {},
			}

		case "SET_IS_LOADING":
			return {
				...state,
				isLoading: !!payload,
			}

		case "SET_IS_IN_CHECKOUT_FLOW":
			return {
				...state,
				isInCheckoutFlow: !!payload,
			}

		case "HIDE_KLARNA_PAYMENTS":
			return {
				...state,
				hideKlarnaPayments: true,
			}

		default:
			console.error(`Undefined reducer action type "${type}"`)
			return state
	}
}

export const CheckoutContext = createContext(defaultCheckoutContext)

export const CheckoutContextProvider = ({
	children,
	shopCountryCode,
	paymentMethods,
}: CheckoutContextProviderProps) => {
	const [state, dispatch] = useReducer(CheckoutContextReducer, {
		...defaultCheckoutContext,
		billingAddress: getDefaultFormAddressFromShopCountryCode(shopCountryCode),
		shippingAddress: getDefaultFormAddressFromShopCountryCode(shopCountryCode),
		paymentMethods,
	})
	return (
		<CheckoutContext.Provider value={{ ...state, dispatch } as any}>
			{children}
		</CheckoutContext.Provider>
	)
}

interface CheckoutContextProviderProps {
	children: React.ReactNode
	shopCountryCode: CountryCode
	paymentMethods: PaymentMethod[]
}

export interface CheckoutContextReducerAction {
	type:
		| "SET_SHIPPING_ADDRESS"
		| "TOGGLE_SHIPPING_ADDRESS_IS_COMPANY"
		| "SET_SHIPPING_ADDRESS_ERRORS"
		| "SET_BILLING_ADDRESS"
		| "TOGGLE_BILLING_ADDRESS_IS_COMPANY"
		| "SET_BILLING_ADDRESS_ERRORS"
		| "TOGGLE_SHIPPING_ADDRESS_DIFFERS"
		| "SET_PAYMENT_METHOD"
		| "TOGGLE_HAS_ORDER_ANNOTATIONS"
		| "SET_ORDER_ANNOTATION"
		| "SET_DELIVERY_OPTIONS_BY_SKU"
		| "SET_SELECTED_DELIVERY_OPTIONS_BY_SKU"
		| "RESET_SELECTED_DELIVERY_OPTIONS_BY_SKU"
		| "SET_IS_INITIALIZED"
		| "SET_IS_IN_CHECKOUT_FLOW"
		| "SET_IS_LOADING"
		| "INITIALIZE_KLARNA_PAYMENTS_SESSION"
		| "UPDATE_KLARNA_PAYMENTS_SESSION"
		| "HIDE_KLARNA_PAYMENTS"
	payload?: any
}

export type DispatchCheckoutContext = (action: CheckoutContextReducerAction) => void

export default CheckoutContext
