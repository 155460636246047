import formatAttributes from "./formatAttributes"
import getProductAncestorBreadCrumbs from "../getProductAncestorBreadCrumbs"
import {
	getLocalizedName,
	getLocalizedMetaTitle,
	getLocalizedMetaDescription,
	LocalizedMetaData,
} from "../getLocalizedMetaData"
import { normalizeCategorySlug } from "./formatCategory"

import {
	ProductVariant as CommerceToolsProductVariant,
	ProductPrice,
} from "@secureo/common/typings/generated/commercetools-graphql"
import {
	Product,
	ProductVariant,
	PricesByCountryCode,
	ProductTypeName,
	ProductAttributes,
} from "@secureo/common/typings/Product"
import { RawProduct } from "../graphql/queries/fetchProduct"
import { ShopKey } from "@secureo/common/typings/Shop"
import { CurrencyCode } from "@secureo/common/typings/Forex"

const channelKey = process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY

export const getFilteredPricesByCountryCodeAndCurrency = (
	prices: ProductPrice[],
	currencyCode: CurrencyCode,
) => {
	const pricesForChannelAndCurrencyCode = prices.filter((price) => {
		return price?.channel?.key === channelKey && price?.value?.currencyCode === currencyCode
	})

	const pricesByCountryCode: PricesByCountryCode = pricesForChannelAndCurrencyCode.reduce(
		(pricesByCountryCode, price) => {
			const {
				value: { centAmount: netPriceCents },
				country: countryCode,
			} = price
			return {
				...pricesByCountryCode,
				[countryCode]: netPriceCents,
			}
		},
		{},
	)

	return pricesByCountryCode
}

export const formatProductVariant = (
	masterVariantSku: string,
	productVariant: CommerceToolsProductVariant,
	productType: ProductTypeName,
	language = "de",
	currencyCode: CurrencyCode = "EUR",
) => {
	const { id, sku, prices, images = [], attributesRaw = [], assets = [] } = productVariant

	const pricesByCountryCode = getFilteredPricesByCountryCodeAndCurrency(prices, currencyCode)

	const formattedImages = images.map((image) => image.url)

	const attributes = formatAttributes(attributesRaw, productType, masterVariantSku, language)

	const formattedAssets = assets.map((asset) => {
		const { name, sources } = asset
		return {
			name,
			url: sources?.[0]?.uri ?? null,
		}
	})

	const formattedProductVariant: ProductVariant = {
		id,
		sku,
		images: formattedImages,
		pricesByCountryCode,
		attributes,
		assets: formattedAssets,
	}

	return formattedProductVariant
}

const getShortDescription = (shopKey: ShopKey, attributes: ProductAttributes) => {
	switch (shopKey) {
		case "TRE":
			return attributes?.shortDescriptionTresoro ?? null
		case "DZN":
			return attributes?.shortDescriptionDigishop ?? null
		default:
			return attributes?.shortDescription ?? null
	}
}

const getDescription = (
	shopKey: ShopKey,
	metaData: LocalizedMetaData,
	attributes: ProductAttributes,
	locale: string,
) => {
	switch (shopKey) {
		case "TRE":
			return attributes?.descriptionTresoro ?? null
		case "DZN":
			return attributes?.descriptionDigishop ?? null
		default:
			switch (locale) {
				case "pl-PL":
					return metaData?.descriptionPL ?? null
				default:
					return metaData?.descriptionDE ?? null
			}
	}
}

const formatProduct = (
	product: RawProduct,
	locale: string,
	shopKey?: ShopKey,
	includeRatings = false,
	language = "de",
	currencyCode: CurrencyCode = "EUR",
) => {
	const {
		id,
		masterData: {
			current: { slug, masterVariant, variants = [], categories = [] },
		},
		reviewRatingStatistics,
	} = product

	const sku = masterVariant.sku
	const productType = product?.productType?.name as ProductTypeName

	const formattedProductVariants = [masterVariant, ...variants].map((variant) =>
		formatProductVariant(masterVariant.sku, variant, productType, language, currencyCode),
	)
	const [formattedMasterVariant] = formattedProductVariants

	const name = getLocalizedName(product.masterData.current, locale)
	const metaTitle = getLocalizedMetaTitle(product.masterData.current, locale)
	const metaDescription = getLocalizedMetaDescription(product.masterData.current, locale)

	const shortDescription = getShortDescription(shopKey, formattedMasterVariant?.attributes)
	const description = getDescription(
		shopKey,
		product.masterData.current,
		formattedMasterVariant?.attributes,
		locale,
	)

	// Prevent duplicated data in response
	delete formattedProductVariants[0]?.attributes?.shortDescription
	delete formattedProductVariants[0]?.attributes?.shortDescriptionTresoro
	delete formattedProductVariants[0]?.attributes?.shortDescriptionDigishop
	delete formattedProductVariants[0]?.attributes?.descriptionTresoro
	delete formattedProductVariants[0]?.attributes?.descriptionDigishop

	const ancestorBreadCrumbs = getProductAncestorBreadCrumbs(categories, locale, shopKey)

	const rawLockingSystemCategory =
		categories.find((category) => category?.parent?.slug === "dzn-schliessanlagen") ?? null
	const isMechanicLockingSystemComponent =
		rawLockingSystemCategory?.custom?.customFieldsRaw?.find(
			({ name }) => name === "lockingSystemType",
		)?.value === "mechanic"
	const rawLockingSystemCategorySlug = isMechanicLockingSystemComponent
		? rawLockingSystemCategory?.slug
		: null

	const formattedProduct: Product = {
		productType,
		id,
		sku,
		slug,
		name,
		description,
		shortDescription,
		metaTitle,
		metaDescription,
		variants: formattedProductVariants,
		ancestorBreadCrumbs,
		lockingSystemCategorySlug: rawLockingSystemCategorySlug
			? normalizeCategorySlug(rawLockingSystemCategorySlug)
			: null,
	}

	if (includeRatings) {
		const averageRating = reviewRatingStatistics?.averageRating ?? 0
		const count = reviewRatingStatistics?.count ?? 0
		const ratingsDistribution = reviewRatingStatistics?.ratingsDistribution ?? {}

		formattedProduct.reviewRatingStatistics = {
			averageRating: averageRating / 10,
			count,
			ratingsDistribution: Object.entries(ratingsDistribution).reduce(
				(formattedRatingDistribution, [stars, count]) => {
					return { ...formattedRatingDistribution, [Number(stars) / 10]: count }
				},
				{},
			),
		}
	}

	return formattedProduct
}

export default formatProduct
