const discountCodeFragment = {
	fragment: /* GraphQL */ `
		fragment discountCode on DiscountCode {
			id
			code
			isActive
			name(locale: $locale)
			description(locale: $locale)
			cartDiscounts {
				value {
					type
				}
				target {
					type
				}
				sortOrder
				cartPredicate
				validFrom
				validUntil
			}
		}
	`,
	dependencies: [],
}

export default discountCodeFragment
