import { Namespace } from "i18next"
import { useTranslation } from "next-i18next"
import { UseTranslationOptions } from "react-i18next"

const useTranslationCustom = (ns?: Namespace, options?: UseTranslationOptions<undefined>) => {
	const { i18n, t } = useTranslation(ns, options)

	return {
		i18n,
		t,
		locale: i18n.language,
	}
}

export default useTranslationCustom
