export const relevantLineItemVariantAttributes = [
	"isHaulageDelivery",
	"deliveryOptions",
	"shippingOriginationCountryCode",
	"deliveryTimeWorkdays",
	"manufacturer",
	"variantName",
]

const includeNamesFilter = relevantLineItemVariantAttributes.reduce(
	(includeNamesFilter, attributeName) => `${includeNamesFilter}, "${attributeName}"`,
	"",
)

const lineItemFragment = {
	fragment: /* GraphQL */ `
		fragment lineItem on LineItem {
			id
			productId
			addedAt
			name(locale: $locale)
			productSlug(locale: $locale)
			productType {
				name
			}
			quantity
			variant {
				id
				sku
				images {
					url
					label
				}
				attributesRaw(includeNames: [${includeNamesFilter}]) {
					name
					value
				}
			}
			taxedPrice {
				totalNet {
					...money
				}
				totalGross {
					...money
				}
			}
			taxRate {
				name
				amount
				includedInPrice
			}
			discountedPricePerQuantity {
				quantity
				discountedPrice {
					value {
						...money
					}
					includedDiscounts {
						discount {
							name(locale: $locale)
							description(locale: $locale)
						}
						discountedAmount {
							...money
						}
					}
				}
			}
			custom {
				customFieldsRaw {
					name
					value
				}
			}
		}
	`,
	dependencies: ["money"],
}

export default lineItemFragment
