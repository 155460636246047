import { TextField } from "@mui/material"

const TextInput = ({
	label,
	name,
	type = "text",
	error = "",
	helperText = "",
	required = false,
	disabled = false,
	style = {},
	...props
}: Props) => {
	return (
		<TextField
			type={type}
			name={name}
			label={label}
			error={!!error}
			helperText={error || helperText}
			required={required}
			disabled={disabled}
			margin="dense"
			variant="outlined"
			style={style}
			inputProps={{ "data-hj-whitelist": true }}
			{...props}
		/>
	)
}

export default TextInput

interface Props {
	type?: string
	label: string
	name: string
	id?: string
	value: string
	onChange: (event: any) => void
	required?: boolean
	disabled?: boolean
	error?: string
	helperText?: string
	fullWidth?: boolean
	style?: React.CSSProperties
	InputProps?: any
}
