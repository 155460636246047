import { CurrencyCode } from "@secureo/common/typings/Forex"
import axios from "axios"

import createMyCart from "@secureo/common/utils/commerceTools/graphql/mutations/createMyCart"
import updateCart from "@secureo/common/utils/commerceTools/graphql/mutations/updateCart"
import fetchActiveCart from "@secureo/common/utils/commerceTools/graphql/queries/fetchActiveCart"
import { getCommerceToolsGraphQLAddressInputFromFormAddress } from "@secureo/common/utils/commerceTools/formatters/formatAddress"
import { trackRemoveFromCart, trackRemoveFromCartGA4 } from "@secureo/common/utils/tracking/cart"
import getRemoveFromCartFieldObjects, {
	getGA4RemoveFromCartItems,
} from "../utils/getRemoveFromCartFieldObjects"

import { CountryCode } from "@secureo/common/typings/CountryCode"
import { Cart } from "@secureo/common/typings/Cart"
import { FormAddress } from "@secureo/common/typings/FormAddress"
import { DispatchAppContext, AppContextState } from "../AppContext"
import {
	MyCartDraft,
	MyCartUpdateAction,

	// LineItems
	MyLineItemDraft,
	AddMyCartLineItem,
	RemoveCartLineItem,
	ChangeCartLineItemQuantity,

	// DiscountCode
	AddCartDiscountCode,
	RemoveCartDiscountCode,

	// Address
	SetCartBillingAddress,
	SetCartShippingAddress,

	// Misc
	TaxMode,
	SetCartCustomerEmail,
	SetCartLocale,
	SetCartCountry,
} from "@secureo/common/typings/generated/commercetools-graphql"
import getLineItemUuidCustomField from "../utils/getUuidCustomField"
import { ProviderContext } from "notistack"

const defaultCurrency = "EUR"
const defaultTaxMode = TaxMode.External
const deleteDaysAfterLastModification = 180

export const dispatchUpdatedCart = (dispatch: DispatchAppContext, updatedCart: Cart) => {
	if (updatedCart) {
		dispatch({
			type: "SET_CART",
			payload: updatedCart,
		})
		return updatedCart
	}

	return null
}

export const createEmptyCart = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	locale: string,
	country: CountryCode,
	currencyCode: CurrencyCode,
	customerEmail?: string,
) => {
	const emptyCartDraft: MyCartDraft = {
		currency: currencyCode ?? defaultCurrency,
		customerEmail,
		taxMode: defaultTaxMode,
		country,
		deleteDaysAfterLastModification,
		locale,
	}

	const cart = await createMyCart(accessToken, locale, emptyCartDraft)
	return dispatchUpdatedCart(dispatch, cart)
}

const getLocalizedTaxUpdateErrorMessage = (language: string) => {
	switch (language) {
		case "pl":
			return "Coś poszło nie tak"
		default:
			return "Etwas ist schiefgelaufen"
	}
}

// Tax calculation
export const updateTaxes = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cartId: string,
	shopCountryCode: CountryCode,
	shippingDestinationCountryCode?: CountryCode,
	includeShippingCosts = false,
	removePrepaidDiscount = true,
	isCompany = false,
	vatId = "",
	enqueueSnackbar?: ProviderContext["enqueueSnackbar"],
	language = "de",
) => {
	const actualShippingDestinationCountryCode = shippingDestinationCountryCode || shopCountryCode

	const queryString = `?cartId=${cartId}&shopCountryCode=${shopCountryCode}&shippingDestinationCountryCode=${actualShippingDestinationCountryCode}&isCompany=${String(
		isCompany,
	)}&vatId=${vatId}&includeShippingCosts=${String(
		includeShippingCosts,
	)}&removePrepaidDiscount=${String(removePrepaidDiscount)}&language=${language}`

	try {
		await axios.get(`/api/cart/updateTaxesAndShippingCosts${queryString}`)

		const updatedCart = await fetchActiveCart(accessToken, language)

		return dispatchUpdatedCart(dispatch, updatedCart)
	} catch (err) {
		console.error(err)
		if (enqueueSnackbar) {
			enqueueSnackbar(getLocalizedTaxUpdateErrorMessage(language), {
				variant: "error",
			})
		}
		return null
	}
}

export const getOrCreateCart = async (
	appContextState: AppContextState,
	locale: string,
	shopCountryCode: CountryCode,
	currencyCode: CurrencyCode,
) => {
	const { cart, accessToken, dispatch, customer } = appContextState
	const customerEmail = customer?.email ?? null

	if (!cart) {
		console.info(`Creating new cart`)
		return await createEmptyCart(
			accessToken,
			dispatch,
			locale,
			shopCountryCode,
			currencyCode,
			customerEmail,
		)
	}

	return cart
}

// LineItems
export const addLineItemsToCart = async (
	appContextState: AppContextState,
	lineItems: MyLineItemDraft[],
	language: string,
	shopCountryCode: CountryCode,
	currencyCode: CurrencyCode,
) => {
	try {
		const { accessToken, dispatch } = appContextState

		const cart = await getOrCreateCart(appContextState, language, shopCountryCode, currencyCode)
		const uuidCustomField = getLineItemUuidCustomField(lineItems)

		const updateActions: MyCartUpdateAction[] = lineItems.reduce((updateActions, lineItem) => {
			const { productId, variantId, quantity, distributionChannel, custom } = lineItem

			const addLineItem: AddMyCartLineItem = {
				productId,
				variantId,
				quantity,
				distributionChannel,
				custom: {
					...custom,
					fields: uuidCustomField ? [...custom.fields, uuidCustomField] : custom.fields,
				},
			}

			return [...updateActions, { addLineItem }]
		}, [])

		await updateCart(accessToken, cart.id, cart.version, language, updateActions)

		return await updateTaxes(
			accessToken,
			dispatch,
			cart.id,
			shopCountryCode,
			shopCountryCode,
			false,
			true,
			false,
			"",
			null,
			language,
		)
	} catch (error) {
		console.error("Failed to add products to cart")
		console.error(error)
	}
}

export const changeLineItemQuantity = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cart: Cart,
	locale: string,
	updateActions: ChangeCartLineItemQuantity[],
) => {
	const [language] = locale.split("-")

	const updatedCart = await updateCart(
		accessToken,
		cart.id,
		cart.version,
		language,
		updateActions.map((updateAction) => ({
			changeLineItemQuantity: updateAction,
		})),
	)
	return dispatchUpdatedCart(dispatch, updatedCart)
}

export const removeLineItemsFromCart = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cart: Cart,
	language: string,
	lineItemIds: string[],
	enableTracking = true,
) => {
	const updateActions: RemoveCartLineItem[] = lineItemIds.reduce((updateActions, lineItemId) => {
		const removeLineItemAction: RemoveCartLineItem = {
			lineItemId,
		}

		return [...updateActions, removeLineItemAction]
	}, [])

	if (enableTracking) {
		const removeFromCartFieldObjects = getRemoveFromCartFieldObjects(cart, lineItemIds)
		trackRemoveFromCart(removeFromCartFieldObjects)
		trackRemoveFromCartGA4(getGA4RemoveFromCartItems(cart, lineItemIds))
	}

	const updatedCart = await updateCart(
		accessToken,
		cart.id,
		cart.version,
		language,
		updateActions.map((updateAction) => ({
			removeLineItem: updateAction,
		})),
	)
	return dispatchUpdatedCart(dispatch, updatedCart)
}

// DiscountCodes
export const addDiscountCodeToCart = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cart: Cart,
	locale: string,
	discountCode: string,
	shopCountryCode: CountryCode,
	shippingDestinationCountryCode: CountryCode,
	includeShippingCosts: boolean,
	isCompany: boolean,
	vatId: string,
	enqueueSnackbar?: ProviderContext["enqueueSnackbar"],
) => {
	const [language] = locale.split("-")
	const addDiscountCode: AddCartDiscountCode = {
		code: discountCode,
	}

	await updateCart(
		accessToken,
		cart.id,
		cart.version,
		language,
		[{ addDiscountCode }],
		enqueueSnackbar,
	)

	const updatedCart = await updateTaxes(
		accessToken,
		dispatch,
		cart.id,
		shopCountryCode,
		shippingDestinationCountryCode,
		includeShippingCosts,
		false,
		isCompany,
		vatId,
		enqueueSnackbar,
		language,
	)

	return updatedCart
}

export const removeDiscountCodeToCart = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cart: Cart,
	locale: string,
	discountCodeId: string,
	shopCountryCode: CountryCode,
	shippingDestinationCountryCode: CountryCode,
	includeShippingCosts: boolean,
	isCompany: boolean,
	vatId: string,
	enqueueSnackbar?: ProviderContext["enqueueSnackbar"],
) => {
	const [language] = locale.split("-")
	const removeDiscountCode: RemoveCartDiscountCode = {
		discountCode: {
			typeId: "discount-code",
			id: discountCodeId,
		},
	}

	await updateCart(accessToken, cart.id, cart.version, language, [{ removeDiscountCode }])
	await updateTaxes(
		accessToken,
		dispatch,
		cart.id,
		shopCountryCode,
		shippingDestinationCountryCode,
		includeShippingCosts,
		false,
		isCompany,
		vatId,
		enqueueSnackbar,
		language,
	)
}

export const setLineItemDeliveryOption = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cartId: string,
	lineItemId: string,
	deliveryOptionSku: string,
	shippingDestinationCountryCode: CountryCode,
	language = "de",
) => {
	const queryString = `?cartId=${cartId}&shippingDestinationCountryCode=${shippingDestinationCountryCode}&lineItemId=${lineItemId}&deliveryOptionSku=${deliveryOptionSku}&language=${language}`

	try {
		await axios.get(`/api/cart/setDeliveryOptionForLineItem${queryString}`)

		const updatedCart = await fetchActiveCart(accessToken, language)

		dispatchUpdatedCart(dispatch, updatedCart)
	} catch (err) {
		console.error(err)
	}
}

export const setCustomLineItemDeliveryOptionRequiresConformityAssessment = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cartId: string,
	deliveryOptionCustomLineItemId: string,
	deliveryOptionSku: string,
	shippingDestinationCountryCode: CountryCode,
	requiresConformityAssessment: boolean,
	language = "de",
) => {
	const queryString = `?cartId=${cartId}&shippingDestinationCountryCode=${shippingDestinationCountryCode}&deliveryOptionSku=${deliveryOptionSku}&deliveryOptionCustomLineItemId=${deliveryOptionCustomLineItemId}&requiresConformityAssessment=${requiresConformityAssessment}&language=${language}`

	try {
		await axios.get(`/api/cart/setDeliveryOptionRequiresConformityAssessment${queryString}`)

		const updatedCart = await fetchActiveCart(accessToken, language)

		dispatchUpdatedCart(dispatch, updatedCart)
	} catch (err) {
		console.error(err)
	}
}

// Addresses
export const setCartBillingAddress = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cart: Cart,
	language: string,
	billingAddress: FormAddress,
) => {
	const setBillingAddress: SetCartBillingAddress = {
		address: getCommerceToolsGraphQLAddressInputFromFormAddress(billingAddress),
	}

	// Simultaneously set "customerEmail"
	const setCustomerEmail: SetCartCustomerEmail = {
		email: billingAddress.email,
	}

	const updateActions: MyCartUpdateAction[] = [{ setBillingAddress }, { setCustomerEmail }]

	const updatedCart = await updateCart(
		accessToken,
		cart.id,
		cart.version,
		language,
		updateActions,
	)
	return dispatchUpdatedCart(dispatch, updatedCart)
}

export const setCartShippingAddress = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cart: Cart,
	language: string,
	shippingAddress: FormAddress,
) => {
	const setShippingAddress: SetCartShippingAddress = {
		address: getCommerceToolsGraphQLAddressInputFromFormAddress(shippingAddress),
	}

	const updateActions: MyCartUpdateAction[] = [{ setShippingAddress }]

	// TODO Check if country is a supported pageInstance before setting it
	const isSupportedInstanceCountry = false
	if (isSupportedInstanceCountry) {
		// Simultaneously set "country" to use countrySpecific prices
		const setCountry: SetCartCountry = {
			country: shippingAddress.country,
		}
		updateActions.push({ setCountry })
	}

	const updatedCart = await updateCart(
		accessToken,
		cart.id,
		cart.version,
		language,
		updateActions,
	)
	return dispatchUpdatedCart(dispatch, updatedCart)
}

// Cart locale
export const setCartLocale = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cart: Cart,
	locale: string,
) => {
	const [language] = locale.split("-")
	const setLocale: SetCartLocale = {
		locale,
	}

	const updatedCart = await updateCart(accessToken, cart.id, cart.version, language, [
		{ setLocale },
	])
	return dispatchUpdatedCart(dispatch, updatedCart)
}

// Cart country
export const setCartCountry = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	cart: Cart,
	locale: string,
	country: string,
) => {
	const [language] = locale.split("-")

	// TODO Check if country is a supported pageInstance before setting it
	const setCountry: SetCartCountry = {
		country,
	}

	const updatedCart = await updateCart(accessToken, cart.id, cart.version, language, [
		{ setCountry },
	])
	return dispatchUpdatedCart(dispatch, updatedCart)
}
