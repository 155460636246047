import * as React from "react"
import { Box } from "@mui/material"

type feedbackType = "success" | "error" | "warning"

const getBackGroundColor = (type: feedbackType) => {
	switch (type) {
		case "success":
			return "rgba(122, 191, 172, 0.3)"
		case "error":
			return "rgba(207, 66, 59, 0.3)"
		case "warning":
			return "rgba(255, 212, 98, 0.3)"
	}
}

const Feedback = ({ type, children }: Props) => {
	const background = getBackGroundColor(type)
	return (
		<Box
			p={2}
			mb={1}
			style={{ background, borderRadius: "3px", border: "1px solid lightgrey" }}
		>
			<div>{children}</div>
		</Box>
	)
}

interface Props {
	children: React.ReactNode
	type: "success" | "error" | "warning"
}

export default Feedback
