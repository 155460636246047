import getValueFromLocalizedAttribute from "../i18n/getValueFromLocalizedAttribute"

import {
	PaymentMethod,
	PaymentMethodKey,
	LocalizedPaymentMethod,
} from "@secureo/common/typings/Payment"
import { KlarnaPaymentMethodCategory } from "@secureo/common/typings/Klarna"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import { DiscountCodeInfo } from "@secureo/common/typings/generated/commercetools-graphql"

export const getPaymentMethodsByKey = (paymentMethods: PaymentMethod[]) => {
	return paymentMethods.reduce(
		(paymentMethodsByKey, paymentMethod) => {
			const { key } = paymentMethod

			return {
				...paymentMethodsByKey,
				[key]: paymentMethod,
			}
		},
		{} as {
			[paymentMethodKey: string]: PaymentMethod
		},
	)
}

export const getLocalizedPaymentMethod = (paymentMethod: PaymentMethod, language: string) => {
	const name = getValueFromLocalizedAttribute(paymentMethod.name, language)
	const description = getValueFromLocalizedAttribute(paymentMethod.description, language)

	const localizedPaymentMethod: LocalizedPaymentMethod = {
		...paymentMethod,
		name,
		description,
	}

	return localizedPaymentMethod
}

export const isPaymentMethodAllowed = (
	paymentMethods: PaymentMethod[],
	paymentMethodKey: PaymentMethodKey,
	billingAddressCountryCode: CountryCode,
	isCompany: boolean,
) => {
	const paymentMethodsByKey = getPaymentMethodsByKey(paymentMethods)
	const paymentMethod = paymentMethodsByKey?.[paymentMethodKey] ?? null

	if (!paymentMethod) return false

	const {
		isSuitableForCompany,
		isSuitableForPrivate,
		isCountryRestricted,
		includedCountryCodes = [],
	} = paymentMethod

	const isAllowedForCustomerType = isCompany ? isSuitableForCompany : isSuitableForPrivate
	const isAllowedForBillingAddressCountry = isCountryRestricted
		? includedCountryCodes.includes(billingAddressCountryCode)
		: true

	return isAllowedForCustomerType && isAllowedForBillingAddressCountry
}

const hasHunterSchoolDiscountCodes = (discountCodes: DiscountCodeInfo[]) => {
	return discountCodes.some((discountCode) =>
		discountCode?.discountCode?.code?.startsWith("JSGC-"),
	)
}

export const getAvailableLocalizedPaymentMethods = (
	paymentMethods: PaymentMethod[],
	isCompany: boolean,
	billingAddressCountryCode: CountryCode,
	language: string,
	klarnaPaymentMethodCategories: KlarnaPaymentMethodCategory[],
	discountCodes: DiscountCodeInfo[],
) => {
	const availableKlarnaPaymentCategoryIdentifiers = klarnaPaymentMethodCategories.map(
		({ identifier }) => identifier,
	)

	const availablePaymentMethods = paymentMethods.filter((paymentMethod) => {
		const { key, provider } = paymentMethod

		if (hasHunterSchoolDiscountCodes(discountCodes) && key !== "prepaid") return false

		const isAllowed = isPaymentMethodAllowed(
			paymentMethods,
			paymentMethod.key,
			billingAddressCountryCode,
			isCompany,
		)

		if (!isAllowed) return false

		switch (provider) {
			case "klarna":
				switch (key) {
					case "direct_bank_transfer":
						return availableKlarnaPaymentCategoryIdentifiers.includes(
							"direct_bank_transfer",
						)
					case "pay_now":
						return availableKlarnaPaymentCategoryIdentifiers.includes("pay_now")
					case "pay_later":
						return availableKlarnaPaymentCategoryIdentifiers.includes("pay_later")
					case "pay_over_time":
						return availableKlarnaPaymentCategoryIdentifiers.includes("pay_over_time")
					default:
						return false
				}
			default:
				return true
		}
	})

	const availableLocalizedPaymentMethods = availablePaymentMethods.map((paymentMethod) =>
		getLocalizedPaymentMethod(paymentMethod, language),
	)

	return availableLocalizedPaymentMethods
}
