import createMyShoppingList from "@secureo/common/utils/commerceTools/graphql/mutations/createMyShoppingList"
import updateMyShoppingList from "@secureo/common/utils/commerceTools/graphql/mutations/updateMyShoppingList"
import getLineItemUuidCustomField from "../utils/getUuidCustomField"

import { DispatchAppContext, AppContextState } from "../AppContext"
import {
	MyShoppingListDraft,
	MyShoppingListUpdateAction,

	// LineItems
	ShoppingListLineItemDraft,
	AddShoppingListLineItem,
	ChangeShoppingListLineItemQuantity,
	AddMyCartLineItem,
	MyCartUpdateAction,
	CustomFieldsType,
	CustomFieldsDraft,

	// // TextLineItems
	// TextLineItemDraft,
	// AddShoppingListTextLineItem,
	// RemoveShoppingListTextLineItem,
	// ChangeShoppingListTextLineItemQuantity,

	// // Misc
	// ChangeShoppingListName,
} from "@secureo/common/typings/generated/commercetools-graphql"
import { ShoppingList } from "@secureo/common/typings/ShoppingList"
import { MergedConfiguredLineItem } from "@secureo/common/typings/Cart"
import { getOrCreateCart, updateTaxes } from "./cartActions"
import { distributionChannel } from "@secureo/common/reducers/productDetailPageReducer"
import updateCart from "@secureo/common/utils/commerceTools/graphql/mutations/updateCart"
import { CurrencyCode } from "@secureo/common/typings/Forex"

export const dispatchUpdatedShoppingList = (
	dispatch: DispatchAppContext,
	updatedShoppingList: ShoppingList,
) => {
	if (updatedShoppingList) {
		dispatch({
			type: "SET_SHOPPING_LIST",
			payload: updatedShoppingList,
		})
		return updatedShoppingList
	}

	return null
}

export const createEmptyShoppingList = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	currencyCode: CurrencyCode,
	name = "Meine Wunschliste",
) => {
	const shoppingListDraft: MyShoppingListDraft = {
		name: [
			{
				locale: "de",
				value: name,
			},
		],
		custom: {
			type: {
				typeId: "type",
				key: "customShoppingList",
			},
			fields: [{ name: "type", value: `"default"` }],
		},
	}

	const shoppingList = await createMyShoppingList(accessToken, shoppingListDraft, currencyCode)
	return dispatchUpdatedShoppingList(dispatch, shoppingList)
}

const getOrCreateShoppingList = async (appContextState: AppContextState) => {
	const { shoppingList, accessToken, dispatch, currencyCode } = appContextState

	if (!shoppingList) {
		console.info(`Creating new shoppingList`)
		return await createEmptyShoppingList(accessToken, dispatch, currencyCode)
	}

	return shoppingList
}

export const addLineItemsToShoppingList = async (
	appContextState: AppContextState,
	shoppingListLineItems: ShoppingListLineItemDraft[],
) => {
	try {
		const { accessToken, dispatch, currencyCode } = appContextState

		const shoppingList = await getOrCreateShoppingList(appContextState)
		const uuidCustomField = getLineItemUuidCustomField(shoppingListLineItems)

		const updateActions: MyShoppingListUpdateAction[] = shoppingListLineItems.reduce(
			(updateActions, lineItem) => {
				const { productId, variantId, quantity, custom } = lineItem

				const addLineItem: AddShoppingListLineItem = {
					productId,
					variantId,
					quantity,
					custom: {
						...custom,
						fields: uuidCustomField
							? [...custom.fields, uuidCustomField]
							: custom.fields,
					},
				}

				return [...updateActions, { addLineItem }]
			},
			[],
		)

		const updatedShoppingList = await updateMyShoppingList(
			accessToken,
			shoppingList.id,
			shoppingList.version,
			updateActions,
			currencyCode,
		)

		return dispatchUpdatedShoppingList(dispatch, updatedShoppingList)
	} catch (error) {
		console.error("Failed to add lineItem to shopping list")
		console.error(error)
	}
}

export const changeShoppingListLineItemQuantity = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	shoppingList: ShoppingList,
	actions: ChangeShoppingListLineItemQuantity[],
	currencyCode: CurrencyCode,
) => {
	const { id, version } = shoppingList

	const updateActions: MyShoppingListUpdateAction[] = actions.map((changeLineItemQuantity) => ({
		changeLineItemQuantity,
	}))

	const updatedShoppingList = await updateMyShoppingList(
		accessToken,
		id,
		version,
		updateActions,
		currencyCode,
	)

	return dispatchUpdatedShoppingList(dispatch, updatedShoppingList)
}

export const removeShoppingListLineItem = async (
	accessToken: string,
	dispatch: DispatchAppContext,
	shoppingList: ShoppingList,
	lineItemIds: string[],
	currencyCode: CurrencyCode,
) => {
	const { id, version } = shoppingList

	const updateActions: MyShoppingListUpdateAction[] = lineItemIds.map((lineItemId) => ({
		removeLineItem: {
			lineItemId,
		},
	}))

	const updatedShoppingList = await updateMyShoppingList(
		accessToken,
		id,
		version,
		updateActions,
		currencyCode,
	)

	return dispatchUpdatedShoppingList(dispatch, updatedShoppingList)
}

export const addShoppingListLineItemsToCart = async (
	appContextState: AppContextState,
	lineItems: MergedConfiguredLineItem[],
	language = "de",
	currencyCode: CurrencyCode = "EUR",
) => {
	const { accessToken, dispatch, countryCode: shopCountryCode } = appContextState
	const cart = await getOrCreateCart(appContextState, language, shopCountryCode, currencyCode)

	try {
		const updateActions: MyCartUpdateAction[] = []

		for (const lineItem of lineItems) {
			const { configurations = [] } = lineItem

			const lineItemUpdateActions: MyCartUpdateAction[] = [
				lineItem,
				...configurations,
			].reduce((updateActions, lineItem) => {
				const {
					productId,
					variant: { id: variantId },
					quantity,
				} = lineItem

				const custom = (lineItem as any).custom as CustomFieldsType

				const customFieldsDraft: CustomFieldsDraft = {
					type: {
						typeId: "type",
						key: "configuredLineItem",
					},
					fields: custom?.customFieldsRaw.map(({ name, value }) => ({
						name,
						value: `"${value}"`,
					})),
				}

				const addLineItem: AddMyCartLineItem = {
					productId,
					variantId,
					quantity,
					distributionChannel,
					custom: customFieldsDraft,
				}

				return [...updateActions, { addLineItem }]
			}, [])

			updateActions.push(...lineItemUpdateActions)
		}

		await updateCart(accessToken, cart.id, cart.version, language, updateActions)

		return await updateTaxes(accessToken, dispatch, cart.id, shopCountryCode)
	} catch (error) {
		console.error("Failed to add shopping list lineItems to cart")
		console.error(error)
	}
}
