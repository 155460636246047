import { SEOPageAttributes } from "../../../typings/SEO"

const title = {
	de: "Account | SafeHero.com 🛡️",
	pl: "Konto | SafeHero.com 🛡️",
}

const description = {}

const openGraph = {}

const twitter = {}

const account: SEOPageAttributes = {
	title,
	description,
	openGraph,
	twitter,
}

export default account
