import {
	RawCylinderLockingTypeAttribute,
	CylinderLockingMechanismSkusByCountryCode,
} from "@secureo/common/typings/Product"

const getCylinderLockingMechanismSkusByCountryCode = (
	cylinderLockingTypeAttribute: RawCylinderLockingTypeAttribute,
) => {
	const cylinderLockingMechanismSkusByCountryCode = cylinderLockingTypeAttribute.value.reduce(
		(
			cylinderLockingMechanismSkusByCountryCode,
			[{ value: countryCode }, { value: rawSku }],
		) => {
			const simultaneousLockingMechanismProductSku = `GSD-${rawSku.replace(/\s/g, "-")}`
			const simultaneousLockingMechanismProductnetPriceCents = Number(
				rawSku.replace(/EUR\s/g, ""),
			)

			return {
				...cylinderLockingMechanismSkusByCountryCode,
				[countryCode]: {
					single: { sku: "EZS", netPriceCents: 0 },
					simultaneous: {
						sku: simultaneousLockingMechanismProductSku,
						netPriceCents: simultaneousLockingMechanismProductnetPriceCents,
					},
				},
			}
		},
		{} as CylinderLockingMechanismSkusByCountryCode,
	)

	return cylinderLockingMechanismSkusByCountryCode
}

export default getCylinderLockingMechanismSkusByCountryCode
