import React from "react"
import Head from "next/head"

const JSONLD = ({ metaKey, json }: JSONLDProps) => {
	return (
		<Head>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
				key={metaKey}
			/>
		</Head>
	)
}

interface JSONLDProps {
	metaKey: string
	json: { [EditAttributesRounded: string]: any }
}

export default JSONLD
