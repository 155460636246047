import { useContext } from "react"
import { Box, Dialog, DialogContent, Hidden } from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"

import AppContext, { AppContextState } from "@secureo/common/context/AppContext/AppContext"

import Button from "../Buttons/Button"
import LoginForm from "../Forms/LoginForm"
import RegisterForm from "../Forms/RegisterForm"
import useTranslationCustom from "hooks/useTranslation"

const useStyles = makeStyles()((theme) => ({
	error: {
		color: "#a83232 !important",
		textAlign: "left",
	},
	headlineColor: {
		color: theme.palette.secondary.dark,
	},
	form: {
		display: "flex",
		flexDirection: "column",
		margin: "auto",
		width: "fit-content",
	},
	formControl: {
		marginTop: theme.spacing(2),
		minWidth: 120,
	},
	formControlLabel: {
		marginTop: theme.spacing(1),
	},
	closeButton: {
		position: "absolute",
		right: 10,
		top: 10,
		zIndex: 9999,
		background: "none",
	},
}))

export const LoginButton = () => {
	const { t } = useTranslationCustom("common")
	const { dispatch } = useContext(AppContext) as AppContextState

	const toggleLoginRegisterFormIsOpen = () => {
		dispatch({
			type: "TOGGLE_LOGIN_REGISTER_FORM_IS_OPEN",
		})
	}

	const openLoginForm = () => {
		dispatch({
			type: "SET_OPEN_LOGIN_REGISTER_FORM_TAB",
			payload: "login",
		})
		toggleLoginRegisterFormIsOpen()
	}
	return (
		<button
			onClick={openLoginForm}
			style={{
				border: "none",
				background: "none",
				color: "inherit",
				fontSize: "inherit",
				cursor: "pointer",
			}}
		>
			{t("login")}
		</button>
	)
}

export const RegisterButton = () => {
	const { t } = useTranslationCustom("common")
	const { dispatch } = useContext(AppContext) as AppContextState

	const toggleLoginRegisterFormIsOpen = () => {
		dispatch({
			type: "TOGGLE_LOGIN_REGISTER_FORM_IS_OPEN",
		})
	}

	const openRegisterForm = () => {
		dispatch({
			type: "SET_OPEN_LOGIN_REGISTER_FORM_TAB",
			payload: "register",
		})
		toggleLoginRegisterFormIsOpen()
	}

	return (
		<button
			onClick={openRegisterForm}
			style={{
				border: "none",
				background: "none",
				color: "inherit",
				fontSize: "inherit",
				cursor: "pointer",
			}}
		>
			{t("register")}
		</button>
	)
}

export const LoginRegisterButton = () => {
	return (
		<div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
			<LoginButton />
			<Hidden mdDown>
				<Box>|</Box>
				<RegisterButton />
			</Hidden>
		</div>
	)
}

const LoginRegister = () => {
	const { classes } = useStyles()
	const { t } = useTranslationCustom("common")
	const { loginRegisterFormIsOpen, openLoginRegisterFormTab, dispatch } = useContext(
		AppContext,
	) as AppContextState

	const isLoginView = openLoginRegisterFormTab === "login"

	const toggleLoginRegisterFormIsOpen = () => {
		dispatch({
			type: "TOGGLE_LOGIN_REGISTER_FORM_IS_OPEN",
		})
	}

	const toggleIsLoginView = () => {
		dispatch({
			type: "SET_OPEN_LOGIN_REGISTER_FORM_TAB",
			payload: isLoginView ? "register" : "login",
		})
	}

	return (
		<Dialog
			open={loginRegisterFormIsOpen}
			onClose={toggleLoginRegisterFormIsOpen}
			aria-labelledby="max-width-dialog-title"
			maxWidth="xl"
		>
			<DialogContent style={{ padding: 0, margin: 0 }}>
				<Hidden mdDown>
					<div className="login">
						<Button
							onClick={toggleLoginRegisterFormIsOpen}
							className={classes.closeButton}
						>
							<Icon path={mdiClose} title="Close Menu" size={1.3} />
						</Button>
						<div className="login-grid">
							<div
								style={{
									transform: `translate(${
										isLoginView ? 0 : 310 // white window
									}px, 0px)`,
								}}
								className="form-div"
							>
								<Box mt={3} px={5}>
									{isLoginView ? <LoginForm /> : <RegisterForm />}
								</Box>
							</div>
							<div
								style={{
									transform: `translate(${
										isLoginView ? 0 : -490 // green window
									}px, 0px)`,
									zIndex: 2,
								}}
								className="button-div"
							>
								<Box mt={4}>
									{isLoginView ? (
										<div>
											<h2>{t("newHere")}</h2>
											<p>{t("registerForBenefits")}</p>
											<ul
												style={{
													textAlign: "left",
													listStylePosition: "outside",
												}}
											>
												<li>{t("manageDeliveryAddresses")}</li>
												<li>
													<Box my={1}>{t("rateProducts")}</Box>
												</li>
												<li>{t("trackYourDelivery")}</li>
											</ul>
										</div>
									) : (
										<div>
											<h2>{t("alreadyRegistered")}</h2>
											<p>{t("areYouACustomer")}</p>
											<p>{t("loginWithYourAccount")}</p>
										</div>
									)}
								</Box>
								<Box mt={4}>
									<Button
										onClick={toggleIsLoginView}
										style={{
											background: "none",
											border: "1px solid #f7fafa",
											color: "#f7fafa",
											width: "50%",
											margin: "auto",
										}}
										color="primary"
									>
										{t(isLoginView ? "goToSignUp" : "goToLogin")}
									</Button>
								</Box>
							</div>
						</div>
					</div>
				</Hidden>
				{/* Mobile */}
				<Hidden mdUp>
					<div style={{ paddingLeft: "-15px", paddingRight: "-15px" }}>
						<Button
							onClick={toggleLoginRegisterFormIsOpen}
							className={classes.closeButton}
						>
							<Icon path={mdiClose} title="Close Menu" size={1.3} />
						</Button>
						<Box mt={3} px={5}>
							{isLoginView ? <LoginForm /> : <RegisterForm />}
							<Box mt={4} pb={5}>
								{isLoginView ? (
									<div>
										<h2>{t("newHere")}</h2>
										<p>{t("registerForBenefits")}</p>
										<ul
											style={{
												textAlign: "left",
												listStylePosition: "outside",
											}}
										>
											<li>{t("manageDeliveryAddresses")}</li>
											<li>
												<Box my={1}>{t("rateProducts")}</Box>
											</li>
											<li>{t("trackYourDelivery")}</li>
										</ul>
									</div>
								) : (
									<div>
										<h2>{t("alreadyRegistered")}</h2>
										<p>{t("areYouACustomer")}</p>
										<p>{t("loginWithYourAccount")}</p>
									</div>
								)}
								<Button
									onClick={toggleIsLoginView}
									style={{
										margin: "auto",
									}}
								>
									{t(isLoginView ? "goToSignUp" : "goToLogin")}
								</Button>
							</Box>
						</Box>
					</div>
				</Hidden>
			</DialogContent>
		</Dialog>
	)
}

export default LoginRegister
