import { useState } from "react"
import { Dialog, DialogContent, Box, useMediaQuery } from "@mui/material"

import Button from "components/Buttons/Button"
import CountryFlag from "components/i18n/CountryFlag"

import isBrowser from "@secureo/common/utils/isBrowser"

// background
import MapAustria from "svg/countrySelect/map-austria.svg"
import MapGermany from "svg/countrySelect/map-germany.svg"
import MapSwitzerland from "svg/countrySelect/map-switzerland.svg"
// import MapPoland from "svg/countrySelect/map-poland.svg"
import { shopCountryCodes } from "utils/i18n/supportedLocalesAndCurrencyCodes"
import { makeStyles } from "makeStyles"

import { CountryCode } from "@secureo/common/typings/CountryCode"
const key = "geoCountryCode"

export const saveGeoCountryCodeToLocalStorage = (geoCountryCode: CountryCode) => {
	localStorage.setItem(key, geoCountryCode)
}
const getGeoCountryCodeFromLocalStorage = () => localStorage.getItem(key)

const getCountry = (geoCountryCode: CountryCode) => {
	switch (geoCountryCode) {
		case "AT":
			return "Österreich"
		case "DE":
			return "Deutschland"
		case "CH":
			return "die Schweiz"
		case "PL":
			return "Polen"
		default:
			return "Deutschland"
	}
}

const getCountryText = (geoCountryCode: CountryCode) => {
	switch (geoCountryCode) {
		case "AT":
			return "Befinden Sie sich in Österreich? Dann empfehlen wir Ihnen, zu unserem Online-Shop für Österreich zu wechseln."
		case "DE":
			return "Befinden Sie sich in Deutschland? Dann empfehlen wir Ihnen, zu unserem Online-Shop für Deutschland zu wechseln."
		case "CH":
			return "Befinden Sie sich in der Schweiz? Dann empfehlen wir Ihnen, zu unserem Online-Shop für die Schweiz zu wechseln."
		case "PL":
			return "Befinden Sie sich in Polen? Dann empfehlen wir Ihnen, zu unserem Online-Shop für Polen zu wechseln."
		default:
			return "Befinden Sie sich in Deutschland? Dann empfehlen wir Ihnen, zu unserem Online-Shop für Deutschland zu wechseln."
	}
}

const getBackgroundImage = (geoCountryCode: CountryCode) => {
	switch (geoCountryCode) {
		case "AT":
			return <MapAustria style={{ backgroundSize: "cover", height: "100%" }} />
		case "DE":
			return <MapGermany style={{ backgroundSize: "cover", height: "100%" }} />
		case "CH":
			return <MapSwitzerland style={{ backgroundSize: "cover", height: "100%" }} />
		// case "PL":
		// 	return <MapPoland style={{ backgroundSize: "cover", height: "100%" }} />
		default:
			return <MapGermany style={{ backgroundSize: "cover", height: "100%" }} />
	}
}

const useStyles = makeStyles()({
	itemLeft: {
		width: "30%",
		display: "block",
	},
	itemRight: {
		width: "70%",
	},
	declineButton: {
		maxWidth: "300px",
		textTransform: "uppercase",
		padding: 5,
		textAlign: "center",
	},
	dialogContent: {
		textAlign: "center",
		marginTop: "80px",
	},
	"@media only screen and (max-width: 800px)": {
		declineButton: {
			fontSize: "12px",
		},
		dialogContent: {
			textAlign: "center",
			marginTop: "10px",
		},
	},
})

// TODO: translate
const SwitchCountryDialog = ({ countryCode, geoCountryCode, language }: Props) => {
	const { classes } = useStyles()
	const isSmallerThan800pxs = useMediaQuery("(max-width: 800px)")
	const [open, setOpen] = useState(true)

	if (!isBrowser()) return null
	if (!geoCountryCode) return null

	const localStorageGeoCountryCode = getGeoCountryCodeFromLocalStorage()
	if (localStorageGeoCountryCode === countryCode) return null

	const isSupportedGeoCountryCode = shopCountryCodes.includes(geoCountryCode)
	const showSwitchCountryDialog = isSupportedGeoCountryCode && geoCountryCode !== countryCode

	const handleClose = () => {
		saveGeoCountryCodeToLocalStorage(countryCode)
		setOpen(false)
	}

	if (!showSwitchCountryDialog) return null

	return (
		<>
			<Dialog
				open={open}
				maxWidth="md"
				fullWidth
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent style={{ margin: 0, padding: 0 }}>
					<div style={{ position: "absolute", top: 30, left: 0 }}>
						<div style={{ height: "60px", width: "60px" }}>
							<CountryFlag countryCode={geoCountryCode as CountryCode} />
						</div>
					</div>
					<div style={{ display: "flex" }}>
						<div
							className={classes.itemLeft}
							style={{
								display: isSmallerThan800pxs ? "none" : "block",
							}}
						>
							{getBackgroundImage(geoCountryCode)}
						</div>
						<div
							className={classes.itemRight}
							style={{
								width: isSmallerThan800pxs ? "100%" : "70%",
							}}
						>
							<Box mt={1} py={5} className={classes.dialogContent}>
								<div style={{ fontSize: "20px" }}>
									<strong>Hallo!</strong>
								</div>
								<Box my={3}>
									<p style={{ maxWidth: "350px", margin: "auto" }}>
										{getCountryText(geoCountryCode)}
									</p>
								</Box>
								<a
									href={`/${language}-${geoCountryCode}`}
									style={{ textDecoration: "none" }}
								>
									<Button color="primary" style={{ maxWidth: "250px" }}>
										Zum Shop für {getCountry(geoCountryCode)}
									</Button>
								</a>
								<Box mt={2}>
									<Button
										color="link"
										onClick={handleClose}
										className={classes.declineButton}
										style={{
											fontSize: isSmallerThan800pxs ? "12px" : "initial",
										}}
									>
										Nein danke, ich möchte hier bleiben
									</Button>
								</Box>
							</Box>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

interface Props {
	countryCode: CountryCode
	geoCountryCode?: CountryCode
	language: string
}

export default SwitchCountryDialog
