import { ReactNode } from "react"

import { FullPageSpinner } from "./Spinner"

import useAuthentication from "../hooks/useAuthentication"

const AuthenticatedPage = ({ children }: Props) => {
	const showPage = useAuthentication()

	if (!showPage) return <FullPageSpinner />

	return <>{children}</>
}

interface Props {
	children: ReactNode
}

export default AuthenticatedPage
