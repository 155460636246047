import getVATRates from "./getVATRates"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const getCompanyCustomerTaxRate = (
	shippingOriginationCountryCode: CountryCode,
	shippingDestinationCountryCode: CountryCode,
) => {
	const taxRates = getVATRates()

	switch (shippingOriginationCountryCode) {
		case "AT":
			switch (shippingDestinationCountryCode) {
				case "AT":
					return taxRates.AT
				default:
					return 0
			}

		case "DE":
			switch (shippingDestinationCountryCode) {
				case "AT":
					return taxRates.AT
				case "DE":
					return taxRates.DE
				default:
					return 0
			}

		// case "PL":
		// 	switch (shippingDestinationCountryCode) {
		// 		case "PL":
		// 			return taxRates.PL
		// 		default:
		// 			return 0
		// 	}

		default:
			switch (shippingDestinationCountryCode) {
				case "DE":
					return taxRates.DE
				case "CH":
				case "GB":
				case "LI":
				case "NO":
					return 0
				default:
					return taxRates.AT
			}
	}
}

export default getCompanyCustomerTaxRate
