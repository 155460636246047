// Matrix is only valid if each row and cell have at least one selected cell
const isValidMatrix = (matrix: boolean[][]) => {
	// Loop over rows and check if each row has at least 1 selected cell
	for (const row of matrix) {
		let numberOfSelectedCellsInRow = 0

		for (const isSelected of row) {
			if (isSelected) numberOfSelectedCellsInRow++
		}

		if (numberOfSelectedCellsInRow === 0) return false
	}

	const numberOfColumns = matrix?.[0]?.length
	const numberOfRows = matrix.length

	// Loop over columns and check if each column has at least 1 selected cell
	for (let columnIndex = 0; columnIndex < numberOfColumns; columnIndex++) {
		let numberOfSelectedCellsInColumn = 0

		for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
			const isSelected = matrix[rowIndex][columnIndex]

			if (isSelected) numberOfSelectedCellsInColumn++
		}

		if (numberOfSelectedCellsInColumn === 0) return false
	}

	return true
}

export default isValidMatrix
