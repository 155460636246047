import getVariantsByVariantId, { VariantsByVariantId } from "./getVariantsByVariantId"
import getMergedAttributes from "./getMergedAttributes"
import getTaxRate from "./getTaxRate"
import getPricePortions from "./getPricePortions"
import getInitialCylinderConfigurations from "./getInitialCylinderConfigurations"
import getInitialCylinderLockingMechanism from "./getInitialCylinderLockingMechanism"
import getMsrpPricePortions from "./getMsrpPricePortions"

import { ColorsBySafeLockConfigurationProductSku, ProductDetailPageReducerState } from ".."
import {
	Product,
	CylinderConfigurationVariant,
	ProductVariant,
} from "@secureo/common/typings/Product"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import getSafeLockConfigurationProduct from "./getSafeLockConfigurationProduct"
import { ShopKey } from "@secureo/common/typings/Shop"
import { CurrencyCode } from "@secureo/common/typings/Forex"

export const getInitialProductId = (
	productId: string,
	initialCylinderConfigurationVariant: CylinderConfigurationVariant,
) => {
	if (initialCylinderConfigurationVariant) return initialCylinderConfigurationVariant.id

	return productId
}

export const getInitialVariantId = (variants: ProductVariant[]) => {
	const initialVariantId = variants.length > 1 ? variants?.[1].id : 1

	return initialVariantId
}

const getInitialSelectedDeliveryOption = (product: Product, countryCode: CountryCode) => {
	const freeDeliveryOption =
		product?.variants?.[0]?.attributes?.deliveryOptionsByDestinationCountryCode?.[
			countryCode
		]?.find((deliveryOption) => deliveryOption.netPriceEuroCents === 0)

	if (!freeDeliveryOption) return null

	const { sku, netPriceEuroCents, deliveryTimeWorkDaysByShopCountryCode } = freeDeliveryOption

	const deliveryTimeWorkDays = deliveryTimeWorkDaysByShopCountryCode?.[countryCode] ?? 0

	const initialSelectedDeliveryOption: ProductDetailPageReducerState["selectedDeliveryOption"] = {
		sku,
		singleNetPriceCents: netPriceEuroCents,
		singleNetMsrpPriceCents: netPriceEuroCents,
		deliveryTimeWorkDays,
	}

	return initialSelectedDeliveryOption
}

const getInitialProductInformation = (
	product: Product,
	variantsByVariantId: VariantsByVariantId,
	variantId: number,
	cylinderConfigurationVariant: CylinderConfigurationVariant,
) => {
	const masterVariant = variantsByVariantId?.[1]
	const { variantsHaveUniqueProductPage = false } = masterVariant?.attributes ?? {}

	if (variantsHaveUniqueProductPage) {
		if (cylinderConfigurationVariant) {
			const {
				name,
				shortDescription,
				description,
				masterVariant: { images = [], assets = [] },
			} = cylinderConfigurationVariant

			return {
				name,
				shortDescription: shortDescription ?? product.shortDescription,
				description: description ?? product.description,
				images: images.length > 0 ? images : masterVariant.images,
				assets: assets.length > 0 ? assets : masterVariant.assets,
			}
		}

		const { images = [], assets: variantAssets = [] } = variantsByVariantId?.[variantId] ?? {}

		const assets = [...variantAssets, ...(masterVariant?.assets ?? [])].filter((asset) =>
			Boolean(asset.name),
		)

		return {
			name: product.name,
			shortDescription: product.shortDescription,
			description: product.description,
			images,
			assets,
		}
	}

	const { name, shortDescription, description } = product
	const { images, assets } = masterVariant

	return {
		name,
		shortDescription,
		description,
		images,
		assets,
	}
}

export const getInitialVariantIdWithSupportForVariantQuery = (
	product: Product,
	initialVariantSku?: string,
) => {
	const { productType, variants, safeLockConfigurationProductsBySku } = product

	const variantId = variants.find((variant) => variant.sku === initialVariantSku)?.id ?? null

	if (productType !== "Zylinder" && variantId) return variantId

	if (productType === "Safe") {
		const variantIdWithFreeLockProduct =
			variants.find((variant) => {
				const {
					attributes: { variantConfigurationProductSku },
				} = variant

				const variantConfigurationProduct =
					safeLockConfigurationProductsBySku?.[variantConfigurationProductSku] ?? null
				const isFree = variantConfigurationProduct?.pricesByCountryCode?.DE === 0

				return isFree
			})?.id ?? null

		if (variantIdWithFreeLockProduct !== null) return variantIdWithFreeLockProduct
	}

	return variants.length > 1 ? variants?.[1].id : 1
}

const getInitialReducerState = (
	product: Product,
	countryCode: CountryCode,
	locale: string,
	shopKey: ShopKey,
	isDebug = false,
	initialVariantSku?: string,
	currencyCode: CurrencyCode = "EUR",
) => {
	if (!product) return {}

	const {
		productType,
		variants,
		slug,
		initialCylinderConfigurationVariant = null,
		cylinderLockingMechanismProducts = null,
		safeLockConfigurationProductsBySku = null,
	} = product

	const variantsByVariantId = getVariantsByVariantId(variants)

	const initialProductId = getInitialProductId(product.id, initialCylinderConfigurationVariant)
	const initialVariantId = getInitialVariantIdWithSupportForVariantQuery(
		product,
		initialVariantSku,
	)
	const initialQuantity = 1

	const attributes = getMergedAttributes(
		variantsByVariantId,
		initialVariantId,
		initialCylinderConfigurationVariant,
	)
	const { name, shortDescription, description, images, assets } = getInitialProductInformation(
		product,
		variantsByVariantId,
		initialVariantId,
		initialCylinderConfigurationVariant,
	)

	const taxRate = getTaxRate(attributes, countryCode)

	const { cylinderConfigurationType, selectedSystem, selectedOuterLength, selectedInnerLength } =
		getInitialCylinderConfigurations(attributes)
	const initialSelectedCylinderLockingMechanism = getInitialCylinderLockingMechanism(
		cylinderLockingMechanismProducts,
	)

	const initialSafeLockConfigurationProduct = getSafeLockConfigurationProduct(
		variantsByVariantId,
		initialVariantId,
		safeLockConfigurationProductsBySku,
	)

	const colorsBySafeLockConfigurationProductSku = Object.keys(
		safeLockConfigurationProductsBySku ?? {},
	).reduce((colorsBySafeLockConfigurationProductSku, safeLockConfigurationProductSku) => {
		const productVariantsForLockSku = variants.filter((variant) => {
			return (
				variant.attributes.variantConfigurationProductSku ===
				safeLockConfigurationProductSku
			)
		})

		const colorsForLockSku = productVariantsForLockSku.map((variant) => {
			const {
				id: variantId,
				attributes: { colorConfigurationHexCode, colorConfigurationName },
			} = variant
			return { variantId, colorConfigurationHexCode, colorConfigurationName }
		})

		return {
			...colorsBySafeLockConfigurationProductSku,
			[safeLockConfigurationProductSku]: colorsForLockSku,
		}
	}, {} as ColorsBySafeLockConfigurationProductSku)

	const initialSelectedDeliveryOption = getInitialSelectedDeliveryOption(product, countryCode)

	const {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
	} = getPricePortions(
		variantsByVariantId,
		initialVariantId,
		taxRate,
		initialQuantity,
		countryCode,
		{},
		initialCylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		initialSelectedCylinderLockingMechanism,
		initialSafeLockConfigurationProduct,
		initialSelectedDeliveryOption,
	)

	const {
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	} = getMsrpPricePortions(
		initialQuantity,
		taxRate,
		countryCode,
		attributes,
		{},
		pricePortions.cylinderLockingMechanismProductSingleNetPriceCents,
		pricePortions.cylinderLockingMechanismProductSingleGrossPriceCents,
		initialSafeLockConfigurationProduct,
		initialSelectedDeliveryOption,
	)

	const initialReducerState: ProductDetailPageReducerState = {
		isLoading: false,
		isDebug,
		showIntermediateShoppingCart: false,
		countryCode,
		currencyCode,
		locale,
		slug,
		productType,
		shopKey,

		productId: initialProductId,
		variantId: initialVariantId,
		name,
		shortDescription,
		description,

		variantsByVariantId,

		images,
		assets,
		attributes: attributes,
		configurationsByConfigurationGroup: {},
		additionalDeliveryTimeWorkDays: 0,

		// Price
		quantity: initialQuantity,
		taxRate,
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,

		// Msrp price
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,

		// Cylinder
		cylinderConfigurationVariant: initialCylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism: initialSelectedCylinderLockingMechanism,
		cylinderConfigurationType,
		selectedSystem,
		selectedOuterLength,
		selectedInnerLength,

		// Safes
		selectedSafeLockConfigurationProduct: initialSafeLockConfigurationProduct,
		safeLockConfigurationProductsBySku,
		selectedDeliveryOption: initialSelectedDeliveryOption,
		colorsBySafeLockConfigurationProductSku,
	}

	return initialReducerState
}

export default getInitialReducerState
