import { Product, CylinderLockingMechanism } from "@secureo/common/typings/Product"

const getInitialCylinderLockingMechanism = (
	cylinderLockingMechanismProducts: Product["cylinderLockingMechanismProducts"],
) => {
	if (!cylinderLockingMechanismProducts) return null

	const initialSelectedCylinderLockingMechanism = {
		mechanism: "single" as CylinderLockingMechanism,
		productId: cylinderLockingMechanismProducts?.single?.productId,
		variantId: 1,
		sku: cylinderLockingMechanismProducts?.single?.sku,
	}

	return initialSelectedCylinderLockingMechanism
}

export default getInitialCylinderLockingMechanism
