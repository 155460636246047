import { useContext } from "react"
import Router from "next/router"
import { DialogContent, MenuItem, Box, TextField } from "@mui/material"
import { makeStyles } from "makeStyles"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import {
	changeCurrency,
	changeLocale as changeAppContextLocale,
} from "@secureo/common/context/AppContext/actions"

import Button from "../Buttons/Button"

import useTranslation from "hooks/useTranslation"
import { useTranslatedShopCountries } from "hooks/useTranslatedCountries"
import useInputValue from "hooks/useInputValue"

// import getCurrencySymbol from "@secureo/common/utils/forex/getCurrencySymbol"
import { saveGeoCountryCodeToLocalStorage } from "../SwitchCountryDialog"

// import {
// 	supportedLanguages,
// 	supportedCurrencyCodes,
// } from "utils/i18n/supportedLocalesAndCurrencyCodes"

import { CurrencyCode } from "typings/Forex"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const i18nNameSpaces = ["common", "languages", "countries"]

const useStyles = makeStyles()(() => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textFieldWith: {
		minWidth: "200px",
	},
}))

export const configByCountryCode = {
	AT: {
		currencyCode: "EUR" as CurrencyCode,
		language: "de",
	},
	CH: {
		currencyCode: "EUR" as CurrencyCode,
		language: "de",
	},
	DE: {
		currencyCode: "EUR" as CurrencyCode,
		language: "de",
	},
	PL: {
		currencyCode: "PLN" as CurrencyCode,
		language: "pl",
	},
}

const ChangeLocalAndCurrencyDialog = ({ handleClose }: Props) => {
	const {
		dispatch,
		countryCode,
		// currencyCode,
		// language,
	} = useContext(AppContext)
	const { t, i18n } = useTranslation(i18nNameSpaces)
	const shopCountries = useTranslatedShopCountries()
	// const selectedLanguage = useInputValue(language)
	const selectedCountryCode = useInputValue(countryCode)
	// const selectedCurrencyCode = useInputValue(currencyCode)
	const { classes } = useStyles()

	const navigateToLocalizedUrl = async (locale: string) => {
		const { route, asPath } = Router.router

		await Router.push(route, asPath, { locale })

		window.location.reload()
	}

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		const countryCode = selectedCountryCode.value as CountryCode
		const { currencyCode, language } =
			configByCountryCode[selectedCountryCode.value as CountryCode]

		// const currencyCode = selectedCurrencyCode.value as CurrencyCode
		// const language = selectedLanguage.value
		// const countryCode = selectedCountryCode.value as CountryCode
		const locale = `${language}-${countryCode}`

		changeCurrency(currencyCode, dispatch)

		i18n.changeLanguage(locale)
		changeAppContextLocale(language, countryCode, dispatch)
		saveGeoCountryCodeToLocalStorage(countryCode)
		await navigateToLocalizedUrl(locale)

		handleClose()
	}

	return (
		<form onSubmit={onSubmit}>
			<DialogContent>
				{/* <TextField
					id="outlined-select-currency"
					select
					label={t("languages:language")}
					margin="dense"
					variant="outlined"
					{...selectedLanguage}
					fullWidth
					inputProps={{
						name: "language",
						id: "language",
					}}
					size="small"
					className={classes.textFieldWith}
					style={{ marginBottom: 15 }}
				>
					{supportedLanguages.map(({ language, i18nKey }) => (
						<MenuItem key={language} value={language}>
							{t("languages:" + i18nKey)}
						</MenuItem>
					))}
				</TextField> */}
				<TextField
					id="outlined-select-currency"
					select
					label={t("countries:country")}
					margin="dense"
					variant="outlined"
					{...selectedCountryCode}
					fullWidth
					inputProps={{
						name: "country",
						id: "country",
					}}
					size="small"
					className={classes.textFieldWith}
					style={{
						// marginBottom: 15
						marginBottom: 0,
					}}
				>
					{shopCountries.map(({ countryCode, name }) => (
						<MenuItem key={countryCode} value={countryCode}>
							{name}
						</MenuItem>
					))}
				</TextField>
				{/* <TextField
					id="outlined-select-currency"
					select
					label="Währung"
					margin="dense"
					variant="outlined"
					{...selectedCurrencyCode}
					fullWidth
					inputProps={{
						name: "currency",
						id: "currency",
					}}
					size="small"
					className={classes.textFieldWith}
					style={{ marginTop: 15 }}
				>
					{supportedCurrencyCodes.map((currencyCode) => (
						<MenuItem key={currencyCode} value={currencyCode}>
							{currencyCode} ({getCurrencySymbol(currencyCode)})
						</MenuItem>
					))}
				</TextField> */}
			</DialogContent>
			<Box px={2} pb={2}>
				<Button type="submit" color="cart">
					{t("common:save")}
				</Button>
			</Box>
		</form>
	)
}

interface Props {
	handleClose: () => void
}

export default ChangeLocalAndCurrencyDialog
