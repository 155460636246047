import axios from "axios"
import base64 from "base-64"

import logAxiosError from "../../logAxiosError"

// https://docs.commercetools.com/http-api-authorization#password-flow-for-customers-in-a-store-beta
const api = `https://auth.europe-west1.gcp.commercetools.com/oauth/${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}/in-store/key=${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}/customers/token`

const createCustomerToken = async (email: string, password: string) => {
	try {
		const { data } = await axios.post(
			api,
			`grant_type=password&username=${email}&password=${password}&scope=${process.env.NEXT_PUBLIC_COMMERCETOOLS_FRONTEND_CLIENT_SCOPES}`,
			{
				headers: {
					Authorization: `Basic ${base64.encode(
						`${process.env.NEXT_PUBLIC_COMMERCETOOLS_FRONTEND_CLIENT_ID}:${process.env.NEXT_PUBLIC_COMMERCETOOLS_FRONTEND_CLIENT_SECRET}`,
					)}`,
					"Content-Type": "application/x-www-form-urlencoded",
				},
			},
		)

		const { token_type, access_token, refresh_token, expires_in } = data

		const expiryDateMs = Date.now() + expires_in * 1000

		return {
			accessToken: `${token_type} ${access_token}`,
			refreshToken: refresh_token,
			expiryDateMs,
		}
	} catch (err) {
		logAxiosError(err)
		throw err.response.data.message
	}
}

export default createCustomerToken
