import getRawAttributeByName from "@secureo/common/utils/commerceTools/getRawAttributeByName"
import getShippingOriginationCountryCodesByShopCountry from "./attributes/getShippingOriginationCountryCodesByShopCountry"
import getDeliveryOptionsByDestinationCountryCode from "./attributes/getDeliveryOptionsByDestinationCountryCode"
import getCustomFieldsByName from "../getCustomFieldsByName"
import formatTaxRate from "./formatTaxRate"
import formatLineItemDiscounts from "./formatLineItemDiscounts"
import parseLineItemUuidCustomField from "../parseLineItemUuidCustomField"
import getDeliveryTimeWorkDaysByShopCountryCode from "./attributes/getDeliveryTimeWorkDaysByShopCountryCode"

import {
	LineItem as CommerceToolsLineItem,
	ProductVariant,
} from "@secureo/common/typings/generated/commercetools-graphql"
import { LineItem, LineItemVariant } from "@secureo/common/typings/Cart"

export const formatLineItemVariant = (lineItemVariant: ProductVariant, language = "de") => {
	const { id, sku, images, attributesRaw } = lineItemVariant

	const formattedImages = images ? images.map(({ url }) => url) : []

	// Find attributes
	const isHaulageDeliveryAttribute = getRawAttributeByName(attributesRaw, "isHaulageDelivery")
	const shippingOriginationCountryCodeAttribute = getRawAttributeByName(
		attributesRaw,
		"shippingOriginationCountryCode",
	)
	const deliveryOptionsAttribute = getRawAttributeByName(attributesRaw, "deliveryOptions")
	const deliveryTimeWorkDaysAttribute = getRawAttributeByName(
		attributesRaw,
		"deliveryTimeWorkdays",
	)
	const variantName = getRawAttributeByName(attributesRaw, "variantName")
	const manufacturer =
		getRawAttributeByName(attributesRaw, "manufacturer")?.value?.label?.de ?? null

	const deliveryTimeWorkDaysByShopCountryCode = getDeliveryTimeWorkDaysByShopCountryCode(
		deliveryTimeWorkDaysAttribute as any,
	)

	// Format attributes
	const isHaulageDelivery = isHaulageDeliveryAttribute ? isHaulageDeliveryAttribute.value : false
	const shippingOriginationCountryCodesByDestinationCountryCode =
		getShippingOriginationCountryCodesByShopCountry(
			shippingOriginationCountryCodeAttribute as any,
		)
	const deliveryOptionsByDestinationCountryCode = deliveryOptionsAttribute
		? getDeliveryOptionsByDestinationCountryCode(deliveryOptionsAttribute as any)
		: {}

	const formattedLineItemVariant: LineItemVariant = {
		id,
		sku,
		images: formattedImages,
		isHaulageDelivery,
		shippingOriginationCountryCodesByDestinationCountryCode,
		deliveryOptionsByDestinationCountryCode,
		deliveryTimeWorkDaysByShopCountryCode,
		manufacturer,
		variantName: variantName?.value?.[language] ?? variantName?.value?.de ?? null,
	}

	return formattedLineItemVariant
}

const formatLineItem = (lineItem: CommerceToolsLineItem, language = "de") => {
	const { id, productId, variant, quantity, taxedPrice, taxRate, custom, addedAt } = lineItem

	const customFieldsByName = getCustomFieldsByName(custom as any)
	const {
		isDiscounted,
		totalDiscountGrossCents,
		totalUnDiscountedPriceGrossCents,
		includedDiscounts,
	} = formatLineItemDiscounts(lineItem)

	const {
		uuid: uuidCustomFieldValue,
		isDeliveryOption = false,
		masterVariantSku = null,
		masterVariantSlug = null,
		lockingSystemId = null,
		lockingSystemComponentName = null,
	} = customFieldsByName

	const { isConfiguration, configurationForProductId, uuid } = parseLineItemUuidCustomField(
		productId,
		uuidCustomFieldValue,
	)

	const name = lineItem.name
	const productSlug = lineItem.productSlug
	const productTypeReference = lineItem.productType
	const productType = productTypeReference.name

	const formattedLineItem: LineItem = {
		id,
		productId,
		addedAt,
		name,
		productSlug: masterVariantSlug || productSlug,
		productType,
		quantity,
		variant: formatLineItemVariant(variant, language),
		taxedPrice,
		taxRate: formatTaxRate(taxRate),
		isDiscounted,
		totalDiscountGrossCents,
		totalUnDiscountedPriceGrossCents,
		includedDiscounts,
		isDeliveryOption,
		isConfiguration,
		configurationForProductId,
		uuid,
		masterVariantSku,
		lockingSystemId,
		lockingSystemComponentName,
	}

	return formattedLineItem
}

export default formatLineItem
