import Cookies from "js-cookie"

export const getCookieForCookieBanner = () => {
	const cookieBannerClicked = Cookies.get("cookieBannerClicked")
	if (cookieBannerClicked === "true") return true
	return false
}

export const setCookieForCookieBanner = () => {
	Cookies.set("cookieBannerClicked", "true", { expires: 30 })
}
