import {
	RawConfigurationLengthCombinationsAttribute,
	LengthCombinationsBySystem,
	SystemAndLengthCombination,
	InnerLengthsByOuterLengths,
} from "@secureo/common/typings/Product"

export const isSystemAndLengthConfiguration = (
	configurationLengthCombinationsAttributeValue: string[],
) => {
	const allValuesContainPipeCharacterAndDashBeforePipe =
		configurationLengthCombinationsAttributeValue.every((entry) => {
			return entry.includes("|") && entry.split("|")?.[0]?.includes("-")
		})

	return allValuesContainPipeCharacterAndDashBeforePipe
}

const isSystemConfiguration = (configurationLengthCombinationsAttributeValue: string[]) => {
	const allValuesContainPipeCharacter = configurationLengthCombinationsAttributeValue.every(
		(entry) => {
			return entry.includes("|")
		},
	)

	return allValuesContainPipeCharacter
}

const systemAndInnerAndOuterLengthConfigurationRegex =
	/^A:\s(?<outerLength>\d+).+\/\sI:\s(?<innerLength>\d+).+$/
const systemAndOuterLengthConfigurationRegex = /^(?<outerLength>\d+)\smm$/

const getInnerAndOuterLengthFromLengthCombination = (lengthCombination: string) => {
	const isSystemAndInnerAndOuterLengthConfiguration =
		systemAndInnerAndOuterLengthConfigurationRegex.test(lengthCombination)

	if (isSystemAndInnerAndOuterLengthConfiguration) {
		const { groups } = systemAndInnerAndOuterLengthConfigurationRegex.exec(lengthCombination)

		const outerLength = Number(groups.outerLength)
		const innerLength = Number(groups.innerLength)

		return {
			outerLength,
			innerLength,
		}
	}

	const isSystemAndOuterLengthConfiguration =
		systemAndOuterLengthConfigurationRegex.test(lengthCombination)

	if (isSystemAndOuterLengthConfiguration) {
		const { groups } = systemAndOuterLengthConfigurationRegex.exec(lengthCombination)

		const outerLength = Number(groups.outerLength)
		const innerLength = null

		return {
			outerLength,
			innerLength,
		}
	}

	return {
		outerLength: null,
		innerLength: null,
	}
}

export const getLengthsBySystem = (
	productSku: string,
	rawSystemAndLengthCombinations: string[],
) => {
	const lengthCombinationsBySystem = rawSystemAndLengthCombinations.reduce(
		(lengthCombinationsBySystem, rawSystemAndLengthCombination) => {
			const [systemAndLengthCombination, lengthCombinationProductSku] =
				rawSystemAndLengthCombination.split("|")
			const [system, lengthCombination] = systemAndLengthCombination.split("-")

			const prevSystemAndLengthCombinations = lengthCombinationsBySystem[system] ?? []

			const { outerLength, innerLength } =
				getInnerAndOuterLengthFromLengthCombination(lengthCombination)

			// If a regex parsing error happened due to faulty data structure
			if (outerLength === null) return lengthCombinationsBySystem

			const systemLengthCombination: SystemAndLengthCombination = {
				outerLength,
				innerLength,
				sku: `${productSku}-${lengthCombinationProductSku}`,
			}

			return {
				...lengthCombinationsBySystem,
				[system]: [...prevSystemAndLengthCombinations, systemLengthCombination],
			}
		},
		{} as LengthCombinationsBySystem,
	)

	return lengthCombinationsBySystem
}

export const getInnerLengthsByOuterLengths = (lengthCombinations: string[]) => {
	const innerLengthsByOuterLengths = lengthCombinations.reduce(
		(innerLengthsByOuterLengths, lengthCombination) => {
			const outerLength = Number(lengthCombination.split("-")[0])
			const innerLength = Number(lengthCombination.split("-")[1])

			const prevInnerLengths = innerLengthsByOuterLengths[outerLength] ?? []

			return {
				...innerLengthsByOuterLengths,
				[outerLength]: [...prevInnerLengths, innerLength],
			}
		},
		{} as InnerLengthsByOuterLengths,
	)

	return innerLengthsByOuterLengths
}

export const getSystems = (productSku: string, rawSystems: string[]) => {
	const systems = rawSystems.map((rawSystem) => {
		const [system, sku] = rawSystem.split("|")

		return {
			system,
			sku: `${productSku}-${sku}`,
		}
	})

	return systems
}

export const getOuterLengths = (rawOuterLengths: string[]) => {
	const outerLengths = rawOuterLengths.map((outerLength) => Number(outerLength))

	return outerLengths
}

const parseRawConfigurationLengthCombinationsAttribute = (
	masterVariantSku: string,
	configurationLengthCombinationsAttribute: RawConfigurationLengthCombinationsAttribute,
) => {
	// Filter falsy values (e.g. empty strings)
	const attributeValue = (configurationLengthCombinationsAttribute?.value ?? []).filter(
		(entry) => !!entry,
	)

	if (isSystemAndLengthConfiguration(attributeValue)) {
		const value = getLengthsBySystem(masterVariantSku, attributeValue)

		const isSystemAndInnerAndOuterLengthConfiguration =
			Object.values(value)?.[0]?.[0]?.innerLength !== null

		if (isSystemAndInnerAndOuterLengthConfiguration) {
			return {
				name: "lengthCombinationsBySystem",
				value,
			}
		}

		return {
			name: "outerLengthsBySystem",
			value,
		}
	}

	if (isSystemConfiguration(attributeValue)) {
		return {
			name: "systems",
			value: getSystems(masterVariantSku, attributeValue),
		}
	}

	const innerLengthsByOuterLengths = attributeValue?.[0]?.includes("-")

	if (innerLengthsByOuterLengths) {
		return {
			name: "innerLengthsByOuterLengths",
			value: getInnerLengthsByOuterLengths(attributeValue),
		}
	}

	return {
		name: "outerLengths",
		value: getOuterLengths(attributeValue),
	}
}

export default parseRawConfigurationLengthCombinationsAttribute
