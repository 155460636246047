import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"
import prependFragmentsToQuery from "../prependFragmentsToQuery"

import {
	CustomerSignMeInDraft,
	AnonymousCartSignInMode,
	Customer,
} from "@secureo/common/typings/generated/commercetools-graphql"

const customerSignMeIn = async (accessToken: string, draft: CustomerSignMeInDraft) => {
	const query = prependFragmentsToQuery(
		/* GraphQL */ `
			mutation customerSignMeIn($draft: CustomerSignMeInDraft!) {
				customerSignMeIn(draft: $draft, storeKey: "${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}") {
					customer {
						id
					}
				}
			}
		`,
		[],
	)

	const signInDraft: CustomerSignMeInDraft = {
		...draft,
		activeCartSignInMode: AnonymousCartSignInMode.UseAsNewActiveCustomerCart,
		updateProductData: true,
	}

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query, {
		draft: signInDraft,
	})) as {
		customerSignMeIn: {
			customer: Pick<Customer, "id">
		}
	}

	return data?.customerSignMeIn?.customer?.id ?? null
}

export default customerSignMeIn
