const parseDeliveryOptionSlug = (slug: string) => {
	const { sku, lineItemId } = /^delivery-\[(?<sku>.+)\]-\[(?<lineItemId>.+)\]$/.exec(slug).groups

	return {
		sku,
		lineItemId,
	}
}

export default parseDeliveryOptionSlug
