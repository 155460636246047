import { useState, useContext } from "react"
import { Box } from "@mui/material"
import axios from "axios"

import AppContext, { AppContextState } from "@secureo/common/context/AppContext/AppContext"
import { signUp } from "@secureo/common/context/AppContext/actions"

import Button from "../Buttons/Button"
import Feedback from "../Feedback/Feedback"
import SalutationInput from "./SalutationInput"
import TextInput from "./TextInput"
import PasswordInput from "./PasswordInput"

import useInputValue from "hooks/useInputValue"
import usePasswordPolicy, { PasswordValidationErrors } from "hooks/usePasswordPolicy"
import useTranslatedFeedback from "hooks/useTranslatedFeedback"
import useTranslation from "hooks/useTranslation"

import isEmail from "utils/validation/isEmail"
import validatePassword from "utils/validation/validatePassword"

interface SignUpValidationErrors {
	email?: string
	password?: string
	firstName?: string
	lastName?: string
	general?: string
}

// TODO: These errors are meant to be i18nKeys, so the corresponding translations should be provided and imported
const validateSignUpForm = (
	firstName: string,
	lastName: string,
	email: string,
	password: string,
	passwordValidationErrors: PasswordValidationErrors,
) => {
	const errors: SignUpValidationErrors = {}

	// FirstName
	if (!firstName) errors.firstName = "noFirstName"

	// LastName
	if (!lastName) errors.lastName = "noLastName"

	// Email
	if (!isEmail(email)) errors.email = "invalidEmail"
	if (!email) errors.email = "noEmail"

	// Password
	const passwordValidationError = validatePassword(password, passwordValidationErrors)
	if (passwordValidationError) errors.password = passwordValidationError

	return {
		isValid: Object.keys(errors).length === 0,
		errors,
	}
}

const RegisterForm = () => {
	const { dispatch, accessToken, language, countryCode } = useContext(
		AppContext,
	) as AppContextState
	const [salutation, setSalutation] = useState("Mr")
	const firstName = useInputValue()
	const lastName = useInputValue()
	const email = useInputValue()
	const password = useInputValue()
	const [, passwordValidationErrors] = usePasswordPolicy(password.value)
	const [errors, setErrors] = useState({} as SignUpValidationErrors)
	const translatedErrors = useTranslatedFeedback(errors)
	const { t } = useTranslation("common")

	const locale = `${language}-${countryCode}`

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setErrors({})

		const { isValid, errors } = validateSignUpForm(
			firstName.value,
			lastName.value,
			email.value,
			password.value,
			passwordValidationErrors,
		)

		try {
			if (isValid) {
				const customer = await signUp(
					{
						salutation,
						firstName: firstName.value,
						lastName: lastName.value,
						email: email.value,
						password: password.value,
					},
					dispatch,
					accessToken,
				)

				// Send emailConfirmation email
				await axios.get(
					`/api/confirmEmailToken?email=${customer.email}&customerId=${customer.id}&locale=${locale}`,
				)

				dispatch({ type: "TOGGLE_LOGIN_REGISTER_FORM_IS_OPEN" })
			} else setErrors(errors)
		} catch (err) {
			console.error(err)
			const isEmailAlreadyExistsError =
				err?.startsWith("There is already an existing customer with the email") ?? false
			isEmailAlreadyExistsError
				? setErrors({ email: "emailAlreadyExists" })
				: setErrors({ general: typeof err === "string" ? err : err.toString() })
		}
	}

	return (
		<form onSubmit={onSubmit}>
			<h2 style={{ color: "#274041" }}>{t("register")}</h2>
			<SalutationInput value={salutation} setSalutation={setSalutation} />

			<TextInput
				name="firstName"
				label={t("firstName")}
				error={translatedErrors.firstName}
				{...firstName}
				required
				fullWidth
			/>
			<TextInput
				name="lastName"
				label={t("lastName")}
				error={translatedErrors.lastName}
				{...lastName}
				required
				fullWidth
			/>
			<TextInput
				type="email"
				name="email"
				label={t("email")}
				error={translatedErrors.email}
				{...email}
				required
				fullWidth
			/>

			<PasswordInput
				name="password"
				label={t("password")}
				error={translatedErrors.password}
				required
				fullWidth
				{...password}
			/>

			<Box my={2}>
				<Button type="submit" color="primary">
					{t("register")}
				</Button>
				{translatedErrors.general && <Feedback type="error">{errors.general}</Feedback>}
			</Box>
		</form>
	)
}

export default RegisterForm
