import fetchExchangeRateBasedOnEuro from "@secureo/common/utils/forex/fetchExchangeRateBasedOnEuro"
import supportsLocalStorage from "@secureo/common/utils/detection/supportsLocalStorage"

import { DispatchAppContext } from "../AppContext"
import { CurrencyCode } from "@secureo/common/typings/Forex"

const localStorageCurrencyCodePreferenceKey = "preferredCurrencyCode"

export const restoreCurrencyPreference = async (dispatch: DispatchAppContext) => {
	if (!supportsLocalStorage()) return null

	const preferredCurrencyCode = localStorage.getItem(
		localStorageCurrencyCodePreferenceKey,
	) as CurrencyCode

	if (!preferredCurrencyCode) return null

	const { rate, validUntilTimestamp } = await fetchExchangeRateBasedOnEuro(preferredCurrencyCode)

	dispatch({
		type: "CHANGE_CURRENCY",
		payload: {
			currencyCode: preferredCurrencyCode,
			rate,
			validUntilTimestamp,
		},
	})
}

export const changeCurrency = async (currencyCode: CurrencyCode, dispatch: DispatchAppContext) => {
	const { rate, validUntilTimestamp } = await fetchExchangeRateBasedOnEuro(currencyCode)

	if (supportsLocalStorage())
		localStorage.setItem(localStorageCurrencyCodePreferenceKey, currencyCode)

	dispatch({
		type: "CHANGE_CURRENCY",
		payload: {
			currencyCode,
			rate,
			validUntilTimestamp,
		},
	})
}
