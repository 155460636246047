import { useContext } from "react"
import { useRouter } from "next/router"
import { Container, Grid, Box, Button } from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import { mdiBasket, mdiCardAccountDetailsOutline } from "@mdi/js"

import Navigation from "./Navigation"

import AppContext from "@secureo/common/context/AppContext/AppContext"

import useTranslation from "hooks/useTranslation"

const useStyles = makeStyles()({
	root: {
		background: "none",
	},
})

const getUpdatedAsPath = (asPath: string, isBusinessVersion: boolean) => {
	const [path, asPathQueryParams] = asPath.split("?")

	const queryParams = new URLSearchParams(asPathQueryParams || "")

	isBusinessVersion ? queryParams.set("pro", "true") : queryParams.delete("pro")

	const updatedQueryParams = queryParams.toString()
	const hasQueryParams = updatedQueryParams.length
	const updatedQueryParamsString = hasQueryParams ? `?${updatedQueryParams}` : ""

	return `${path}${updatedQueryParamsString}`
}

const BusinessVersionToggle = () => {
	const { isBusinessVersion, dispatch } = useContext(AppContext)
	const { t } = useTranslation("common")
	const router = useRouter()

	const togglePrivateBusiness = () => {
		dispatch({ type: isBusinessVersion ? "SET_DEFAULT_VERSION" : "SET_BUSINESS_VERSION" })
		const asPath = getUpdatedAsPath(router.asPath, !isBusinessVersion)
		router.push(router.pathname, asPath, { shallow: true })
	}

	const title = isBusinessVersion ? (
		<div>{t("privateCustomers")}</div>
	) : (
		<div style={{ color: "white" }}>{t("businessCustomers")}</div>
	)
	const iconPath = isBusinessVersion ? mdiBasket : mdiCardAccountDetailsOutline
	const color = isBusinessVersion ? "#31414D" : "#f7fafa"

	return (
		<Button
			onClick={togglePrivateBusiness}
			style={{
				padding: "2px",
				margin: "0",
				width: "100%",
			}}
		>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Icon path={iconPath} size={0.8} color={color} style={{ marginRight: "5px" }} />
				{title}
			</div>
		</Button>
	)
}

const DesktopMenu = () => {
	const { classes } = useStyles()

	return (
		<Box
			className={classes.root}
			sx={{
				display: {
					xs: "none",
					md: "block",
				},
			}}
		>
			<Container>
				<Grid container>
					<Grid item xs={10}>
						<Navigation />
					</Grid>
					<Grid
						item
						xs={2}
						style={{
							fontSize: "13px",
							right: "0",
							top: "10",
							fontWeight: "bold",
						}}
					>
						<div style={{ display: "flex", float: "right" }}>
							<Box ml={1} pt={1}>
								<BusinessVersionToggle />
							</Box>
						</div>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default DesktopMenu
