export type Fragment =
	| "money"
	| "address"
	| "lineItem"
	| "customLineItem"
	| "customer"
	| "cart"
	| "discountCode"
	| "taxedPrice"
	| "order"
	| "payment"
	| "shoppingList"

import addressFragment from "./fragments/address"
import cartFragment from "./fragments/cart"
import shoppingListFragment from "./fragments/shoppingList"
import customerFragment from "./fragments/customer"
import lineItemFragment from "./fragments/lineItem"
import customLineItemFragment from "./fragments/customLineItem"
import moneyFragment from "./fragments/money"
import discountCodeFragment from "./fragments/discountCode"
import taxedPriceFragment from "./fragments/taxedPrice"
import orderFragment from "./fragments/order"
import paymentFragment from "./fragments/payment"

// Be sure to define all fragments here
const fragments = {
	money: moneyFragment,
	cart: cartFragment,
	address: addressFragment,
	lineItem: lineItemFragment,
	customLineItem: customLineItemFragment,
	customer: customerFragment,
	discountCode: discountCodeFragment,
	order: orderFragment,
	taxedPrice: taxedPriceFragment,
	payment: paymentFragment,
	shoppingList: shoppingListFragment,
} as {
	[fragmentName: string]: {
		fragment: string
		dependencies: Fragment[]
	}
}

const getFragmentDependencies = (fragmentName: Fragment, parentDependencies: Fragment[] = []) => {
	const { dependencies } = fragments[fragmentName]
	if (dependencies.length === 0) return parentDependencies as Fragment[]

	return [
		...dependencies.map((fragmentName) =>
			getFragmentDependencies(fragmentName, [...parentDependencies, fragmentName]),
		),
	].flat()
}

const appendFragmentsToQuery = (query: string, fragmentDependencies: Fragment[]) => {
	const dependencies = fragmentDependencies.reduce(
		(dependencies, fragmentName) => [...dependencies, ...getFragmentDependencies(fragmentName)],
		fragmentDependencies,
	)

	const uniqueDependencies = Array.from(new Set(dependencies))

	// This is actually a prepend
	const queryWithPrependedFragments = uniqueDependencies.reduce(
		(queryWithPrependedFragments, fragmentName) =>
			`${fragments[fragmentName].fragment}${queryWithPrependedFragments}`,
		query,
	)

	return queryWithPrependedFragments
}

export default appendFragmentsToQuery
