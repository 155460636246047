import { useState, useEffect, useContext } from "react"
import * as React from "react"
import { useRouter } from "next/router"
import { connectSearchBox } from "react-instantsearch-dom"
import Icon from "@mdi/react"
import { mdiMagnify } from "@mdi/js"
import { Paper, InputBase, Button } from "@mui/material"

import { makeStyles } from "makeStyles"

import AppContext, { AppContextState } from "@secureo/common/context/AppContext/AppContext"

import SearchResults from "./Results/SearchResults"

import useDebounce from "../../hooks/useDebounce"

import searchConfig from "config/searchConfig"
import useTranslation from "hooks/useTranslation"

const useStyles = makeStyles()((theme) => ({
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "100%",
		borderRadius: "25px",
		boxShadow: "none",
		height: "35px",
	},
	input: {
		marginLeft: theme.spacing(2),
		flex: 1,
		fontFamily: "Barlow",
	},
	iconButton: {
		padding: 5,
		color: "#f7fafa",
		background: theme.palette.primary.main,
		borderRadius: "18px",
		width: "90px",
		height: "29px",
		"&:hover": {
			background: theme.palette.primary.main,
		},
	},
	searchInputLabel: {
		fontSize: "12px",
		color: "black",
		fontWeight: "bold",
	},
}))

const SearchBox = ({ searchQuery, setSearchQuery, onSubmit }: SearchBoxProps) => {
	const { classes } = useStyles()
	const { t } = useTranslation("common")

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { value } = e.target
		setSearchQuery(value)
	}

	const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { keyCode, which } = e
		if (keyCode === 13 || which === 13) onSubmit()
	}

	return (
		<Paper className={classes.root}>
			<InputBase
				id="outlined-basic"
				inputProps={{ "aria-label": "search", onKeyPress, "data-hj-whitelist": true }}
				onChange={onChange}
				value={searchQuery}
				className={classes.input}
				placeholder={t("whatAreYouLookingFor")}
				fullWidth
			/>
			<Button
				type="submit"
				className={classes.iconButton}
				aria-label="search"
				onClick={onSubmit}
			>
				<Icon path={mdiMagnify} title="Search" size={0.9} color="#f7fafa" />
				<span className={classes.searchInputLabel}>{t("search")}</span>
			</Button>
		</Paper>
	)
}

interface SearchBoxProps {
	searchQuery: string
	setSearchQuery: (searchQuery: string) => void
	onSubmit: () => void
}

const Search = connectSearchBox(({ locale, currentRefinement, refine }: Props) => {
	const { isBusinessVersion, currencyCode } = useContext(AppContext) as AppContextState
	const [searchQuery, setSearchQuery] = useState(currentRefinement)
	const debouncedSearchQuery = useDebounce(searchQuery, searchConfig.debounceSearchMs)
	const router = useRouter()

	useEffect(() => {
		refine(debouncedSearchQuery)
	}, [debouncedSearchQuery, refine])

	const onSubmit = () => {
		if (searchQuery.length > 0) {
			const businessVersionQuery = isBusinessVersion ? "&pro=true" : ""
			router.push("/search", `/search?query=${searchQuery}${businessVersionQuery}`)
			setSearchQuery("")
		}
	}

	return (
		<>
			<SearchBox
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				onSubmit={onSubmit}
			/>
			<SearchResults locale={locale} showAllResults={onSubmit} currencyCode={currencyCode} />
		</>
	)
})

interface Props {
	locale: string
	currentRefinement: string
	isSearchStalled: boolean
	refine: (value: string) => void
}

export default Search
