import { ProductAttributes, CylinderConfigurationVariant } from "@secureo/common/typings/Product"
import { VariantsByVariantId } from "./getVariantsByVariantId"

const getMergedAttributes = (
	variantsByVariantId: VariantsByVariantId,
	variantId: number,
	cylinderConfigurationVariant?: CylinderConfigurationVariant,
) => {
	const masterVariantAttributes = variantsByVariantId[1]?.attributes ?? {}

	const variantAttributes = variantId > 0 ? variantsByVariantId[variantId]?.attributes ?? {} : {}
	const cylinderConfigurationVariantAttributes =
		cylinderConfigurationVariant?.masterVariant?.attributes ?? {}

	const mergedAttributes: ProductAttributes = {
		...masterVariantAttributes,
		...variantAttributes,
		...cylinderConfigurationVariantAttributes,
	}

	return mergedAttributes
}

export default getMergedAttributes
