import { useContext } from "react"
import { Container, Grid, Box } from "@mui/material"
import { makeStyles } from "makeStyles"
import { InstantSearch } from "react-instantsearch-dom"

import AppContext from "@secureo/common/context/AppContext/AppContext"

import Link from "../i18n/Link"
import TopBar from "./TopBar"
import Search from "../SearchBar/Search"
import MobileMenu from "./MobileMenu"
import DesktopMenu from "./DesktopMenu"
import Help from "./Help"
import ChangeLocaleAndCurrency from "./ChangeLocaleAndCurrency"
import CartPreview from "./CartPreview"

import algoliaClient from "../../utils/algolia/searchClient"

import searchConfig from "config/searchConfig"

import LogoLight from "../../svg/logo/SafeHero-light.svg"
import LogoDark from "../../svg/logo/SafeHero-dark.svg"

// This searchClient only returns results when a query is entered
const searchClient = {
	search(requests) {
		if (
			requests.every(
				({ params }) =>
					!params.query || params.query.length < searchConfig.minCharactersBeforeSearch,
			)
		) {
			return Promise.resolve({
				results: requests.map(() => ({
					hits: [],
					nbHits: 0,
					nbPages: 0,
					processingTimeMS: 0,
				})),
			})
		}

		return algoliaClient.search(requests)
	},
}

const useStyles = makeStyles()((theme) => ({
	root: {
		flexGrow: 1,
		background: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
		zIndex: 1,
		paddingTop: "25px",
		[theme.breakpoints.down("md")]: {
			paddingTop: "0px",
		},
	},
	grow: {
		flexGrow: 1,
	},
	title: {
		display: "block",
		marginRight: "20px",
		[theme.breakpoints.up("sm")]: {
			display: "block",
			position: "absolute",
			left: "0",
		},
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	// TODO: Realize this using useScreenSize hook with breakpoints
	sectionMobile: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	paper: {
		position: "absolute",
		top: 60,
		right: 0,
		left: 0,
	},
}))

const Header = ({ locale }: Props) => {
	const { classes } = useStyles()
	const { isBusinessVersion } = useContext(AppContext)

	const indexName = `${locale}-products`

	return (
		<InstantSearch indexName={indexName} searchClient={searchClient}>
			<TopBar />
			<div className={classes.root}>
				{/* Desktop Header */}
				<Container className={classes.sectionDesktop}>
					<Grid container spacing={3}>
						<Grid item xs={3}>
							<Box mt={1} style={{ position: "relative" }}>
								<Link href="/" data-cy="safeHero-logo">
									{/* TODO: Make this a inline SVG */}
									{!isBusinessVersion ? (
										<LogoLight
											style={{
												maxWidth: "200px",
												paddingTop: "8px",
												maxHeight: "70px",
												margin: "0",
												objectFit: "contain",
												position: "relative",
											}}
										/>
									) : (
										<LogoDark
											style={{
												maxWidth: "200px",
												paddingTop: "8px",
												margin: "0",
												maxHeight: "70px",
												objectFit: "contain",
												position: "relative",
											}}
										/>
									)}
								</Link>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box mt={3}>
								<Search locale={locale} />
							</Box>
						</Grid>
						<Grid item xs={3}>
							<Box mt={2}>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										maxWidth: "400px",
										float: "right",
									}}
								>
									<div style={{ textAlign: "center", marginRight: "15px" }}>
										<ChangeLocaleAndCurrency />
									</div>
									<div style={{ textAlign: "center", marginRight: "15px" }}>
										<Help />
									</div>
									<div style={{ textAlign: "center" }}>
										<CartPreview />
									</div>
								</div>
							</Box>
						</Grid>
					</Grid>
				</Container>
				{/* Mobile Header */}
				<Container className={classes.sectionMobile}>
					<Grid container spacing={3}>
						<Grid item xs={4} style={{ marginTop: "15px" }}>
							<Box>
								<MobileMenu />
							</Box>
						</Grid>
						<Grid item xs={4}>
							<Box style={{ textAlign: "center" }}>
								<Link href="/">
									{!isBusinessVersion ? (
										<LogoLight
											style={{
												height: "40px",
												marginLeft: "-20px",
												paddingTop: "20px",
												objectFit: "contain",
												position: "relative",
											}}
										/>
									) : (
										<LogoDark
											style={{
												height: "40px",
												marginLeft: "-20px",
												paddingTop: "20px",
												objectFit: "contain",
												position: "relative",
											}}
										/>
									)}
								</Link>
							</Box>
						</Grid>
						<Grid item xs={4} style={{ marginTop: "20px", textAlign: "right" }}>
							<CartPreview />
						</Grid>
						<Grid
							item
							xs={12}
							className={classes.sectionMobile}
							style={{ marginTop: "-15px" }}
						>
							<Search locale={locale} />
						</Grid>
					</Grid>
				</Container>
				<div style={{ paddingTop: "10px" }}>
					<DesktopMenu />
				</div>
			</div>
		</InstantSearch>
	)
}

interface Props {
	locale: string
}

export default Header
