interface CustomFields {
	customFieldsRaw: {
		name: string
		value: any
	}[]
}

interface CustomFieldsByName {
	[name: string]: any
}

const getCustomFieldsByName = (custom?: CustomFields) => {
	if (!custom) return {}

	const { customFieldsRaw } = custom

	const customFieldsByName = customFieldsRaw.reduce((customFieldsByName, { name, value }) => {
		return {
			...customFieldsByName,
			[name]: value,
		}
	}, {}) as CustomFieldsByName

	return customFieldsByName
}

export default getCustomFieldsByName
