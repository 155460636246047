import { useContext } from "react"
import { Container, Box, Grid, Hidden } from "@mui/material"
import Icon from "@mdi/react"
import { mdiCellphone, mdiChevronLeft, mdiPhoneInTalk } from "@mdi/js"

import Link from "components/i18n/Link"
import { getLocalizedPhoneNumber, Phone } from "components/i18n/Contact"
import AppContext from "@secureo/common/context/AppContext/AppContext"

import LogoLight from "svg/logo/SafeHero-light.svg"
import LogoDark from "svg/logo/SafeHero-dark.svg"
import { makeStyles } from "makeStyles"

import useTranslation from "hooks/useTranslation"

const useStyles = makeStyles()((theme) => ({
	style: {
		background: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
	},
}))

const CheckoutHeader = () => {
	const { classes } = useStyles()
	const { isBusinessVersion, countryCode } = useContext(AppContext)
	const { t } = useTranslation(["checkout", "countries"])

	const phoneNumber = getLocalizedPhoneNumber(countryCode)

	return (
		<Box py={1} className={classes.style}>
			<Container style={{ position: "relative" }}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<Link href="/">
							<Hidden mdDown>
								{!isBusinessVersion ? (
									<LogoLight
										style={{
											maxWidth: "180px",
											maxHeight: "70px",
											margin: "0",
											paddingTop: "5px",
											position: "relative",
											left: 0,
										}}
									/>
								) : (
									<LogoDark
										style={{
											maxWidth: "180px",
											maxHeight: "70px",
											margin: "0",
											paddingTop: "5px",
											position: "relative",
											left: 0,
										}}
									/>
								)}
							</Hidden>
							<Hidden mdUp>
								<Box mt={1}>
									<Icon
										path={mdiChevronLeft}
										size={1.7}
										color={isBusinessVersion ? "#274041" : "#f7fafa"}
									/>
								</Box>
							</Hidden>
						</Link>
					</Grid>
					<Grid item xs={4}>
						<Box>
							<div
								className="h1"
								style={{
									textAlign: "center",
									margin: "0",
									marginTop: "10px",
									fontSize: "1.5rem",
								}}
							>
								{t("checkout:checkout")}
							</div>
						</Box>
					</Grid>
					<Grid item xs={4}>
						<Box
							mt={1}
							style={{
								display: "flex",
								alignItems: "center",
								position: "absolute",
								right: "25px",
							}}
						>
							<Hidden mdDown>
								<div>
									<Icon path={mdiCellphone} size={1.7} color="#7abfac" />
								</div>
								<div>
									<div style={{ fontWeight: "bold", letterSpacing: "0.17em" }}>
										<Phone />
									</div>
									<div className="small">
										{t("checkout:freeIn")} {t(`countries:${countryCode}`)}
									</div>
								</div>
							</Hidden>
							<Hidden mdUp>
								<Box mt={1}>
									<a href={`tel:${phoneNumber}`}>
										<Icon
											path={mdiPhoneInTalk}
											size={1.1}
											color={isBusinessVersion ? "#274041" : "#f7fafa"}
										/>
									</a>
								</Box>
							</Hidden>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default CheckoutHeader
