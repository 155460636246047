const taxedPriceFragment = {
	fragment: /* GraphQL */ `
		fragment taxedPrice on TaxedPrice {
			totalNet {
				...money
			}
			totalGross {
				...money
			}
			taxPortions {
				name
				rate
				amount {
					...money
				}
			}
		}
	`,
	dependencies: ["money"],
}

export default taxedPriceFragment
