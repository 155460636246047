import getPricePortions from "@secureo/common/reducers/productDetailPageReducer/utils/getPricePortions"
import getMsrpPricePortions from "@secureo/common/reducers/productDetailPageReducer/utils/getMsrpPricePortions"

import {
	ProductDetailPageReducerState,
	DispatchProductDetailPageReducer,
} from "@secureo/common/reducers/productDetailPageReducer"
import { CylinderLockingMechanism } from "@secureo/common/typings/Product"

type SetSelectedCylinderLockingMechanismPayload = Pick<
	ProductDetailPageReducerState,
	// Price
	| "singleNetPriceCents"
	| "singleGrossPriceCents"
	| "totalNetPriceCents"
	| "totalGrossPriceCents"
	| "pricePortions"
	| "singleNetMsrpPriceCents"
	| "singleGrossMsrpPriceCents"
	| "totalNetMsrpPriceCents"
	| "totalGrossMsrpPriceCents"
	| "msrpPricePortions"

	// Cylinder
	| "selectedCylinderLockingMechanism"
>

export const setSelectedCylinderLockingMechanism = (
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	mechanism: CylinderLockingMechanism,
) => {
	const {
		countryCode,

		variantsByVariantId,
		variantId,
		configurationsByConfigurationGroup,
		attributes,

		// Price
		quantity,
		taxRate,

		// Cylinder
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
	} = reducerState

	const selectedCylinderLockingMechanismProduct = cylinderLockingMechanismProducts[mechanism]

	const selectedCylinderLockingMechanism: ProductDetailPageReducerState["selectedCylinderLockingMechanism"] =
		{
			mechanism,
			productId: selectedCylinderLockingMechanismProduct.productId,
			variantId: selectedCylinderLockingMechanismProduct.variantId,
			sku: selectedCylinderLockingMechanismProduct?.sku,
		}

	const {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
	} = getPricePortions(
		variantsByVariantId,
		variantId,
		taxRate,
		quantity,
		countryCode,
		configurationsByConfigurationGroup,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
	)

	const {
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	} = getMsrpPricePortions(
		quantity,
		taxRate,
		countryCode,
		attributes,
		configurationsByConfigurationGroup,
		pricePortions.cylinderLockingMechanismProductSingleNetPriceCents,
		pricePortions.cylinderLockingMechanismProductSingleGrossPriceCents,
	)

	const payload: SetSelectedCylinderLockingMechanismPayload = {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
		selectedCylinderLockingMechanism,
	}

	dispatch({
		type: "SET_CYLINDER_LOCKING_MECHANISM",
		payload: payload,
	})
}
