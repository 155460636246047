import getCustomFieldsByName from "../getCustomFieldsByName"
import parseLineItemUuidCustomField from "../parseLineItemUuidCustomField"
import { formatLineItemVariant } from "./formatLineItem"
import { getFilteredPricesByCountryCodeAndCurrency } from "./formatProduct"

import {
	ShoppingList as CommerceToolsShoppingList,
	ShoppingListLineItem as CommerceToolsShoppingListLineItem,
} from "@secureo/common/typings/generated/commercetools-graphql"
import {
	ShoppingList,
	ShoppingListLineItem,
	ShoppingListLineItemVariant,
} from "@secureo/common/typings/ShoppingList"
import { CurrencyCode } from "@secureo/common/typings/Forex"

export const formatShoppingListLineItemVariant = (
	lineItemVariant: CommerceToolsShoppingListLineItem["variant"],
	currencyCode: CurrencyCode,
) => {
	const { prices } = lineItemVariant

	const formattedLineItemVariant = formatLineItemVariant(lineItemVariant)

	const pricesByCountryCode = getFilteredPricesByCountryCodeAndCurrency(prices, currencyCode)

	const fallbackPricesByCountryCode = {
		AT: prices?.[0]?.value?.centAmount ?? 0,
		CH: prices?.[0]?.value?.centAmount ?? 0,
		DE: prices?.[0]?.value?.centAmount ?? 0,
	}

	const formattedShoppingListLineItemVariant: ShoppingListLineItemVariant = {
		...formattedLineItemVariant,
		pricesByCountryCode:
			Object.keys(pricesByCountryCode).length > 0
				? pricesByCountryCode
				: fallbackPricesByCountryCode,
	}

	return formattedShoppingListLineItemVariant
}

const formatShoppingListLineItem = (
	shoppingListLineItem: CommerceToolsShoppingListLineItem,
	currencyCode: CurrencyCode,
) => {
	const { id, productId, productSlug, quantity, name, variant, custom } = shoppingListLineItem

	const customFieldsByName = getCustomFieldsByName(custom as any)

	const {
		uuid: uuidCustomFieldValue,
		isDeliveryOption = false,
		masterVariantSku = null,
		masterVariantSlug = null,
	} = customFieldsByName

	const { isConfiguration, configurationForProductId, uuid } = parseLineItemUuidCustomField(
		productId,
		uuidCustomFieldValue,
	)

	const formattedShoppingListLineItem: ShoppingListLineItem = {
		id,
		productId,
		productSlug: masterVariantSlug || productSlug,
		quantity,
		name,
		variant: formatShoppingListLineItemVariant(variant, currencyCode),
		isDeliveryOption,
		isConfiguration,
		configurationForProductId,
		uuid,
		masterVariantSku,
		custom,
	}

	return formattedShoppingListLineItem
}

const formatShoppingList = (
	shoppingList: CommerceToolsShoppingList,
	currencyCode: CurrencyCode,
) => {
	const {
		id,
		version,
		createdAt,
		lastModifiedAt,

		// LineItems
		lineItems,
		textLineItems,
		custom,
	} = shoppingList

	const formattedShoppingList: ShoppingList = {
		id,
		version,
		createdAt,
		lastModifiedAt,

		// LineItems
		lineItems: lineItems.map((lineItem) => formatShoppingListLineItem(lineItem, currencyCode)),
		textLineItems,
		custom,
	}

	return formattedShoppingList
}

export default formatShoppingList
