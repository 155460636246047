import { RawTechnicalDetailsAttribute, TechnicalDetails } from "@secureo/common/typings/Product"

const getTechnicalDetails = (technicalDetailsAttribute: RawTechnicalDetailsAttribute) => {
	const technicalDetails = technicalDetailsAttribute.value.reduce(
		(technicalDetails, rawTechnicalDetailsEntry) => {
			const [rawNames, rawValues] = rawTechnicalDetailsEntry

			const name = rawNames?.value?.de ?? null
			const values = rawValues?.value?.de ?? null

			return { ...technicalDetails, [name]: values }
		},
		{} as TechnicalDetails,
	)

	return technicalDetails
}

export default getTechnicalDetails
