import * as React from "react"
import { IconButton, InputAdornment } from "@mui/material"
import Icon from "@mdi/react"
import { mdiEye, mdiEyeOffOutline } from "@mdi/js"

import TextInput from "../Forms/TextInput"

import useToggle from "../../hooks/useToggle"

const PasswordInput = ({
	value,
	onChange,
	name = "password",
	label,
	error = "",
	required = true,
}: Props) => {
	const [showPassword, toggleShowPassword] = useToggle()

	return (
		<TextInput
			type={showPassword ? "text" : "password"}
			name={name}
			label={label}
			value={value}
			onChange={onChange}
			required={required}
			error={error}
			fullWidth
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							edge="end"
							aria-label="toggle password visibility"
							onClick={toggleShowPassword}
							size="large"
						>
							{showPassword ? (
								<Icon path={mdiEyeOffOutline} size={1} />
							) : (
								<Icon path={mdiEye} size={1} />
							)}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	)
}

interface Props {
	value: string
	onChange: (e: any) => void
	label: string
	name?: string
	error?: string
	required?: boolean
	disabled?: boolean
	style?: React.CSSProperties
	fullWidth?: any
}

export default PasswordInput
