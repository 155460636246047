import { CountryCode } from "@secureo/common/typings/CountryCode"

import {
	ProductConfiguration,
	ProductConfigurationsBySku,
} from "@secureo/common/reducers/productDetailPageReducer"
import getProductConfigurationFromConfigurationProduct from "./getProductConfigurationFromConfigurationProduct"
import { ConfigurationGroupProductsBySku } from "@secureo/common/typings/Product"

export const getConfigurationsBySkuFromConfigurations = (
	configurations: ProductConfiguration[],
) => {
	const configurationsBySku = configurations.reduce((configurationsBySku, configuration) => {
		return {
			...configurationsBySku,
			[configuration.sku]: configuration,
		}
	}, {} as ProductConfigurationsBySku)

	return configurationsBySku
}

export const getUpdatedConfigurationsForMultiConfigurationGroup = (
	configurations: ProductConfiguration[],
	configurationGroupProductsBySku: ConfigurationGroupProductsBySku,
	configurationGroupName: string,
	sku: string,
	quantity: number,
	countryCode: CountryCode,
) => {
	const filteredConfigurations = configurations.filter(
		(configuration) => configuration.sku !== sku,
	)

	if (quantity === 0) {
		return filteredConfigurations
	}

	const configurationProduct = configurationGroupProductsBySku?.[sku]
	const configuration = getProductConfigurationFromConfigurationProduct(
		configurationProduct,
		configurationGroupName,
		quantity,
		countryCode,
	)

	const updatedConfigurations = [...filteredConfigurations, configuration]

	return updatedConfigurations
}
