import { formatProductVariant } from "./formatProduct"
import { getLocalizedName } from "../getLocalizedMetaData"

import { CylinderConfigurationVariant } from "@secureo/common/typings/Product"
import { RawProduct } from "../graphql/queries/fetchProduct"

const formatCylinderConfigurationVariant = (
	cylinderConfigurationProduct: RawProduct,
	locale: string,
) => {
	const {
		id,
		masterData: {
			current: { masterVariant },
		},
	} = cylinderConfigurationProduct

	const sku = masterVariant.sku

	const formattedMasterVariant = formatProductVariant(
		masterVariant.sku,
		masterVariant,
		"Zylinder",
	)

	const name = getLocalizedName(cylinderConfigurationProduct.masterData.current, locale)

	const shortDescription = formattedMasterVariant?.attributes?.shortDescription ?? null
	const description = formattedMasterVariant?.attributes?.descriptionDigishop ?? null
	// Prevent duplicated data in response
	delete formattedMasterVariant?.attributes?.shortDescription
	delete formattedMasterVariant?.attributes?.description

	const formattedCylinderConfigurationVariant: CylinderConfigurationVariant = {
		id,
		sku,
		name,
		description,
		masterVariant: formattedMasterVariant,
		shortDescription,
	}

	return formattedCylinderConfigurationVariant
}

export default formatCylinderConfigurationVariant
