import getUserLocale from "@secureo/common/utils/i18n/getUserLocale"
import getCurrencySymbol from "./getCurrencySymbol"
import getNumberOfCurrencyFractionsDigits from "./getNumberOfCurrencyFractionsDigits"

import { CurrencyCode } from "@secureo/common/typings/Forex"

const getLocalizedPrice = (
	priceInSmallestFraction: number,
	numberOfFractionDigits: number,
	userLocale: string,
) => {
	const price = priceInSmallestFraction / 10 ** numberOfFractionDigits

	try {
		const localizedPrice = new Intl.NumberFormat(userLocale, {
			minimumFractionDigits: 2,
		}).format(price)

		return localizedPrice
	} catch (err) {
		return price.toLocaleString()
	}
}

const formatPrice = (
	priceInSmallestFraction: number,
	locale?: string,
	currencyCode: CurrencyCode = "EUR",
) => {
	const numberOfFractionDigits = getNumberOfCurrencyFractionsDigits(currencyCode)
	const currencySymbol = getCurrencySymbol(currencyCode)
	const userLocale = getUserLocale(locale)

	const localizedPrice = getLocalizedPrice(
		priceInSmallestFraction,
		numberOfFractionDigits,
		userLocale,
	)

	const formattedPrice = `${localizedPrice} ${currencySymbol}`

	return formattedPrice
}

export default formatPrice
