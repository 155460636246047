import useTranslation from "./useTranslation"

import {
	defaultMinLength,
	defaultMaxLength,
	defaultMinNumbers,
	defaultMinSpecialChars,
} from "./usePasswordPolicy"

import { TFunction } from "i18next"

const geTranslatedValidationResult = (validationResult: string, t: TFunction) => {
	switch (validationResult) {
		case "minPasswordLength":
			return t(validationResult, { length: defaultMinLength })
		case "maxPasswordLength":
			return t(validationResult, { length: defaultMaxLength })
		case "minNumbers":
			return t(validationResult, { length: defaultMinNumbers })
		case "minSpecialChars":
			return t(validationResult, { length: defaultMinSpecialChars })
		default:
			return t(validationResult)
	}
}

const useTranslatedFeedback = (errors: any) => {
	const { t } = useTranslation("feedback")

	const translatedFeedback = Object.entries(errors).reduce(
		(translatedFeedback, [key, validationResult]) => {
			return {
				...translatedFeedback,
				[key]: geTranslatedValidationResult(validationResult as string, t),
			}
		},
		{},
	) as {
		[key: string]: string
	}

	return translatedFeedback
}

export default useTranslatedFeedback
