import { useContext } from "react"
import { Box, List, ListItem, ListItemText } from "@mui/material"
import Icon from "@mdi/react"
import {
	mdiAccountCircle,
	mdiLoginVariant,
	mdiCart,
	mdiChevronRight,
	mdiChevronLeft,
	mdiSafe,
	mdiLockOpenOutline,
	mdiVideoWirelessOutline,
	mdiAlertOutline,
	mdiMailboxOpenOutline,
	mdiHelpCircleOutline,
} from "@mdi/js"

import Link from "../i18n/Link"
import AppContext from "@secureo/common/context/AppContext/AppContext"
import { LoginRegisterButton } from "../Login/LoginRegister"

import useTranslation from "hooks/useTranslation"

import getValueFromLocalizedAttribute from "utils/i18n/getValueFromLocalizedAttribute"
import { makeStyles } from "makeStyles"
import navCategories from "config/navCategories.json"

import { NavCategory } from "typings/Category"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const useStyles = makeStyles()((theme) => ({
	root: {
		width: "100%",
		maxWidth: "100%",
	},
	iconGrid: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: "#274041",
		fontSize: "13px",
		background: "none",
	},
}))

// TODO: USE category keys
const navigationIconConfig = {
	"Tresore & Safes": mdiSafe,
	Zutrittskontrolle: mdiLockOpenOutline,
	"Alarm & Video": mdiVideoWirelessOutline,
	Hausabsicherung: mdiAlertOutline,
	"Eingangsbereich & Garten": mdiMailboxOpenOutline,
}

interface NavChildProps {
	language: string
	countryCode: CountryCode
	navCategory: NavCategory
	filterCategorySlug?: string
	mostPopularCategories?: NavCategory[]
	toggleIsOpen: () => void
}

const NavLink = ({
	navCategory,
	children,
	toggleIsOpen,
	language,
}: NavChildProps & {
	children: React.ReactNode
}) => {
	const isParentCategory = !navCategory.parentId

	const slug = getValueFromLocalizedAttribute(navCategory.slug, language)
	const href = `/${isParentCategory ? "pc" : "c"}/${slug}`

	return (
		<Link href={href} onClick={toggleIsOpen}>
			{children}
		</Link>
	)
}

const NavListItem = ({ navCategory, toggleIsOpen, language, countryCode }: NavChildProps) => {
	const name = getValueFromLocalizedAttribute(navCategory.name, language)

	return (
		<NavLink
			navCategory={navCategory}
			toggleIsOpen={toggleIsOpen}
			language={language}
			countryCode={countryCode}
		>
			<ListItem>
				<ListItemText primary={name} />
			</ListItem>
		</NavLink>
	)
}

const Filter = ({ filter, filterCategorySlug, toggleIsOpen }: FilterProps) => {
	const { name, url } = filter
	const slug = `${filterCategorySlug}${url}`

	return (
		<Link href={`/c/${slug}`} onClick={toggleIsOpen}>
			<ListItem component="span">
				<Box pl={1}>
					<ListItemText primary={name} />
				</Box>
			</ListItem>
		</Link>
	)
}

interface FilterProps {
	filter: { name: string; url: string }
	filterCategorySlug: string
	toggleIsOpen: () => void
}

const NavList = ({
	navCategory,
	filterCategorySlug,
	mostPopularCategories = [],
	toggleIsOpen,
	language,
	countryCode,
}: NavChildProps) => {
	const name = getValueFromLocalizedAttribute(navCategory.name, language)

	const { children, attributes } = navCategory
	const { isMostPopularCategory, isFilterCategory, filters } = attributes

	const childCategories = isMostPopularCategory
		? mostPopularCategories.filter(({ id }) => id !== navCategory.id)
		: children

	return (
		<Box mt={2}>
			<ListItem style={{ background: "whitesmoke" }}>
				<NavLink
					navCategory={navCategory}
					toggleIsOpen={toggleIsOpen}
					language={language}
					countryCode={countryCode}
				>
					<strong>{name}</strong>
				</NavLink>
			</ListItem>
			{isFilterCategory ? (
				<List component="nav" aria-label="category-list">
					{filters.map((filter) => {
						return (
							<Filter
								key={`${navCategory.id}-${filter.name}`}
								filter={filter}
								filterCategorySlug={filterCategorySlug}
								toggleIsOpen={toggleIsOpen}
							/>
						)
					})}
				</List>
			) : (
				<List component="nav" aria-label="category-list">
					{childCategories
						.filter(
							(childCategories) =>
								childCategories.attributes.showInNavbar &&
								!childCategories.attributes.excludedCountryCodes?.includes(
									countryCode.toLowerCase(),
								),
						)
						.map((navCategory) => {
							return (
								<NavListItem
									key={navCategory.id}
									navCategory={navCategory}
									toggleIsOpen={toggleIsOpen}
									language={language}
									countryCode={countryCode}
								/>
							)
						})}
				</List>
			)}
		</Box>
	)
}

const NavSlideOpen = ({ navCategory, toggleIsOpen, language, countryCode }: NavChildProps) => {
	const name = getValueFromLocalizedAttribute(navCategory.name, language)
	const icon = navigationIconConfig[getValueFromLocalizedAttribute(navCategory.name, "de")]

	const { children, mostPopularCategories } = navCategory
	const filteredChildren = children.filter((childCategory) => {
		return !childCategory.attributes.excludedCountryCodes?.includes(countryCode.toLowerCase())
	})

	const filterCategorySlug = getValueFromLocalizedAttribute(children[0].slug, language)

	return (
		<ul className="main-buttons">
			<li>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<Icon path={icon} title="Safes" size={1} className="icon" />
					<div className="category">{name}</div>
					<Icon path={mdiChevronRight} title="Open" size={1} />
				</div>
				<ul className="hidden" style={{ overflowY: "scroll" }}>
					{filteredChildren.map((navCategory) => {
						return (
							<NavList
								key={navCategory.id}
								navCategory={navCategory}
								filterCategorySlug={filterCategorySlug}
								mostPopularCategories={mostPopularCategories}
								toggleIsOpen={toggleIsOpen}
								language={language}
								countryCode={countryCode}
							/>
						)
					})}
				</ul>
			</li>
		</ul>
	)
}

const QuickLinks = ({ toggleIsOpen }: Props) => {
	const { classes } = useStyles()
	const { t } = useTranslation("common")
	const { dispatch, isLoggedIn } = useContext(AppContext)

	const toggleLoginRegisterFormIsOpen = () => {
		dispatch({
			type: "TOGGLE_LOGIN_REGISTER_FORM_IS_OPEN",
		})
	}

	const openLoginForm = () => {
		dispatch({
			type: "SET_OPEN_LOGIN_REGISTER_FORM_TAB",
			payload: "login",
		})
		toggleLoginRegisterFormIsOpen()
	}

	return (
		<div style={{ display: "flex", justifyContent: "space-evenly" }}>
			<div className={classes.iconGrid}>
				{isLoggedIn ? (
					<Link href="/account" onClick={toggleIsOpen} style={{ color: "black" }}>
						<Icon path={mdiAccountCircle} title="Account" size={1} />
						<div>Account</div>
					</Link>
				) : (
					<a style={{ color: "black" }}>
						<button
							style={{
								background: "none",
								border: "none",
							}}
							onClick={openLoginForm}
						>
							<Icon path={mdiLoginVariant} title="Account" size={1} />
						</button>
						<LoginRegisterButton />
					</a>
				)}
			</div>
			<div className={classes.iconGrid}>
				<Link href="/help" onClick={toggleIsOpen} style={{ color: "black" }}>
					<Icon
						path={mdiHelpCircleOutline}
						title="Help"
						size={1}
						style={{ marginBottom: "5px" }}
					/>
					<div>{t("help")}</div>
				</Link>
			</div>

			<div className={classes.iconGrid}>
				<Link href="/cart" onClick={toggleIsOpen} style={{ color: "black" }}>
					<Icon path={mdiCart} title="Cart" size={1} style={{ marginBottom: "5px" }} />
					<div>{t("cart")}</div>
				</Link>
			</div>
		</div>
	)
}

const MobileMenuList = ({ toggleIsOpen }: Props) => {
	const { language, countryCode } = useContext(AppContext)
	const { t } = useTranslation("common")

	const filteredNavCategories = navCategories.filter((navCategory) => {
		return !navCategory.attributes.excludedCountryCodes?.includes(countryCode.toLowerCase())
	})

	return (
		<>
			<QuickLinks toggleIsOpen={toggleIsOpen} />
			<nav className="sidenav" style={{ zIndex: 9 }}>
				<Box
					mt={4}
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Box ml={2}>
						<Icon path={mdiChevronLeft} title="Back" size={1} />
					</Box>
					<div
						className="category"
						style={{
							textTransform: "uppercase",
							fontWeight: "bold",
							marginLeft: "-40px",
						}}
					>
						{t("products")}
					</div>
					<div />
				</Box>
				<div className="navbar">
					{filteredNavCategories.map((navCategory) => {
						return (
							<NavSlideOpen
								key={navCategory.id}
								navCategory={navCategory as any as NavCategory}
								toggleIsOpen={toggleIsOpen}
								language={language}
								countryCode={countryCode}
							/>
						)
					})}
				</div>
			</nav>
		</>
	)
}

interface Props {
	toggleIsOpen: () => void
}

export default MobileMenuList
