import { useState, useContext } from "react"
import { Box } from "@mui/material"
import axios from "axios"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import { login } from "@secureo/common/context/AppContext/actions"

import Button from "../Buttons/Button"
import Feedback from "../Feedback/Feedback"
import TextInput from "./TextInput"
import PasswordInput from "./PasswordInput"

import useInputValue from "hooks/useInputValue"
import useToggle from "@secureo/common/hooks/useToggle"
import useTranslatedFeedback from "hooks/useTranslatedFeedback"
import useTranslation from "hooks/useTranslation"

import isEmail from "utils/validation/isEmail"

import Icon from "@mdi/react"
import { mdiChevronLeft } from "@mdi/js"

interface ForgotPasswordValidationErrors {
	email?: string
	general?: string
}

const validateForgotPasswordForm = (email: string) => {
	const errors: ForgotPasswordValidationErrors = {}

	// Email
	if (!isEmail(email)) errors.email = "invalidEmail"
	if (!email) errors.email = "required"

	return {
		isValid: Object.keys(errors).length === 0,
		errors,
	}
}

const ForgotPasswordForm = ({ toggleIsForgotPasswordView }: ForgotPasswordFormProps) => {
	const email = useInputValue()
	const [isSuccess, setIsSuccess] = useState(false)
	const [errors, setErrors] = useState({} as ForgotPasswordValidationErrors)
	const translatedErrors = useTranslatedFeedback(errors) as ForgotPasswordValidationErrors
	const { t } = useTranslation("common")

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		setErrors({})
		const { isValid, errors } = validateForgotPasswordForm(email.value)

		if (!isValid) {
			setErrors(errors)
			return
		}

		try {
			await axios.get(`/api/resetPasswordToken?email=${email.value}`)
		} catch (err) {
			console.error(err)
		} finally {
			setIsSuccess(true)
		}
	}

	return (
		<>
			<button
				onClick={toggleIsForgotPasswordView}
				style={{
					background: "none",
					border: "none",
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
				}}
			>
				<Icon path={mdiChevronLeft} size={0.7} /> {t("goBack")}
			</button>
			<div style={{ color: "#274041" }}>
				<h2>{t("resetPassword")}</h2>
				{!isSuccess && <p>{t("pleaseEnterEmail")}</p>}
			</div>
			{!isSuccess ? (
				<form onSubmit={onSubmit}>
					<TextInput
						type="email"
						name="email"
						label={t("email")}
						error={translatedErrors.email}
						required
						{...email}
						fullWidth
					/>

					<Box my={2}>
						<Button color="primary" type="submit">
							{t("resetPassword")}
						</Button>
						{errors.general && (
							<Box my={2}>
								<Feedback type="error">{translatedErrors.general}</Feedback>
							</Box>
						)}
					</Box>
				</form>
			) : (
				<p>{t("pleaseCheckInbox", { email: email.value })}</p>
			)}
		</>
	)
}

interface ForgotPasswordFormProps {
	toggleIsForgotPasswordView: () => void
}

interface LoginValidationErrors {
	email?: string
	password?: string
	general?: string
}

const validateLoginForm = (email: string, password: string) => {
	const errors: LoginValidationErrors = {}

	// Email
	if (!isEmail(email)) errors.email = "invalidEmail"
	if (!email) errors.email = "required"

	// Password
	if (!password) errors.password = "required"

	return {
		isValid: Object.keys(errors).length === 0,
		errors,
	}
}

const LoginForm = () => {
	const { dispatch, accessToken } = useContext(AppContext)
	const [isForgotPasswordView, toggleIsForgotPasswordView] = useToggle()
	const email = useInputValue()
	const password = useInputValue()
	const [errors, setErrors] = useState({} as LoginValidationErrors)
	const translatedErrors = useTranslatedFeedback(errors) as LoginValidationErrors
	const { t } = useTranslation("common")

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setErrors({})

		const { isValid, errors } = validateLoginForm(email.value, password.value)

		if (isValid) {
			try {
				await login(email.value, password.value, dispatch, accessToken)

				dispatch({ type: "TOGGLE_LOGIN_REGISTER_FORM_IS_OPEN" })
			} catch (err) {
				const isAccountNotFoundError =
					err === "Account with the given credentials not found."
				isAccountNotFoundError
					? setErrors({ general: "accountNotFound" })
					: setErrors({ general: err })
			}
		} else setErrors(errors)
	}

	if (isForgotPasswordView)
		return <ForgotPasswordForm toggleIsForgotPasswordView={toggleIsForgotPasswordView} />

	return (
		<form onSubmit={onSubmit}>
			<div style={{ color: "#274041" }}>
				<h2>{t("login")}</h2>
				<p>{t("welcomeBackToYourAccount")}</p>
			</div>
			<TextInput
				type="email"
				name="email"
				label={t("email")}
				error={translatedErrors.email}
				required
				{...email}
				fullWidth
			/>
			<PasswordInput
				label={t("password")}
				error={translatedErrors.password}
				{...password}
				required
			/>

			<Box my={2}>
				<Button color="primary" type="submit">
					{t("login")}
				</Button>
				<Button
					className="link mt-2"
					style={{ marginTop: "1rem" }}
					onClick={toggleIsForgotPasswordView}
				>
					{t("forgotPassword")}
				</Button>
				{errors.general && (
					<Box my={2}>
						<Feedback type="error">{translatedErrors.general}</Feedback>
					</Box>
				)}
			</Box>
		</form>
	)
}

export default LoginForm
