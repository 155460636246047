import { ToggleButton, ToggleButtonGroup } from "@mui/material"

import useTranslation from "../../hooks/useTranslation"

import { Salutation } from "../../typings/FormAddress"

const salutations: Salutation[] = ["Mr", "Mrs"]

const SalutationInput = ({ value, setSalutation, disabled = false, ...props }: Props) => {
	const { t } = useTranslation("address")

	const toggleSalutation = () => {
		setSalutation(value === "Mr" ? "Mrs" : "Mr")
	}

	return (
		<ToggleButtonGroup
			value={value}
			onChange={toggleSalutation}
			exclusive
			size="small"
			{...props}
		>
			{salutations.map((salutation) => {
				return (
					<ToggleButton
						key={salutation}
						value={salutation}
						aria-label={salutation}
						disabled={disabled}
					>
						{t(salutation)}
					</ToggleButton>
				)
			})}
		</ToggleButtonGroup>
	)
}

interface Props {
	value: string
	setSalutation: (salutation: Salutation) => void
	name?: string
	id?: string
	required?: boolean
	disabled?: boolean
	style?: React.CSSProperties
}

export default SalutationInput
