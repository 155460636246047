import { useContext } from "react"
import { IconButton, Box } from "@mui/material"
import { mdiHelpCircleOutline } from "@mdi/js"
import Icon from "@mdi/react"
import { Theme } from "@mui/material/styles"
import Link from "../i18n/Link"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import { makeStyles } from "makeStyles"
import useTranslation from "hooks/useTranslation"

const useStyles = makeStyles()((theme: Theme) => ({
	sectionMobile: {
		display: "block",
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
}))

const Help = () => {
	const { classes } = useStyles()
	const { isBusinessVersion } = useContext(AppContext)
	const { t } = useTranslation("common")

	return (
		<>
			<Link href="/help">
				<IconButton
					aria-label="Help"
					aria-haspopup="false"
					color="inherit"
					style={{ padding: "2px" }}
					size="large"
				>
					<Icon
						path={mdiHelpCircleOutline}
						title="Help"
						size={1.3}
						color={!isBusinessVersion ? "#ffffff" : ""}
					/>
				</IconButton>
				<Box className={classes.sectionMobile} style={{ fontSize: "14px" }}>
					{t("help")}
				</Box>
			</Link>
		</>
	)
}

export default Help
