import { DispatchAppContext } from "../AppContext"

// Token
import createAnonymousToken from "@secureo/common/utils/commerceTools/token/createAnonymousToken"
import createCustomerToken from "@secureo/common/utils/commerceTools/token/createCustomerToken"
import refreshAuthToken from "@secureo/common/utils/commerceTools/token/refreshAuthToken"
import isAuthTokenExpired from "@secureo/common/utils/commerceTools/token/isAuthTokenExpired"
import {
	saveAuthTokenToLocalStorage,
	restoreAuthTokenFromLocalStorage,
	removeAuthTokenFromLocalStorage,
} from "@secureo/common/utils/auth/authTokenPersistence"

// Queries
import fetchCustomer from "@secureo/common/utils/commerceTools/graphql/queries/fetchCustomer"

// Mutations
import signUpCustomer, {
	SignUpDraft,
} from "@secureo/common/utils/commerceTools/graphql/mutations/signUpCustomer"
import customerSignMeIn from "@secureo/common/utils/commerceTools/graphql/mutations/customerSignMeIn"

export const login = async (
	email: string,
	password: string,
	dispatch: DispatchAppContext,
	anonymousAccessToken?: string,
) => {
	if (anonymousAccessToken) {
		await customerSignMeIn(anonymousAccessToken, {
			email,
			password,
		})
	}

	const { accessToken, refreshToken, expiryDateMs } = await createCustomerToken(email, password)
	saveAuthTokenToLocalStorage({ accessToken, refreshToken, expiryDateMs }, true)
	dispatch({
		type: "SET_ACCESS_TOKEN",
		payload: { accessToken, expiryDateMs },
	})

	const customer = await fetchCustomer(accessToken)

	dispatch({
		type: "SET_CUSTOMER",
		payload: customer,
	})
}

export const signUp = async (
	customerSignUpDraft: SignUpDraft,
	dispatch: DispatchAppContext,
	anonymousAccessToken: string,
) => {
	const { email, password } = customerSignUpDraft

	// Can throw exceptions, be sure to catch them higher in the call stack
	const customer = await signUpCustomer(anonymousAccessToken, customerSignUpDraft)

	// Login customer after signUp
	await login(email, password, dispatch)

	return customer
}

export const logout = (dispatch: DispatchAppContext) => {
	removeAuthTokenFromLocalStorage()

	dispatch({
		type: "LOGOUT",
	})
}

export const createAndDispatchAnonymousToken = async (dispatch: DispatchAppContext) => {
	try {
		const { accessToken, refreshToken, expiryDateMs } = await createAnonymousToken()

		saveAuthTokenToLocalStorage({ accessToken, refreshToken, expiryDateMs })
		dispatch({
			type: "SET_ACCESS_TOKEN",
			payload: { accessToken, expiryDateMs },
		})
		return { accessToken, isCustomerToken: false }
	} catch (error) {
		console.error("Failed to createAndDispatchAnonymousToken")
		console.error(error)
	}
}

export const refreshAndDispatchAuthToken = async (dispatch: DispatchAppContext) => {
	try {
		const localStorageToken = restoreAuthTokenFromLocalStorage()

		const { isCustomerToken } = localStorageToken
		const { accessToken, refreshToken, expiryDateMs } = await refreshAuthToken(
			localStorageToken.refreshToken,
		)

		saveAuthTokenToLocalStorage(
			{
				accessToken,
				refreshToken,
				expiryDateMs,
			},
			isCustomerToken,
		)
		dispatch({
			type: "SET_ACCESS_TOKEN",
			payload: { accessToken, expiryDateMs },
		})
		return { accessToken, isCustomerToken }
	} catch (error) {
		console.error(error)
		return await createAndDispatchAnonymousToken(dispatch)
	}
}

export const restoreOrCreateAuthToken = async (dispatch: DispatchAppContext) => {
	const localStorageToken = restoreAuthTokenFromLocalStorage()

	const isExpired = localStorageToken ? isAuthTokenExpired(localStorageToken.expiryDateMs) : true

	try {
		return !isExpired
			? await refreshAndDispatchAuthToken(dispatch)
			: await createAndDispatchAnonymousToken(dispatch) // If token is expired, create a new anonymous token
	} catch (err) {
		console.error("Failed to restore authToken", err)
		return await createAndDispatchAnonymousToken(dispatch)
	}
}
