import { useState, useContext } from "react"
import Router from "next/router"
import { makeStyles } from "makeStyles"
import {
	Box,
	Dialog,
	DialogContent,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	AppBar,
	Toolbar,
	IconButton,
	Grid,
} from "@mui/material"

import Icon from "@mdi/react"
import { mdiCircleMedium, mdiClose } from "@mdi/js"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import {
	changeCurrency,
	changeLocale as changeAppContextLocale,
} from "@secureo/common/context/AppContext/actions"

import Button from "../Buttons/Button"
import CountryFlag from "../i18n/CountryFlag"

import useTranslation from "hooks/useTranslation"
import { useTranslatedShopCountries } from "hooks/useTranslatedCountries"
import useInputValue from "hooks/useInputValue"
import { CountryCode } from "@secureo/common/typings/CountryCode"

import getCurrencySymbol from "@secureo/common/utils/forex/getCurrencySymbol"

// import { CurrencyCode } from "typings/Forex"

// import {
// 	supportedLanguages,
// 	supportedCurrencyCodes,
// } from "utils/i18n/supportedLocalesAndCurrencyCodes"
import { saveGeoCountryCodeToLocalStorage } from "components/SwitchCountryDialog"
import { configByCountryCode } from "components/i18n/ChangeLocalAndCurrencyPopover"

const i18nNameSpaces = ["common", "languages", "countries"]

const useStyles = makeStyles()((theme) => ({
	appBar: {
		position: "relative",
		background: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	headline: {
		fontWeight: "bold",
		textAlign: "center",
		color: "#274041",
		borderBottom: "1px solid lightgrey",
	},
	section: {
		padding: theme.spacing(1),
		background: "whitesmoke",
		borderRadius: "5px",
		boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.20)",
	},
	root: {
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	icon: {
		borderRadius: "50%",
		width: 16,
		height: 16,
		boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
		backgroundColor: "#f5f8fa",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		"$root.Mui-focusVisible &": {
			outline: "2px auto rgba(19,124,189,.6)",
			outlineOffset: 2,
		},
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5",
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)",
		},
	},
	checkedIcon: {
		backgroundColor: "#137cbd",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			width: 16,
			height: 16,
			backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
			content: '""',
		},
		"input:hover ~ &": {
			backgroundColor: "#106ba3",
		},
	},
}))

const MobileChangeLocaleAndCurrency = () => {
	const { classes } = useStyles()
	const { dispatch, currencyCode, language, countryCode } = useContext(AppContext)
	const [open, setOpen] = useState(false)
	const { t, i18n } = useTranslation(i18nNameSpaces)
	const shopCountries = useTranslatedShopCountries()
	// const selectedLanguage = useInputValue(language)
	const selectedCountryCode = useInputValue(countryCode)
	// const selectedCurrencyCode = useInputValue(currencyCode)

	const navigateToLocalizedUrl = async (locale: string) => {
		const { route, asPath } = Router.router

		await Router.push(route, asPath, { locale })
		window.location.reload()
	}

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		const countryCode = selectedCountryCode.value as CountryCode
		const { currencyCode, language } =
			configByCountryCode[selectedCountryCode.value as CountryCode]

		// const currencyCode = selectedCurrencyCode.value as CurrencyCode
		// const language = selectedLanguage.value
		// const countryCode = selectedCountryCode.value as CountryCode
		const locale = `${language}-${countryCode}`

		changeCurrency(currencyCode, dispatch)
		i18n.changeLanguage(locale)
		changeAppContextLocale(language, countryCode, dispatch)
		saveGeoCountryCodeToLocalStorage(countryCode)
		await navigateToLocalizedUrl(locale)

		handleClose()
	}

	return (
		<div>
			<Box style={{ width: "120px" }} onClick={handleClickOpen}>
				<Box
					py={2}
					style={{
						justifyContent: "center",
						display: "flex",
						alignItems: "center",
					}}
				>
					<div
						style={{
							marginTop: "4px",
							width: "25px",
							height: "100%",
							marginLeft: "30px",
						}}
					>
						<CountryFlag countryCode={countryCode as CountryCode} />
					</div>
					<Box ml={1}>
						{language.toUpperCase()}
						<Icon path={mdiCircleMedium} size={0.6} color="black" />
						{getCurrencySymbol(currencyCode)}
					</Box>
				</Box>
			</Box>
			<Dialog fullScreen open={open} onClose={handleClose}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							onClick={handleClose}
							aria-label="close"
							size="large"
						>
							<Icon path={mdiClose} size={1} color="white" />
						</IconButton>
					</Toolbar>
				</AppBar>

				<form onSubmit={onSubmit}>
					<DialogContent style={{ paddingBottom: "100px" }}>
						{/* <Box mt={2} className={classes.section}>
							<div className={classes.headline}>Sprache</div>
							<Box>
								<FormControl component="fieldset">
									<RadioGroup
										aria-label="language"
										name="language"
										{...selectedLanguage}
									>
										<Box mt={2}>
											<Grid container spacing={1}>
												{supportedLanguages.map(({ language, i18nKey }) => (
													<Grid item xs={6} key={language}>
														<FormControlLabel
															value={language}
															control={<Radio color="primary" />}
															label={
																<div>
																	{t("languages:" + i18nKey)}
																</div>
															}
														/>
													</Grid>
												))}
											</Grid>
										</Box>
									</RadioGroup>
								</FormControl>
							</Box>
						</Box> */}
						<Box mt={2} className={classes.section}>
							<div className={classes.headline}>Land</div>
							<FormControl component="fieldset">
								<RadioGroup
									aria-label="country"
									name="country"
									{...selectedCountryCode}
								>
									<Box mt={2}>
										<Grid container spacing={1}>
											{shopCountries.map(({ countryCode, name }) => (
												<Grid item xs={6} key={countryCode}>
													<FormControlLabel
														value={countryCode}
														control={<Radio color="primary" />}
														label={name}
													/>
												</Grid>
											))}
										</Grid>
									</Box>
								</RadioGroup>
							</FormControl>
						</Box>
						{/* <Box mt={2} className={classes.section}>
							<div className={classes.headline}>Währung</div>
							<FormControl component="fieldset">
								<RadioGroup
									aria-label="currency"
									name="currency"
									{...selectedCurrencyCode}
								>
									<Box mt={2}>
										<Grid container spacing={1}>
											{supportedCurrencyCodes.map((currencyCode) => (
												<Grid item xs={6} key={currencyCode}>
													<FormControlLabel
														value={currencyCode}
														control={<Radio color="primary" />}
														label={`${currencyCode} (${getCurrencySymbol(
															currencyCode,
														)})`}
													/>
												</Grid>
											))}
										</Grid>
									</Box>
								</RadioGroup>
							</FormControl>
						</Box> */}
					</DialogContent>
					<Box
						style={{
							width: "100%",
							justifyContent: "center",
							position: "fixed",
							bottom: "20px",
							zIndex: 1,
							margin: "auto",
						}}
					>
						<Button
							type="submit"
							color="cart"
							style={{
								left: "50%",
								transform: "translate(-50%, -50%)",
								width: "50%",
								padding: "0",
								height: "42px",
								position: "relative",
								borderRadius: "25px",
							}}
						>
							{t("common:save")}
						</Button>
					</Box>
				</form>
			</Dialog>
		</div>
	)
}

export default MobileChangeLocaleAndCurrency
