import { CurrencyCode } from "@secureo/common/typings/Forex"

const exchangeRateValidityMs = 1000 * 60 ** 2 * 24 // 24h

// TODO: Fetch actual exchangeRate
const fetchExchangeRateBasedOnEuro = async (currencyCode: CurrencyCode) => {
	let rate = 1
	let validUntilTimestamp = Infinity

	if (currencyCode !== "EUR") {
		rate = 1.2
		validUntilTimestamp = Date.now() + exchangeRateValidityMs
	}

	return {
		rate,
		validUntilTimestamp,
	}
}

export default fetchExchangeRateBasedOnEuro
