// This config is used as a default fallback, in case there is no config specified on the page

import { getCountryNameFromLocale } from "../../../components/BreadCrumbs"
import { flatFeesByCurrencyCode } from "@secureo/common/config/shippingZonesAndCosts"
import getCurrencySymbol from "@secureo/common/utils/forex/getCurrencySymbol"
import { NEXT_PUBLIC_HOST } from "utils/envVariables"

import { CurrencyCode } from "@secureo/common/typings/Forex"

export const isSwitzerlandLocale = (locale: string) => locale?.toLowerCase() === "de-ch"

const getTitle = (locale: string) => {
	return {
		de: `Sicherheitsprodukte günstig kaufen | SafeHero ${getCountryNameFromLocale(locale)} 🛡️`,
		pl: `Kupuj tanio produkty bezpieczeństwa | SafeHero ${getCountryNameFromLocale(locale)} 🛡️`,
	}
}

const getDescription = (locale: string, currencyCode: CurrencyCode) => {
	const flatFeeForSmallAmountThresholdGrossCents =
		flatFeesByCurrencyCode[currencyCode].flatFeeForSmallAmountThresholdGrossCents

	return {
		de: `Hochwertige & geprüfte Sicherheitsprodukte führender Markenhersteller bei SafeHero ${getCountryNameFromLocale(
			locale,
		)} günstig kaufen ✓ Portofrei ab ${
			flatFeeForSmallAmountThresholdGrossCents / 100
		}${getCurrencySymbol(currencyCode)} ✓ Trusted Shop®${
			isSwitzerlandLocale(locale) ? "" : " ✓ Kauf auf Rechnung"
		}`,
		pl: `Wysokiej jakości i sprawdzone produkty bezpieczeństwa od wiodących producentów w SafeHero ${getCountryNameFromLocale(
			locale,
		)} günstig kaufen ✓ Wysyłka gratis od ${
			flatFeeForSmallAmountThresholdGrossCents / 100
		}${getCurrencySymbol(currencyCode)} ✓ Trusted Shop®`,
	}
}

const getOpenGraph = (locale: string, currencyCode: CurrencyCode) => ({
	appId: "2448075292178780",
	title: getTitle(locale),
	description: getDescription(locale, currencyCode),
	images: [
		{
			url: `${NEXT_PUBLIC_HOST}/assets/twitter-facebook-card.png`,
			width: 1200,
			height: 630,
			alt: "SafeHero Mobile and Features",
		},
		{
			url: `${NEXT_PUBLIC_HOST}/assets/twitter-summary-card-small.png`,
			width: 601,
			height: 601,
			alt: "SafeHero Mobile",
		},
	],
	site_name: "Safehero",
})

const twitter = {
	site: "", // TODO: Add as soon as we have a twitter account
	cardType: "summary_large_image",
}

const getFallback = (locale: string, currencyCode: CurrencyCode) => ({
	title: getTitle(locale),
	description: getDescription(locale, currencyCode),
	openGraph: getOpenGraph(locale, currencyCode),
	twitter,
})

export default getFallback
