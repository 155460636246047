import getPricePortions from "../getPricePortions"
import getMsrpPricePortions from "../getMsrpPricePortions"

import { ProductDetailPageReducerState, ProductConfigurationsByConfigurationGroup } from "../.."
import getAdditionalDeliveryTimeWorkDays from "../getTotalDeliveryTimeWorkDays"

type SetConfigurationsPayload = Pick<
	ProductDetailPageReducerState,
	| "configurationsByConfigurationGroup"
	| "singleNetPriceCents"
	| "singleGrossPriceCents"
	| "totalNetPriceCents"
	| "totalGrossPriceCents"
	| "pricePortions"
	| "singleNetMsrpPriceCents"
	| "singleGrossMsrpPriceCents"
	| "totalNetMsrpPriceCents"
	| "totalGrossMsrpPriceCents"
	| "msrpPricePortions"
	| "additionalDeliveryTimeWorkDays"
>

const getSetConfigurationsPayload = (
	reducerState: ProductDetailPageReducerState,
	configurationsByConfigurationGroup: ProductConfigurationsByConfigurationGroup,
) => {
	const {
		variantsByVariantId,
		variantId,
		attributes,
		taxRate,
		quantity,
		countryCode,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	} = reducerState

	const {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
	} = getPricePortions(
		variantsByVariantId,
		variantId,
		taxRate,
		quantity,
		countryCode,
		configurationsByConfigurationGroup,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	)

	const {
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	} = getMsrpPricePortions(
		quantity,
		taxRate,
		countryCode,
		attributes,
		configurationsByConfigurationGroup,
		pricePortions.cylinderLockingMechanismProductSingleNetPriceCents,
		pricePortions.cylinderLockingMechanismProductSingleGrossPriceCents,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	)

	const additionalDeliveryTimeWorkDays = getAdditionalDeliveryTimeWorkDays({
		configurationsByConfigurationGroup,
		selectedDeliveryOption,
	})

	const payload: SetConfigurationsPayload = {
		configurationsByConfigurationGroup,
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
		additionalDeliveryTimeWorkDays,
	}

	return payload
}

export default getSetConfigurationsPayload
