import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"

import {
	Customer,
	CustomerSignMeUpDraft,
} from "@secureo/common/typings/generated/commercetools-graphql"

export interface SignUpDraft extends CustomerSignMeUpDraft {
	firstName: string
	lastName: string
	salutation: string
}

const customerSignMeUp = async (accessToken: string, customerSignUpDraft: SignUpDraft) => {
	const query = /* GraphQL */ `
		mutation customerSignMeUp($draft: CustomerSignMeUpDraft!) {
			customerSignMeUp(draft: $draft, storeKey: "${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}") {
				customer {
					email
					id
				}
			}
		}
	`

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query, {
		draft: customerSignUpDraft,
	})) as {
		customerSignMeUp: {
			customer: Pick<Customer, "email" | "id">
		}
	}

	return data?.customerSignMeUp?.customer ?? null
}

export default customerSignMeUp
