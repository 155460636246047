import { SEOPageAttributes } from "../../../typings/SEO"

const title = {
	de: "Suche | SafeHero.com 🛡️",
	en: "Search | SafeHero.com 🛡️",
	pl: "Szukaj | SafeHero.com 🛡️",
}

const description = {}

const openGraph = {}

const twitter = {}

const search: SEOPageAttributes = {
	title,
	description,
	openGraph,
	twitter,
}

export default search
