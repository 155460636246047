import {
	RawDeliveryTimeWorkdaysAttribute,
	DeliveryTimeWorkDaysByShopCountryCode,
} from "@secureo/common/typings/Product"

const getDeliveryTimeWorkDaysByShopCountryCode = (
	deliveryTimeWorkDaysAttribute: RawDeliveryTimeWorkdaysAttribute,
) => {
	if (!deliveryTimeWorkDaysAttribute) return {}

	const deliveryTimeWorkDaysByShopCountryCode: DeliveryTimeWorkDaysByShopCountryCode =
		deliveryTimeWorkDaysAttribute.value?.reduce(
			(
				deliveryTimeWorkDaysByShopCountryCode,
				[{ value: shopCountryCode }, { value: deliveryTimeWorkDays }],
			) => {
				return {
					...deliveryTimeWorkDaysByShopCountryCode,
					[shopCountryCode]: Number(deliveryTimeWorkDays?.replace(",", ".")),
				}
			},
			{},
		) ?? {}

	return deliveryTimeWorkDaysByShopCountryCode
}

export default getDeliveryTimeWorkDaysByShopCountryCode
