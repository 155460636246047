const oneDayInMs = 24 * 60 * 60 * 1000
const refreshTokenValidityMs = oneDayInMs * 180
const accessTokenValidityMs = oneDayInMs * 2

const bufferMs = oneDayInMs * 10 // Just to be sure
const maxAllowedAccessTokenAgeMs = refreshTokenValidityMs - bufferMs

const isAuthTokenExpired = (accessTokenExpiryDateMs: number) => {
	const currentDateMs = Date.now()
	const issueDateMs = accessTokenExpiryDateMs - accessTokenValidityMs

	const accessTokenAgeMs = currentDateMs - issueDateMs

	return accessTokenAgeMs > maxAllowedAccessTokenAgeMs
}

export const getRemainingAccessTokenLifeTimeMs = (accessTokenExpiryDateMs: number) => {
	const currentDateMs = Date.now()

	const remainingAccessTokenLifeTimeMs = accessTokenExpiryDateMs - currentDateMs

	return remainingAccessTokenLifeTimeMs
}

export default isAuthTokenExpired
