import { useContext } from "react"
import { Container, List, ListItem, ListItemText, Hidden } from "@mui/material"
import { createCloudinaryLegacyURL } from "@cloudinary/url-gen"

import Link from "../i18n/Link"

import AppContext from "@secureo/common/context/AppContext/AppContext"

import getValueFromLocalizedAttribute from "utils/i18n/getValueFromLocalizedAttribute"
import navCategories from "config/navCategories.json"
import { makeStyles } from "makeStyles"

import { NavCategory } from "typings/Category"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const useStyles = makeStyles()((theme) => ({
	themeColor: {
		color: theme.palette.secondary.contrastText,
	},
}))

interface NavChildProps {
	language: string
	navCategory: NavCategory
	filterCategorySlug?: string
	mostPopularCategories?: NavCategory[]
	countryCode: CountryCode
}

const NavLink = ({
	navCategory,
	children,
	language,
}: NavChildProps & {
	children: React.ReactNode
}) => {
	const isParentCategory = !navCategory.parentId

	const slug = getValueFromLocalizedAttribute(navCategory.slug, language)
	const href = `/${isParentCategory ? "pc" : "c"}/${slug}`

	return <Link href={href}>{children}</Link>
}

const NavListItem = ({ navCategory, language, countryCode }: NavChildProps) => {
	const name = getValueFromLocalizedAttribute(navCategory.name, language)

	return (
		<NavLink navCategory={navCategory} language={language} countryCode={countryCode}>
			<ListItem style={{ paddingTop: "0px", paddingBottom: "0px", fontSize: "14px" }}>
				<ListItemText primary={name} disableTypography />
			</ListItem>
		</NavLink>
	)
}

const Filter = ({ filter, filterCategorySlug }: FilterProps) => {
	const { name, url } = filter
	const slug = `${filterCategorySlug}${url}`

	return (
		<Link href={`/c/${slug}`}>
			<ListItem
				component="span"
				style={{ paddingTop: "0px", paddingBottom: "0px", fontSize: "14px" }}
			>
				<ListItemText primary={name} disableTypography />
			</ListItem>
		</Link>
	)
}

interface FilterProps {
	filter: { name: string; url: string }
	filterCategorySlug: string
}

const NavList = ({
	navCategory,
	filterCategorySlug,
	mostPopularCategories = [],
	language,
	countryCode,
}: NavChildProps) => {
	const name = getValueFromLocalizedAttribute(navCategory.name, language)

	const { children, attributes } = navCategory
	const { isMostPopularCategory, isFilterCategory, filters } = attributes

	const childCategories = isMostPopularCategory
		? mostPopularCategories.filter(({ id }) => id !== navCategory.id)
		: children

	return (
		<div className="column">
			<ListItem>
				<h4>{name}</h4>
			</ListItem>
			{isFilterCategory ? (
				<List component="nav" aria-label="category-list">
					{filters.map((filter) => {
						return (
							<Filter
								key={`${navCategory.id}-${filter.name}`}
								filter={filter}
								filterCategorySlug={filterCategorySlug}
							/>
						)
					})}
				</List>
			) : (
				<List component="nav" aria-label="category-list">
					{childCategories
						.filter(
							(childCategories) =>
								childCategories.attributes.showInNavbar &&
								!childCategories.attributes.excludedCountryCodes?.includes(
									countryCode.toLowerCase(),
								),
						)
						.map((navCategory) => {
							return (
								<NavListItem
									key={navCategory.id}
									navCategory={navCategory}
									language={language}
									countryCode={countryCode}
								/>
							)
						})}
				</List>
			)}
		</div>
	)
}

const NavTab = ({ navCategory, language, countryCode }: NavChildProps) => {
	const { classes } = useStyles()

	const name = getValueFromLocalizedAttribute(navCategory.name, language)

	const { children, mostPopularCategories } = navCategory
	const filteredChildren = children.filter((childCategory) => {
		return !childCategory.attributes.excludedCountryCodes?.includes(countryCode.toLowerCase())
	})

	const filterCategorySlug = getValueFromLocalizedAttribute(children[0].slug, language)

	const { key } = navCategory
	const imageUrl = createCloudinaryLegacyURL(`Navigation/${key}`, {
		cloud_name: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
		secure: true,
		transformation: [
			{ height: 300 },
			{ crop: "fill" },
			{ dpr: "auto" },
			{ fetchFormat: "auto" },
			{ quality: "auto" },
		],
	})

	return (
		<div className="dropdown">
			<button className="dropbtn">
				<NavLink navCategory={navCategory} language={language} countryCode={countryCode}>
					<div className={classes.themeColor}>{name}</div>
				</NavLink>
			</button>
			<div className="dropdown-content">
				<Container>
					<div className="row">
						{filteredChildren.map((navCategory) => {
							return (
								<NavList
									key={navCategory.id}
									navCategory={navCategory}
									filterCategorySlug={filterCategorySlug}
									mostPopularCategories={mostPopularCategories}
									language={language}
									countryCode={countryCode}
								/>
							)
						})}
					</div>
				</Container>
				<Hidden lgDown>
					<Container style={{ position: "relative" }}>
						<img
							src={imageUrl}
							alt=""
							style={{
								position: "absolute",
								maxHeight: "300px",
								objectFit: "cover",
								right: 0,
								zIndex: -1,
							}}
						/>
					</Container>
				</Hidden>
			</div>
		</div>
	)
}

const Navigation = () => {
	const { language, countryCode } = useContext(AppContext)

	const filteredNavCategories = navCategories.filter((navCategory) => {
		return !navCategory.attributes.excludedCountryCodes?.includes(countryCode.toLowerCase())
	})

	return (
		<div className="desktop-menu">
			<Container style={{ position: "relative" }}>
				<div className="navbar">
					{filteredNavCategories.map((navCategory) => {
						return (
							<NavTab
								key={navCategory.id}
								navCategory={navCategory as any as NavCategory}
								language={language}
								countryCode={countryCode}
							/>
						)
					})}
				</div>
			</Container>
		</div>
	)
}

export default Navigation
