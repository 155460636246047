const orderFragment = {
	fragment: /* GraphQL */ `
		fragment order on Order {
			id
			orderNumber
			customerId
			customerEmail
			createdAt
			country
			billingAddress {
				...address
			}
			shippingAddress {
				...address
			}
			lineItems {
				...lineItem
			}
			customLineItems {
				...customLineItem
			}
			taxMode
			taxedPrice {
				...taxedPrice
			}
			paymentInfo {
				payments {
					...payment
				}
			}
		}
	`,
	dependencies: ["lineItem", "customLineItem", "taxedPrice", "address", "payment"],
}

export default orderFragment
