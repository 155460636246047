import formatLineItem from "./formatLineItem"
import formatCustomLineItem from "./formatCustomLineItem"
import formatLockingSystems from "./formatLockingSystems"

import { Cart as CommerceToolsCart } from "@secureo/common/typings/generated/commercetools-graphql"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import { Cart } from "@secureo/common/typings/Cart"
import { CurrencyCode } from "@secureo/common/typings/Forex"

const formatCart = (cart: CommerceToolsCart) => {
	const {
		id,
		version,
		anonymousId,
		customerId,
		cartState,
		lastModifiedAt,

		// Tax
		country,
		taxMode,
		taxRoundingMode,
		taxCalculationMode,
		totalPrice: { currencyCode },
		taxedPrice,

		// LineItems
		lineItems,
		customLineItems,

		// Addresses & CustomerInformation
		customerEmail,
		locale,
		shippingAddress,
		billingAddress,
		discountCodes,
	} = cart

	const formattedLineItems = lineItems.map((lineItem) => formatLineItem(lineItem, locale))
	const formattedCustomLineItems = customLineItems.map(formatCustomLineItem)
	const lockingSystemIds = formattedCustomLineItems
		.map(({ lockingSystemId }) => lockingSystemId)
		.filter((id) => !!id)

	const nonLockingSystemLineItems = formattedLineItems.filter(
		({ lockingSystemId }) => !lockingSystemId,
	)
	const nonLockingSystemCustomLineItems = formattedCustomLineItems.filter(
		({ lockingSystemId }) => !lockingSystemId,
	)

	const lockingSystems = formatLockingSystems(
		lockingSystemIds,
		formattedLineItems,
		formattedCustomLineItems,
	)

	const formattedCart: Cart = {
		id,
		version,
		anonymousId,
		customerId,
		cartState,
		lastModifiedAt,

		// Tax
		country: country as CountryCode,
		currencyCode: currencyCode as CurrencyCode,
		taxMode,
		taxRoundingMode,
		taxCalculationMode,
		taxedPrice,

		// LineItems
		lineItems: nonLockingSystemLineItems,
		customLineItems: nonLockingSystemCustomLineItems,

		// LockingSystems
		lockingSystems,

		// DiscountCodes
		discountCodes,

		// Addresses & CustomerInformation
		customerEmail,
		locale,
		shippingAddress,
		billingAddress,
	}

	return formattedCart
}

export default formatCart
