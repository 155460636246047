const customerFragment = {
	fragment: /* GraphQL */ `
		fragment customer on Customer {
			id
			version
			createdAt
			email
			isEmailVerified
			customerNumber
			firstName
			lastName
			title
			salutation
			dateOfBirth
			companyName
			vatId
			defaultBillingAddress {
				id
			}
			defaultShippingAddress {
				id
			}
			addresses {
				...address
			}
		}
	`,
	dependencies: ["address"],
}

export default customerFragment
