import { decodeMatrixCustomField } from "@secureo/common/utils/commerceTools/escapeMatrixCustomField"
import getMatrixType from "@secureo/common/utils/getMatrixType"
import { CartLockingSystem, CustomLineItem, LineItem } from "@secureo/common/typings/Cart"
import mergeConfiguredLineItems from "../mergeConfiguredLineItems"

const formatLockingSystems = (
	lockingSystemIds: string[],
	lineItems: LineItem[],
	customLineItems: CustomLineItem[],
) => {
	const cartLockingSystems = lockingSystemIds.map((lockingSystemId) => {
		const lockingSystemComponents = lineItems.filter(
			(lineItem) => lineItem.lockingSystemId === lockingSystemId,
		)
		const mergedConfiguredComponents = mergeConfiguredLineItems(lockingSystemComponents)
		const lockingSystemCustomLineItem = customLineItems.find(
			(customLineItem) => customLineItem.lockingSystemId === lockingSystemId,
		)

		const {
			quantity,
			name,
			matrix: encodedMatrix,
			numberOfFreeIncludedKeys,
			lockingComponentsUuids,
			keyComponentsUuids,
			customName = "",
			lockingSystemCategoryName,
			lockingSystemCategorySlug,
		} = lockingSystemCustomLineItem

		const taxRate = lockingSystemCustomLineItem?.taxRate?.rate ?? 0
		const totalNetChargeCents =
			lockingSystemCustomLineItem.taxedPrice?.totalNet?.centAmount ?? 0
		const totalGrossChargeCents =
			lockingSystemCustomLineItem.taxedPrice?.totalGross?.centAmount ?? 0

		const matrix = encodedMatrix ? decodeMatrixCustomField(encodedMatrix) : null

		const charges: CartLockingSystem["charges"] = {
			numberOfFreeIncludedKeys,
			totalNetChargeCents,
			totalGrossChargeCents,
		}

		const lockingComponentLineItems = lockingComponentsUuids.map((uuid) =>
			mergedConfiguredComponents.find(
				(component) => component.uuid === uuid && !component.configurationForProductId,
			),
		)
		const keyComponentLineItems = keyComponentsUuids.map((uuid) =>
			mergedConfiguredComponents.find(
				(component) => component.uuid === uuid && !component.configurationForProductId,
			),
		)

		const matrixType = getMatrixType(matrix)

		const totalNetCents = mergedConfiguredComponents.reduce((totalNetCents, component) => {
			return totalNetCents + (component?.taxedPrice?.totalNet?.centAmount ?? 0)
		}, totalNetChargeCents)
		const totalGrossCents = mergedConfiguredComponents.reduce((totalGrossCents, component) => {
			return totalGrossCents + (component?.taxedPrice?.totalGross?.centAmount ?? 0)
		}, totalGrossChargeCents)

		const totalUnDiscountedPriceGrossCents = [
			...lockingComponentLineItems,
			...keyComponentLineItems,
		].reduce((totalUnDiscountedPriceGrossCents, lineItem) => {
			return (
				totalUnDiscountedPriceGrossCents + (lineItem?.totalUnDiscountedPriceGrossCents ?? 0)
			)
		}, totalGrossChargeCents)

		const totalDiscountGrossCents = totalUnDiscountedPriceGrossCents - totalGrossCents

		const taxedPrice: CartLockingSystem["taxedPrice"] = {
			totalNet: {
				centAmount: totalNetCents,
			},
			totalGross: {
				centAmount: totalGrossCents,
			},
		}

		const cartLockingSystem: CartLockingSystem = {
			id: lockingSystemId,
			quantity,
			name,
			lockingSystemCategorySlug,
			type: "mechanic",
			matrix,
			matrixType,
			lockingComponentLineItems,
			keyComponentLineItems,
			customLineItems: customLineItems.filter(
				(customLineItem) => customLineItem.lockingSystemId === lockingSystemId,
			),
			charges,
			taxRate,
			taxedPrice,
			totalDiscountGrossCents,
			totalUnDiscountedPriceGrossCents,
			shippingOriginationCountryCode: null, // TODO
			customName,
			lockingSystemCategoryName,
		}

		return cartLockingSystem
	})

	return cartLockingSystems
}

export default formatLockingSystems
