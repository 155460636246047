import {
	RawConfigurationGroupsAttribute,
	ConfigurationGroup,
} from "@secureo/common/typings/Product"

const getConfigurationGroupAttributeValue = (
	rawAttribute: { name: string; value: any },
	language: string,
) => {
	const { name, value } = rawAttribute

	switch (name) {
		case "name":
		case "description":
			return { name, value: value?.[language] ?? value?.de ?? null }

		case "skus":
			return {
				name: "rawSkus",
				value,
			}

		case "maxQuantities":
			return {
				name: "rawMaxQuantities",
				value,
			}

		case "configSelectionType":
			return {
				name: "type",
				value,
			}

		default:
			return { name, value }
	}
}

const getConfigurationGroups = (
	configurationGroupsAttribute: RawConfigurationGroupsAttribute,
	language: string,
) => {
	const configurationGroups = configurationGroupsAttribute.value.map((rawConfigurationGroup) => {
		const configurationGroupAttributesByName = rawConfigurationGroup.reduce(
			(configurationGroupAttributesByName, rawAttribute) => {
				const { name, value } = getConfigurationGroupAttributeValue(rawAttribute, language)

				return {
					...configurationGroupAttributesByName,
					[name]: value,
				}
			},
			{} as { [name: string]: any },
		)

		const {
			name,
			description,
			rawSkus,
			rawMaxQuantities,
			type,
			// configExcludedCountryCodes,
			excludeFromLockingSystemComponent,
		} = configurationGroupAttributesByName

		const skus = rawSkus.split(";")
		const maxQuantities = rawMaxQuantities.split(";")

		const items = skus.map((sku, i) => {
			const maxQuantity = Number(maxQuantities[i])

			return {
				sku,
				maxQuantity,
			}
		})

		const configurationGroup: ConfigurationGroup = {
			name,
			description,
			type,
			items,
			excludeFromLockingSystemComponent: !!excludeFromLockingSystemComponent,
		}

		return configurationGroup
	})

	return configurationGroups
}

export default getConfigurationGroups
