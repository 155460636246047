import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"
import prependFragmentsToQuery from "../prependFragmentsToQuery"
import formatShoppingList from "@secureo/common/utils/commerceTools/formatters/formatShoppingList"

import {
	ShoppingList,
	MyShoppingListDraft,
} from "@secureo/common/typings/generated/commercetools-graphql"
import { CurrencyCode } from "@secureo/common/typings/Forex"

const createMyShoppingList = async (
	accessToken: string,
	draft: MyShoppingListDraft,
	currencyCode: CurrencyCode,
) => {
	const query = prependFragmentsToQuery(
		/* GraphQL */ `
			mutation createMyShoppingList($draft: MyShoppingListDraft!) {
				createMyShoppingList(draft: $draft, storeKey: "${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}") {
					...shoppingList
				}
			}
		`,
		["shoppingList"],
	)

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query, {
		draft,
	})) as {
		createMyShoppingList: ShoppingList
	}

	const shoppingList = data?.createMyShoppingList ?? null
	const formattedShoppingList = formatShoppingList(shoppingList, currencyCode)

	return formattedShoppingList
}

export default createMyShoppingList
