import {
	RawCrossSellProductSkus,
	ProductSkusByCountryCode,
	RawUpSellProductSkus,
} from "@secureo/common/typings/Product"

const getProductSkusByCountryCode = (
	productSkusAttribute: RawCrossSellProductSkus | RawUpSellProductSkus,
) => {
	const productSkusByCountryCode = productSkusAttribute?.value?.reduce(
		(productSkusByCountryCode, productSkus) => {
			const [{ value: countryCode }, { value: rawSkus }] = productSkus

			const skus = rawSkus.split("|")

			return {
				...productSkusByCountryCode,
				[countryCode]: skus,
			}
		},
		{} as ProductSkusByCountryCode,
	)

	return productSkusByCountryCode
}

export default getProductSkusByCountryCode
