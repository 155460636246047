import setIsLoading from "@secureo/common/reducers/productDetailPageReducer/utils/setIsLoading"
import { getAvailableLengthCombinationsForSystem } from "@secureo/common/hooks/cylinderConfigurations/useLengthCombinationsBySystemConfiguration"
import { getAvailableOuterLengthsForSystem } from "@secureo/common/hooks/cylinderConfigurations/useOuterLengthsBySystemConfiguration"
import getCylinderConfigurationVariantSku from "@secureo/common/reducers/productDetailPageReducer/utils/getCylinderConfigurationVariantSku"
import fetchCylinderConfigurationVariant from "@secureo/common/utils/commerceTools/graphql/queries/fetchCylinderConfigurationVariant"
import { setCylinderConfigurationVariant } from "./setCylinderConfigurationVariant"

import {
	DispatchProductDetailPageReducer,
	ProductDetailPageReducerState,
} from "@secureo/common/reducers/productDetailPageReducer"
import { LengthCombinationsBySystem, OuterLengthsBySystem } from "@secureo/common/typings/Product"
import { CylinderConfigurationType } from "@secureo/common/reducers/productDetailPageReducer/utils/getInitialCylinderConfigurations"

const getValidInnerAndOuterLengthForLengthCombinationsBySystem = (
	lengthCombinationsBySystem: LengthCombinationsBySystem,
	selectedSystem: string,
	selectedOuterLength: number,
	selectedInnerLength: number,
) => {
	const availableLengthCombinationsForSelectedSystem = getAvailableLengthCombinationsForSystem(
		lengthCombinationsBySystem,
		selectedSystem,
	)

	const isValidInnerAndOuterLengthCombination =
		!!availableLengthCombinationsForSelectedSystem.find((lengthCombination) => {
			return (
				lengthCombination.outerLength === selectedOuterLength &&
				lengthCombination.innerLength === selectedInnerLength
			)
		})

	if (!isValidInnerAndOuterLengthCombination) {
		return {
			selectedOuterLength:
				availableLengthCombinationsForSelectedSystem?.[0]?.outerLength ?? null,
			selectedInnerLength:
				availableLengthCombinationsForSelectedSystem?.[0]?.innerLength ?? null,
		}
	}

	return {
		selectedInnerLength,
		selectedOuterLength,
	}
}

const getValidOuterLengthForOuterLengthsBySystem = (
	outerLengthsBySystem: OuterLengthsBySystem,
	selectedSystem: string,
	selectedOuterLength: number,
) => {
	const availableOuterLengths = getAvailableOuterLengthsForSystem(
		outerLengthsBySystem,
		selectedSystem,
	)

	const isValidOuterLength = !!availableOuterLengths.includes(selectedOuterLength)

	if (!isValidOuterLength) {
		return availableOuterLengths?.[0] ?? null
	}

	return selectedOuterLength
}

export const getSelectedConfigurationsAfterSystemChange = (
	cylinderConfigurationType: CylinderConfigurationType,
	attributes: ProductDetailPageReducerState["attributes"],
	selectedSystem: string,
	selectedOuterLength: number,
	selectedInnerLength: number,
): {
	selectedSystem: string
	selectedOuterLength: number
	selectedInnerLength: number
} => {
	const { outerLengthsBySystem, lengthCombinationsBySystem } = attributes

	switch (cylinderConfigurationType) {
		case "outerLengthsBySystem": {
			const validSelectedOuterLength = getValidOuterLengthForOuterLengthsBySystem(
				outerLengthsBySystem,
				selectedSystem,
				selectedOuterLength,
			)

			return {
				selectedSystem,
				selectedOuterLength: validSelectedOuterLength,
				selectedInnerLength: null,
			}
		}

		case "lengthCombinationsBySystem": {
			const {
				selectedOuterLength: validSelectedOuterLength,
				selectedInnerLength: validSelectedInnerLength,
			} = getValidInnerAndOuterLengthForLengthCombinationsBySystem(
				lengthCombinationsBySystem,
				selectedSystem,
				selectedOuterLength,
				selectedInnerLength,
			)

			return {
				selectedSystem,
				selectedOuterLength: validSelectedOuterLength,
				selectedInnerLength: validSelectedInnerLength,
			}
		}

		case "systems": {
			return {
				selectedSystem,
				selectedOuterLength: null,
				selectedInnerLength: null,
			}
		}

		default:
			console.error(
				`No action defined for cylinderConfigurationType "${cylinderConfigurationType}"`,
			)
			return null
	}
}

export const setSelectedSystem = async (
	accessToken: string,
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	selectedSystem: string,
) => {
	const { cylinderConfigurationType, attributes, variantsByVariantId, locale } = reducerState

	setIsLoading(dispatch, true)

	const { selectedOuterLength, selectedInnerLength } = getSelectedConfigurationsAfterSystemChange(
		cylinderConfigurationType,
		attributes,
		selectedSystem,
		reducerState.selectedOuterLength,
		reducerState.selectedInnerLength,
	)

	dispatch({
		type: "SET_CYLINDER_CONFIGURATIONS",
		payload: {
			selectedSystem,
			selectedOuterLength,
			selectedInnerLength,
		},
	})

	const cylinderConfigurationVariantSku = getCylinderConfigurationVariantSku(
		cylinderConfigurationType,
		attributes,
		variantsByVariantId,
		selectedSystem,
		selectedOuterLength,
		selectedInnerLength,
	)

	const cylinderConfigurationVariant = await fetchCylinderConfigurationVariant(
		accessToken,
		cylinderConfigurationVariantSku,
		locale,
	)
	setCylinderConfigurationVariant(dispatch, reducerState, cylinderConfigurationVariant)

	setIsLoading(dispatch, false)
}
