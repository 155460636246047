// This function expects "UnitPriceLevel" TaxCalculationMode
// https://docs.commercetools.com/http-api-projects-carts#taxcalculationmode

import { LineItem } from "@secureo/common/typings/generated/commercetools-graphql"
import { netToGross } from "@secureo/common/utils/tax"

interface LineItemDiscount {
	name: string
	description?: string
	netDiscountCents: number
}

interface LineItemDiscounts {
	isDiscounted: boolean
	totalDiscountGrossCents: number
	unDiscountedPricePerQuantityNetCents: number
	unDiscountedPricePerQuantityGrossCents: number
	totalUnDiscountedPriceGrossCents: number
	includedDiscounts: LineItemDiscount[]
}

const formatLineItemDiscounts = (lineItem: LineItem): LineItemDiscounts => {
	if (!lineItem.taxedPrice || !lineItem.discountedPricePerQuantity)
		return {
			isDiscounted: false,
			totalDiscountGrossCents: 0,
			unDiscountedPricePerQuantityNetCents: 0,
			unDiscountedPricePerQuantityGrossCents: 0,
			totalUnDiscountedPriceGrossCents: 0,
			includedDiscounts: [],
		}
	const {
		quantity,
		taxedPrice: {
			totalGross: { centAmount: totalGrossCents },
			totalNet: { centAmount: totalNetCents },
		},
		// taxRate: { amount: taxRate },
		taxRate,
		discountedPricePerQuantity,
	} = lineItem

	const discountedPricePerQuantityNetCents = totalNetCents / quantity

	const lineItemDiscountPerQuantityNetCents = discountedPricePerQuantity.reduce(
		(lineItemDiscountPerQuantityNetCents, { discountedPrice: { includedDiscounts } }) => {
			const discountPerQuantityNetCents = includedDiscounts.reduce(
				(discountPerQuantityNetCents, { discountedAmount }) => {
					return discountPerQuantityNetCents + discountedAmount.centAmount
				},
				0,
			)

			return lineItemDiscountPerQuantityNetCents + discountPerQuantityNetCents
		},
		0,
	)

	const unDiscountedPricePerQuantityNetCents =
		discountedPricePerQuantityNetCents + lineItemDiscountPerQuantityNetCents

	const unDiscountedPricePerQuantityGrossCents = Math.round(
		netToGross(unDiscountedPricePerQuantityNetCents, taxRate?.amount),
	)

	const totalUnDiscountedPriceGrossCents = unDiscountedPricePerQuantityGrossCents * quantity
	const totalDiscountGrossCents = totalUnDiscountedPriceGrossCents - totalGrossCents

	const includedDiscounts = discountedPricePerQuantity
		.map(({ discountedPrice: { includedDiscounts } }) =>
			includedDiscounts.map((includedDiscount) => {
				const {
					discount: { name, description },
					discountedAmount: { centAmount: netDiscountCents },
				} = includedDiscount as any

				const discount: LineItemDiscount = {
					name,
					description,
					netDiscountCents,
				}

				return discount
			}),
		)
		.flat()

	return {
		isDiscounted: totalDiscountGrossCents !== 0,
		totalDiscountGrossCents,
		unDiscountedPricePerQuantityNetCents,
		unDiscountedPricePerQuantityGrossCents,
		totalUnDiscountedPriceGrossCents,
		includedDiscounts,
	}
}

export default formatLineItemDiscounts
