import axios from "axios"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const api = `https://get.geojs.io/v1/ip/country.json`

const getGeoCountry = async () => {
	try {
		const response = await axios.get(api)
		const {
			data: { country },
		} = response
		return country as CountryCode
	} catch (error) {
		console.warn(`Failed to fetch geo country`)
		return null
	}
}

export default getGeoCountry
