// VAT rates for member countries of the European Union
// https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_de.htm
// See git history for latest update to this file
const getVATRates = () => {
	return {
		AT: 0.2,
		BE: 0.21,
		BG: 0.2,
		CY: 0.19,
		CZ: 0.21,
		DE: 0.19,
		DK: 0.25,
		EE: 0.2,
		ES: 0.21,
		FI: 0.24,
		FR: 0.2,
		GR: 0.24,
		HU: 0.27,
		HR: 0.25,
		IE: 0.23,
		IT: 0.22,
		LT: 0.21,
		LU: 0.17,
		LV: 0.21,
		MT: 0.18,
		NL: 0.21,
		PL: 0.23,
		PT: 0.23,
		RO: 0.19,
		SE: 0.25,
		SI: 0.22,
		SK: 0.2,
	}
}

export default getVATRates
