import Router from "next/router"
import { DispatchProductDetailPageReducer, ProductDetailPageReducerState } from "../.."
import validateConfigurations from "../../utils/configurationGroups/validateConfigurations"
import getMergedAttributes from "../../utils/getMergedAttributes"
import getMsrpPricePortions from "../../utils/getMsrpPricePortions"
import getPricePortions from "../../utils/getPricePortions"
import getAdditionalDeliveryTimeWorkDays from "../../utils/getTotalDeliveryTimeWorkDays"

interface SetSafeVariantPayload
	extends Pick<
		ProductDetailPageReducerState,
		| "variantId"
		// General
		| "attributes"
		| "configurationsByConfigurationGroup"
		| "additionalDeliveryTimeWorkDays"
		// Price
		| "singleNetPriceCents"
		| "singleGrossPriceCents"
		| "totalNetPriceCents"
		| "totalGrossPriceCents"
		| "pricePortions"
		| "singleNetMsrpPriceCents"
		| "singleGrossMsrpPriceCents"
		| "totalNetMsrpPriceCents"
		| "totalGrossMsrpPriceCents"
		| "msrpPricePortions"
		// Safe
		| "selectedSafeLockConfigurationProduct"
	> {
	images?: ProductDetailPageReducerState["images"]
	assets?: ProductDetailPageReducerState["assets"]
}

const setSafeVariant = (
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	variantId: number,
) => {
	const {
		countryCode,
		variantsByVariantId,
		safeLockConfigurationProductsBySku,
		slug,
		isDebug,
		shopKey,

		taxRate,
		quantity,
		configurationsByConfigurationGroup: prevConfigurationsByConfigurationGroup,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
		selectedDeliveryOption,
	} = reducerState

	const variant = variantsByVariantId?.[variantId]
	const {
		images = [],
		assets = [],
		attributes: {
			configurationGroups: newConfigurationGroups = [],
			variantConfigurationProductSku,
		},
	} = variant
	const variantsHaveUniqueProductPage =
		variantsByVariantId?.[1]?.attributes?.variantsHaveUniqueProductPage ?? false

	// Validate configurations
	const validPrevConfigurations = validateConfigurations(
		prevConfigurationsByConfigurationGroup,
		newConfigurationGroups,
	)
	const selectedSafeLockConfigurationProduct =
		safeLockConfigurationProductsBySku?.[variantConfigurationProductSku]

	const {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
	} = getPricePortions(
		variantsByVariantId,
		variantId,
		taxRate,
		quantity,
		countryCode,
		validPrevConfigurations,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	)

	const attributes = getMergedAttributes(variantsByVariantId, variantId)

	const {
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	} = getMsrpPricePortions(
		quantity,
		taxRate,
		countryCode,
		attributes,
		validPrevConfigurations,
		pricePortions.cylinderLockingMechanismProductSingleNetPriceCents,
		pricePortions.cylinderLockingMechanismProductSingleGrossPriceCents,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	)

	const additionalDeliveryTimeWorkDays = getAdditionalDeliveryTimeWorkDays({
		configurationsByConfigurationGroup: validPrevConfigurations,
		selectedDeliveryOption,
	})

	const payload: SetSafeVariantPayload = {
		variantId,

		// General
		attributes,
		configurationsByConfigurationGroup: validPrevConfigurations,
		additionalDeliveryTimeWorkDays,

		// Price
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,

		// Safes
		selectedSafeLockConfigurationProduct,
	}

	if (variantsHaveUniqueProductPage) {
		if (images.length > 0) payload.images = images
		if (assets.length > 0) payload.assets = assets
	}

	dispatch({
		type: "SET_VARIANT",
		payload,
	})

	if (["TRE", "SH"].includes(shopKey))
		Router.push("/p/[slug]", `/p/${slug}?variant=${variant.sku}${isDebug ? `&debug` : ""}`, {
			shallow: true,
		})
}

export default setSafeVariant
