import { relevantLineItemVariantAttributes } from "./lineItem"

const includeNamesFilter = relevantLineItemVariantAttributes.reduce(
	(includeNamesFilter, attributeName) => `${includeNamesFilter}, "${attributeName}"`,
	"",
)

const shoppingListFragment = {
	fragment: /* GraphQL */ `
		fragment shoppingList on ShoppingList {
			id
			version
			createdAt
			lastModifiedAt
			name(locale: "de")
			description(locale: "de")
			lineItems {
				id
				productId
				name(locale: "de")
				productSlug(locale: "de")
				quantity
				variant {
					id
					sku
					images {
						url
					}
					attributesRaw(includeNames: [${includeNamesFilter}]) {
						name
						value
					}	
					prices {
						country
						channel {
							key
						}
						value {
							centAmount
							currencyCode
						}
					}
				}
				custom {
					customFieldsRaw {
						name
						value
					}
				}
			}
			textLineItems {
				id
				name(locale: "de")
			}
			custom {
				customFieldsRaw {
					name
					value
				}
			}
		}
	`,
	dependencies: [],
}

export default shoppingListFragment
