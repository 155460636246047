const HttpBackend = require("i18next-http-backend/cjs")
const ChainedBackend = require("i18next-chained-backend").default
const LocalStorageBackend = require("i18next-localstorage-backend").default

const isBrowser = typeof window !== "undefined"

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
	// https://www.i18next.com/overview/configuration-options#logging
	debug: process.env.NODE_ENV === "development",
	i18n: {
		locales: ["default", "de-DE", "de-AT", "de-CH", "pl-PL"],
		defaultLocale: "default",
		localeDetection: false,
	},
	fallbackLng: {
		default: ["de"],
		"de-DE": ["de"],
		"de-AT": ["de"],
		"de-CH": ["de"],
		"pl-PL": ["pl"],
	},
	backend: {
		backendOptions: [
			{ expirationTime: 60 * 60 * 1000 }, // 1 hour
			{
				/* loadPath: 'https:// somewhere else' */
			},
		],
		backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
	},
	partialBundledLanguages: isBrowser && true,
	serializeConfig: false,
	use: isBrowser ? [ChainedBackend] : [],
	/** To avoid issues when deploying to some PaaS (Vercel...) */
	localePath:
		typeof window === "undefined" ? require("path").resolve("./public/locales") : "/locales",
}
