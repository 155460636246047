import getMergedAttributes from "@secureo/common/reducers/productDetailPageReducer/utils/getMergedAttributes"
import getPricePortions from "@secureo/common/reducers/productDetailPageReducer/utils/getPricePortions"
import getMsrpPricePortions from "@secureo/common/reducers/productDetailPageReducer/utils/getMsrpPricePortions"
import validateConfigurations from "@secureo/common/reducers/productDetailPageReducer/utils/configurationGroups/validateConfigurations"

import { DispatchProductDetailPageReducer, ProductDetailPageReducerState } from "../.."
import { ProductAttributes, CylinderConfigurationVariant } from "@secureo/common/typings/Product"

interface SetCylinderConfigurationVariantPayload
	extends Pick<
		ProductDetailPageReducerState,
		| "productId"
		// General
		| "attributes"
		// Price
		| "singleNetPriceCents"
		| "singleGrossPriceCents"
		| "totalNetPriceCents"
		| "totalGrossPriceCents"
		| "pricePortions"

		// Msrp
		| "singleNetMsrpPriceCents"
		| "singleGrossMsrpPriceCents"
		| "totalNetMsrpPriceCents"
		| "totalGrossMsrpPriceCents"
		| "msrpPricePortions"
		| "cylinderConfigurationVariant"
		| "configurationsByConfigurationGroup"
	> {
	name?: ProductDetailPageReducerState["name"]
	description?: ProductDetailPageReducerState["description"]
	shortDescription?: ProductDetailPageReducerState["shortDescription"]
	images?: ProductDetailPageReducerState["images"]
	assets?: ProductDetailPageReducerState["assets"]
}

export const setCylinderConfigurationVariant = (
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	cylinderConfigurationVariant: CylinderConfigurationVariant,
) => {
	const {
		countryCode,

		variantsByVariantId,
		variantId,

		attributes: prevAttributes,
		configurationsByConfigurationGroup: prevConfigurationsByConfigurationGroup,

		// Price
		quantity,
		taxRate,

		// Cylinder
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
	} = reducerState

	const { variantsHaveUniqueProductPage = false } = prevAttributes

	const {
		id: productId,
		shortDescription,
		name,
		description,
		masterVariant: { assets = [], images = [] },
	} = cylinderConfigurationVariant

	const { technicalDetails, ...cylinderConfigurationVariantAttributes } =
		cylinderConfigurationVariant?.masterVariant?.attributes ?? {}
	const attributes = getMergedAttributes(variantsByVariantId, variantId)
	const mergedAttributes: ProductAttributes = {
		...attributes,
		...cylinderConfigurationVariantAttributes,
	}
	const { configurationGroups: newConfigurationGroups = [] } = mergedAttributes

	// Validate configurations
	const validPrevConfigurations = validateConfigurations(
		prevConfigurationsByConfigurationGroup,
		newConfigurationGroups,
	)

	const {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
	} = getPricePortions(
		variantsByVariantId,
		variantId,
		taxRate,
		quantity,
		countryCode,
		validPrevConfigurations,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
	)

	const {
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	} = getMsrpPricePortions(
		quantity,
		taxRate,
		countryCode,
		mergedAttributes,
		validPrevConfigurations,
		pricePortions.cylinderLockingMechanismProductSingleNetPriceCents,
		pricePortions.cylinderLockingMechanismProductSingleGrossPriceCents,
	)

	const payload: SetCylinderConfigurationVariantPayload = {
		productId,
		cylinderConfigurationVariant,
		attributes: mergedAttributes,
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
		configurationsByConfigurationGroup: validPrevConfigurations,
	}

	if (variantsHaveUniqueProductPage) {
		payload.name = name
		if (shortDescription) payload.shortDescription = shortDescription
		if (description) payload.description = description

		if (images.length > 0) payload.images = images
		if (assets.length > 0) payload.assets = assets

		if (technicalDetails) payload.attributes.technicalDetails = technicalDetails
	}

	dispatch({
		type: "SET_CYLINDER_CONFIGURATION_VARIANT",
		payload,
	})
}
