import isValidMatrix from "./isValidMatrix"

import { LockingSystemMatrixType } from "../typings/LockingSystem"

// Each key only locks 1 lock
// Each column has only 1 value
const isSingleMatrixType = (matrix: boolean[][]) => {
	const numberOfColumns = matrix?.[0]?.length
	const numberOfRows = matrix.length

	for (let columnIndex = 0; columnIndex < numberOfColumns; columnIndex++) {
		let numberOfSelectedCells = 0

		for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
			const isSelected = matrix[rowIndex][columnIndex]

			if (isSelected) numberOfSelectedCells++
			if (numberOfSelectedCells > 1) return false
		}
	}

	return true
}

// If at least one cell is not selected, it is not "simultaneous"
const isSimultaneous = (matrix: boolean[][]) => {
	for (const row of matrix) {
		for (const isSelected of row) {
			if (!isSelected) return false
		}
	}

	return true
}

const getMatrixType = (matrix: boolean[][]): LockingSystemMatrixType => {
	if (!isValidMatrix(matrix)) return null
	if (isSingleMatrixType(matrix)) return "single"
	if (isSimultaneous(matrix)) return "simultaneous"
	return "system"
}

export default getMatrixType
