const paymentFragment = {
	fragment: /* GraphQL */ `
		fragment payment on Payment {
			id
			amountPlanned {
				...money
			}
			paymentStatus {
				state {
					name(locale: $locale)
				}
			}
			paymentMethodInfo {
				name(locale: $locale)
				method
			}
			transactions {
				id
				type
				timestamp
				state
				amount {
					...money
				}
			}
		}
	`,
	dependencies: ["money"],
}

export default paymentFragment
