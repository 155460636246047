import { ChangeEvent } from "react"
import { OuterLengthsBySystem } from "@secureo/common/typings/Product"

export const getAvailableOuterLengthsForSystem = (
	outerLengthsBySystem: OuterLengthsBySystem,
	system: string,
) => {
	const availableOuterLengthsForSystem = (outerLengthsBySystem?.[system] ?? []).map(
		(lengthCombination) => lengthCombination.outerLength,
	)

	return availableOuterLengthsForSystem
}
const useOuterLengthsBySystemConfiguration = (
	outerLengthsBySystem: OuterLengthsBySystem,
	selectedSystem: string,
	selectedOuterLength: number,
	onSystemChange: (selectedSystem: string) => void,
	onOuterLengthChange: (selectedOuterLength: number) => void,
) => {
	const availableSystems = Object.keys(outerLengthsBySystem)

	const availableOuterLengths = getAvailableOuterLengthsForSystem(
		outerLengthsBySystem,
		selectedSystem,
	)

	const systemInputProps = {
		value: selectedSystem,
		onChange: (_: any, selectedSystem: string) => {
			onSystemChange(selectedSystem)
		},
	}

	const outerLengthInputProps = {
		value: selectedOuterLength,
		onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			const selectedOuterLength = Number(event.target.value)
			onOuterLengthChange(selectedOuterLength)
		},
	}

	return {
		systemInputProps,
		outerLengthInputProps,
		availableSystems,
		availableOuterLengths,
	}
}

export default useOuterLengthsBySystemConfiguration
