import { ConfigurationGroup } from "@secureo/common/typings/Product"

const getGroupsWithRequiredConfiguration = (
	configurationGroups: ConfigurationGroup[],
	isLockingSystemComponent = false,
) => {
	const groupsWithRequiredConfiguration = configurationGroups.filter((configurationGroup) => {
		if (isLockingSystemComponent && configurationGroup.excludeFromLockingSystemComponent)
			return false

		return ["single", "dropDown"].includes(configurationGroup.type)
	})

	return groupsWithRequiredConfiguration
}

export default getGroupsWithRequiredConfiguration
