import getInitialReducerState from "../utils/getInitialReducerState"

import { DispatchProductDetailPageReducer } from ".."
import { Product } from "@secureo/common/typings/Product"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import { ShopKey } from "@secureo/common/typings/Shop"
import { CurrencyCode } from "@secureo/common/typings/Forex"

export const resetReducerState = (
	dispatch: DispatchProductDetailPageReducer,
	product: Product,
	countryCode: CountryCode,
	shopKey: ShopKey,
	locale: string,
	isDebug: boolean,
	initialVariantSku?: string,
	currencyCode: CurrencyCode = "EUR",
) => {
	console.info("Resetting reducer state")
	dispatch({
		type: "RESET_STATE",
		payload: getInitialReducerState(
			product,
			countryCode,
			locale,
			shopKey,
			isDebug,
			initialVariantSku,
			currencyCode,
		),
	})
}
