import { CountryCode } from "@secureo/common/typings/CountryCode"

export interface Country {
	name: string
	countryCode: CountryCode
}

// European Union
const euCountries: Country[] = [
	{ name: "Austria", countryCode: "AT" },
	{ name: "Belgium", countryCode: "BE" },
	{ name: "Bulgaria", countryCode: "BG" },
	//{ name: "Cyprus", countryCode: "CY" },
	{ name: "Czech Republic", countryCode: "CZ" },
	{ name: "Germany", countryCode: "DE" },
	{ name: "Denmark", countryCode: "DK" },
	{ name: "Estonia", countryCode: "EE" },
	{ name: "Spain", countryCode: "ES" },
	{ name: "Finland", countryCode: "FI" },
	{ name: "France", countryCode: "FR" },
	{ name: "Greece", countryCode: "GR" },
	{ name: "Hungary", countryCode: "HU" },
	{ name: "Croatia", countryCode: "HR" },
	{ name: "Ireland", countryCode: "IE" },
	{ name: "Italy", countryCode: "IT" },
	{ name: "Lithuania", countryCode: "LT" },
	{ name: "Luxembourg", countryCode: "LU" },
	{ name: "Latvia", countryCode: "LV" },
	//{ name: "Malta", countryCode: "MT" },
	{ name: "Netherlands", countryCode: "NL" },
	{ name: "Poland", countryCode: "PL" },
	{ name: "Portugal", countryCode: "PT" },
	{ name: "Romania", countryCode: "RO" },
	{ name: "Sweden", countryCode: "SE" },
	{ name: "Slovenia", countryCode: "SI" },
	{ name: "Slovakia", countryCode: "SK" },
]

// European Free Trade Association
const eftaCountries: Country[] = [
	{ name: "Switzerland", countryCode: "CH" },
	// { name: "Iceland", countryCode: "IS" }, // Not supported atm
	{ name: "Liechtenstein", countryCode: "LI" },
	{ name: "Norway", countryCode: "NO" },
]

const otherCountries: Country[] = [{ name: "Great Britain", countryCode: "GB" }]

const countries: Country[] = [...euCountries, ...eftaCountries, ...otherCountries].sort((a, b) =>
	a.countryCode.localeCompare(b.countryCode),
)
export const countryCodes = countries.map(({ countryCode }) => countryCode)

export const countryCodesByCountry = countries.reduce(
	(countryCodesByCountry, { name, countryCode }) => ({
		...countryCodesByCountry,
		[name]: countryCode,
	}),
	{},
) as {
	[countryName: string]: CountryCode
}

export const countriesByCountryCode = countries.reduce(
	(countryCodesByCountry, { name, countryCode }) => ({
		...countryCodesByCountry,
		[countryCode]: name,
	}),
	{},
) as {
	[countryCode: string]: string
}

export default countries
