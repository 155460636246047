import countries from "@secureo/common/config/countries"

import {
	RawShippingOriginationCountryCodeAttribute,
	ShippingOriginationCountryCodesByDestinationCountryCode,
} from "@secureo/common/typings/Product"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const getShippingOriginationCountryCodesByShopCountry = (
	shippingOriginationCountryCountryCodeAttribute: RawShippingOriginationCountryCodeAttribute,
) => {
	try {
		const { value: shippingOriginationCountries } =
			shippingOriginationCountryCountryCodeAttribute

		const shippingOriginationCountryCodesByDestinationCountryCode =
			shippingOriginationCountries.reduce(
				(
					shippingOriginationCountryCodesByDestinationCountryCode,
					shippingOriginationCountry,
				) => {
					const [
						{ value: destinationCountryCode },
						{ value: shippingOriginationCountryCode },
					] = shippingOriginationCountry

					return {
						[destinationCountryCode.toUpperCase() as CountryCode]:
							shippingOriginationCountryCode.toUpperCase() as CountryCode,
						...shippingOriginationCountryCodesByDestinationCountryCode,
					}
				},
				{} as ShippingOriginationCountryCodesByDestinationCountryCode,
			)

		return shippingOriginationCountryCodesByDestinationCountryCode
	} catch (error) {
		// Fallback: Return DE as destinationCountry for each originationCountry
		const fallbackShippingOriginationCountryCodesByDestinationCountryCode = countries.reduce(
			(fallbackShippingOriginationCountryCodesByDestinationCountryCode, { countryCode }) => {
				return {
					...fallbackShippingOriginationCountryCodesByDestinationCountryCode,
					[countryCode]: "DE" as CountryCode,
				}
			},
			{} as ShippingOriginationCountryCodesByDestinationCountryCode,
		)

		return fallbackShippingOriginationCountryCodesByDestinationCountryCode
	}
}

export default getShippingOriginationCountryCodesByShopCountry
