import { DispatchProductDetailPageReducer } from ".."

const setIsLoading = (dispatch: DispatchProductDetailPageReducer, isLoading: boolean) => {
	dispatch({
		type: "SET_IS_LOADING",
		payload: isLoading,
	})
}

export default setIsLoading
