import getSetConfigurationsPayload from "../utils/configurationGroups/getSetConfigurationsPayload"
import getProductConfigurationFromConfigurationProduct from "../utils/configurationGroups/getProductConfigurationFromConfigurationProduct"
import {
	getUpdatedConfigurationsForMultiConfigurationGroup,
	getConfigurationsBySkuFromConfigurations,
} from "../utils/configurationGroups/multi"
import getGroupsWithRequiredConfiguration from "../utils/getGroupsWithRequiredConfiguration"

import {
	DispatchProductDetailPageReducer,
	ProductDetailPageReducerState,
	ProductConfigurationsByConfigurationGroup,
} from ".."
import {
	ConfigurationGroup,
	ConfigurationGroupProductsBySku,
} from "@secureo/common/typings/Product"

const isValidPrevConfiguration = (
	prevConfigurationsByConfigurationGroup: ProductConfigurationsByConfigurationGroup,
	newConfigurationGroup: ConfigurationGroup,
) => {
	const { name: configurationGroupName, items } = newConfigurationGroup
	const configurationGroupSkus = items.map(({ sku }) => sku)

	const prevConfigurationGroupSku =
		Object.values(prevConfigurationsByConfigurationGroup?.[configurationGroupName] ?? {})?.[0]
			?.sku ?? null

	const isValidPrevConfiguration =
		prevConfigurationGroupSku && configurationGroupSkus.includes(prevConfigurationGroupSku)

	return isValidPrevConfiguration
}

export const setInitialConfigurations = (
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	configurationGroupProductsBySku: ConfigurationGroupProductsBySku,
) => {
	const {
		attributes: { configurationGroups },
		countryCode,
		configurationsByConfigurationGroup: prevConfigurationsByConfigurationGroup,
	} = reducerState

	const groupsWithRequiredConfiguration = getGroupsWithRequiredConfiguration(configurationGroups)

	const initialConfigurationsByConfigurationGroup = groupsWithRequiredConfiguration.reduce(
		(initialConfigurationsByConfigurationGroup, configurationGroup) => {
			const { items, name: configurationGroupName } = configurationGroup

			const sku = items?.[0]?.sku ?? null
			const configurationProduct = configurationGroupProductsBySku?.[sku] ?? null

			if (!sku || !configurationProduct) return initialConfigurationsByConfigurationGroup

			// If a configuration from this group was previously added + is still valid, keep it
			if (
				isValidPrevConfiguration(prevConfigurationsByConfigurationGroup, configurationGroup)
			)
				return {
					...initialConfigurationsByConfigurationGroup,
					[configurationGroupName]:
						prevConfigurationsByConfigurationGroup[configurationGroupName],
				}

			const configuration = getProductConfigurationFromConfigurationProduct(
				configurationProduct,
				configurationGroupName,
				1,
				countryCode,
			)

			return {
				...initialConfigurationsByConfigurationGroup,
				[configurationGroupName]: { [sku]: configuration },
			}
		},
		{} as ProductConfigurationsByConfigurationGroup,
	)

	const payload = getSetConfigurationsPayload(
		reducerState,
		initialConfigurationsByConfigurationGroup,
	)

	dispatch({
		type: "SET_CONFIGURATIONS",
		payload,
	})
}

export const setSingleConfigurationGroupConfiguration = (
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	configurationGroupProductsBySku: ConfigurationGroupProductsBySku,
	configurationGroupName: string,
	sku: string,
) => {
	const { countryCode, configurationsByConfigurationGroup } = reducerState

	const configurationProduct = configurationGroupProductsBySku?.[sku]
	const configuration = getProductConfigurationFromConfigurationProduct(
		configurationProduct,
		configurationGroupName,
		1,
		countryCode,
	)

	const updatedConfigurationsByConfigurationGroup = {
		...configurationsByConfigurationGroup,
		[configurationGroupName]: {
			[configuration.sku]: configuration,
		},
	}

	const payload = getSetConfigurationsPayload(
		reducerState,
		updatedConfigurationsByConfigurationGroup,
	)

	dispatch({
		type: "SET_CONFIGURATIONS",
		payload,
	})
}

export const setMultiConfigurationGroupConfiguration = (
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	configurationGroupProductsBySku: ConfigurationGroupProductsBySku,
	configurationGroupName: string,
	sku: string,
	configurationQuantity: number,
) => {
	const { countryCode, configurationsByConfigurationGroup } = reducerState

	const prevGroupConfigurations = Object.values(
		configurationsByConfigurationGroup?.[configurationGroupName] ?? {},
	)
	const updatedGroupConfigurations = getUpdatedConfigurationsForMultiConfigurationGroup(
		prevGroupConfigurations,
		configurationGroupProductsBySku,
		configurationGroupName,
		sku,
		configurationQuantity,
		countryCode,
	)
	const updatedGroupConfigurationsBySku = getConfigurationsBySkuFromConfigurations(
		updatedGroupConfigurations,
	)

	const updatedConfigurationsByConfigurationGroup = {
		...configurationsByConfigurationGroup,
		[configurationGroupName]: updatedGroupConfigurationsBySku,
	}

	const payload = getSetConfigurationsPayload(
		reducerState,
		updatedConfigurationsByConfigurationGroup,
	)

	dispatch({
		type: "SET_CONFIGURATIONS",
		payload,
	})
}
