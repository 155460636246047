import getPrivateCustomerTaxRate from "./getPrivateCustomerTaxRate"
import getCompanyCustomerTaxRate from "./getCompanyCustomerTaxRate"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const getLineItemTaxRate = (
	shippingOriginationCountryCode: CountryCode,
	shippingDestinationCountryCode: CountryCode,
	isCompany = false,
	vatId?: string,
) => {
	const isCompanyTaxRate = isCompany && !!vatId

	const taxRate = !isCompanyTaxRate
		? getPrivateCustomerTaxRate(shippingOriginationCountryCode, shippingDestinationCountryCode)
		: getCompanyCustomerTaxRate(shippingOriginationCountryCode, shippingDestinationCountryCode)

	return {
		taxRate,
		isCompanyTaxRate,
	}
}

export default getLineItemTaxRate
