import { connectHits } from "react-instantsearch-dom"
import { Box, ListItem, Hidden } from "@mui/material"

import Link from "../../i18n/Link"

import { CategoryHit } from "../../../typings/Algolia"

const CategoryResult = ({ hit, closeSearchBar }: ContentResultProps) => {
	const { name, slug, isParentCategory = false } = hit

	const href = `/${isParentCategory ? "pc" : "c"}/${slug}`

	return (
		<Link href={href} onClick={closeSearchBar}>
			<ListItem style={{ paddingLeft: 0 }}>{name}</ListItem>
		</Link>
	)
}

interface ContentResultProps {
	hit: CategoryHit
	closeSearchBar: () => void
}

const ContentResults = ({ hits, isExpanded, closeSearchBar }: Props) => {
	const hasResults = hits.length > 0

	if (!isExpanded) return null

	return (
		<Hidden mdDown>
			<div>
				<strong style={{ textTransform: "uppercase" }}>Kategorien</strong>
				<Box mt={2}>
					{hasResults ? (
						hits.map((hit) => {
							const { objectID } = hit
							return (
								<CategoryResult
									key={objectID}
									hit={hit}
									closeSearchBar={closeSearchBar}
								/>
							)
						})
					) : (
						<p>Keine Ergebnisse</p>
					)}
				</Box>
			</div>
		</Hidden>
	)
}

interface Props {
	hits: CategoryHit[]
	isExpanded: boolean
	closeSearchBar: () => void
}

export default connectHits(ContentResults)
