import { CountryCode } from "@secureo/common/typings/CountryCode"
import getLanguageAndCountryCodeFromLocale from "@secureo/common/utils/i18n/getLanguageAndCountryCodeFromLocale"

const normalizeLocale = (rawLocale: string) => {
	const { language, countryCode } = getLanguageAndCountryCodeFromLocale(rawLocale)

	const lowerCasedLanguage = language.toLowerCase()
	const upperCasedCountryCode = countryCode.toUpperCase() as CountryCode

	const locale = `${language}-${countryCode}`

	return {
		locale,
		language: lowerCasedLanguage,
		countryCode: upperCasedCountryCode,
	}
}

export default normalizeLocale
