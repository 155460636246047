import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"

const deleteMyCart = async (accessToken: string, id: string, version: number) => {
	const query = /* GraphQL */ `
		mutation deleteMyCart($id: String!, $version: Long!) {
			deleteMyCart(id: $id, version: $version) {
				id
			}
		}
	`

	await fetchCommerceToolsGraphQLApi(accessToken, query, {
		id,
		version,
	})
}

export default deleteMyCart
