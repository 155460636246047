const getIncludeNamesFilter = (includeNames: string[]) => {
	const includeNamesFilter = includeNames.reduce(
		(includeNamesFilter, attributeName) => `${includeNamesFilter}, "${attributeName}"`,
		"",
	)

	return includeNamesFilter
}

export default getIncludeNamesFilter
