import {
	pushEventToDataLayer,
	EnhancedEcommerceCartProductFieldObject,
	EnhancedEcommerceAddToCartEvent,
	EnhancedEcommerceRemoveFromCartEvent,
	GA4AddToCartEvent,
	GA4RemoveFromCartEvent,
	GA4Item,
	GA4ViewCartEvent,
	GA4AddToWishlistEvent,
} from "@secureo/common/components/GoogleTagManager"
import { Cart } from "@secureo/common/typings/Cart"
import { centsToEuros } from "../forex/convertCurrencyFraction"
import { getGA4ItemsFieldFromCart } from "./checkout"

export const trackAddToCart = (products: EnhancedEcommerceCartProductFieldObject[]) => {
	const event: EnhancedEcommerceAddToCartEvent = {
		event: "addToCart",
		ecommerce: {
			currencyCode: "EUR",
			add: {
				products,
			},
		},
	}

	pushEventToDataLayer(event)
}

export const trackAddToCartGA4 = (items: GA4Item[]) => {
	pushEventToDataLayer({ ecommerce: null })

	const event: GA4AddToCartEvent = {
		event: "add_to_cart",
		ecommerce: {
			items,
		},
	}
	pushEventToDataLayer(event)
}

export const trackRemoveFromCart = (products: EnhancedEcommerceCartProductFieldObject[]) => {
	const event: EnhancedEcommerceRemoveFromCartEvent = {
		event: "removeFromCart",
		ecommerce: {
			remove: {
				products,
			},
		},
	}

	pushEventToDataLayer(event)
}

export const trackRemoveFromCartGA4 = (items: GA4Item[]) => {
	pushEventToDataLayer({ ecommerce: null })

	const event: GA4RemoveFromCartEvent = {
		event: "remove_from_cart",
		ecommerce: {
			items,
		},
	}
	pushEventToDataLayer(event)
}

export const trackGA4ViewCart = (cart: Cart) => {
	pushEventToDataLayer({ ecommerce: null })

	const items = getGA4ItemsFieldFromCart(cart)
	const totalGrossEuros = centsToEuros(cart.taxedPrice.totalGross.centAmount)

	const event: GA4ViewCartEvent = {
		event: "view_cart",
		ecommerce: {
			currency: "EUR",
			value: totalGrossEuros,
			items,
		},
	}
	pushEventToDataLayer(event)
}

export const trackGA4AddToWishlist = (items: GA4Item[]) => {
	pushEventToDataLayer({ ecommerce: null })

	const event: GA4AddToWishlistEvent = {
		event: "add_to_wishlist",
		ecommerce: {
			items,
		},
	}
	pushEventToDataLayer(event)
}
