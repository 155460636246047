import { useContext } from "react"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import removeWhiteSpace from "utils/removeWhiteSpace"

import { CountryCode } from "@secureo/common/typings/CountryCode"

export const getLocalizedEmail = (countryCode: CountryCode) => {
	switch (countryCode) {
		default:
			return "office@safehero.com"
	}
}

export const Email = () => {
	const { countryCode } = useContext(AppContext)

	const email = getLocalizedEmail(countryCode)

	return (
		<a href={`mailto:${email}`} className="link" data-hj-whitelist>
			{email}
		</a>
	)
}

export const getLocalizedPhoneNumber = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return "+43 800 640357"
		case "DE":
			return "+49 800 5895548"
		default:
			return "+43 512 932791"
	}
}

export const Phone = () => {
	const { countryCode } = useContext(AppContext)

	const phoneNumber = getLocalizedPhoneNumber(countryCode)
	return (
		<a href={`tel:${removeWhiteSpace(phoneNumber)}`} className="link" data-hj-whitelist>
			{phoneNumber}
		</a>
	)
}

// i18n key -- product namespace
export const getLocalizedVATPrefix = (countryCode: CountryCode): "including" | "excluding" => {
	switch (countryCode) {
		case "CH":
			return "excluding"
		default:
			return "including"
	}
}
