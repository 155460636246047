import getShippingOriginationCountryCodesByShopCountry from "./attributes/getShippingOriginationCountryCodesByShopCountry"
import getDeliveryOptionsByDestinationCountryCode from "./attributes/getDeliveryOptionsByDestinationCountryCode"
import getDeliveryTimeWorkDaysByShopCountryCode from "./attributes/getDeliveryTimeWorkDaysByShopCountryCode"
import getNetMSRPByShopCountryCode from "./attributes/getNetMSRPByShopCountryCode"
import getMiscAttributes from "./attributes/getMiscAttributes"
import getTechnicalDetails from "./attributes/getTechnicalDetails"
import parseRawConfigurationLengthCombinationsAttribute from "./attributes/parseRawConfigurationLengthCombinationsAttribute"
import getConfigurationGroups from "./attributes/getConfigurationGroups"
import getCylinderLockingMechanismSkusByCountryCode from "./attributes/getCylinderLockingMechanismSkusByCountryCode"
import getProductSkusByCountryCode from "./attributes/getProductSkusByCountryCode"
import getLockingSystemChargesByMatrixType from "./attributes/getLockingSystemChargesByMatrixType"

import { RawProductAttribute } from "@secureo/common/typings/generated/commercetools-graphql"
import {
	ProductAttributes,
	RawShippingOriginationCountryCodeAttribute,
	RawDeliveryOptionsAttribute,
	RawDeliveryTimeWorkdaysAttribute,
	RawMSRPPriceAttribute,
	RawMiscAttributesAttributesAttribute,
	RawTechnicalDetailsAttribute,
	RawConfigurationLengthCombinationsAttribute,
	ProductTypeName,
	RawConfigurationGroupsAttribute,
	RawCylinderLockingTypeAttribute,
	RawCrossSellProductSkus,
	RawUpSellProductSkus,
	RawLockingSystemCharges,
} from "@secureo/common/typings/Product"

export const formatAttribute = (
	masterVariantSku: string,
	rawAttribute: Omit<RawProductAttribute, "referencedResourceSet">,
	language: string,
): {
	name: string
	value: any
	containsMultipleAttributes?: boolean
} => {
	const { name: rawName, value: rawValue } = rawAttribute

	let name = null
	let value = null
	let containsMultipleAttributes = false

	switch (rawName) {
		case "shortDescriptionDigishop": {
			name = "shortDescriptionDigishop"
			value = rawValue
			break
		}
		case "shortDescription": {
			name = "shortDescription"
			value = rawValue?.[language] ?? rawValue?.de
			break
		}
		case "color": {
			name = "color"
			value = rawValue?.[language] ?? rawValue?.de
			break
		}
		case "shippingOriginationCountryCode": {
			name = "shippingOriginationCountryCodesByDestinationCountryCode"
			value = getShippingOriginationCountryCodesByShopCountry(
				rawAttribute as RawShippingOriginationCountryCodeAttribute,
			)
			break
		}
		case "deliveryOptions": {
			name = "deliveryOptionsByDestinationCountryCode"
			value = getDeliveryOptionsByDestinationCountryCode(
				rawAttribute as RawDeliveryOptionsAttribute,
			)
			break
		}

		case "deliveryTimeWorkdays": {
			name = "deliveryTimeWorkDaysByShopCountryCode"
			value = getDeliveryTimeWorkDaysByShopCountryCode(
				rawAttribute as RawDeliveryTimeWorkdaysAttribute,
			)
			break
		}

		case "customScoreMultiplier": {
			name = "customScoreMultiplier"
			value = getDeliveryTimeWorkDaysByShopCountryCode(
				rawAttribute as RawDeliveryTimeWorkdaysAttribute,
			)
			break
		}

		case "msrpPrice": {
			name = "netMsrpPriceByShopCountryCode"
			value = getNetMSRPByShopCountryCode(rawAttribute as RawMSRPPriceAttribute)
			break
		}

		case "manufacturer": {
			name = "manufacturer"
			value = rawValue?.label?.[language] ?? rawValue?.label?.de ?? null
			break
		}

		case "systemConfigurationToolTip": {
			name = "systemConfigurationToolTip"
			value = rawValue?.[language] ?? rawValue?.de ?? null
			break
		}

		case "variantName": {
			name = "variantName"
			value = rawValue?.[language] ?? rawValue?.de ?? null
			break
		}

		case "modelName": {
			name = "modelName"
			value = rawValue?.[language] ?? rawValue?.de ?? null
			break
		}

		case "safeType": {
			name = "safeType"
			value = rawValue?.label ?? null
			break
		}

		case "securityGradeCertificate": {
			name = "securityGradeCertificate"
			value = rawValue?.split(";") ?? null
			break
		}

		case "variantLockTypes": {
			name = "variantLockTypes"
			value = rawValue.split(";") ?? null
			break
		}

		case "positionOfHinges": {
			name = "positionOfHinges"
			value = rawValue?.label ?? null
			break
		}

		case "doorSwingDirection": {
			name = "doorSwingDirection"
			value = rawValue?.label ?? null
			break
		}

		case "anchoring": {
			name = "anchoring"
			value = rawValue?.label ?? null
			break
		}

		case "miscAttributes": {
			containsMultipleAttributes = true
			value = getMiscAttributes(rawAttribute as RawMiscAttributesAttributesAttribute)
			break
		}

		case "technDetails": {
			name = "technicalDetails"
			value = getTechnicalDetails(rawAttribute as RawTechnicalDetailsAttribute)
			break
		}

		case "configurationsGroups": {
			name = "configurationGroups"
			value = getConfigurationGroups(
				rawAttribute as RawConfigurationGroupsAttribute,
				language,
			)
			break
		}

		case "configurationLengthCombinations": {
			return parseRawConfigurationLengthCombinationsAttribute(
				masterVariantSku,
				rawAttribute as RawConfigurationLengthCombinationsAttribute,
			)
		}

		case "zylinderLockingType": {
			name = "cylinderLockingMechanismSkusByCountryCode"
			value = getCylinderLockingMechanismSkusByCountryCode(
				rawAttribute as RawCylinderLockingTypeAttribute,
			)
			break
		}

		case "crossSellProductSkus": {
			name = "crossSellProductSkusByCountryCode"
			value = getProductSkusByCountryCode(rawAttribute as RawCrossSellProductSkus)
			break
		}

		case "upSellProductSkus": {
			name = "upSellProductSkusByCountryCode"
			value = getProductSkusByCountryCode(rawAttribute as RawUpSellProductSkus)
			break
		}

		case "lockingSystemComponentType": {
			name = "lockingSystemComponentType"
			value = rawValue?.key ?? null
			break
		}

		case "lockingSystemComponentName": {
			name = "lockingSystemComponentName"
			value = rawValue?.[language] ?? rawValue?.de ?? null
			break
		}

		case "lockingSystemCharges": {
			name = "lockingSystemChargesByMatrixType"
			value = getLockingSystemChargesByMatrixType(rawAttribute as RawLockingSystemCharges)
			break
		}

		case "purchasePrice": {
			name = "purchasePriceNetCents"
			value = (rawAttribute.value as any).centAmount
			break
		}

		case "360DegreeImages": {
			name = "images360Degree"
			value = rawValue?.split(";")
			break
		}
		case "lockSkus": {
			name = "lockSkus"
			value = rawValue?.split(";")
			break
		}

		case "atAGlanceContent":
		case "anchoringContent":
		case "bulgaryProtectionContent":
		case "closureContent": {
			name = rawName
			value = rawValue?.[language] ?? rawValue?.de ?? ""
			break
		}

		case "excludedCountryCodes":
		case "excludedShopCodes": {
			name = rawName
			value = rawAttribute.value.map(({ label }) => label)
			break
		}

		case "vendor": {
			name = rawName
			value = rawAttribute?.value?.label
			break
		}

		default: {
			name = rawName
			value = rawValue
		}
	}

	const formattedAttribute = {
		name,
		value,
		containsMultipleAttributes,
	}

	return formattedAttribute
}

const formatAttributes = (
	rawAttributes: Pick<RawProductAttribute, "name" | "value">[],
	productType?: ProductTypeName,
	masterVariantSku?: string,
	language = "de",
) => {
	const attributesByAttributeName: ProductAttributes = rawAttributes.reduce(
		(attributesByAttributeName, rawAttribute) => {
			if (
				productType !== "Zylinder" &&
				rawAttribute.name === "configurationLengthCombinations"
			) {
				return attributesByAttributeName
			}

			if (productType === "SerienProdukt" && rawAttribute.name === "shortDescription") {
				return { ...attributesByAttributeName, shortDescription: rawAttribute?.value?.de }
			}

			const {
				name,
				value,
				containsMultipleAttributes = false,
			} = formatAttribute(masterVariantSku, rawAttribute, language)

			if (containsMultipleAttributes) {
				return {
					...attributesByAttributeName,
					...value,
				}
			}

			return {
				...attributesByAttributeName,
				[name]: value,
			}
		},
		{} as ProductAttributes,
	)

	return attributesByAttributeName
}
export default formatAttributes
