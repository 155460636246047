import { useContext, memo } from "react"

import AppContext from "@secureo/common/context/AppContext/AppContext"

import formatPrice from "@secureo/common/utils/forex/formatPrice"
import useTranslation from "hooks/useTranslation"

import { getLocalizedVATPrefix } from "./i18n/Contact"

const TaxStringComponent = () => {
	const { t } = useTranslation("common")
	const { isBusinessVersion, countryCode } = useContext(AppContext)
	const vatPrefix = isBusinessVersion ? "excluding" : getLocalizedVATPrefix(countryCode)

	return (
		<span>
			{t(vatPrefix)} {t("vat")}
		</span>
	)
}

const Price = ({ priceInEuroCents, style = {} }: Props) => {
	const {
		currencyCode,
		//exchangeRatesEuro,
		language,
	} = useContext(AppContext)

	// const exchangeRate = exchangeRatesEuro[currencyCode]
	// const convertedPriceInSmallestFraction = Math.round(priceInEuroCents * exchangeRate.rate)
	const formattedPrice = formatPrice(priceInEuroCents, language, currencyCode)

	return <span style={{ ...style, whiteSpace: "nowrap" }}>{formattedPrice}</span>
}

interface Props {
	priceInEuroCents: number
	style?: React.CSSProperties
}

export default memo(Price)
export const TaxString = memo(TaxStringComponent)
