import { useState, useContext } from "react"
import {
	Container,
	Grid,
	Theme,
	ClickAwayListener,
	Paper,
	List,
	ListItemButton,
	ListItemText,
	Box,
} from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import {
	mdiCellphone,
	mdiEmailOutline,
	mdiLoginVariant,
	mdiAccountCircle,
	mdiChevronDown,
} from "@mdi/js"

import AppContext from "@secureo/common/context/AppContext/AppContext"

import useTranslation from "hooks/useTranslation"

import Link from "../i18n/Link"
import { Phone, Email } from "../i18n/Contact"
import { LoginRegisterButton } from "../Login/LoginRegister"

import { accountLinks } from "../../containers/Account/AccountNav"

const useStyles = makeStyles()((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		fontSize: "12px",
		color: "#f7fafa",
		border: 0,
		borderRadius: 0,
		paddingTop: "3px",
		paddingBottom: "3px",
		position: "fixed",
		width: "100%",
		zIndex: 3,
		height: "22px",
		boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.1)",
	},
	root2: {
		position: "relative",
	},
	paper: {
		position: "absolute",
		top: 36,
		right: 0,
		left: "-100px",
		minWidth: "220px",
		color: theme.palette.secondary.dark,
	},
}))

const TopBar = () => {
	const { classes } = useStyles()
	const { t } = useTranslation("common")
	const { isLoggedIn, isInitialized } = useContext(AppContext)

	return (
		<Box
			className={classes.root}
			sx={{
				display: {
					xs: "none",
					md: "block",
				},
			}}
		>
			<Container>
				<Grid container spacing={3}>
					<Grid
						item
						xs={6}
						style={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						<div className="bold">{t("askAnExpert")}</div>
						<div style={{ display: "flex" }}>
							<div className="mt-1 ml-1">
								<Icon
									path={mdiCellphone}
									title="Phone"
									size={0.7}
									color="#31414D"
								/>
							</div>
							<div className="mt-1">
								<Phone />
							</div>
						</div>
						<div style={{ display: "flex" }}>
							<div className="mt-1 ml-1">
								<Icon
									path={mdiEmailOutline}
									title="Mail"
									size={0.7}
									color="#31414D"
								/>
							</div>
							<div className="ml-1 mt-1">
								<Email />
							</div>
						</div>
					</Grid>
					{isInitialized && (
						<Grid
							item
							xs={6}
							style={{
								textAlign: "right",
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
							}}
						>
							{isLoggedIn ? (
								<AccountDropdown />
							) : (
								<div style={{ display: "flex" }} className="bold">
									<Icon
										path={mdiLoginVariant}
										title="Login"
										size={0.7}
										color="#31414D"
									/>
									<div className="ml-1">
										<LoginRegisterButton />
									</div>
								</div>
							)}
						</Grid>
					)}
				</Grid>
			</Container>
		</Box>
	)
}

const AccountDropdown = () => {
	const { classes } = useStyles()
	const { customer, language } = useContext(AppContext)
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useTranslation()

	const handleClick = () => {
		setIsOpen(!isOpen)
	}

	const handleClickAway = () => {
		setIsOpen(false)
	}

	return (
		<>
			<div className={classes.root2}>
				<ClickAwayListener onClickAway={handleClickAway}>
					<div>
						<button
							onClick={handleClick}
							style={{
								cursor: "pointer",
								border: "none",
								background: "none",
								outline: "none",
							}}
						>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{ marginRight: "4px", marginTop: "2px" }}>
									<Icon path={mdiAccountCircle} size={0.7} color="#1B5C71" />
								</div>

								<span style={{ color: "#fff" }}>
									<strong>
										{t("hello")} {customer.firstName}
									</strong>
								</span>

								<div style={{ marginTop: "2px" }}>
									<Icon path={mdiChevronDown} size={0.7} color="#f7fafa" />
								</div>
							</div>
						</button>
						{isOpen && (
							<Paper className={classes.paper}>
								<List component="nav" aria-label="main mailbox folders">
									{accountLinks.map(({ href, labelByLanguage }) => {
										const label =
											labelByLanguage[language] ?? labelByLanguage["de"] ?? ""
										return (
											<Link href={href} key={href}>
												<ListItemButton>
													<ListItemText primary={label} />
												</ListItemButton>
											</Link>
										)
									})}
								</List>
							</Paper>
						)}
					</div>
				</ClickAwayListener>
			</div>
		</>
	)
}

export default TopBar
