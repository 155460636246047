import { useState, useEffect } from "react"
import { Index, connectSearchBox } from "react-instantsearch-dom"
import { Box, Container, Hidden, IconButton, useMediaQuery } from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"

import Button from "../../Buttons/Button"
import Grid from "@mui/material/Grid"
import ProductResults from "./ProductResults"
import CategoryResults from "./CategoryResults"

import searchConfig from "../../../config/searchConfig"
import { CurrencyCode } from "typings/Forex"

const useStyles = makeStyles()((theme) => ({
	root: {
		flexGrow: 1,
	},
	searchResults: {
		color: theme.palette.secondary.dark,
		position: "absolute",
		zIndex: 9999,
		height: "100%",
		maxHeight: "auto",
		width: "100%",
		left: 0,
		border: "none",
		margin: "0",
		marginTop: "10px",
		overflow: "hidden",
	},
	searchPopper: {
		background: "white",
		// background: "whitesmoke",
		boxShadow: "0px 3px 5px 0px rgba(0,0,0,0.25)",
		border: "1px solid lightgrey",
	},
	productResults: {
		height: "380px",
		overflowY: "scroll",
		overflowX: "hidden",
	},
	hidden: {
		height: 0,
		display: "none",
	},
}))

const SearchResults = ({
	currentRefinement,
	locale,
	refine,
	showAllResults,
	currencyCode,
}: Props) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const { classes } = useStyles()
	const isBiggerThan960pxs = useMediaQuery("(min-width: 959.58px)")

	// TODO: Also make this dependent on onFocus pseudo state
	useEffect(() => {
		const searchQueryHasLength =
			currentRefinement.length >= searchConfig.minCharactersBeforeSearch

		if (searchQueryHasLength && !isExpanded) setIsExpanded(true)
		if (!searchQueryHasLength && isExpanded) setIsExpanded(false)
	}, [currentRefinement, isExpanded])

	const closeSearchBar = () => {
		refine("")
	}

	const categoryIndexName = `${locale}-categories`

	return (
		<>
			<div
				className={!isExpanded ? classes.hidden : classes.searchResults}
				style={{
					marginTop: isBiggerThan960pxs ? "67px" : "initial",
				}}
			>
				<div className={classes.searchPopper}>
					<Container style={{ position: "relative" }}>
						<div style={{ margin: "auto" }}>
							<Grid container spacing={0}>
								<Hidden mdDown>
									<Grid
										item
										xs={12}
										md={3}
										style={{
											borderRight: "2px solid #efefef",
										}}
									>
										<Box p={2}>
											<Index
												indexName={categoryIndexName}
												indexId={categoryIndexName}
											>
												<CategoryResults
													isExpanded={isExpanded}
													closeSearchBar={closeSearchBar}
												/>
											</Index>
										</Box>
									</Grid>
								</Hidden>
								<Grid item xs={12} md={9}>
									<Box py={2}>
										<Box pb={1} ml={3}>
											<strong style={{ textTransform: "uppercase" }}>
												Produkte
											</strong>
										</Box>
										<Box px={1} ml={1} className={classes.productResults}>
											<ProductResults
												isExpanded={isExpanded}
												closeSearchBar={closeSearchBar}
												currencyCode={currencyCode}
											/>
										</Box>
										<Box
											style={{
												maxWidth: "300px",
												margin: "auto",
												marginTop: "20px",
											}}
										>
											<Button color="primary" onClick={showAllResults}>
												Alle Ergebnisse anzeigen
											</Button>
										</Box>
									</Box>
								</Grid>
								<Hidden mdUp>
									<Grid item xs={12} md={3}>
										<Box mt={3}>
											<Index
												indexName={categoryIndexName}
												indexId={categoryIndexName}
											>
												<CategoryResults
													isExpanded={isExpanded}
													setIsExpanded={setIsExpanded}
													closeSearchBar={closeSearchBar}
												/>
											</Index>
										</Box>
									</Grid>
								</Hidden>
							</Grid>
						</div>
						<div style={{ position: "absolute", right: "50px", top: "5px" }}>
							<IconButton
								aria-label="close search"
								onClick={closeSearchBar}
								size="large"
							>
								<Icon path={mdiClose} size={1} />
							</IconButton>
						</div>
					</Container>
				</div>
			</div>
		</>
	)
}

interface Props {
	currentRefinement: string
	isSearchStalled: boolean
	searchQuery: string
	locale: string
	refine: (query: string) => void
	showAllResults: () => void
	currencyCode: CurrencyCode
}

export default connectSearchBox(SearchResults)
