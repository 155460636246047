import getVATRates from "./getVATRates"

import { CountryCode } from "@secureo/common/typings/CountryCode"

// https://www.timeanddate.com/worldclock/converter.html?iso=20210630T220000&p1=37&p2=1440
// July 1st, 2021, 00:00 in CEST
const startOfNewEUOSSVATRateTimeStamp = new Date(Date.UTC(2021, 5, 30, 22)).getTime()

const newEUOSSHasStarted = () => {
	const currentTimeStamp = new Date(Date.now()).getTime()

	return currentTimeStamp >= startOfNewEUOSSVATRateTimeStamp
}

const getPrivateCustomerTaxRate = (
	shippingOriginationCountryCode: CountryCode,
	shippingDestinationCountryCode: CountryCode,
) => {
	const taxRates = getVATRates()

	if (newEUOSSHasStarted()) {
		switch (shippingDestinationCountryCode) {
			case "CH":
			case "GB":
			case "LI":
			case "NO":
				return 0
			default:
				return taxRates?.[shippingDestinationCountryCode] ?? 0
		}
	}

	switch (shippingOriginationCountryCode) {
		case "AT":
			switch (shippingDestinationCountryCode) {
				case "AT":
					return taxRates.AT
				case "DE":
					return taxRates.DE
				case "CH":
				case "GB":
				case "LI":
				case "NO":
					return 0
				default:
					return taxRates.AT
			}

		case "DE":
			switch (shippingDestinationCountryCode) {
				case "AT":
					return taxRates.AT
				case "CH":
				case "GB":
				case "LI":
				case "NO":
					return 0
				default:
					return taxRates.DE
			}

		default:
			switch (shippingDestinationCountryCode) {
				case "DE":
					return taxRates.DE
				case "CH":
				case "GB":
				case "LI":
				case "NO":
					return 0
				default:
					return taxRates.AT
			}
	}
}

export default getPrivateCustomerTaxRate
