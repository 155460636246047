import { DeliveryOption } from "@secureo/common/typings/ProductAttributes"
import { DispatchProductDetailPageReducer, ProductDetailPageReducerState } from "../.."
import getMsrpPricePortions from "../../utils/getMsrpPricePortions"
import getPricePortions from "../../utils/getPricePortions"
import getAdditionalDeliveryTimeWorkDays from "../../utils/getTotalDeliveryTimeWorkDays"

type SetDeliveryOptionPayload = Pick<
	ProductDetailPageReducerState,
	// Price
	| "singleNetPriceCents"
	| "singleGrossPriceCents"
	| "totalNetPriceCents"
	| "totalGrossPriceCents"
	| "pricePortions"
	| "singleNetMsrpPriceCents"
	| "singleGrossMsrpPriceCents"
	| "totalNetMsrpPriceCents"
	| "totalGrossMsrpPriceCents"
	| "msrpPricePortions"
	| "selectedDeliveryOption"
	| "additionalDeliveryTimeWorkDays"
>

export const setDeliveryOption = (
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	deliveryOption: DeliveryOption,
) => {
	const {
		variantsByVariantId,
		variantId,
		attributes,
		taxRate,
		countryCode,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
		configurationsByConfigurationGroup,
		selectedSafeLockConfigurationProduct,
		quantity,
	} = reducerState

	const { sku, netPriceEuroCents, deliveryTimeWorkDaysByShopCountryCode } = deliveryOption

	const deliveryTimeWorkDays = deliveryTimeWorkDaysByShopCountryCode?.[countryCode] ?? 0

	const selectedDeliveryOption: ProductDetailPageReducerState["selectedDeliveryOption"] = {
		sku,
		singleNetPriceCents: netPriceEuroCents,
		singleNetMsrpPriceCents: netPriceEuroCents,
		deliveryTimeWorkDays,
	}

	const {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
	} = getPricePortions(
		variantsByVariantId,
		variantId,
		taxRate,
		quantity,
		countryCode,
		configurationsByConfigurationGroup,
		cylinderConfigurationVariant,
		cylinderLockingMechanismProducts,
		selectedCylinderLockingMechanism,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	)

	const {
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
	} = getMsrpPricePortions(
		quantity,
		taxRate,
		countryCode,
		attributes,
		configurationsByConfigurationGroup,
		pricePortions.cylinderLockingMechanismProductSingleNetPriceCents,
		pricePortions.cylinderLockingMechanismProductSingleGrossPriceCents,
		selectedSafeLockConfigurationProduct,
		selectedDeliveryOption,
	)

	const additionalDeliveryTimeWorkDays = getAdditionalDeliveryTimeWorkDays({
		configurationsByConfigurationGroup,
		selectedDeliveryOption,
	})

	const payload: SetDeliveryOptionPayload = {
		singleNetPriceCents,
		singleGrossPriceCents,
		totalNetPriceCents,
		totalGrossPriceCents,
		pricePortions,
		singleNetMsrpPriceCents,
		singleGrossMsrpPriceCents,
		totalNetMsrpPriceCents,
		totalGrossMsrpPriceCents,
		msrpPricePortions,
		selectedDeliveryOption,
		additionalDeliveryTimeWorkDays,
	}

	dispatch({
		type: "SET_DELIVERY_OPTION",
		payload,
	})
}
