interface LocalizedAttribute {
	[locale: string]: any
}

const fallBackLocales = ["en", "de"]

const getValueFromLocalizedAttribute = (localizedAttribute: LocalizedAttribute, locale: string) => {
	if (localizedAttribute?.[locale]) return localizedAttribute[locale]

	for (const fallBackLocale of fallBackLocales) {
		if (localizedAttribute?.[fallBackLocale]) return localizedAttribute[fallBackLocale]
	}

	return null
}

export default getValueFromLocalizedAttribute
