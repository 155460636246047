import { PasswordValidationErrors } from "../../hooks/usePasswordPolicy"

const validatePassword = (
	newPassword: string,
	passwordValidationErrors: PasswordValidationErrors,
) => {
	let validationError = null

	// New Password
	if (passwordValidationErrors.minPasswordLength) validationError = "minPasswordLength"
	if (passwordValidationErrors.maxPasswordLength) validationError = "maxPasswordLength"
	if (passwordValidationErrors.minNumbers) validationError = "minNumbers"
	if (passwordValidationErrors.minSpecialChars) validationError = "minSpecialChars"
	if (!newPassword) validationError = "required"

	return validationError
}

export default validatePassword
