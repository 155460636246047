import { useState } from "react"
import { Theme, Box, Snackbar } from "@mui/material"
import { makeStyles } from "makeStyles"
import IconButton from "@mui/material/IconButton"
import Icon from "@mdi/react"
import { mdiCookie, mdiChevronDoubleRight } from "@mdi/js"
import { AiOutlineClose } from "react-icons/ai"

import Link from "components/i18n/Link"

import useTranslation from "hooks/useTranslation"

const useStyles = makeStyles()((theme: Theme) => ({
	close: {
		padding: theme.spacing(0.5),
	},
}))

const CookieSnackbar = ({ cookieBannerClickHandler, showCookieBanner }: Props) => {
	const { classes } = useStyles()
	const [open, setOpen] = useState(true)
	const { t } = useTranslation("common")

	const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
		if (reason === "clickaway") {
			return
		}

		setOpen(false)
	}
	if (!showCookieBanner) return null

	return (
		<div>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={open}
				onClose={handleClose}
				ContentProps={{
					"aria-describedby": "message-id",
				}}
				message={
					<div style={{ display: "flex", alignItems: "center" }}>
						<Icon path={mdiCookie} size={1.5} color="#f7fafa" />
						<Box ml={1}>
							<div id="message-id" style={{ maxWidth: "370px" }}>
								{t("cookieConsentMessage")} <br />
								<Link
									href="/privacy-policy"
									style={{
										color: "#7abfac",
										display: "flex",
										alignItems: "center",
									}}
								>
									<Icon path={mdiChevronDoubleRight} color="#7abfac" size={0.8} />{" "}
									{t("additionalInformation")}
								</Link>
							</div>
						</Box>
					</div>
				}
				action={[
					<IconButton
						key="close"
						aria-label="close"
						color="inherit"
						className={classes.close}
						onClick={cookieBannerClickHandler}
						size="large"
					>
						<AiOutlineClose size="1.2rem" />
					</IconButton>,
				]}
			/>
		</div>
	)
}

interface Props {
	showCookieBanner: boolean
	cookieBannerClickHandler: any
}

export default CookieSnackbar
