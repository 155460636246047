import useTranslation from "./useTranslation"

import countries, { Country } from "config/countries"
import { shopCountryCodes } from "utils/i18n/supportedLocalesAndCurrencyCodes"

const sortCountries = (countries: Country[]) =>
	countries.sort((a, b) => a.name.localeCompare(b.name))

const useTranslatedCountries = () => {
	const { t } = useTranslation("countries")

	const translatedCountries = countries.map(({ countryCode }) => ({
		name: t(countryCode),
		countryCode,
	}))

	const sortedTranslatedCountries = sortCountries(translatedCountries)

	return sortedTranslatedCountries
}

export const useTranslatedShopCountries = () => {
	const { t } = useTranslation("countries")

	const translatedShopCountries = shopCountryCodes.map((countryCode) => ({
		name: t(countryCode),
		countryCode,
	}))

	const sortedTranslatedShopCountries = sortCountries(translatedShopCountries)

	return sortedTranslatedShopCountries
}

export default useTranslatedCountries
