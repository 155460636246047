import {
	ProductAttributes,
	CylinderConfigurationVariant,
	CylinderLockingMechanism,
	CylinderLockingMechanismProduct,
	ProductVariantAsset,
	SafeLockConfigurationProduct,
	SafeLockConfigurationProductsBySku,
	Product,
} from "@secureo/common/typings/Product"
import { VariantsByVariantId } from "./utils/getVariantsByVariantId"
import { CylinderConfigurationType } from "./utils/getInitialCylinderConfigurations"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import { ShopKey } from "@secureo/common/typings/Shop"
import { CurrencyCode } from "@secureo/common/typings/Forex"

export * from "./actions"

export interface PDPDeliveryOption {
	sku: string
	singleNetPriceCents: number
	singleNetMsrpPriceCents: number
	deliveryTimeWorkDays: number
}

export interface ProductConfiguration {
	productId: string
	variantId: number
	sku: string
	name: string

	// Price
	/**
	 * Quantity per product quantity
	 */
	quantity: number
	singleNetPriceCents: number
	singleNetMsrpPriceCents: number

	// Configuration group
	configurationGroupName: string

	// Delivery time
	deliveryTimeWorkDays: number
}

export interface ProductConfigurationsBySku {
	[sku: string]: ProductConfiguration
}

export interface ProductConfigurationsByConfigurationGroup {
	[configurationGroupName: string]: ProductConfigurationsBySku
}

export interface PricePortions {
	productSingleNetPriceCents: number
	productSingleGrossPriceCents: number
	cylinderLockingMechanismProductSingleNetPriceCents: number
	cylinderLockingMechanismProductSingleGrossPriceCents: number
	totalConfigurationsNetPriceCentsPerProductQuantity: number
	totalConfigurationsGrossPriceCentsPerProductQuantity: number
	safeLockConfigurationProductSingleNetPriceCents: number
	safeLockConfigurationProductSingleGrossPriceCents: number
	deliveryOptionSingleNetPriceCents: number
	deliveryOptionSingleGrossPriceCents: number
}

export interface ColorsBySafeLockConfigurationProductSku {
	[safeLockConfigurationProductSku: string]: {
		colorConfigurationHexCode: string
		colorConfigurationName: { [language: string]: string }
		variantId: number
	}[]
}

export interface ProductDetailPageReducerState {
	isLoading: boolean
	isDebug: boolean
	showIntermediateShoppingCart: boolean
	countryCode: CountryCode
	currencyCode: CurrencyCode
	locale: string
	slug: string
	productType: Product["productType"]
	shopKey: ShopKey

	productId: string
	variantId: number
	name: string
	shortDescription: string
	description: string
	variantsByVariantId: VariantsByVariantId
	images: string[]
	assets: ProductVariantAsset[]
	attributes: ProductAttributes
	configurationsByConfigurationGroup: ProductConfigurationsByConfigurationGroup
	additionalDeliveryTimeWorkDays: number

	// Price
	quantity: number
	taxRate: number
	singleNetPriceCents: number
	singleGrossPriceCents: number
	totalNetPriceCents: number
	totalGrossPriceCents: number
	pricePortions: PricePortions

	// Msrp price
	singleNetMsrpPriceCents: number
	singleGrossMsrpPriceCents: number
	totalNetMsrpPriceCents: number
	totalGrossMsrpPriceCents: number
	msrpPricePortions: PricePortions

	// Cylinders
	cylinderConfigurationVariant?: CylinderConfigurationVariant
	cylinderLockingMechanismProducts?: {
		single: CylinderLockingMechanismProduct
		simultaneous: CylinderLockingMechanismProduct
	}
	selectedCylinderLockingMechanism?: {
		mechanism: CylinderLockingMechanism
		productId: string
		variantId: number
		sku: string
	}
	cylinderConfigurationType?: CylinderConfigurationType
	selectedSystem?: string
	selectedOuterLength?: number
	selectedInnerLength?: number

	// Safes
	selectedSafeLockConfigurationProduct?: SafeLockConfigurationProduct
	safeLockConfigurationProductsBySku?: SafeLockConfigurationProductsBySku
	selectedDeliveryOption?: PDPDeliveryOption
	colorsBySafeLockConfigurationProductSku?: ColorsBySafeLockConfigurationProductSku
}

interface ProductDetailPageReducerAction {
	type:
		| "RESET_STATE"
		| "SET_IS_LOADING"
		| "SET_PRODUCT_QUANTITY"
		| "SET_CONFIGURATIONS"
		| "SET_CYLINDER_CONFIGURATIONS"
		| "SET_CYLINDER_CONFIGURATION_VARIANT"
		| "SET_CYLINDER_LOCKING_MECHANISM"
		| "SET_VARIANT"
		| "SET_DELIVERY_OPTION"
		| "TOGGLE_INTERMEDIATE_SHOPPING_CART"
	payload?: any
}

export type DispatchProductDetailPageReducer = (action: ProductDetailPageReducerAction) => void

const reducer = (state: ProductDetailPageReducerState, action: ProductDetailPageReducerAction) => {
	const { type, payload } = action

	if (state.isDebug) console.info(action)

	switch (type) {
		case "RESET_STATE":
			return payload

		case "SET_IS_LOADING":
			return {
				...state,
				isLoading: payload,
			}

		case "SET_CYLINDER_CONFIGURATIONS":
			return {
				...state,
				selectedSystem: payload?.selectedSystem ?? state.selectedSystem,
				selectedOuterLength: payload?.selectedOuterLength ?? state.selectedOuterLength,
				selectedInnerLength: payload?.selectedInnerLength ?? state.selectedInnerLength,
			}

		case "SET_PRODUCT_QUANTITY":
		case "SET_VARIANT":
		case "SET_CYLINDER_CONFIGURATION_VARIANT":
		case "SET_CYLINDER_LOCKING_MECHANISM":
		case "SET_CONFIGURATIONS":
		case "SET_DELIVERY_OPTION":
			return {
				...state,
				...payload,
			}

		case "TOGGLE_INTERMEDIATE_SHOPPING_CART":
			return { ...state, showIntermediateShoppingCart: !state.showIntermediateShoppingCart }

		default:
			return state
	}
}

const printSeparator = () => {
	console.info("=".repeat(30))
}

const debuggableReducer = (
	state: ProductDetailPageReducerState,
	action: ProductDetailPageReducerAction,
) => {
	if (state.isDebug) printSeparator()

	const updatedState = reducer(state, action)

	if (state.isDebug) {
		console.info(updatedState)
		printSeparator()
	}

	return updatedState
}

export default debuggableReducer
