import getCategoryAncestorBreadCrumbs from "../getCategoryAncestorBreadCrumbs"
import {
	getLocalizedName,
	getLocalizedMetaTitle,
	getLocalizedMetaDescription,
} from "../getLocalizedMetaData"
import getRawAttributeByName from "../getRawAttributeByName"

import { RawCategory } from "../graphql/queries/fetchCategory"
import { Category, ChildCategory } from "@secureo/common/typings/Category"
import { LocalizedString } from "@secureo/common/typings/i18n"
import { ShopKey } from "@secureo/common/typings/Shop"

const formatChildCategory = (
	childCategory: RawCategory,
	locale: string,
	shopKey: ShopKey = "DZN",
) => {
	const { slug } = childCategory

	const name = getLocalizedName(childCategory, locale)

	const formattedChildCategory: ChildCategory = {
		slug: normalizeCategorySlug(slug, shopKey),
		name,
	}

	return formattedChildCategory
}

const getSlugWithoutPrefix = (slug: string, shopKey: ShopKey) => {
	switch (shopKey) {
		case "DZN":
			return slug.replace("dzn-", "")
		case "TRE":
			return slug.replace("tre-", "")
		case "SEC":
			return slug.replace("sec-", "")
		default:
			return slug
	}
}

export const normalizeCategorySlug = (slug: string, shopKey: ShopKey = "DZN") => {
	const slugWithoutPrefix = getSlugWithoutPrefix(slug, shopKey)

	// Remove locking system prefix
	return slugWithoutPrefix.startsWith("sa-")
		? slugWithoutPrefix.replace("sa-", "")
		: slugWithoutPrefix
}

export const getLocalizedLongSEOText = (category: RawCategory, locale: string) => {
	const { custom } = category

	const longSeoText: LocalizedString =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "longSeoText")?.value ?? {}

	let description = ""

	switch (locale.toLowerCase()) {
		case "de-at":
			description = longSeoText?.["de-AT"] ?? ""
			break
		case "de-ch":
			description = longSeoText?.["de-CH"] ?? ""
			break
	}

	if (!description) return longSeoText?.de ?? ""
	return description
}

export const getLocalizedShortSEOText = (category: RawCategory, locale: string) => {
	const { custom } = category

	const shortSeoText: LocalizedString =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "shortSeoText")?.value ?? {}

	let description = ""

	switch (locale.toLowerCase()) {
		case "de-at":
			description = shortSeoText?.["de-AT"] ?? ""
			break
		case "de-ch":
			description = shortSeoText?.["de-CH"] ?? ""
			break
	}

	if (!description) return shortSeoText?.de ?? ""
	return description
}

const formatCategory = (category: RawCategory, locale: string, shopKey: ShopKey = "DZN") => {
	const { id, slug, children = [], custom } = category

	const normalizedSlug = normalizeCategorySlug(slug, shopKey)

	const name = getLocalizedName(category, locale)
	const description = getLocalizedLongSEOText(category, locale)
	const shortDescription = getLocalizedShortSEOText(category, locale)
	const metaTitle = getLocalizedMetaTitle(category, locale)
	const metaDescription = getLocalizedMetaDescription(category, locale)

	const icon = getRawAttributeByName(custom?.customFieldsRaw ?? [], "icon")?.value ?? null
	const iconDark = getRawAttributeByName(custom?.customFieldsRaw ?? [], "iconDark")?.value ?? null

	// Landing page categories
	const isFilterCategory =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "isFilterCategory")?.value ?? false
	const isBrandCategory =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "isBrandCategory")?.value ?? false
	const referencedCategoryKey =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "referencedCategoryKey")?.value ?? null
	const refinementList =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "refinementList")?.value ?? null

	const categoryImg =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "categoryImg")?.value ?? null
	const securityScore =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "securityScore")?.value ?? null
	const base64PreviewIcons =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "base64PreviewIcons")?.value ?? null
	const previewBulletPoints =
		getRawAttributeByName(custom?.customFieldsRaw ?? [], "previewBulletPoints")?.value ?? null

	const ancestorBreadCrumbs = getCategoryAncestorBreadCrumbs(category, locale, shopKey)

	const hiddenSubCategory = children.find(({ slug }) => slug.includes("alle"))
	const actualChildren = hiddenSubCategory ? hiddenSubCategory?.children ?? [] : children
	const sortedChildren = actualChildren.sort((a, b) => Number(a.orderHint) - Number(b.orderHint))

	const formattedCategory: Category = {
		id,
		slug: normalizedSlug,
		name,
		description,
		shortDescription,
		metaTitle,
		metaDescription,
		icon,
		iconDark,
		children: sortedChildren.map((childCategory) =>
			formatChildCategory(childCategory, locale, shopKey),
		),
		ancestorBreadCrumbs,
	}

	if (isFilterCategory) formattedCategory.isFilterCategory = isFilterCategory
	if (referencedCategoryKey) formattedCategory.referencedCategoryKey = referencedCategoryKey
	if (refinementList) formattedCategory.refinementList = refinementList

	if (categoryImg) formattedCategory.categoryImg = categoryImg
	if (securityScore) formattedCategory.securityScore = securityScore
	if (base64PreviewIcons) formattedCategory.base64PreviewIcons = base64PreviewIcons
	if (previewBulletPoints) formattedCategory.previewBulletPoints = previewBulletPoints
	if (isBrandCategory) formattedCategory.isBrandCategory = isBrandCategory

	return formattedCategory
}

export default formatCategory
