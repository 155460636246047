import { useContext } from "react"
import { NextSeo, DefaultSeo as DefaultNextSeo, NextSeoProps } from "next-seo"
import AppContext from "@secureo/common/context/AppContext/AppContext"

import useTranslation from "hooks/useTranslation"
import getNextSEOProps from "utils/seo/getNextSEOProps"

import { SEOPageType } from "utils/seo/config"

const SEO = ({ pageType, asPath, title, description, openGraph, ...props }: Props) => {
	const { locale } = useTranslation()
	const { language, currencyCode } = useContext(AppContext)
	const nextSEOProps = getNextSEOProps(
		language,
		locale,
		currencyCode,
		asPath,
		pageType,
		title,
		description,
		openGraph,
	)

	return <NextSeo {...nextSEOProps} {...props} />
}

interface Props extends NextSeoProps {
	pageType: SEOPageType
	asPath: string
}

export default SEO

export const DefaultSEO = ({ asPath }: DefaultSEOProps) => {
	const { locale } = useTranslation()
	const { language, currencyCode } = useContext(AppContext)
	const defaultSEOProps = getNextSEOProps(language, locale, currencyCode, asPath)

	return <DefaultNextSeo {...defaultSEOProps} />
}

interface DefaultSEOProps {
	asPath: string
}
