import { escapeGraphQLCustomFieldStringArrayValue } from "@secureo/common/utils/commerceTools/escapeGraphQLCustomFieldValue"

export const encodeMatrixCustomField = (matrix: boolean[][]) => {
	const escapedMatrixCustomField = matrix.map((row, rowIndex) => {
		const escapedRow = `(${rowIndex}) ${row
			.map((isSelected) => (isSelected ? 1 : 0))
			.join("-")}`

		return escapedRow
	})

	return escapeGraphQLCustomFieldStringArrayValue(escapedMatrixCustomField)
}

export const decodeMatrixCustomField = (encodedMatrix: string[]) => {
	const decodedMatrix: boolean[][] = encodedMatrix.map((encodedMatrixRow) => {
		const decodedMatrixRow =
			encodedMatrixRow
				.split(" ")?.[1]
				.split("-")
				?.map((value) => Number(value) === 1) ?? []

		return decodedMatrixRow
	})

	return decodedMatrix
}
