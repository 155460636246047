import {
	GA4AddPaymentInfoEvent,
	GA4AddShippingInfoEvent,
	GA4BeginCheckoutEvent,
	GA4Item,
	GA4MeasurePurchaseEvent,
	GA4PurchaseItem,
	pushEventToDataLayer,
} from "../../components/GoogleTagManager"
import { centsToEuros } from "../forex/convertCurrencyFraction"

import { getPaymentMethodsByKey } from "../payments/paymentMethods"

import {
	CheckoutStep,
	EnhancedEcommerceCheckoutStepEvent,
	EnhancedEcommerceCartProductFieldObject,
	EnhancedEcommercePurchaseEvent,
	EnhancedEcommercePurchaseProductFieldObject,
	EnhancedEcommerceCheckoutOptionEvent,
} from "@secureo/common/typings/EnhancedEcommerce"
import { PaymentMethod, PaymentMethodKey } from "@secureo/common/typings/Payment"
import { Cart } from "@secureo/common/typings/Cart"
import { Order } from "@secureo/common/typings/Order"

export const getProductFieldObjectsFromCart = (cart: Cart) => {
	const productFieldObjects = cart?.lineItems?.map((lineItem) => {
		const {
			name,
			masterVariantSku,
			variant: { sku, manufacturer },
			taxedPrice: {
				totalGross: { centAmount: totalGrossCents },
			},
			quantity,
		} = lineItem

		const singleGrossCents = totalGrossCents / quantity
		const singleGrossEuros = centsToEuros(singleGrossCents)

		const productFieldObject: EnhancedEcommerceCartProductFieldObject = {
			name,
			id: masterVariantSku || sku,
			price: singleGrossEuros,
			brand: manufacturer,
			category: "",
			variant: sku,
			quantity,
		}

		return productFieldObject
	})

	return productFieldObjects
}

export const trackCheckoutStep = (
	paymentMethods: PaymentMethod[],
	step: CheckoutStep,
	paymentMethodKey: PaymentMethodKey,
	cart: Cart,
) => {
	const paymentMethodsByKey = getPaymentMethodsByKey(paymentMethods)
	const option = paymentMethodsByKey[paymentMethodKey]?.name?.de ?? paymentMethodKey
	const products = getProductFieldObjectsFromCart(cart)

	const event: EnhancedEcommerceCheckoutStepEvent = {
		event: "checkoutStep",
		ecommerce: {
			checkout: {
				actionField: {
					step,
					option,
				},
				products,
			},
		},
	}

	pushEventToDataLayer(event)
}

export const getGA4ItemsFieldFromCart = (cart: Cart) => {
	const items = cart?.lineItems?.map((lineItem) => {
		const {
			name,
			masterVariantSku,
			variant: { sku, manufacturer },
			taxedPrice: {
				totalGross: { centAmount: totalGrossCents },
			},
			quantity,
		} = lineItem

		const singleGrossCents = totalGrossCents / quantity
		const singleGrossEuros = centsToEuros(singleGrossCents)

		const item: GA4Item = {
			item_name: name,
			item_id: masterVariantSku || sku,
			price: singleGrossEuros,
			item_brand: manufacturer,
			item_category: "",
			item_variant: sku,
			quantity,
		}

		return item
	})

	return items
}

export const trackGA4BeginCheckout = (cart: Cart) => {
	pushEventToDataLayer({ ecommerce: null })
	const items = getGA4ItemsFieldFromCart(cart)

	const event: GA4BeginCheckoutEvent = {
		event: "begin_checkout",
		ecommerce: {
			currency: "EUR",
			value: centsToEuros(cart?.taxedPrice?.totalGross?.centAmount ?? 0),
			items,
		},
	}

	pushEventToDataLayer(event)
}

export const trackGA4AddPaymentInfo = (cart: Cart, paymentMethod: string) => {
	pushEventToDataLayer({ ecommerce: null })
	const items = getGA4ItemsFieldFromCart(cart)

	const event: GA4AddPaymentInfoEvent = {
		event: "add_payment_info",
		ecommerce: {
			currency: "EUR",
			value: centsToEuros(cart?.taxedPrice?.totalGross?.centAmount ?? 0),
			payment_type: paymentMethod,
			items,
		},
	}

	pushEventToDataLayer(event)
}

export const trackGA4AddShippingInfo = (cart: Cart, shippingMethod: string) => {
	pushEventToDataLayer({ ecommerce: null })
	const items = getGA4ItemsFieldFromCart(cart)

	const event: GA4AddShippingInfoEvent = {
		event: "add_shipping_info",
		ecommerce: {
			currency: "EUR",
			value: centsToEuros(cart?.taxedPrice?.totalGross?.centAmount ?? 0),
			shipping_tier: shippingMethod,
			items,
		},
	}

	pushEventToDataLayer(event)
}

export const trackCheckoutOption = (
	paymentMethods: PaymentMethod[],
	step: CheckoutStep,
	paymentMethodKey: PaymentMethodKey,
) => {
	const paymentMethodsByKey = getPaymentMethodsByKey(paymentMethods)
	const option = paymentMethodsByKey[paymentMethodKey]?.name?.de ?? paymentMethodKey

	const event: EnhancedEcommerceCheckoutOptionEvent = {
		event: "checkoutOption",
		ecommerce: {
			checkout_option: {
				actionField: { step, option },
			},
		},
	}

	pushEventToDataLayer(event)
}

export const getProductFieldObjectsFromOrder = (order: Order) => {
	const {
		lineItems,
		//customLineItems
	} = order

	const products = lineItems.map((lineItem) => {
		const {
			name,
			variant: { sku, manufacturer },
			quantity,
			taxedPrice: {
				totalGross: { centAmount: totalGrossCents },
			},
		} = lineItem

		const singleGrossCents = totalGrossCents / quantity
		const singleGrossEuros = centsToEuros(singleGrossCents)

		const product: EnhancedEcommercePurchaseProductFieldObject = {
			id: sku,
			name: name,
			brand: manufacturer,
			category: "",
			price: singleGrossEuros,
			quantity,
		}

		return product
	})

	return products
}

export const trackPurchase = (order: Order) => {
	const {
		id,
		taxedPrice: {
			totalGross: { centAmount: totalGrossCents },
			totalNet: { centAmount: totalNetCents },
		},
		customLineItems,
	} = order

	const products = getProductFieldObjectsFromOrder(order)

	const totalGrossEuros = centsToEuros(totalGrossCents)

	const totalTaxCents = totalGrossCents - totalNetCents
	const totalTaxEuros = centsToEuros(totalTaxCents)

	const shippingAndDeliveryCustomLineItems = customLineItems.filter(
		({ name, slug }) => name.includes("shipping") || slug.includes("delivery"),
	)
	const totalShippingAndDeliveryCostsGrossCents = shippingAndDeliveryCustomLineItems.reduce(
		(totalShippingAndDeliveryCostsGrossCents, { taxedPrice }) =>
			totalShippingAndDeliveryCostsGrossCents + (taxedPrice?.totalGross?.centAmount ?? 0),
		0,
	)
	const totalShippingCostsEuro = centsToEuros(totalShippingAndDeliveryCostsGrossCents)

	const event: EnhancedEcommercePurchaseEvent = {
		event: "purchase",
		ecommerce: {
			purchase: {
				actionField: {
					id,
					revenue: totalGrossEuros,
					tax: totalTaxEuros,
					shipping: totalShippingCostsEuro,
					coupon: "",
				},
				products,
			},
		},
	}

	pushEventToDataLayer(event)
}

const getGA4ItemsFieldFromOrder = (order: Order) => {
	const {
		lineItems,
		//customLineItems
	} = order

	const items = lineItems.map((lineItem) => {
		const {
			name,
			variant: { sku, manufacturer },
			quantity,
			taxedPrice: {
				totalGross: { centAmount: totalGrossCents },
			},
			masterVariantSku,
		} = lineItem

		const singleGrossCents = totalGrossCents / quantity
		const singleGrossEuros = centsToEuros(singleGrossCents)

		const item: GA4PurchaseItem = {
			item_id: masterVariantSku,
			item_name: name,
			item_brand: manufacturer,
			item_variant: sku,
			item_category: "",
			price: singleGrossEuros,
			quantity,
		}

		return item
	})

	return items
}

export const trackPurchaseGA4 = (order: Order) => {
	pushEventToDataLayer({ ecommerce: null })

	const {
		id,
		taxedPrice: {
			totalGross: { centAmount: totalGrossCents },
			totalNet: { centAmount: totalNetCents },
		},
		customLineItems,
	} = order

	const items = getGA4ItemsFieldFromOrder(order)

	const totalGrossEuros = centsToEuros(totalGrossCents)

	const totalTaxCents = totalGrossCents - totalNetCents
	const totalTaxEuros = centsToEuros(totalTaxCents)

	const shippingAndDeliveryCustomLineItems = customLineItems.filter(
		({ name, slug }) => name.includes("shipping") || slug.includes("delivery"),
	)
	const totalShippingAndDeliveryCostsGrossCents = shippingAndDeliveryCustomLineItems.reduce(
		(totalShippingAndDeliveryCostsGrossCents, { taxedPrice }) =>
			totalShippingAndDeliveryCostsGrossCents + (taxedPrice?.totalGross?.centAmount ?? 0),
		0,
	)
	const totalShippingCostsEuro = centsToEuros(totalShippingAndDeliveryCostsGrossCents)

	const event: GA4MeasurePurchaseEvent = {
		event: "purchase_GA4",
		ecommerce: {
			transaction_id: id,
			value: String(totalGrossEuros),
			tax: String(totalTaxEuros),
			shipping: String(totalShippingCostsEuro),
			currency: "EUR",
			items,
		},
	}

	pushEventToDataLayer(event)
}
