import validLocales from "./validLocales"
import { CurrencyCode } from "../../typings/Forex"
import { CountryCode } from "@secureo/common/typings/CountryCode"

export const fallBackLocale = "de-DE"
export const fallbackLanguage = "de"

const ENABLE_POLAND = process.env.NEXT_PUBLIC_ENABLE_POLAND === "true"

export const supportedLanguages = [
	{ language: "de", i18nKey: "german" },
	{ language: "pl", i18nKey: "polish" },
	// { language: "en", i18nKey: "english" },
].filter(({ language }) => ENABLE_POLAND || language !== "pl")

export const supportedLanguageCodes = supportedLanguages.map(({ language }) => language)

// Shop countries
// For all of these countries, prices in commerceTools have to be defined!
export const shopCountryCodes: CountryCode[] = ["AT", "DE", "CH", "PL"].filter(
	(countryCode) => ENABLE_POLAND || countryCode !== "PL",
) as CountryCode[]

export const supportedCurrencyCodes: CurrencyCode[] = [
	"EUR",
	// "PLN",
	// "USD",
	// "GBP",
	// "BTC",
	// "CNY",
	// "CHF",
	// "JPY",
].sort() as CurrencyCode[]

const supportedLocales = supportedLanguages
	.map(({ language }) => shopCountryCodes.map((countryCode) => `${language}-${countryCode}`))
	.flat()
	.sort()

export const supportedValidLocales = supportedLocales.filter((locale) =>
	validLocales.includes(locale),
)

export default supportedLocales
