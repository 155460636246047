import { useState, useEffect } from "react"

import useDebounce from "./useDebounce"

import AnyObject from "../typings/AnyObject"

export interface PasswordValidationErrors {
	minPasswordLength: boolean
	maxPasswordLength: boolean
	minNumbers: boolean
	minSpecialChars: boolean
}

export const defaultMinLength = 8
export const defaultMaxLength = 100
export const defaultMinNumbers = 0
export const defaultMinSpecialChars = 0

const getNumberOfIntegers = (string = "") => string.replace(/[^0-9]/g, "").length

const getNumberOfSpecialChars = (string = "") => string.replace(/[a-zA-Z0-9]/g, "").length

const usePasswordPolicy = (
	password: string,
	passwordPolicy = {
		minLength: defaultMinLength,
		maxLength: defaultMaxLength,
		minNumbers: defaultMinNumbers,
		minSpecialChars: defaultMinSpecialChars,
	},
) => {
	const {
		minLength = defaultMinLength,
		maxLength = defaultMaxLength,
		minNumbers = defaultMinNumbers,
		minSpecialChars = defaultMinSpecialChars,
	} = passwordPolicy

	const debouncedPassword = useDebounce(password)
	const [isValid, setIsValid] = useState(null)
	const [validationErrors, setValidationErrors] = useState({})

	useEffect(() => {
		let didCancel = false
		const errors: AnyObject = {}
		if (debouncedPassword.length < minLength) errors.minPasswordLength = true
		if (debouncedPassword.length > maxLength) errors.maxPasswordLength = true

		if (getNumberOfIntegers(debouncedPassword) < minNumbers) errors.minNumbers = true

		if (getNumberOfSpecialChars(debouncedPassword) < minSpecialChars)
			errors.minSpecialChars = true

		if (!didCancel) {
			setIsValid(Object.keys(errors).length === 0)
			setValidationErrors(errors)
		}

		return () => {
			didCancel = true
		}
	}, [debouncedPassword, minLength, maxLength, minNumbers, minSpecialChars])

	return [isValid, validationErrors] as [boolean, PasswordValidationErrors]
}

export default usePasswordPolicy
