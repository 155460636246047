// 💎💎💎💎💎💎💎💎💎💎💎💎💎💎

import { SEOPageAttributes } from "../../typings/SEO"

// Define all pageTypes here
export type SEOPageType =
	| "about"
	| "account"
	| "accountAddresses"
	| "accountSecurity"
	| "accountOrders"
	| "accountNewsletter"
	| "accountSettings"
	| "blogPost"
	| "brands"
	| "cart"
	| "checkout"
	| "confirmEmail"
	| "contact"
	| "error"
	| "errorNotFound"
	| "home"
	| "imprint"
	| "logout"
	| "privacyPolicy"
	| "resetPassword"
	| "search"
	| "productDetail"
	| "category"

// Import all page configurations here
import getFallback from "./pages/fallback"
import account from "./pages/account"
import imprint from "./pages/imprint"
import privacyPolicy from "./pages/privacyPolicy"
import search from "./pages/search"
import { CurrencyCode } from "@secureo/common/typings/Forex"

// Add a case for every page here
const getSEOConfig = (
	locale: string,
	currencyCode: CurrencyCode,
	pageType?: SEOPageType,
): SEOPageAttributes => {
	switch (pageType) {
		case "account":
		case "accountAddresses":
		case "accountSecurity":
		case "accountOrders":
		case "accountNewsletter":
			return account
		case "imprint":
			return imprint
		case "privacyPolicy":
			return privacyPolicy
		case "search":
			return search
		default:
			return getFallback(locale, currencyCode)
	}
}

export default getSEOConfig
