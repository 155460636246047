import setIsLoading from "@secureo/common/reducers/productDetailPageReducer/utils/setIsLoading"
import { getAvailableLengthCombinationsForSystem } from "@secureo/common/hooks/cylinderConfigurations/useLengthCombinationsBySystemConfiguration"
import { getAvailableInnerLengthsForOuterLength } from "@secureo/common/hooks/cylinderConfigurations/useInnerLengthsByOuterLengthsConfiguration"
import getCylinderConfigurationVariantSku from "@secureo/common/reducers/productDetailPageReducer/utils/getCylinderConfigurationVariantSku"
import fetchCylinderConfigurationVariant from "@secureo/common/utils/commerceTools/graphql/queries/fetchCylinderConfigurationVariant"
import { setCylinderConfigurationVariant } from "./setCylinderConfigurationVariant"

import {
	DispatchProductDetailPageReducer,
	ProductDetailPageReducerState,
} from "@secureo/common/reducers/productDetailPageReducer"
import {
	InnerLengthsByOuterLengths,
	LengthCombinationsBySystem,
} from "@secureo/common/typings/Product"
import { CylinderConfigurationType } from "@secureo/common/reducers/productDetailPageReducer/utils/getInitialCylinderConfigurations"

const getValidInnerLengthForInnerAndOuterLength = (
	innerLengthsByOuterLengths: InnerLengthsByOuterLengths,
	selectedOuterLength: number,
	selectedInnerLength: number,
) => {
	const availableInnerLengthsForSelectedOuterLength = getAvailableInnerLengthsForOuterLength(
		innerLengthsByOuterLengths,
		selectedOuterLength,
	)

	const isValidSelectedInnerLength =
		availableInnerLengthsForSelectedOuterLength.includes(selectedInnerLength)

	if (!isValidSelectedInnerLength) {
		return availableInnerLengthsForSelectedOuterLength?.[0] ?? null
	}

	return selectedInnerLength
}

const getValidInnerLengthForLengthCombinationsBySystem = (
	lengthCombinationsBySystem: LengthCombinationsBySystem,
	selectedSystem: string,
	selectedOuterLength: number,
	selectedInnerLength: number,
) => {
	const availableLengthCombinationsForSelectedSystem = getAvailableLengthCombinationsForSystem(
		lengthCombinationsBySystem,
		selectedSystem,
	)

	const isValidInnerLength = !!availableLengthCombinationsForSelectedSystem.find(
		(lengthCombination) => {
			return (
				lengthCombination.outerLength === selectedOuterLength &&
				lengthCombination.innerLength === selectedInnerLength
			)
		},
	)

	if (!isValidInnerLength) {
		const availableLengthCombinationsForSelectedOuterLength =
			availableLengthCombinationsForSelectedSystem.filter(
				(lengthCombination) => lengthCombination.outerLength === selectedOuterLength,
			)

		return availableLengthCombinationsForSelectedOuterLength?.[0]?.innerLength ?? null
	}

	return selectedInnerLength
}

export const getSelectedConfigurationsAfterOuterLengthChange = (
	cylinderConfigurationType: CylinderConfigurationType,
	attributes: ProductDetailPageReducerState["attributes"],
	selectedSystem: string,
	selectedOuterLength: number,
	selectedInnerLength: number,
): {
	selectedSystem: string
	selectedOuterLength: number
	selectedInnerLength: number
} => {
	const { innerLengthsByOuterLengths, lengthCombinationsBySystem } = attributes

	switch (cylinderConfigurationType) {
		case "innerLengthsByOuterLengths": {
			const validSelectedInnerLength = getValidInnerLengthForInnerAndOuterLength(
				innerLengthsByOuterLengths,
				selectedOuterLength,
				selectedInnerLength,
			)

			return {
				selectedSystem: null,
				selectedOuterLength,
				selectedInnerLength: validSelectedInnerLength,
			}
		}

		case "lengthCombinationsBySystem": {
			const validSelectedInnerLength = getValidInnerLengthForLengthCombinationsBySystem(
				lengthCombinationsBySystem,
				selectedSystem,
				selectedOuterLength,
				selectedInnerLength,
			)

			return {
				selectedSystem,
				selectedOuterLength,
				selectedInnerLength: validSelectedInnerLength,
			}
		}

		case "outerLengths":
			return {
				selectedSystem: null,
				selectedOuterLength,
				selectedInnerLength: null,
			}

		case "outerLengthsBySystem":
			return {
				selectedSystem,
				selectedOuterLength,
				selectedInnerLength: null,
			}

		default:
			console.error(
				`No action defined for cylinderConfigurationType "${cylinderConfigurationType}"`,
			)
			return null
	}
}

export const setSelectedOuterLength = async (
	accessToken: string,
	dispatch: DispatchProductDetailPageReducer,
	reducerState: ProductDetailPageReducerState,
	selectedOuterLength: number,
) => {
	const { cylinderConfigurationType, attributes, variantsByVariantId, locale } = reducerState

	setIsLoading(dispatch, true)

	const { selectedSystem, selectedInnerLength } = getSelectedConfigurationsAfterOuterLengthChange(
		cylinderConfigurationType,
		attributes,
		reducerState.selectedSystem,
		selectedOuterLength,
		reducerState.selectedInnerLength,
	)

	dispatch({
		type: "SET_CYLINDER_CONFIGURATIONS",
		payload: {
			selectedOuterLength,
			selectedInnerLength,
		},
	})

	const cylinderConfigurationVariantSku = getCylinderConfigurationVariantSku(
		cylinderConfigurationType,
		attributes,
		variantsByVariantId,
		selectedSystem,
		selectedOuterLength,
		selectedInnerLength,
	)
	const cylinderConfigurationVariant = await fetchCylinderConfigurationVariant(
		accessToken,
		cylinderConfigurationVariantSku,
		locale,
	)
	setCylinderConfigurationVariant(dispatch, reducerState, cylinderConfigurationVariant)

	setIsLoading(dispatch, false)
}
