import { ChangeEvent } from "react"
import { LengthCombinationsBySystem } from "@secureo/common/typings/Product"

export const getAvailableLengthCombinationsForSystem = (
	lengthCombinationsBySystem: LengthCombinationsBySystem,
	system: string,
) => {
	const availableLengthCombinationsForSelectedSystem = lengthCombinationsBySystem?.[system] ?? []

	return availableLengthCombinationsForSelectedSystem
}

const useLengthCombinationsBySystemConfiguration = (
	lengthCombinationsBySystem: LengthCombinationsBySystem,
	selectedSystem: string,
	selectedOuterLength: number,
	selectedInnerLength: number,
	onSystemChange: (selectedSystem: string) => void,
	onOuterLengthChange: (selectedOuterLength: number) => void,
	onInnerLengthChange: (selectedInnerLength: number) => void,
) => {
	const availableLengthCombinationsForSelectedSystem = getAvailableLengthCombinationsForSystem(
		lengthCombinationsBySystem,
		selectedSystem,
	)
	const availableOuterLengths = Array.from(
		new Set(availableLengthCombinationsForSelectedSystem.map(({ outerLength }) => outerLength)),
	)
	const availableInnerLengths = Array.from(
		new Set(availableLengthCombinationsForSelectedSystem.map(({ innerLength }) => innerLength)),
	)
	const availableSystems = Object.keys(lengthCombinationsBySystem)

	const systemInputProps = {
		value: selectedSystem,
		onChange: (_: any, selectedSystem: string) => {
			onSystemChange(selectedSystem)
		},
	}

	const outerLengthInputProps = {
		value: selectedOuterLength,
		onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			const selectedOuterLength = Number(event.target.value)
			onOuterLengthChange(selectedOuterLength)
		},
	}

	const innerLengthInputProps = {
		value: selectedInnerLength,
		onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			const selectedInnerLength = Number(event.target.value)
			onInnerLengthChange(selectedInnerLength)
		},
	}

	return {
		systemInputProps,
		outerLengthInputProps,
		innerLengthInputProps,
		availableOuterLengths,
		availableInnerLengths,
		availableSystems,
	}
}

export default useLengthCombinationsBySystemConfiguration
