import { useState, useEffect } from "react"

import Router from "next/router"

const timeOutBeforeSpinner = 500

const useRouteChange = () => {
	const [isChangingRoute, setIsChangingRoute] = useState(false)
	const [isTimedOut, setIsTimedOut] = useState(false)

	useEffect(() => {
		let timeOutId = null

		if (isChangingRoute) {
			timeOutId = setTimeout(() => {
				setIsTimedOut(true)
				window.scrollTo(0, 0)
			}, timeOutBeforeSpinner)
		}

		return () => {
			clearTimeout(timeOutId)
		}
	}, [isChangingRoute])

	const onRouteChangeStart = () => {
		setIsChangingRoute(true)
	}

	const onRouteChangeComplete = () => {
		setIsChangingRoute(false)
		setIsTimedOut(false)
	}

	Router.events.on("routeChangeStart", onRouteChangeStart)
	Router.events.on("routeChangeComplete", onRouteChangeComplete)
	Router.events.on("routeChangeError", onRouteChangeComplete)

	return isTimedOut
}

export default useRouteChange
