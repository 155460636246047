const customLineItemFragment = {
	fragment: /* GraphQL */ `
		fragment customLineItem on CustomLineItem {
			id
			name(locale: "de")
			slug
			quantity
			totalPrice {
				centAmount
			}
			taxRate {
				name
				amount
				includedInPrice
			}
			discountedPricePerQuantity {
				quantity
				discountedPrice {
					includedDiscounts {
						discount {
							name(locale: $locale)
						}
					}
				}
			}
			custom {
				customFieldsRaw {
					name
					value
				}
			}
		}
	`,
	dependencies: ["money"],
}

export default customLineItemFragment
