import formatTaxRate from "./formatTaxRate"
import { grossToNet, netToGross } from "@secureo/common/utils/tax"
import parseDeliveryOptionSlug from "../parseDeliveryOptionSlug"
import getCustomFieldsByName from "../getCustomFieldsByName"
import { normalizeCategorySlug } from "./formatCategory"

import { CustomLineItem as CommerceToolsCustomLineItem } from "@secureo/common/typings/generated/commercetools-graphql"
import { CustomLineItem, TaxedItemPrice } from "@secureo/common/typings/Cart"

const formatCustomLineItem = (customLineItem: CommerceToolsCustomLineItem) => {
	const {
		id,
		name,
		slug = "",
		quantity,
		totalPrice: { centAmount: totalPriceCents },
		taxRate,
		// discountedPricePerQuantity,
		custom,
	} = customLineItem

	const customFieldsByName = getCustomFieldsByName(custom as any)
	const {
		lockingSystemId = null,
		numberOfFreeIncludedKeys = null,
		lockingComponentsUuids = null,
		keyComponentsUuids = null,
		matrix = null,
		customName = null,
		lockingSystemCategoryName = null,
		lockingSystemCategorySlug = null,
		requiresConformityAssessment = false,
	} = customFieldsByName

	const formattedTaxRate = formatTaxRate(taxRate)
	const { includedInPrice = false, rate = 0 } = formattedTaxRate ?? {}

	const totalNetCents = includedInPrice
		? Math.round(grossToNet(totalPriceCents / quantity, rate)) * quantity
		: totalPriceCents

	const totalGrossCents = includedInPrice
		? totalPriceCents
		: Math.round(netToGross(totalPriceCents / quantity, rate)) * quantity

	const taxedPrice: TaxedItemPrice = {
		totalNet: {
			centAmount: totalNetCents,
		},
		totalGross: {
			centAmount: totalGrossCents,
		},
	}
	const isDeliveryOption = slug.includes("delivery")

	const formattedCustomLineItem: CustomLineItem = {
		id,
		name,
		slug,
		quantity,
		taxedPrice,
		taxRate: formattedTaxRate,
		isDeliveryOption,
		lockingSystemId,
		numberOfFreeIncludedKeys,
		lockingComponentsUuids,
		keyComponentsUuids,
		matrix,
		requiresConformityAssessment,
	}

	if (customName) {
		formattedCustomLineItem.customName = customName
	}
	if (lockingSystemCategoryName) {
		formattedCustomLineItem.lockingSystemCategoryName = lockingSystemCategoryName
	}
	if (lockingSystemCategorySlug) {
		formattedCustomLineItem.lockingSystemCategorySlug =
			normalizeCategorySlug(lockingSystemCategorySlug)
	}
	if (isDeliveryOption) {
		const { lineItemId: deliveryOptionForLineItemId, sku: deliveryOptionSku } =
			parseDeliveryOptionSlug(slug)

		formattedCustomLineItem.deliveryOptionSku = deliveryOptionSku
		formattedCustomLineItem.deliveryOptionForLineItemId = deliveryOptionForLineItemId
	}

	return formattedCustomLineItem
}

export default formatCustomLineItem
