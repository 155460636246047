export interface GraphQLError {
	code: string
	message: string
	path: string[]
	locations: { line: number; column: number }[]
	extensions?: {
		code: string
	}
}

const handleGraphQLError = (graphQlErrors: GraphQLError[]) => {
	if (!graphQlErrors) return

	console.error("GraphQL errors:", graphQlErrors)

	const [firstGraphQlError] = graphQlErrors
	throw firstGraphQlError.message
}

export default handleGraphQLError
