import { CountryCode } from "@secureo/common/typings/CountryCode"

const getLanguageAndCountryCodeFromLocale = (
	locale: string,
): { language: string; countryCode: CountryCode } => {
	try {
		const [language, countryCode] = locale.split("-") as [string, CountryCode]

		return {
			language: language.toLowerCase(),
			countryCode: countryCode.toUpperCase() as CountryCode,
		}
	} catch (err) {
		// console.info("Returning fallback query")
		return { language: "de", countryCode: "DE" }
	}
}

export default getLanguageAndCountryCodeFromLocale
