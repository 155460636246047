import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"
import prependFragmentsToQuery from "../prependFragmentsToQuery"
import formatShoppingList from "@secureo/common/utils/commerceTools/formatters/formatShoppingList"

import { ShoppingList } from "@secureo/common/typings/generated/commercetools-graphql"
import { CurrencyCode } from "@secureo/common/typings/Forex"

const fetchMyDefaultShoppingList = async (accessToken: string, currencyCode: CurrencyCode) => {
	const query = prependFragmentsToQuery(
		/* GraphQL */ `
			query myShoppingList {
				me {
					shoppingLists(where: "custom(fields(type=\\"default\\"))", limit: 1) {
						results {
							...shoppingList
						}
					}
				}
			}
		`,
		["shoppingList"],
	)

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query)) as {
		me: {
			shoppingLists: {
				results: ShoppingList[]
			}
		}
	}

	const defaultShoppingList = data?.me?.shoppingLists?.results?.[0] ?? null

	if (!defaultShoppingList) return null

	const formattedShoppingList = formatShoppingList(defaultShoppingList, currencyCode)

	return formattedShoppingList
}

export default fetchMyDefaultShoppingList
