import { v4 as uuid } from "uuid"

import {
	CustomFieldInput,
	MyLineItemDraft,
	ShoppingListLineItemDraft,
} from "@secureo/common/typings/generated/commercetools-graphql"

const getLineItemUuidCustomField = (lineItems: (MyLineItemDraft | ShoppingListLineItemDraft)[]) => {
	if (lineItems.length === 1) return null

	const [{ productId }] = lineItems

	const uuidCustomField: CustomFieldInput = {
		name: "uuid",
		value: `"[${productId}]-${uuid()}"`,
	}

	return uuidCustomField
}

export default getLineItemUuidCustomField
