import { CurrencyCode } from "@secureo/common/typings/Forex"
import { CountryCode } from "@secureo/common/typings/CountryCode"

export const fallBackLocale = "de-DE"
export const fallbackLanguage = "de"

export const supportedLanguages = [
	{ language: "de", i18nKey: "german" },
	// { language: "en", i18nKey: "english" },
]

export const supportedLanguageCodes = supportedLanguages.map(({ language }) => language)

// Shop countries
// For all of these countries, prices in commerceTools have to be defined!
export const shopCountryCodes: CountryCode[] = ["AT", "DE", "CH"]

export const validLocales = ["de-DE", "de-AT", "de-CH"]

export const supportedCurrencyCodes: CurrencyCode[] = [
	"EUR",
	// "USD",
	// "GBP",
	// "BTC",
	// "CNY",
	// "CHF",
	// "JPY",
].sort() as CurrencyCode[]

const supportedLocales = supportedLanguages
	.map(({ language }) => shopCountryCodes.map((countryCode) => `${language}-${countryCode}`))
	.flat()
	.sort()

export const supportedValidLocales = supportedLocales.filter((locale) =>
	validLocales.includes(locale),
)

export default supportedLocales
